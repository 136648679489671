import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Pricelist, PricelistSelect } from "../../types/pricelist.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";

type PricelistState = {
    result: Pricelist[];
    message: string;
    // imessage: string;
    isFetching: boolean;
    isError: boolean;
}
// const initialValues: Pricelist = {
//     id: "",
//     buCode: "",
//     priceDescription: "",
//     priceDate: new Date(),
//     priceLevel: "",
//     conditionType: "",
//     condRecNo: "",
//     priceListData: [],
//     remark: "",
//     documentStatus: "",
// };


const initialStateValues: PricelistState = {
    result: [],
    message: "",
    // imessage:"",
    isFetching: false,
    isError: false,
};


export const PricelistScaleGetData = createAsyncThunk(
    "PricelistScale/getPricelistScale",
    async (pricelistspecial: string, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist[]>(`${server.PRICELIST_URL}/scale/all`+ "?pricelevel=" + pricelistspecial);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const pricelistScaleUpdateDataList = createAsyncThunk(
    "pricelistscale/putpricelistscalelist",
    async ({ formData }: {formData: Pricelist[] }, thunkApi) => {
        try {
            // if(formData.length>1){
            //         let dateFrom = new Date(formData[0].validFrom!);
            //         let dateTo= new Date(formData[0].validTo!);
            //         let dateNow = new Date();
            //         let checkError = false;
            //         for (let i = 0; i < formData.length; i++) {
            //             let idateFrom = new Date(formData[i].validFrom!);
            //             let idateTo= new Date(formData[i].validTo!);

            //             console.log("idateFrom :"+idateFrom)
            //             console.log("idateTo :"+idateTo)
            //             console.log("dateFrom :"+dateFrom)
            //             console.log("dateTo :"+dateTo)

            //             if (dateFrom.setHours(0, 0, 0, 0)  != idateFrom.setHours(0, 0, 0, 0) ) {
            //                 // return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
            //                 formData[i].message="เอกสารที่เลือกวันที่ ValidFrom ไม่ตรงกัน กรุณาตรวจสอบ!";
            //                 checkError =true;
            //             }
            //             if (dateTo.setHours(0, 0, 0, 0)  != idateTo.setHours(0, 0, 0, 0) ) {
            //                 // return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
            //                 formData[i].message= formData[i].message +",เอกสารที่เลือกวันที่ Valid To ไม่ตรงกัน กรุณาตรวจสอบ!";
            //                 checkError =true;
            //             }
            //         }
            //         if(checkError==true){
            //             return thunkApi.rejectWithValue("เอกสารที่เลือกวันที่ไม่ตรงกัน กรุณาตรวจสอบ!");
            //         }
            // }
            const   result = await httpClient.put(`${server.PRICELIST_URL}/list/${formData[0].id!}`, formData);
        //    console.log("status:"+result.data.status)
            if(result.data.status=="OK"){
                let pricelistspecial ='5';
                const result = await httpClient.get<Pricelist[]>(`${server.PRICELIST_URL}/scale/all`+ "?pricelevel=" + pricelistspecial);
                return result;
            }
            return result.data;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const PricelistScaleGetTemplate = createAsyncThunk(
    "pricelistscale/getPricelistScaletemplate",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/scale/template`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
const pricelistScaleSlice = createSlice({
    name: "PricelistScale",
    initialState: initialStateValues,
    reducers: {
        updatePricelistScaleData: (state, action: PayloadAction<Pricelist[]>) => {
            state.result = action.payload!;
            // console.log("state.result.priceListData:" + state.result.priceListData)
        },
        reloadPricelistScaleData: (state, action: PayloadAction<Pricelist[]>) => {
            state.result = action.payload!;
        },
    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(PricelistScaleGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(PricelistScaleGetTemplate.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(PricelistScaleGetTemplate.fulfilled, (state, action: PayloadAction<any>) => {

                // console.log(action!.payload.data);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                // console.log("rows:"+action!.payload.data.length)
                state.result = action!.payload.data;
                // console.log("check",state.result);
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetTemplate.rejected, (state, action: PayloadAction<any>) => {

                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(pricelistScaleUpdateDataList.pending, (state, action) => {
                // state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
                // state.imessage = "";
            })
            .addCase(pricelistScaleUpdateDataList.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log("status :"+ action!.payload.status)
                // console.log("message : " + action!.payload.data.id!)
             
                if (action!.payload.status == "OK") {
                    state.result = action!.payload.data;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                    // state.imessage = action!.payload.status!;
                    // history.go(0);
                    // history.replace("/pricelistscalelist")
                    // window.location.reload();
                    // history.push("/pricelistscalelist");
                }else{
                    
                    state.result = action!.payload.data;
                    state.message = action!.payload.message!;
                    state.isFetching = false;
                    state.isError = true;    
                    // state.imessage = action!.payload.status!;
                }
            })
            .addCase(pricelistScaleUpdateDataList.rejected, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;//action.payload.data;
                state.message = action.payload.message!;
                state.isFetching = false;
                state.isError = true;
                // state.imessage = action!.payload.status!;
            });
    }
})


export const { updatePricelistScaleData,reloadPricelistScaleData} = pricelistScaleSlice.actions;
export const PricelistScaleSelector = (store: RootState) => store.pricelistScaleReducer;
export default pricelistScaleSlice.reducer;