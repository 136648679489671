import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link, useMatch } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import Select ,{ SelectChangeEvent }from '@mui/material/Select';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { roleGetData, roleSelector } from "../../../store/slices/roleSlice";
import { userActionSelector, userGetDataById, userUpdateData } from "../../../store/slices/userActionSlice";
import { User } from "../../../types/user.type";
import Loader from "../../component/Loader";


type UserEditPageProps = {
  //
};


const UserEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const roleReducer = useSelector(roleSelector);
  const userActionReducer = useSelector(userActionSelector);
  const match = useMatch("/user/edit/:id");
  const initialValues: User = { id: "", username: "",password:"", fullname: "", email: "",emailCC: "",approveLevel:1, isAd:true,roleId: null, active: true ,remark:""};
  const [approveLevel, setApproveLevel] = React.useState(1);

  React.useEffect(() => {
    dispatch(roleGetData(""));
  }, [])


  React.useEffect(() => {
    let id = match?.params.id;
    dispatch(userGetDataById(id!));
    // setApproveLevel(Number(userActionReducer.result!.approveLevel!));
  }, [])

  const handleChangeApproveLevel = (event: SelectChangeEvent) => {
    let chkNum = (event.target.value! == null|| event.target.value! ==undefined?1:event.target.value!)
    setApproveLevel(Number(chkNum));
  };

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<User>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Edit User
            </Typography>

            <TextField id="username"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('username')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              helperText={
                errors.username && touched.username
                  ? errors.username
                  : ''
              }
              error={
                errors.username && touched.username
                  ? true
                  : false
              } />

            <TextField id="fullname"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('fullname')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullname}
              helperText={
                errors.fullname && touched.fullname
                  ? errors.fullname
                  : ''
              }
              error={
                errors.fullname && touched.fullname
                  ? true
                  : false
              } />

            <TextField id="email"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('email')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              helperText={
                errors.email && touched.email
                  ? errors.email
                  : ''
              }
              error={
                errors.email && touched.email
                  ? true
                  : false
              } />
            <TextField id="emailCC"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('emailCC_ex')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.emailCC}
              helperText={
                errors.emailCC && touched.emailCC
                  ? errors.emailCC
                  : 'email cc หากมากกว่า 1 คน ให้ใช้ comma กั้น เช่น xxx@btg.com,xxx2@btg.com'
              }
              error={
                errors.emailCC && touched.emailCC
                  ? true
                  : false
              } />

            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="roleId">{t('selectRole')}</InputLabel>
              <Select
                name="roleId"
                value={values.roleId}
                onChange={(event) => { setFieldValue('roleId', event.target.value) }}
                error={
                  errors.roleId && touched.roleId
                    ? true
                    : false
                }
              >
                {(roleReducer.result!.filter((x) => x.active===true)).map((item, index) => (
                  <MenuItem key={item.roleId} value={item.roleId}>
                    {item.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ marginTop: 16 }} fullWidth>
            <InputLabel id="demo-simple-select-autowidth-label">{t('approveLevel')}</InputLabel>
            <Select
            // defaultValue="1"
              labelId="demo-simple-select-autowidth-label"
              id="approveLevel"
              value={values.approveLevel}
              onChange={(event) => { setFieldValue('approveLevel', event.target.value) }}
              // autoWidth
              label={t('approveLevel')}
              error={
                errors.approveLevel && touched.approveLevel
                  ? true
                  : false
              } 
            >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            </Select>

            </FormControl>

            <TextField id="remark"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('remark')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.remark 
                  ? errors.remark
                  : 'ระบุ Remark!'
              }
              error={
                errors.remark 
                  ? true
                  : false
              } />

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {userActionReducer.isError && <Alert severity="error">{userActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={userActionReducer.isFetching}
            >
              {t('btnEdit')}
            </Button>
            <Button component={Link} to="/user" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(inputRequired),
    fullname: Yup.string().required(inputRequired),
    email: Yup.string().email('Invalid email').required(inputRequired),
    roleId: Yup.string().required(inputRequired),
    remark: Yup.string().required(inputRequired),
  });


  if (userActionReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={userActionReducer.result ? userActionReducer.result! : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(userUpdateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default UserEditPage;
