import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Customer } from "../../types/customer.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";

type CustomerState = {
    result: Customer[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: CustomerState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const customerGetData = createAsyncThunk(
    "customer/getCustomers",
    async (iaAd: boolean, thunkApi) => {
        try {
            const result = await httpClient.get<Customer[]>(server.CUSTOMER_URL + "?isad=" + iaAd);
            // console.log(result)
            return result;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const customerSlice = createSlice({
    name: "customer",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(customerGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(customerGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(customerGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = customerSlice.actions;
export const customerSelector = (store: RootState) => store.customerReducer;
export default customerSlice.reducer;