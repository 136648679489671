import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, Radio, InputLabel, Alert, FormHelperText, IconButton, Stack, FormLabel, Grid, FormGroup, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import * as XLSX from "xlsx";
import { DataGridPro, GridColDef, GridRenderCellParams }from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import { readExcelItem } from "../../../utils/excel";
import { Item, ItemData } from "../../../types/items.type";
import { itemsCreateData, itemsActionSelector, itemsImportData } from "../../../store/slices/itemsActionSlice";
import Loader from "../../component/Loader";
import { brandGetData, brandSelector } from "../../../store/slices/brandSlice";
import { color } from "@mui/system";
import { useDebounce } from "@react-hook/debounce";
import { LinearProgress } from "@mui/material";

type ItemCreatePageProps = {
  //
};

const initialValues: Item = { ItemData: [] };



const ItemCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const itemsActionReducer = useSelector(itemsActionSelector)
  const [fileName, setFileName] = React.useState(null);
  const [rowsData, setRowsData] = React.useState<ItemData[]>([]);
  const [pageSize, setPageSize] = React.useState(50);
  const [refreshImport, setRefreshImport] = React.useState<boolean>(false);
  const  brandReducer = useSelector(brandSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search


  React.useEffect(() => {
    dispatch(brandGetData(keywordSearch));
  }, [keywordSearch])
  
  const columns: GridColDef[] = [
    {
      headerName: "",
      field: ".",
      width: 60,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              handleDeleteConfirm(row);
            }}
          >
            <DeleteIcon fontSize="inherit" style={{ color: "#f00c50" }} />
          </IconButton>
        </Stack>
      ),
    },


    { field: "itemCode", headerName: "Article Code", width: 100 },
    { field: "itemName", headerName: "Article Name", width: 200 },
    { field: "uom", headerName: "Sales Unit", width: 100 },
    { field: "ean11", headerName: "EAN/UPC", width: 100 },
    { field: "hpean", headerName: "hpean(M)", width: 150 },
    { field: "taxType", headerName: "TaxCl", width: 150 },
    { field: "matkl", headerName: "Matl Group", width: 150 },
    { field: "scagr", headerName: "Mark Weight", width: 100 },
    { field: "brandId", headerName: "Brand", width: 150 },
    { field: "brandDesc", headerName: "brand Desc", width: 150 },
    { field: "curcy", headerName: "THB", width: 150 },
    { field: "aenkz", headerName: "DIRC", width: 150 },
    { field: "kwdht", headerName: "kwdht", width: 150 },
    // { field: "amountInFinalPrice", headerName: "Amount in Final Price", width: 150 },
    // { field: "priceQTY1", headerName: "PQtyB1 (Scale 1)", width: 150 },
    // { field: "price1", headerName: "PMPrice1 (Scale 1)", width: 150 },
    // { field: "priceQTY2", headerName: "PQtyB2 (Scale 2)", width: 150 },
    // { field: "price2", headerName: "PMPrice2 (Scale 2)", width: 150 },
    // { field: "priceQTY3", headerName: "PQtyB3 (Scale 3)", width: 150 },
    // { field: "price3", headerName: "PMPrice3 (Scale 3)", width: 150 },
    // { field: "priceQTY4", headerName: "PQtyB4 (Scale 4)", width: 150 },
    // { field: "price4", headerName: "PMPrice4 (Scale 4)", width: 150 },
    // { field: "priceQTY5", headerName: "PQtyB5 (Scale 5)", width: 150 },
    // { field: "price5", headerName: "PMPrice5 (Scale 5)", width: 150 },

  ];


  const handleExcelChange = (e: any) => {
    setRefreshImport(true);
    // e.preventDefault();
    // dispatch(brandGetData(""));
    // console.log("Brand:"+ brandReducer.result.length)
    // itemsActionReducer.message ="";
    const files = e.target.files;
    setFileName(files[0].name)

    if (e.target.files) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      dispatch(brandGetData(""));
      // console.log("Brand2:"+ brandReducer.result.length)

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet);
        const ItemData = readExcelItem(jsonData,brandReducer.result);

        setRowsData(ItemData);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    setRefreshImport(false);
  }


  const handleDeleteConfirm = (e: any) => {
    setRowsData(rowsData.filter((row) => row.id !== e.id));
  };


  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<ItemData>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Import Items
            </Typography>

            <FormControl style={{ marginTop: 10, marginBottom: 10 }}>
              <h5 style={{ color: "red" }}>***Please Upload Excel file here</h5>
              <Button variant="contained" component="label">
                Upload
                <input hidden type="file" multiple={false}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleExcelChange.bind(this)}
                  onClick={(event: any) => {
                    event.target.value = null;
                    // itemsActionReducer.isError=false;
                    // itemsActionReducer.message ="";
                  }} />
              </Button>
              <FormHelperText> {fileName}</FormHelperText>
            </FormControl>
            {itemsActionReducer.isFetching && (
                    <LinearProgress />
                )}
                <br></br>
                {/* {refreshImport ===true && (
                    <LinearProgress />
                )}
                <br></br> */}
                
            <DataGridPro
              sx={{ backgroundColor: "white", height: "80vh" }}
              getRowId={(row) => row.id}
              rows={rowsData}
              columns={columns}
              rowsPerPageOptions={[10, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />

            <FormControl style={{ marginTop: 10 }} fullWidth>
              {itemsActionReducer.isError && <Alert severity="error">{itemsActionReducer.message}</Alert>}
            </FormControl>

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={itemsActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/items" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');
  // const validationSchema = Yup.object().shape({
  //   templateName: Yup.string().required(inputRequired),
  //   priceLevel: Yup.string().required(inputRequired),
  // });


  if (itemsActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <>
      <Box>
        <Formik
          // validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            values.ItemData = rowsData;
            //console.log(values);
            dispatch(itemsImportData(values));
            setSubmitting(false);
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default ItemCreatePage;
