import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Pricelist, PricelistData } from "../../types/pricelist.type";
import { Template } from "../../types/template.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";


type PricelistState = {
    result: Pricelist;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialValues: Pricelist = {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    priceLevel: "",
    conditionType: "",
    condRecNo: "",
    priceListData: [],
    remark: "",
    documentStatus: "",
};
const initialStateValues: PricelistState = {
    result: initialValues,
    message: "",
    isFetching: false,
    isError: false,
};


export const PricelistGetDataById = createAsyncThunk(
    "pricelist/getPricelistbyID",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id}`);
            //const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id}`);
            //console.log(result.data);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const priceListGetTemplateDataById = createAsyncThunk(
    "template/priceListGetTemplateDataById",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id}`);
            return result;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const priceListGetTemplateDataByIdCreatePage = createAsyncThunk(
    "template/priceListGetTemplateDataByIdCreatePage",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id}`);
            return result;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const pricelistCreateData = createAsyncThunk(
    "pricelist/postpricelist",
    async ({ formData, selectList }: { formData: Pricelist, selectList: PricelistData[] }, thunkApi) => {
        try {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() );
            // console.log("Time:"+ formData.validFrom + " now:" +currentDate)
            let dateFrom = new Date(formData.validFrom!);
            let dateTo= new Date(formData.validTo!);
            let dateNow = new Date(currentDate);
      
            //** check Date now for normal price */
            // if (dateFrom.setHours(0, 0, 0, 0) <= dateNow.setHours(0, 0, 0, 0)) {
            //     // return thunkApi.rejectWithValue("ValidFrom invalid! This price is effective tomorrow. Not now");
            //     return thunkApi.rejectWithValue("วันที่เริ่มต้นไม่ถูกต้อง! ราคา lv1,lv2 จะมีผลในวันถัดไปเท่านั้น!");
            // }

            

            // const start = new Date();
            // start.setDate(start.getDate())
            // if (formData.validFrom! > start) {
            //     return thunkApi.rejectWithValue("ValidFrom invalid! This price is effective tomorrow.");
            // }
            if (dateTo.setHours(0, 0, 0, 0) < dateFrom.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ช่วงวันที่ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            if (dateFrom.setHours(0, 0, 0, 0) < dateNow.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("วันที่เริ่มไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            if(formData.isPriority != true){
                if (dateFrom.setHours(0, 0, 0, 0) <= dateNow.setHours(0, 0, 0, 0)) {
                    return thunkApi.rejectWithValue("ValidFrom ราคาทั่วไป ไม่สามารถกำหนดวันเริ่มต้นเป็นวันปัจจุบัน! ต้องเริ่ม Day+1 เท่านั้น");
                }
            }
            let priceListData: PricelistData[] = [];

            for (let i = 0; i < formData.priceListData.length; i++) {
                let found = selectList.find((x) => x.id === formData.priceListData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: true,validFrom:dateFrom, validTo:dateTo,price1:formData.priceListData[i].amountInFinalPrice,priceQTY1:1};
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: false,validFrom:dateFrom, validTo:dateTo,price1:formData.priceListData[i].amountInFinalPrice,priceQTY1:1 }
                    priceListData.push(valuesToSend);
                }
            }

            formData.priceListData = priceListData.filter((x)=>x.isSelect===true);;

            const result = await httpClient.post(server.PRICELIST_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const pricelistUpdateData = createAsyncThunk(
    "pricelist/putpricelist",
    // async (formData: Pricelist, thunkApi) => {
    async ({ formData, selectList }: { formData: Pricelist, selectList: PricelistData[] }, thunkApi) => {
        try {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() );

            let dateFrom = new Date(formData.validFrom!);
            let dateTo= new Date(formData.validTo!);
            let dateNow = new Date(currentDate);
            // console.log("date1:"+ dateFrom + " date2:" +dateTo + "now:"+dateNow)
            // if (dateFrom.setHours(0, 0, 0, 0) < dateNow.setHours(0, 0, 0, 0)) {
            //     return thunkApi.rejectWithValue("วันที่สิ้นสุดไม่ถูกต้อง! ราคา lv1,lv2 จะมีผลในวันถัดไปเท่านั้น!");
            // }
            // const start = new Date();
            // start.setDate(start.getDate())
            // if (formData.validFrom! > start) {
            //     return thunkApi.rejectWithValue("ValidFrom invalid! This price is effective tomorrow.");
            // }

            //** check Date now for normal price */
            if(formData.isPriority != true){
                if (dateFrom.setHours(0, 0, 0, 0) <= dateNow.setHours(0, 0, 0, 0)) {
                    return thunkApi.rejectWithValue("ValidFrom ราคาทั่วไป ไม่สามารถกำหนดวันเริ่มต้นเป็นวันปัจจุบัน! ต้องเริ่ม Day+1 เท่านั้น!");
                }
            }


            if (dateTo.setHours(0, 0, 0, 0) < dateFrom.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ช่วงวันที่ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            if (dateFrom.setHours(0, 0, 0, 0) < dateNow.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("วันที่เริ่มไม่ถูกต้อง กรุณาตรวจสอบ!");
            }


            let priceListData: PricelistData[] = [];
            for (let i = 0; i < formData.priceListData.length; i++) {
                let found = selectList.find((x) => x.id === formData.priceListData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: true,validFrom:dateFrom, validTo:dateTo,price1:formData.priceListData[i].amountInFinalPrice,priceQTY1:1 }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: false,validFrom:dateFrom, validTo:dateTo,price1:formData.priceListData[i].amountInFinalPrice,priceQTY1:1}
                    priceListData.push(valuesToSend);
                }
            }

            formData.priceListData = priceListData.filter((x)=>x.isSelect===true);;

            const result = await httpClient.put(`${server.PRICELIST_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const pricelistDeleteData = createAsyncThunk(
    "pricelist/deletepricelist",
     async (id: string, thunkApi) => {
        try {
    
            const resultDel = await httpClient.delete<Pricelist>(`${server.PRICELIST_URL}/${id}`);
            let keyword=""
            const result = await httpClient.get<Pricelist[]>(server.PRICELIST_URL + "?keyword=" + keyword);
            return result.data;
       
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
const pricelistActionSlice = createSlice({
    name: "PricelistAction",
    initialState: initialStateValues,
    reducers: {
        removePricelistData: (state, action: PayloadAction<PricelistData[]>) => {
            state.result.priceListData = action.payload;
        },
        updatePricelistData: (state, action: PayloadAction<PricelistData[]>) => {
            state.result.priceListData = action.payload!;
        },
        clearPricelist: (state, action: PayloadAction) => {
            state.result = initialValues;
        },
        selectPricelist: (state, action: PayloadAction<PricelistData[]>) => {
            action.payload.map((item) => {
                let index = state.result.priceListData.findIndex((x) => x.id === item.id);
                state.result.priceListData[index] = { ...state.result.priceListData[index], isSelect: true };
            })
        },
    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(PricelistGetDataById.pending, (state, action) => {
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(PricelistGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                state.result = action!.payload.data;
                // console.log("check",state.result);
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(pricelistCreateData.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                if (action!.payload.status === "OK") {
                    state.result = initialValues;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                    history.back();
                } else {
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
                }
            })
            .addCase(pricelistCreateData.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(priceListGetTemplateDataById.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(priceListGetTemplateDataById.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data.templateData);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.templateData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                // const templateData: TemplateData[] = JSON.parse(action!.payload.data.templateData) as TemplateData[];
                state.result.priceListData = action!.payload.data.templateData;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;


            })
            .addCase(priceListGetTemplateDataById.rejected, (state, action: PayloadAction<any>) => {

                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(priceListGetTemplateDataByIdCreatePage.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(priceListGetTemplateDataByIdCreatePage.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data.templateData);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.templateData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                // const templateData: TemplateData[] = JSON.parse(action!.payload.data.templateData) as TemplateData[];
                state.result.priceListData = action!.payload.data.templateData;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;


            })
            .addCase(priceListGetTemplateDataByIdCreatePage.rejected, (state, action: PayloadAction<any>) => {

                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(pricelistUpdateData.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                // state.result = action!.payload.data;
                if (action!.payload.status == "OK") {
                    state.result = initialValues;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                    history.back()
                } else {
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
                }
            })
            .addCase(pricelistUpdateData.rejected, (state, action: PayloadAction<any>) => {

                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
            builder
            .addCase(pricelistDeleteData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(pricelistDeleteData.fulfilled, (state, action: PayloadAction<any>) => {
                if (action!.payload.status == "OK") {
                    state.result = initialValues; //
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                    
                }else{
                    
                    state.result = action!.payload.data;
                    state.message = action!.payload.message!;
                    state.isFetching = false;
                    state.isError = true;    
                }
            })
            .addCase(pricelistDeleteData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { clearPricelist, updatePricelistData, removePricelistData, selectPricelist } = pricelistActionSlice.actions;
export const PricelistActionSelector = (store: RootState) => store.pricelistActionReducer;
export default pricelistActionSlice.reducer;