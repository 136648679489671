import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Site } from "../../types/site.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";

type SiteEditState = {
    result: Site | null;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: SiteEditState = {
    result: null,
    message: "",
    isFetching: false,
    isError: false,
};


export const siteGetDataById = createAsyncThunk(
    "site/getSite",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Site>(`${server.SITE_URL}/${id}`);
            return result;
            
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const siteCreateData = createAsyncThunk(
    "site/postSite",
    async (formData: Site, thunkApi) => {
        try {
            const result = await httpClient.post(server.SITE_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const siteUpdateData = createAsyncThunk(
    "site/putSite",
    async (formData: Site, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.SITE_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const siteActionSlice = createSlice({
    name: "siteedit",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(siteGetDataById.pending, (state, action) => {
                state.result = null;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(siteGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(siteGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(siteCreateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(siteCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(siteCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(siteUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(siteUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(siteUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = siteActionSlice.actions;
export const siteActionSelector = (store: RootState) => store.siteActionReducer;
export default siteActionSlice.reducer;