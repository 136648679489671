import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Approve, WorkFlowHistory } from "../../types/approve.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";

type ApproveHistoryState = {
    result: Approve[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: ApproveHistoryState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};

export const ApproveHistoryGetData = createAsyncThunk(
    "approvehistory/getApproveHistory",
    async (keyword: string, thunkApi) => {
        try {
            // APPROVEHISTORY_URL: `pricelists/approve/history`,

            // const result = await httpClient.get<Approve[]>(server.APPROVE_URL + "/history?keyword=" + keyword);
            const result = await httpClient.get<Approve[]>(server.APPROVE_URL + "/historyByValues?keyword=" + keyword);
            // if (type ==0){
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==1 || Number(x.priceLevel)==2)
            //     result.data = selectData
            // }else if(type==1){
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==5 )  
            //     result.data = selectData
            // }else{
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==7 )
            //     result.data = selectData
            // }

            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const ApproveHistoryGetDataByPriceLevel = createAsyncThunk(
    "approvehistory/getApproveHistory",
    async ({ keyword,type}: {keyword: string,type :Number}, thunkApi) => {
        try {
            // APPROVEHISTORY_URL: `pricelists/approve/history`,

            const result = await httpClient.get<Approve[]>(server.APPROVE_URL + "/history?keyword=" + keyword);
            // if (type ==0){
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==1 || Number(x.priceLevel)==2)
            //     result.data = selectData
            // }else if(type==1){
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==5 )  
            //     result.data = selectData
            // }else{
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==7 )
            //     result.data = selectData
            // }

            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const approveHistorySlice = createSlice({
    name: "ApproveHistory",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(ApproveHistoryGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(ApproveHistoryGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(ApproveHistoryGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})

export const { } = approveHistorySlice.actions;
export const ApproveHistorySelector = (store: RootState) => store.approveHistoryReducer;
export default approveHistorySlice.reducer;