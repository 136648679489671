import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { ApprovePriceListByID, ApprovePricelist , ApprovePricelistData} from "../../types/approve.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";

const initialValues: ApprovePricelist = {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    priceLevel: "",
    conditionType: "",
    condRecNo: "",
    priceListData: [],
    priceListOldData: [],
    remark: "",
    approveBy: "",
    wfStep: null,
    wfStepAll: null,
    documentStatus: "",
    isPriority:false,
  };

type ApproveActionState = {
    result: ApprovePricelist;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: ApproveActionState = {
    result: initialValues,
    message: "",
    isFetching: false,
    isError: false,
};


export const approveGetDataById = createAsyncThunk(
    "approve/getPricelistbyID",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<ApprovePricelist>(`${server.PRICELIST_URL}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const approvePriceListById = createAsyncThunk(
    "approve",
    async (formData: ApprovePriceListByID,thunkApi) => {
    try {
            // if (formData.id ==='' || formData.id ===null ||formData.id ===undefined) {
            //     return thunkApi.rejectWithValue("ไม่พบเลขที่เอกสาร กรุณาตรวจสอบ!!");
            // }

            if (formData.comment ==='' || formData.comment ===null ||formData.comment ===undefined) {
                return thunkApi.rejectWithValue("กรุณาระบุ Comment!!");
            }
            const result = await httpClient.post(server.APPROVE_URL, formData)
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const approveActionSlice = createSlice({
    name: "approveaction",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(approveGetDataById.pending, (state, action) => {
                state.result = initialValues;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(approveGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                //console.log(action!.payload.data);
            //     const approvePricelistDataNew: ApprovePricelistData[] = JSON.parse(action!.payload.data.priceListData) as ApprovePricelistData[];
            //    action!.payload.data.priceListData = approvePricelistDataNew;        
                //console.log(approvePricelistDataNew)
                //console.log(approvePricelistDataOld)
                //console.log(action!.payload.data)

                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(approveGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.result = initialValues;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });     

        builder
            .addCase(approvePriceListById.pending, (state, action) => {
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(approvePriceListById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = initialValues;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(approvePriceListById.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = approveActionSlice.actions;
export const ApproveActionSelector = (store: RootState) => store.approveActionReducer;
export default approveActionSlice.reducer;