export class Business {
    id!: string;
    buCode!: string;
    buName!: string;
    conditionType!: string;
    buPriceLevel?: BusinessPriceLevel[] | null;
    active?: boolean;
}

export class BusinessPriceLevel {
    id!: string;
    priceLevelId!: string;
    priceLevelDesc!: string;
    wfStep?: number | 1;
}