// export class Template {
//     id!: string;
//     priceDate!: Date;
//     priceLevel!: string;
//     templateName!: string;
//     templateData?: TemplateData[];
//     active?: boolean;
// }

export interface Template {
    id: string;
    priceDate: Date;
    priceLevel: string;
    templateName: string;
    templateData: TemplateData[];
    active: boolean;
}


export class TemplateData {
    id!: string;
    isSelect?: boolean | false;
    priceLevel!: string;
    itemCode!: string;
    itemName!: string;
    saleUnit!: string;
    ean11!: string;
    hpean!: string;
    taxType!: string;
    matkl!: string;
    
    scagr!:string;
    brandId!: string;
    brandDesc!: string;
    saleOrg!: string;
    distributionChannel!: string;
    priceList!: string;
    siteCode!: string;
    siteGroup!: string;
    vendor!: string;
    validFrom?: Date| null;
    validTo?: Date| null;
    amountInMargin?: number | null;
    amountInFinalPrice?: number | null;
    priceQTY1?: number | null;
    price1?: number | null;
    priceQTY2?: number | null;
    price2?: number | null;
    priceQTY3?: number | null;
    price3?: number | null;
    priceQTY4?: number | null;
    price4?: number | null;
    priceQTY5?: number | null;
    price5?: number | null;

    validFromO?: Date| null;
    validToO?: Date| null;
    amountInMarginO?: number | null;
    amountInFinalPriceO?: number | null;
    priceQTY1O?: number | null;
    price1O?: number | null;
    priceQTY2O?: number | null;
    price2O?: number | null;
    priceQTY3O?: number | null;
    price3O?: number | null;
    priceQTY4O?: number | null;
    price4O?: number | null;
    priceQTY5O?: number | null;
    price5O?: number | null;
    message!: string;
}

