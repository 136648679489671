import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import approveReducer from "./slices/approveSlice";
import approveActionReducer from "./slices/approveActionSlice";
import approveHistoryReducer from "./slices/approveHistorySlice";
import approveWorkFlowHistoryReducer from "./slices/approveWorkFlowHistorySlice";
import businessReducer from "./slices/businessSlice";
import businessActionReducer from "./slices/businessActionsSlice";
import priceLevelReducer from "./slices/priceLevelSlice";
import priceLevelActionReducer from "./slices/priceLevelActionSlice";
import roleReducer from "./slices/roleSlice";
import roleActionReducer from "./slices/roleActionSlice";
import userReducer from "./slices/userSlice";
import userActionReducer from "./slices/userActionSlice";
import loginReducer from "./slices/loginSlice";
import siteGroupReducer from "./slices/siteGroupSlice";
import siteGroupActionReducer from "./slices/siteGroupActionSlice";
import loaderReducer from "./slices/loaderSlice";
import siteReducer from "./slices/siteSlice";
import siteRoleReducer from "./slices/siteRoleSlice";
import siteActionReducer from "./slices/siteActionSlice";
import templateReducer from "./slices/templateSlice";
import templateActionReducer from "./slices/templateActionSlice";
import menuReducer from "./slices/menuSlice";
import menuListReducer from "./slices/menuListSlice";
import rolemenuReducer from "./slices/rolemenuSlice"
import pricelistReducer from "./slices/priceListSlice"
import pricelistActionReducer from "./slices/priceListActionSlice";
import itemsReducer from "./slices/itemsSlice"
import itemsActionReducer from "./slices/itemsActionSlice"
import pricelistScaleReducer from "./slices/priceListScaleSlice"
import pricelistScaleActionReducer from "./slices/priceListScaleActionSlice"
import actualReducer from "./slices/actualSlice"
import customerReducer from "./slices/customerSlice"
import customerActionReducer from "./slices/customerActionSlice"
import pricelistSpecialReducer from "./slices/priceListSpecialSlice"
import pricelistSpecialActionReducer from "./slices/priceListSpecialActionSlice"
import brandReducer from "./slices/brandSlice"
import brandActionReducer from "./slices/brandActionSlice"
import monitorJobHistoryReducer from "./slices/monitorJobHistorySlice"

const reducer = {
    approveReducer,
    approveActionReducer,
    approveHistoryReducer,
    monitorJobHistoryReducer,
    approveWorkFlowHistoryReducer,
    businessReducer,
    businessActionReducer,
    priceLevelReducer,
    priceLevelActionReducer,
    roleReducer,
    roleActionReducer,
    siteGroupReducer,
    siteGroupActionReducer,
    userReducer,
    userActionReducer,
    loginReducer,
    loaderReducer,
    siteReducer,
    siteActionReducer,
    templateReducer,
    templateActionReducer,
    menuReducer,
    menuListReducer,
    rolemenuReducer,
    pricelistReducer,
    pricelistActionReducer,
    itemsReducer,
    itemsActionReducer,
    pricelistScaleReducer,
    pricelistScaleActionReducer,
    pricelistSpecialActionReducer,
    pricelistSpecialReducer,
    siteRoleReducer,
    actualReducer,
    customerReducer,
    customerActionReducer,
    brandReducer,
    brandActionReducer,
    
};

export const store = configureStore({
    reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

    devTools: process.env.NODE_ENV === "development",
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();