import * as React from "react";
import { Checkbox,Autocomplete,Card, CardContent, Typography, CardActions, Button, Box, FormControl, FormControlLabel, Radio, Alert, InputLabel, Select, OutlinedInput, MenuItem, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { SiteGroup } from "../../../types/sitegroup.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { siteGroupActionSelector, siteGroupCreateData } from "../../../store/slices/siteGroupActionSlice";
import { siteGetData, siteSelector } from "../../../store/slices/siteSlice";
import Loader from "../../component/Loader";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip,ListItemText } from '@mui/material';

type SiteGroupCreatePageProps = {
  //
};


const SiteGroupCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const siteGroupActionReducer = useSelector(siteGroupActionSelector);
  const siteReducer = useSelector(siteSelector);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [petInputValue, setPetInputValue] = React.useState("");
  const [selectedSite, setSelectedSite] =  React.useState<any>([]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  React.useEffect(() => {
    dispatch(siteGetData(""));
  }, []);

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<SiteGroup>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4}}>
            <Typography gutterBottom variant="h5">
              Create Site Group
            </Typography>

            <TextField
              name="siteGroupCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('siteGroupCode')}
              value={values.siteGroupCode}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.siteGroupCode && touched.siteGroupCode
                  ? errors.siteGroupCode
                  : ''
              }
              error={
                errors.siteGroupCode && touched.siteGroupCode
                  ? true
                  : false
              } />

            <TextField
              name="siteGroupName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('siteGroupName')}
              value={values.siteGroupName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.siteGroupName && touched.siteGroupName
                  ? errors.siteGroupName
                  : ''
              }
              error={
                errors.siteGroupName && touched.siteGroupName
                  ? true
                  : false
              } />

            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="siteCode">{t('siteCode')}</InputLabel>
              <Select
                multiple
                MenuProps={MenuProps}
                name="siteCode"
            
                value={values.siteCode}
                  
                input={<OutlinedInput id="select-multiple-chip" label="siteCode" />}  
                label={t("siteCode")}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {siteReducer.result.filter(x => x.siteCode !== null && x.siteCode !== '').map((item, index) => (
        
                  <MenuItem key={item.id} value={item.siteCode}>
                  <Checkbox checked={values.siteCode.includes(item.siteCode!)} />
                  <ListItemText primary={item.siteName} />
           
               </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl style={{ marginTop: 16 ,width: '99.9%'}} >
            <Autocomplete
                  multiple
                  id="siteCode1"
                  options={siteReducer.result
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.siteName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.siteName}
                    </li>
                    
                  )}
                  inputValue={petInputValue}
                  onInputChange={(event, newPetInputValue) => {
                    setPetInputValue(newPetInputValue);
                  }}
                  value={selectedSite}
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    // console.log("check select site :" + newValue);
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('siteCode')} placeholder="Search" />
                  )}
                />
              </FormControl>

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {siteGroupActionReducer.isError && <Alert severity="error">{siteGroupActionReducer.message}</Alert>}

          </CardContent>
          <CardActions >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={siteGroupActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/siteGroup" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const initialValues: SiteGroup = { siteGroupCode: "", siteGroupName: "", siteCode: [], active: true };
  const inputRequired = t('inputRequired');

  const validationSchema = Yup.object().shape({
    // siteGroupCode: Yup.string().required(inputRequired),
    siteGroupName: Yup.string().required(inputRequired),
  });


  if (siteGroupActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          
          let selectSite=[]
          for (let iSite of selectedSite) {
            selectSite.push(iSite.siteCode)
          }
          // console.log(selectSite)

          const valuesToSend = {
            ...values,siteCode:selectSite,
          };


          // dispatch(siteGroupCreateData(values));
          dispatch(siteGroupCreateData(valuesToSend));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default SiteGroupCreatePage;
