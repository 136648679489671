export class Pricelist {
    id?: string
    buCode?: string
    priceDescription?: string
    priceDate?: Date
    validFrom?: Date;
    validTo?: Date;
    priceLevel?: string
    conditionType?: string
    condRecNo?: string
    docRef?: string
    site?: any
    priceListData!: PricelistData[]
    remark?: string
    ApproveBy?: string | null
    wfStep?: number | null
    wfStepAll?: number | null
    documentStatus?: string
    sendToPos?: boolean;
    sendToSap?: boolean;
    createdBy?: string;
    message?:string;
    iSelect?:boolean;
    isPriority?:boolean;
    attachment?:string[]
}

export class PricelistData {
    id!: string;
    isSelect?: boolean;
    priceLevel!: string;
    itemCode!: string;
    itemName!: string;
    ean11!: string;
    hpean!: string;
    taxType!: string;
    matkl!: string;

    scagr!:string;
    brandId!: string;
    brandDesc!: string;
    saleUnit!: string;
    saleOrg!: string;
    distributionChannel!: string;
    priceList!: string;
    siteCode!: string;
    siteGroup!: string;
    vendor!: string;
    validFrom?: Date;
    validTo?: Date;
    amountInMargin?: number | null;
    amountInFinalPrice?: number | null;
    priceQTY1?: number | null;
    price1?: number | null;
    priceQTY2?: number | null;
    price2?: number | null;
    priceQTY3?: number | null;
    price3?: number | null;
    priceQTY4?: number | null;
    price4?: number | null;
    priceQTY5?: number | null;
    price5?: number | null;
    validFromO?: Date;
    validToO?: Date;
    amountInMarginO?: number | null;
    amountInFinalPriceO?: number | null;
    priceQTY1O?: number | null;
    price1O?: number | null;
    priceQTY2O?: number | null;
    price2O?: number | null;
    priceQTY3O?: number | null;
    price3O?: number | null;
    priceQTY4O?: number | null;
    price4O?: number | null;
    priceQTY5O?: number | null;
    price5O?: number | null;
    condRec!: string;
    message!: string;
}

export class PricelistDataExportExcel {
    docRef!: string;
    priceLevel!: string;
    itemCode!: string;
    itemName!:string;
    saleUnit!: string;
    siteCode!: string;
    siteGroup!: string;
    // saleOrg!: string;
    // distributionChannel!: string;
    // priceList!: string;
    // vendor!: string;
    // amountInMargin?: number | null;
    amountInFinalPrice?: number | null;
    priceQTY1?: number | null;
    price1?: number | null;
    priceQTY2?: number | null;
    price2?: number | null;
    priceQTY3?: number | null;
    price3?: number | null;
    priceQTY4?: number | null;
    price4?: number | null;
    priceQTY5?: number | null;
    price5?: number | null;

}
export class PricelistDataExportExcelLv7 {
    docRef!: string;
    priceLevel!: string;
    itemCode!: string;
    itemName!:string;
    saleUnit!: string;
    siteCode!: string;
    siteGroup!: string;
    vendor!: string;
    amountInFinalPrice?: number | null;
    // priceQTY1?: number | null;
    // price1?: number | null;
    // priceQTY2?: number | null;
    // price2?: number | null;
    // priceQTY3?: number | null;
    // price3?: number | null;
    // priceQTY4?: number | null;
    // price4?: number | null;
    // priceQTY5?: number | null;
    // price5?: number | null;

}

export class PricelistLog {
    site!:string;
    posMessage!: string;
    sapMessage!: string;
    wfpMessage!: string;

}

export class PricelistSelect {
    id?: string
    buCode?: string
    priceDescription?: string
    priceDate?: Date
    validFrom?: Date;
    validTo?: Date;
    priceLevel?: string
    conditionType?: string
    remark?: string
    approveBy?: string | null
    documentStatus?: string
    sendToPos?: string;
    sendToSap?: string;
    createdBy?: string;
    message?:string;
}
