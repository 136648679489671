import * as React from "react";
import { DataGridPro, GridColDef,GridSelectionModel, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Formik, FormikProps, Form ,ErrorMessage } from "formik";
import { Box,FormControl,Alert, Card,CardActions,Button, CardContent, Chip, Color, Fab, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Add, Check, Clear, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { PricelistScaleGetData, PricelistScaleSelector ,updatePricelistScaleData,pricelistScaleUpdateDataList,reloadPricelistScaleData} from "../../../store/slices/priceListScaleSlice";
import Loader from "../../component/Loader";
import moment from "moment";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import withReactContent from "sweetalert2-react-content";
import { httpClient } from "../../../utils/httpclient";
import Swal from "sweetalert2";
import { PricelistLog } from "../../../types/pricelist.type";
import { server } from "../../../Constants";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import InfoIcon from '@mui/icons-material/Info';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ButtonBackToHome from "../../layouts/ButtonBackToHome";
import {
  PricelistSelect,
  Pricelist,
  PricelistData,
  PricelistDataExportExcel,
} from "../../../types/pricelist.type";
// import{pricelistScaleUpdateDataList} from "../../../store/slices/priceListScaleActionSlice"
import { pricelistDeleteData } from "../../../store/slices/priceListActionSlice";
import DeleteIcon from "@mui/icons-material/Delete";

type PriceListScaletPageProps = {
  //
};
interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}
// type PricelistSelect = {
//   result: Pricelist[];
//   message: string;
//   isFetching: boolean;
//   isError: boolean;
// }

  let initialValues: PricelistSelect= {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    validFrom: new Date(),
    validTo: new Date(),
    priceLevel: "",
    conditionType: "",
    remark: "",
    approveBy: "",
    documentStatus: "",
    sendToPos:  "",
    sendToSap: "",
    createdBy: "",
    message:"",
  };

const PriceListScalePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pricelistScaleReducer = useSelector(PricelistScaleSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const [colorbtn, setColorbtn] = React.useState<Color>();
  const [specialprice, setSpecialprice] = React.useState<string>("5")
  const inputSearch = t('inputSearch');
  const [value, setValue] = React.useState(0);
  const [docstatus, setDocstatus] = React.useState<any>("");
  const [selectedRows, setSelectedRows] = React.useState<Pricelist[]>([]);
  const [selectedRowsId, setSelectedRowsId] = React.useState<PricelistSelect[]>([]);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>();
  const [priceLists, setPriceLists] = React.useState<PricelistSelect[]>([]);
  const [imessage, setiMessages] = React.useState<boolean>(false);
  const [valueDel, setValueDel] = React.useState(0);

  React.useEffect(() => {
    dispatch(PricelistScaleGetData(specialprice));

  }, [keywordSearch])


  const handleDeleteConfirm = (e: any) => {
    // setRowsData(rowsData.filter((row) => row.id !== e.id));
    Swal.fire({
      title: 'คุณต้องการลบเอกสาร :   ',
      text: e.priceDescription! + ' ?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(pricelistDeleteData(e.id!));
        // dispatch(templateGetData(""));
        Swal.fire('ลบเรียบร้อย!', '', 'success')
        // dispatch(reloadTemplateData(templateReducer.result!));
        const newUpdatedRows = pricelistScaleReducer.result!.filter((row) => row.id !== e.id!)
        // setUpdateRow(newUpdatedRows);
        dispatch(reloadPricelistScaleData(newUpdatedRows));
        // dispatch(PricelistGetData(keywordSearch));
      } 
    })
  
  };

  // React.useEffect(() => {
  //   if(pricelistScaleReducer.imessage == "OK"){
  //     refreshPage();
  //   }
  // }, [dispatch])


//   React.useEffect(() => {
//     dispatch(PricelistScaleGetData('5'));
//  }, [dispatch]);
  const handleReprocess = (row: any) => {
    // console.log(row)
  };
  const setStatus = (status:boolean)=>{
    {setiMessages(status!)}
  }
  const handleSave = (docstatus: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
    //   let priceLists: Pricelist[]=[];
    //         for (let i = 0; i < selectedRows.length; i++) {
    //           let itemModel = new Pricelist();
    //           itemModel.id= selectedRows[i].id!
    //           itemModel.buCode= selectedRows[i].buCode!
    //           itemModel.priceDate = selectedRows[i].priceDate!
    //           itemModel.validFrom = selectedRows[i].validFrom!
    //           itemModel.validTo= selectedRows[i].validTo!
    //           itemModel.priceLevel= selectedRows[i].priceLevel!
    //           itemModel.conditionType= selectedRows[i].conditionType!
    //           itemModel.condRecNo= selectedRows[i].condRecNo!
    //           itemModel.docRef= selectedRows[i].docRef!
    //           itemModel.site= selectedRows[i].site!
    //           itemModel.priceListData= selectedRows[i].priceListData!
    //           itemModel.remark= selectedRows[i].remark!
    //           itemModel.approveBy= selectedRows[i].approveBy!
    //           itemModel.wfStep= selectedRows[i].wfStep!
    //           itemModel.wfStepAll= selectedRows[i].wfStepAll!
    //           itemModel.documentStatus= selectedRows[i].documentStatus!
    //           itemModel.sendToPos= selectedRows[i].sendToPos!
    //           itemModel.sendToSap= selectedRows[i].sendToSap!
    //           itemModel.createdBy= selectedRows[i].createdBy!
        
    //           priceLists.push(itemModel);
    //         }
    //         setPriceLists(priceLists)
    //         dispatch(pricelistScaleUpdateDataList({
    //           formData: priceLists}))

    // let alertText ;
    // if (docstatus.documentStatus ==="Draft"){
    //   alertText = "ยืนยันการบันทึก Draft?"
    // }
    // else{
    //   alertText = "ยืนยันการส่ง Approve?"
    // }
    //   Swal.fire({
    //     title: 'Confirm!',
    //     text: alertText,
    //     confirmButtonText: 'Yes',
    //     cancelButtonText: 'No',
    //     showCancelButton: true,
    //   }).then((result) => {
    //     if (result.isConfirmed) {   
    //       console.log("Update List :"+priceLists[0].id!)       
    //       dispatch(pricelistScaleUpdateDataList({
    //         formData: priceLists,
    //   }));
    //   dispatch(PricelistScaleGetData(specialprice));
    //       // Swal.fire('ดำเนินการเรียบร้อย!', '', 'success')
    //     } 
    //   })

    
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setValueDel(newValue);
    dispatch(PricelistScaleGetData(specialprice));
    // dispatch(PricelistGetByStatus({
    //   formData:pricelistReducer.result!,
    //   type:newValue
    // }))
  };

  const refreshPage = () => {
      navigate("/pricelistscalelist")

  }

  const logSwal = withReactContent(Swal)
  const MySwal = withReactContent(Swal);

  const PricelistGetLog = async (row: any) => {
    try {
      const result = await httpClient.get<PricelistLog[]>(`${server.PRICELISTSITE_URL}/${row.id}`);
      // JSON.stringify(result.data)
      logSwal.fire({
        title: <strong>{t("LogEror")}</strong>,
        html: `<table border="1" align="center" class="w3-table-all" style="auto; height:auto;">
            <thead>
                <tr>
                    
                    <th>No</th>
                    <th>Site</th>
                    <th>POS</th>
                    <th>SAP</th>
                    <th>WFP</th>
                </tr>
            </thead>
            <tbody> ${result.data.map((row: PricelistLog, index) => ` <tr>
                    <td>${index + 1}</td>
                    <td>${row.site === null ? "" : row.site}</td>
                    <td>${row.posMessage === null ? "" : row.posMessage}</td>
                    <td>${row.sapMessage === null ? "" : row.sapMessage}</td>
                    <td>${row.wfpMessage === null ? "" : row.wfpMessage}</td>
                  
                </tr> `).join('')} </tbody>
        </table>
        `,
        showClass: {
          popup: 'animate__animated animate__bounceInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        customClass: 'swal-wide',
      })
      // return result;
    } catch (error: any) {
      let err = error.response.data.message ?? error.message

    }
    return PricelistGetLog
  }


  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0, }}>
        <div style={{ height: '60px' }}></div>

        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/pricelistscale/create"
          sx={{ position: "absolute", top: 10, right: 10, }}>
          <Add />
        </Fab>
      </Box>
    );
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3, pl: 0, pr: 0 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const columns: GridColDef[] = [
    {
      headerName: "Edit",
      field: ".",
      width: 80,
      renderHeader: () => (<strong> {t('GridEdit')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              navigate("/pricelistscale/edit/" + row.id);
            }}
          >
           
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      headerName: "Preview",
      field: "preview",
      width: 80,
      renderHeader: () => (<strong> {t('GridPreview')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="preview"
            size="large"
            onClick={() => {
              navigate("/pricelist/preview/" + row.id);
            }}
          >
            <VisibilityIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      headerName: "Delete",
      field: "Delete",
      width: 80,
      renderHeader: () => (<strong> {t('GridDelete')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="Delete"
            size="large"
            disabled={valueDel > 0}
            // hidden={valueDel2}
            // disabled={pricelistReducer.result!.filter((x) => x.documentStatus !=="Draft")}
            onClick={() => {
              handleDeleteConfirm(row);
            }}
          >
            <DeleteIcon fontSize="inherit" style={{ color: "#f00c50" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "priceDescription", width: 250,
      renderHeader: () => (<strong> {t('priceDescription')} </strong>),
    },
    {
      field: "priceDate", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
    },
    // {
    //   field: "conditionType", width: 80,headerAlign: 'center',align: 'center',
    //   renderHeader: () => (<strong> {t('conditionType')} </strong>),
    // },
    {
      field: "remark", width: 200,
      renderHeader: () => (<strong> {t('remark')} </strong>),
    },
    {
      field: "documentStatus", width: 150,
      renderHeader: () => (<strong> {t('documentStatus')} </strong>),
    },
    {
      field: "createdBy", width: 150,
      renderHeader: () => (<strong> {t('createdBy')} </strong>),
    },

    // {
    //   field: "sendToPos", width: 150,
    //   renderHeader: () => (<strong> {t('sendToPos')} </strong>),
    // },
    // {
    //   field: "sendToSap", width: 150, type: 'boolean',
    //   renderHeader: () => (<strong> {t('sendToSap')} </strong>),
    // },

    // {
    //   // headerName: "POS",
    //   renderHeader: () => (<strong> {t('sendToPos')} </strong>),
    //   field: "sendToPos",
    //   type: 'string',
    //   width: 100,headerAlign: 'center',align: 'center',
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     row.sendToPos == "Queue" ? <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
    //       : row.sendToPos == "Successed" ? <Chip className="w-chip" label={row.sendToPos} color="success"></Chip>
    //         : row.sendToPos == "Processing" ? <Chip className="w-chip" label={row.sendToPos} color="info"></Chip>
    //           : row.sendToPos == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToPos} color="error"></Chip>
    //             : <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
    //   ),
    // },
    // {
    //   // headerName: "SAP",
    //   renderHeader: () => (<strong> {t('sendToSap')} </strong>),
    //   field: "sendToSap",
    //   type: 'boolean',
    //   width: 100,headerAlign: 'center',align: 'center',
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     row.sendToSap == "Queue" ? <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
    //       : row.sendToSap == "Successed" ? <Chip className="w-chip" label={row.sendToSap} color="success"></Chip>
    //         : row.sendToSap == "Processing" ? <Chip className="w-chip" label={row.sendToSap} color="info"></Chip>
    //           : row.sendToSap == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToSap} color="error"></Chip>
    //             : <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
    //   ),
    // },
    // {
    //   // headerName: "WFP",
    //   renderHeader: () => (<strong> {t('sendToWFP')} </strong>),
    //   field: "sendToWFP",
    //   type: 'boolean',
    //   width: 100,headerAlign: 'center',align: 'center',
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     row.sendToWFP == "Queue" ? <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
    //       : row.sendToWFP == "Successed" ? <Chip className="w-chip" label={row.sendToWFP} color="success"></Chip>
    //         : row.sendToWFP == "Processing" ? <Chip className="w-chip" label={row.sendToWFP} color="info"></Chip>
    //           : row.sendToWFP == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToWFP} color="error"></Chip>
    //             : <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
    //   ),
    // },
    {
      field: "message",
      headerName: "Message",
      width: 450,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "red",
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
  ];
  const columns2: GridColDef[] = [
    // {
    //   headerName: "Edit",
    //   field: ".",
    //   width: 80,
    //   renderHeader: () => (<strong> {t('GridEdit')} </strong>),
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     <Stack direction="row">
    //       <IconButton
    //         aria-label="edit"
    //         size="large"
    //         onClick={() => {
    //           navigate("/pricelistscale/edit/" + row.id);
    //         }}
    //       >
           
    //         <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
    //       </IconButton>
    //     </Stack>
    //   ),
    // },
    {
      headerName: "Preview",
      field: "preview",
      width: 80,
      renderHeader: () => (<strong> {t('GridPreview')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="preview"
            size="large"
            onClick={() => {
              navigate("/pricelist/preview/" + row.id);
            }}
          >
            <VisibilityIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "priceDescription", width: 250,
      renderHeader: () => (<strong> {t('priceDescription')} </strong>),
    },
    {
      field: "priceDate", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
    },
    // {
    //   field: "conditionType", width: 80,headerAlign: 'center',align: 'center',
    //   renderHeader: () => (<strong> {t('conditionType')} </strong>),
    // },
    {
      field: "remark", width: 200,
      renderHeader: () => (<strong> {t('remark')} </strong>),
    },
    {
      field: "documentStatus", width: 150,
      renderHeader: () => (<strong> {t('documentStatus')} </strong>),
    },
    {
      field: "createdBy", width: 150,
      renderHeader: () => (<strong> {t('createdBy')} </strong>),
    },
    {
      field: "ApproveBy", width: 150,
      renderHeader: () => (<strong> {t('Approver')} </strong>),
    },
    // {
    //   field: "sendToPos", width: 150,
    //   renderHeader: () => (<strong> {t('sendToPos')} </strong>),
    // },
    // {
    //   field: "sendToSap", width: 150, type: 'boolean',
    //   renderHeader: () => (<strong> {t('sendToSap')} </strong>),
    // },

    {
      // headerName: "POS",
      renderHeader: () => (<strong> {t('sendToPos')} </strong>),
      field: "sendToPos",
      type: 'string',
      width: 100,headerAlign: 'center',align: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToPos == "Queue" ? <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
          : row.sendToPos == "Successed" ? <Chip className="w-chip" label={row.sendToPos} color="success"></Chip>
            : row.sendToPos == "Processing" ? <Chip className="w-chip" label={row.sendToPos} color="info"></Chip>
              : row.sendToPos == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToPos} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
      ),
    },
    {
      // headerName: "SAP",
      renderHeader: () => (<strong> {t('sendToSap')} </strong>),
      field: "sendToSap",
      type: 'boolean',
      width: 100,headerAlign: 'center',align: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToSap == "Queue" ? <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
          : row.sendToSap == "Successed" ? <Chip className="w-chip" label={row.sendToSap} color="success"></Chip>
            : row.sendToSap == "Processing" ? <Chip className="w-chip" label={row.sendToSap} color="info"></Chip>
              : row.sendToSap == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToSap} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
      ),
    },
    {
      // headerName: "WFP",
      renderHeader: () => (<strong> {t('sendToWFP')} </strong>),
      field: "sendToWFP",
      type: 'boolean',
      width: 100,headerAlign: 'center',align: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToWFP == "Queue" ? <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
          : row.sendToWFP == "Successed" ? <Chip className="w-chip" label={row.sendToWFP} color="success"></Chip>
            : row.sendToWFP == "Processing" ? <Chip className="w-chip" label={row.sendToWFP} color="info"></Chip>
              : row.sendToWFP == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToWFP} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
      ),
    },
    {
      field: "message",
      headerName: "Message",
      width: 450,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "red",
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
  ];
  const handleCellEditCommit = ({ id, field, value }: any) => {
    //console.log({ id, field, value });
    const newUpdatedRows =
      pricelistScaleReducer.result!.map((row) => {
        //console.log(field)
        // ########################## Fix  level1 to level5 ###################

        if (row.id === id) {
          return { ...row, [field]: value};
        }
        // console.log("row", row)
        return row;
      });
 
    // setUpdateRow(newUpdatedRows);
    dispatch(updatePricelistScaleData(newUpdatedRows));
  };

  const showForm = ({
    isSubmitting,
    submitForm,
    errors,
    handleSubmit,
    resetForm,
  }: FormikProps<Pricelist>) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            PRICE LIST SCALE
          </Typography>

          <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'  }}>
                  <Tabs  value={value} onChange={handleChangeTab} aria-label="PriceList" variant="fullWidth">
                    <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<LibraryAddIcon />} label={t('StatusDraft')} {...a11yProps(0)} />
                    <Tab sx={{  fontSize: 18, fontWeight: 'medium' }}icon={<HowToRegIcon />} label={t('StatusWaitForApprove')}{...a11yProps(1)} />
                    <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<DoneOutlineIcon />} label={t('StatusApproved')} {...a11yProps(2)} />
                  </Tabs>
                </Box>
          <TabPanel value={value} index={0}>
          <DataGridPro
            checkboxSelection
            disableSelectionOnClick={true}
            sx={{ backgroundColor: "white", height: "80vh" }}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: keywordSearchNoDelay,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setKeywordSearch(e.target.value);
                  setKeywordSearchNoDelay(e.target.value);
                },
                clearSearch: () => {
                  setKeywordSearch("");
                  setKeywordSearchNoDelay("");
                },
              },
              
            }}
            // onChange={handleChange}
            onCellEditCommit={handleCellEditCommit}
            getRowId={(row) => row.id}
            // rows={pricelistScaleReducer.result!}
            // pricelistScaleActionReducer.result
            // ? pricelistScaleActionReducer!.result!.priceListData
            // : []
            rows={pricelistScaleReducer.result! ? 
                pricelistScaleReducer.result!.filter((x) => x.documentStatus! ==="Draft"):[]}
            columns={columns}
            // pageSize={50}
            pagination
            rowsPerPageOptions={[10]}
            onSelectionModelChange={(ids) => {
              setSelectionModel(ids);
              const selectedIDs = new Set(ids);
              const selectedRows =
                pricelistScaleReducer.result!.filter(
                  (row) => selectedIDs.has(row.id!)
                );
              setSelectedRows(selectedRows);

              // dispatch(selectPricelist(selectedRows));
            }}
            selectionModel={selectionModel}
            // {...pricelistScaleReducer.result}
          />
                  <FormControl style={{ marginTop: 16, marginBottom: 16 }} fullWidth>
              {pricelistScaleReducer.isError && (
                <Alert severity="error">{pricelistScaleReducer.message}</Alert>
              )}
            </FormControl>
            <CardActions>
              <Button
                variant="contained"
                fullWidth
                color="success"
                // disabled={isSubmitting}
                type="submit"
                // onClick={() =>
                //   handleSave({ documentStatus: "Waiting for Approve" })
                // } 
                onClick={submitForm}
                //  disabled={pricelistScaleReducer.isFetching}
             

              >  
                {" "}
                Send for Approve (List)
              </Button>

              <Button
                component={Link}
                to="/"
                variant="outlined"
                fullWidth
                sx={{ marginLeft: 2 }}
              >
                Cancel
              </Button>

            </CardActions>
               {/* <pre style={{ fontSize: 10 }}>
                    {JSON.stringify(selectedRows, null, 4)}
                </pre> */}
             <FormControl style={{ marginTop: 16, marginBottom: 16 }} fullWidth>
              {/* {pricelistScaleReducer.isError && (
                <Alert severity="error">
                  {pricelistScaleReducer.message}
                </Alert>
                
              )} */}
              
            </FormControl>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <DataGridPro
            // checkboxSelection={true}
            disableSelectionOnClick={true}
            sx={{ backgroundColor: "white", height: "80vh" }}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: keywordSearchNoDelay,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setKeywordSearch(e.target.value);
                  setKeywordSearchNoDelay(e.target.value);
                },
                clearSearch: () => {
                  setKeywordSearch("");
                  setKeywordSearchNoDelay("");
                },
              },
            }}
            getRowId={(row) => row.id}
            // rows={templateReducer.result!}
            rows={pricelistScaleReducer.result! ? 
              pricelistScaleReducer.result!.filter((x) => x.documentStatus! ==="Waiting for Approve"):[]}
            // rows={pricelistScaleReducer.result!.filter((x) => x.documentStatus==="Waiting for Approve")}
            columns={columns2}
            // pageSize={50}
            pagination
            rowsPerPageOptions={[10]}
          
          />
          </TabPanel>              
          <TabPanel value={value} index={2}>
          <DataGridPro
            // checkboxSelection={true}
            disableSelectionOnClick={true}
            sx={{ backgroundColor: "white", height: "80vh" }}
            components={{ Toolbar: QuickSearchToolbar }}
            componentsProps={{
              toolbar: {
                value: keywordSearchNoDelay,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setKeywordSearch(e.target.value);
                  setKeywordSearchNoDelay(e.target.value);
                },
                clearSearch: () => {
                  setKeywordSearch("");
                  setKeywordSearchNoDelay("");
                },
              },
            }}
            getRowId={(row) => row.id}
            // rows={templateReducer.result!}
            rows={pricelistScaleReducer.result! ? 
              pricelistScaleReducer.result!.filter((x) => x.documentStatus! ==="Approved"):[]}
            // rows={pricelistScaleReducer.result!.filter((x) => x.documentStatus==="Approved")}
            columns={columns2}
            // pageSize={50}
            pagination
            rowsPerPageOptions={[10]}
          />
          </TabPanel>
        </Box>

        </CardContent>
      </Card>
  </Form>
  );
};

  if (pricelistScaleReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <>
      <Box>
        <Formik
          // validationSchema={validationSchema} 
          // initialValues={initialValues}
          initialValues={selectedRows}
          onSubmit= {(values, {setSubmitting}) => {
            let alertText ;
            if (docstatus.documentStatus ==="Draft"){
              alertText = "ยืนยันการบันทึก Draft?"
            }
            else{
              alertText = "ยืนยันการส่ง Approve?"
            }
            // console.log("Submit:"+selectedRows.length);
            if ((selectedRows.length === 0)) {
              MySwal.fire({
                title: <p>{t('itemselected')}</p>,
                didOpen: () => {
                  // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                },
              })
            }
            else {
                  Swal.fire({
                    title: 'Confirm!',
                    text: alertText,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true,
                  }).then((result) => {
                    if (result.isConfirmed) {       
                      
                      dispatch(pricelistScaleUpdateDataList({
                        formData: selectedRows,
                  }));
                  setSubmitting(false); 
                  // refreshPage();    
            
                  // dispatch(PricelistScaleGetData(specialprice));

                  // setSubmitting(true);   
                  // console.log("message:"+pricelistScaleReducer.message)
                  // console.log("imessage:"+pricelistScaleReducer.imessage)
                  // console.log("isError:"+pricelistScaleReducer.isError)

                      // Swal.fire('ดำเนินการเรียบร้อย!', '', 'success')
                    } 
                  })
          }}
          }
        >
        {(props: any) => showForm(props)}

        </Formik>
      </Box>
    </>
  );

};

export default PriceListScalePage;
