import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CabinIcon from "@mui/icons-material/Cabin";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ManIcon from "@mui/icons-material/Man";
import LockIcon from "@mui/icons-material/Lock";
import DescriptionIcon from "@mui/icons-material/Description";
import { Link, NavLink } from "react-router-dom";
import { Collapse, PropTypes, Stack, Typography } from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { MenuSelector, GetRoleMenu } from "../../../store/slices/rolemenuSlice";
import { useAppDispatch } from "../../../store/store";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Icon from '@mui/material/Icon';
import { green } from "@mui/material/colors";



const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

type MenuProp = {
  open: boolean;
  onDrawerClose: () => void;
};


export default function Menu({ open, onDrawerClose }: MenuProp) {
  const dispatch = useAppDispatch();
  const rolemenuReducer = useSelector(MenuSelector);
  const theme = useTheme();
  const [opened, setOpened] = React.useState<any>({});


  React.useEffect(() => {
    dispatch(GetRoleMenu());
  }, [])

  const handleClick = (e: any) => {
    // console.log([e]);
    setOpened({ [e]: !opened[e] });
  };


  const handleDrawerClose = () => {
    // setOpen(false);
    onDrawerClose();
  };

  const MyNavLink = React.forwardRef<any, any>((props, ref) => (
    <NavLink
      ref={ref}
      to={props.to}
      className={({ isActive }) =>
        `${props.className} ${isActive ? props.activeClassName : ""}`
      }
    >
      {props.children}
    </NavLink>
  ));

  // if (rolemenuReducer.isFetching) {
  //   return <><Loader /></>
  // }


  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            style={{ height: 30 }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Stack>
      </DrawerHeader>
      <Divider />

      {/* <List>
        <ListItem
          button
          to="/approve"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <TaskAltIcon />
          </ListItemIcon>
          <ListItemText primary="Approve" />
        </ListItem>


        <ListItem
          button
          to="/business"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Business" />
        </ListItem>

        <ListItem
          button
          to="/pricelevel"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Price Level" />
        </ListItem>
        <ListItem
          button
          to="/role"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary="Role" />
        </ListItem>


        <ListItem
          button
          to="/sitegroup"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <CabinIcon />
          </ListItemIcon>
          <ListItemText primary="Site Group" />
        </ListItem>

        <ListItem
          button
          to="/site"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <HomeWorkIcon />
          </ListItemIcon>
          <ListItemText primary="Site" />
        </ListItem>


        <ListItem
          button
          to="/user"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <ManIcon />
          </ListItemIcon>
          <ListItemText primary="User" />
        </ListItem>

        <ListItem
          button
          to="/template"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Template" />
        </ListItem>

        <ListItem
          button
          to="/pricelist"
          component={MyNavLink}
          activeClassName="Mui-selected"
          exact
        >
          <ListItemIcon>
            <CurrencyRubleIcon />
          </ListItemIcon>
          <ListItemText primary="Pricelist" />
        </ListItem>
      </List>
      <Divider /> */}



      <List>
        {rolemenuReducer.result.map((item, index) =>
          <div key={index}>
            <ListItemButton onClick={handleClick.bind(
              opened,
              item.menuname
            )}>
              <Icon sx={{ color: green[500] }}>{item.icon} </Icon>

              <ListItemText primary={item.menuname} sx={{ pl: 1 }} />
              {opened[item.menuname] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
            <Collapse key={item.menuId} in={opened[item.menuname]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.children.map(cilditem =>
                  <ListItemButton sx={{ pl: 4 }}
                    key={cilditem.childrenId}
                    component={Link}
                    to={cilditem.routeName}
                  >
                    <Icon sx={{ color: green[500] }}>{cilditem.icon} </Icon>
                    <ListItemText primary={cilditem.menuname} sx={{ pl: 1 }} />
                  </ListItemButton>
                )}
              </List>
            </Collapse>
          </div>
        )}
      </List>
      <Divider />


    </Drawer>
  );
}
