import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Fab, IconButton, Pagination, Stack, TextField, Typography } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
// import { templateGetData, templateSelector } from "../../../store/slices/templateSlice";
import { itemsGetData, itemsSelector } from "../../../store/slices/itemsSlice";
import moment from "moment";
import Loader from "../../component/Loader";


type Props = {}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}


export default function ItemPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const itemsReducer = useSelector(itemsSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const inputSearch = t('inputSearch');


  React.useEffect(() => {
    dispatch(itemsGetData(keywordSearch));
  }, [keywordSearch])



  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0, }}>
        <div style={{height:'60px'}}></div>

        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/items/create"
          sx={{ position: "absolute", top: 10, right: 10, }}>
          <Add />
        </Fab>
      </Box>
    );
  }

  const columns: GridColDef[] = [
    {
      headerName: "",
      field: ".",
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              navigate("/items/edit/" + row.id);
            }}
          >
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "itemCode", width: 150,
      renderHeader: () => (<strong> {t('itemCode')} </strong>),
    },
    // {
    //   field: "itemName", width: 200,
    //   renderCell: params => moment(params.row.priceDate).format('DD-MM-YYYY'),
    //   renderHeader: () => (<strong> {t('priceDate')} </strong>),
    // },
    {
      field: "itemName", width: 150,
      renderHeader: () => (<strong> {t('itemName')} </strong>),
    },
    {
      field: "uom", width: 100,
      renderHeader: () => (<strong> {t('uom')} </strong>),
    },
    {
      field: "ean11", width: 150,
      renderHeader: () => (<strong> {t('ean11')} </strong>),
    },
    {
      field: "taxType", width: 100,
      renderHeader: () => (<strong> {t('taxType')} </strong>),
    },
    {
      field: "hpean", width: 100,
      renderHeader: () => (<strong> {t('hpean')} </strong>),
    },
    {
      field: "curcy", width: 100,
      renderHeader: () => (<strong> {t('curry')} </strong>),
    },
    {
      field: "matkl", width: 100,
      renderHeader: () => (<strong> {t('matkl')} </strong>),
    },
    {
      field: "aenkz", width: 100,
      renderHeader: () => (<strong> {t('aenkz')} </strong>),
    },
    // {
    //   field: "curry", width: 100,
    //   renderHeader: () => (<strong> {t('curry')} </strong>),
    // },
    {
      field: "scagr", width: 100,
      renderHeader: () => (<strong> {t('scagr')} </strong>),
    },
    {
      field: "kwdht", width: 100,
      renderHeader: () => (<strong> {t('kwdht')} </strong>),
    },
    {
      field: "brandId", width: 100,
      renderHeader: () => (<strong> {t('brandId')} </strong>),
    },
    {
      field: "brandDesc", width: 100,
      renderHeader: () => (<strong> {t('brandDesc')} </strong>),
    },
    {
      field: "active", type: "boolean", width: 100,
      renderHeader: () => (<strong> {t('active')} </strong>),
    },
  ];

  if (itemsReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          ITEMS
        </Typography>
        <DataGridPro
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          rows={itemsReducer.result!}
          pagination
          columns={columns}
          // pageSize={50}
          rowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  )
}

