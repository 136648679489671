import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, Radio, InputLabel, Alert, FormHelperText, IconButton, Stack, FormLabel, Grid, FormGroup, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector } from "react-redux";
import { Template, TemplateData } from "../../../types/template.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { priceLevelGetDataList, priceLevelSelector } from "../../../store/slices/priceLevelSlice";
import * as XLSX from "xlsx";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import uuid from 'react-uuid';
import { readExcelTemplate } from "../../../utils/excel";
import { templateActionSelector, templateCreateData,clearTemplateData ,templateGetDataImport,updateTemplateData} from "../../../store/slices/templateActionSlice";
import Loader from "../../component/Loader";
import ButtonBack from "../../layouts/ButtonBack";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {GridSelectionModel} from "@mui/x-data-grid-pro";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type TemplateCreatePageProps = {
  //
};

let initialValues: Template = { id: "", priceDate: new Date(), priceLevel: "", templateName: "", templateData: [], active: true };


const TemplateCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const priceLevelReducer = useSelector(priceLevelSelector);
  const templateActionReducer = useSelector(templateActionSelector);
  // const [priceLevel, setPriceLevel] = React.useState("");
  const [fileName, setFileName] = React.useState(null);
  const [rowsData, setRowsData] = React.useState<TemplateData[]>([]);
  const [pageSize, setPageSize] = React.useState(50);
  const [selectedRows, setSelectedRows] = React.useState<TemplateData[]>([]);
  const [loadtemplate, setLoadtemplate] = React.useState(true);
  const [selectedScaleRows, setSelectedScaleRows] = React.useState<TemplateData[]>([]);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(templateActionReducer.result.templateData.filter((x) => x.isSelect === true).map((x) => x.id));
  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    // dispatch(clearTemplateData());
    dispatch(priceLevelGetDataList());
  }, [])
  React.useEffect(() => {
    dispatch(clearTemplateData());
  }, []);

  const TemplateGetCheckboxDataById = createAsyncThunk(
    "template/getTemplatebyIDCheck",
    //async (id: string, thunkApi) => {
      async ({ formData }: { formData: Template}, thunkApi) => {
      try {

       const result =  setSelectedScaleRows(formData.templateData.filter((x) => x.isSelect === true))

        return result;
      } catch (error: any) {
        let err = error.response.data.message ?? error.message
        return thunkApi.rejectWithValue(err);
      }
    }
  );

  const columns: GridColDef[] = [
    // {
    //   headerName: "",
    //   field: ".",
    //   width: 60,
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     <Stack direction="row">
    //       <IconButton
    //         aria-label="edit"
    //         size="large"
    //         onClick={() => {
    //           handleDeleteConfirm(row);
    //         }}
    //       >
    //         <DeleteIcon fontSize="inherit" style={{ color: "#f00c50" }} />
    //       </IconButton>
    //     </Stack>
    //   ),
    // },


    { field: "priceLevel", headerName: "Price Level", width: 100 },
    { field: "itemCode", headerName: "Article Code", width: 100 ,   editable: true},
    { field: "itemName", headerName: "Article Name", width: 200 },
    { field: "saleUnit", headerName: "Sales Unit", width: 100 ,   editable: true},
    // { field: "saleOrg", headerName: "Sales Organization", width: 100 },
    // { field: "distributionChannel", headerName: "Distribution Channel", width: 150 },
    // { field: "priceList", headerName: "Price List", width: 150 },
    { field: "siteCode", headerName: "Site", width: 150, editable: true },
    { field: "siteGroup", headerName: "Site Group", width: 150 , editable: true },
    { field: "vendor", headerName: "Vendor", width: 150 ,   editable: true},
    // { field: "validFrom", headerName: "Valid From", width: 150 },
    // { field: "validTo", headerName: "Valid To", width: 150 },
    // { field: "amountInMargin", headerName: "Amount in Margin", width: 150,   editable: true,
    // type: "number", },
    { field: "amountInFinalPrice", headerName: "Amount in Final Price", width: 250 ,   editable: true,type: "number",
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',},
    // { field: "priceQTY1", headerName: "PQtyB1 (Scale 1)", width: 150 },
    // { field: "price1", headerName: "PMPrice1 (Scale 1)", width: 150 },
    { field: "priceQTY2", headerName: "PQtyB2 (Scale 2)", width: 150 ,   editable: true,type: "number",
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',},
    // { field: "price2", headerName: "PMPrice2 (Scale 2)", width: 150 },
    // { field: "priceQTY3", headerName: "PQtyB3 (Scale 3)", width: 150 },
    // { field: "price3", headerName: "PMPrice3 (Scale 3)", width: 150 },
    // { field: "priceQTY4", headerName: "PQtyB4 (Scale 4)", width: 150 },
    // { field: "price4", headerName: "PMPrice4 (Scale 4)", width: 150 },
    // { field: "priceQTY5", headerName: "PQtyB5 (Scale 5)", width: 150 },
    // { field: "price5", headerName: "PMPrice5 (Scale 5)", width: 150 },
    {
      field: "message",
      headerName: "Message",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "red",
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
  ];


  const handleExcelChange = (e: any) => {
    // e.preventDefault();
    const files = e.target.files;
    setFileName(files[0].name)

    if (e.target.files) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet,{header: ['A','B','C','D', 'E', 'F', 'G', 'H', 'I', 'J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X'], blankrows: false });
        const templateData = readExcelTemplate(jsonData);

    
        setRowsData(templateData);
        // const valuesToSend = { ...initialValues, templateData: templateData }
        // // console.log("tempdate:" + templateData)
        dispatch(templateGetDataImport({ formData:templateData}));
        // //templateActionReducer.result.templateData=templateData;
        // console.log("Reducer : "+priceLevelReducer.result )
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }


  const handleDeleteConfirm = (e: any) => {
    dispatch(templateGetDataImport({ formData:templateActionReducer.result.templateData.filter((row) => row.id !== e.id)}));
    // setRowsData(rowsData.filter((row) => row.id !== e.id));
    setLoadtemplate(false);
  };

const handleCellEditCommit = ({ id, field, value }: any) => {
    //console.log({ id, field, value });
    const newUpdatedRows = templateActionReducer.result.templateData!.map(
      (row) => {
        if(typeof value != "number")
        {
          let setValue = value.toUpperCase();
          value = setValue
        }else{
          // console.log("checkNum: false" + value)
          value = Number(Number(value!).toFixed(2))
       
        }
        //console.log(field)
        if (row.id === id) {
          return { ...row, [field]: value,message:''};
          
        }
        return row;
      }
    );
    // setUpdateRow(newUpdatedRows);
    // console.log("check:"+newUpdatedRows!.length)
    // console.log("check2:"+newUpdatedRows!)
    // dispatch(updateTemplateData(newUpdatedRows));
      dispatch(templateGetDataImport({ formData:newUpdatedRows}));
     
  };


  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Template>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create Template
            </Typography>

            <TextField
              id="templateName"
              style={{ marginTop: 16}}
              fullWidth
              variant="outlined"
              label={t("templateName")}
              onChange={handleChange}
              onBlur={handleBlur}
              //  value={values.templateName}
              helperText={
                errors.templateName && touched.templateName
                  ? errors.templateName
                  : ""
              }
              error={
                errors.templateName && touched.templateName
                  ? true
                  : false
              }
              
            />
            {/* <TextField
              id="templateName"
              style={{ marginTop: 16}}
              fullWidth
              variant="outlined"
              label={t("templateName")}
              onChange={handleChange}
              onBlur={handleBlur}
              // value={values.priceDescription}
              helperText={
                errors.templateName && touched.templateName
                  ? errors.templateName
                  : ""
              }
              error={
                errors.templateName && touched.templateName
                  ? true
                  : false
              }
              
            /> */}

            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="priceLevel">{t('select_PriceLevel')}</InputLabel>
              <Select
                name="priceLevel"
                // onChange={(event: SelectChangeEvent) => {
                //   setFieldValue('priceLevel', event.target.value);
                //   setPriceLevel(event.target.value);
                // }}
                // value={values.priceLevel}
                onChange={(event) => { setFieldValue('priceLevel', event.target.value) }}
                error={
                  errors.priceLevel && touched.priceLevel
                    ? true
                    : false
                }
              >
                {priceLevelReducer.result.map((item, index) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.priceLevelDesc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            {/* <FormControl style={{ marginTop: 10, marginBottom: 10 }}>
              </FormControl> */}
            <Card>
                <CardActions>        
                  <Button variant="contained" component="label">
                    Upload
                    <input hidden type="file" multiple={false}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={handleExcelChange.bind(this)}
                      onClick={(event: any) => {
                        event.target.value = null
                      }} />
                  </Button>
              <div/>       
                <ButtonBack  ></ButtonBack>
                </CardActions>
                {/* <h5 style={{ color: "red" }}>***{t('createtemplate')}</h5> */}
                {/* <FormHelperText> {fileName}</FormHelperText>  */}
              </Card>
              


            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              {templateReducer.isError && <Alert severity="error">{templateReducer.message}</Alert>}
            </FormControl> */}

            <DataGridPro
            checkboxSelection
              sx={{ backgroundColor: "white", height: "80vh" }}
              getRowId={(row) => row.id}
              // rows={rowsData}
              rows={
                templateActionReducer.result.templateData
                  ? templateActionReducer!.result!.templateData!
                  : rowsData
              }
              columns={columns}
              rowsPerPageOptions={[20,50,100]}
              pageSize={pageSize}
              onCellEditCommit={handleCellEditCommit}
              disableSelectionOnClick={true}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
                const selectedIDs = new Set(ids);
                const selectedRows =
                templateActionReducer.result.templateData!.filter(
                    (row: any) => selectedIDs.has(row.id)
                  );
               
                setSelectedRows(selectedRows);
                // dispatch(selectPricelist(selectedRows));
              }}
              selectionModel={selectionModel}
              onPageSizeChange={(newPageSize) => 
                setPageSize(newPageSize)}
            />

            <FormControl style={{ marginTop: 10 }} fullWidth>
              {templateActionReducer.isError && (
              <Alert severity="error">{templateActionReducer.message}</Alert>)}
            </FormControl>

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={templateActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/template" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required(inputRequired),
    priceLevel: Yup.string().required(inputRequired),
  });


  if (templateActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <>
      <Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            // initialValues = { ...initialValues, templateName: values.templateName, priceLevel: values.priceLevel }
            // values.templateData = templateActionReducer.result.templateData;
          //  values.templateData = rowsData;
           const valuesToSend = {
            ...values,
            templateData :templateActionReducer.result.templateData,
          };
          if( selectedRows.length === 0 ){
                MySwal.fire({
                  title: <p>{t("itemselected")}</p>,
                  didOpen: () => {
                    // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                  },
                });
          }
          else {
                      // console.log(values);
                      dispatch(templateCreateData({
                        formData:valuesToSend,
                        selectList:selectedRows,
                      }));
                      setSubmitting(false);
                    }}
          }

        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default TemplateCreatePage;
