import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { Business, BusinessPriceLevel } from "../../types/business.type";
import { history } from "../../index";
import { PriceLevel } from "../../types/pricelevel.type";
import uuid from "react-uuid";

const initialValues: Business = { id: "", buCode: "", buName: "", conditionType: "", buPriceLevel: [], active: true };

type BusinessEditState = {
    result: Business;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: BusinessEditState = {
    result: initialValues,
    message: "",
    isFetching: false,
    isError: false,
};


export const businessGetDataById = createAsyncThunk(
    "business/getBusiness",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get(`${server.BUSINESS_URL}/${id}`);
            //console.log(result);
            return result;
        } catch (err: any) {
            return thunkApi.rejectWithValue(err.message);
        }
    }
);


export const businessPriceGetData = createAsyncThunk(
    "businessPrice/getPriceLevel",
    async (_, thunkApi) => {
        try {
            const result = await httpClient.get<PriceLevel[]>(server.PRICELEVEL_URL + "?keyword=");

            return result;

        } catch (err: any) {
            return thunkApi.rejectWithValue(err.message);
        }
    }
);



export const businessCreateData = createAsyncThunk(
    "business/postBusiness",
    async (formData: Business, thunkApi) => {
        try {
            const result = await httpClient.post(server.BUSINESS_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const businessUpdateData = createAsyncThunk(
    "business/putBusiness",
    async (formData: Business, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.BUSINESS_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const businessActionSlice = createSlice({
    name: "businessActions",
    initialState: initialStateValues,
    reducers: {
        removeBusinessPriceData: (state, action: PayloadAction<BusinessPriceLevel[]>) => {
            state.result.buPriceLevel = action.payload!;
        },
        updateBusinessPriceData: (state, action: PayloadAction<BusinessPriceLevel[]>) => {
            state.result.buPriceLevel = action.payload!;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(businessGetDataById.pending, (state, action) => {
                state.result = initialValues;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(businessGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                // const priceLevel: BusinessPriceLevel[] = JSON.parse(action!.payload.data.buPriceLevel) as BusinessPriceLevel[];
                // action!.payload.data.buPriceLevel = priceLevel;

                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(businessGetDataById.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(businessPriceGetData.pending, (state, action) => {
                state.result = initialValues;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(businessPriceGetData.fulfilled, (state, action: PayloadAction<any>) => {
                let priceLevel: PriceLevel[] = action!.payload.data as PriceLevel[];
                let businessPriceLevels: BusinessPriceLevel[] = [];
                priceLevel.map((item) => {
                    if (item.active === true) {
                        var businessPriceLevel = new BusinessPriceLevel();

                        businessPriceLevel.id = uuid();
                        businessPriceLevel.priceLevelId = item.id!;
                        businessPriceLevel.priceLevelDesc = item.priceLevelDesc;
                        businessPriceLevel.wfStep = 1;

                        businessPriceLevels.push(businessPriceLevel);
                    }
                })

                const budata = { ...state.result, buPriceLevel: businessPriceLevels }

                state.result = budata;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(businessPriceGetData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });



        builder
            .addCase(businessCreateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(businessCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = initialValues;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(businessCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(businessUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(businessUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                // state.result = action!.payload.data;
                state.result = initialValues;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(businessUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { updateBusinessPriceData } = businessActionSlice.actions;
export const businessActionSelector = (store: RootState) => store.businessActionReducer;
export default businessActionSlice.reducer;