import * as React from "react";
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardContent, Button,FormControl,CardActions, Fab, Alert } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import { useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApprovePricelist,ApprovePricelistLv7ExportExcel } from "../../../types/approve.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { approveGetDataById, ApproveActionSelector } from "../../../store/slices/approveActionSlice";
import { approveWorkFlowHistoryGetData, ApproveWorkFlowHistorySelector } from "../../../store/slices/approveWorkFlowHistorySlice";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { DataGrid, GridColDef, } from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Loader from "../../component/Loader";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewListIcon from '@mui/icons-material/ViewList';
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment";
import { useDebounce } from "@react-hook/debounce";
import { format } from "date-fns";
import ButtonBack from "../../layouts/ButtonBack";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { exportExcelItem,exportExcelItemPreviewLv7 } from "../../../utils/excel";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 80,
    color: theme.palette.common.white,
  }
}));

const StyledTableCellHeadSpan1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 40,
    color: theme.palette.common.white,
  }
}));

const StyledTableCellHeadSpan2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 40,
    color: theme.palette.common.white,
  }
}));

const StyledTableCellData1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // color: "#0000FF",
    // fontWeight: "bold",
  }
}));

const StyledTableCellData2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: "#43a047",
    fontWeight: "bold",
  }
}));


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pl: 0, pr: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type PriceListSpecialPreviewPageProps = {

  //
};

const PriceListSpecialPreviewPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const approveActionReducer = useSelector(ApproveActionSelector);
  const approveWorkFlowHistoryReducer = useSelector(ApproveWorkFlowHistorySelector);
  const match = useMatch("/pricelistspecial/preview/:id");
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [docstatus, setDocstatus] = React.useState<any>("");
  const [value, setValue] = React.useState(0);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const inputSearch = t('inputSearch');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const initialValues: ApprovePricelist = {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    priceLevel: "",
    conditionType: "",
    condRecNo: "",
    priceListData: [],
    priceListOldData: [],
    remark: "",
    comment: "",
    approveBy: "",
    wfStep: null,
    wfStepAll: null,
    documentStatus: "",
  };

  React.useEffect(() => {
    let id = match?.params.id;
    dispatch(approveGetDataById(id!));
    // dispatch(approveWorkFlowHistoryGetData(id!));
  }, [])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - approveActionReducer.result.priceListData.length) : 0;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSave = (docstatus: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
  }

  const ExportExcel = (excelData: any) => {
    let approvePricelistDatas: ApprovePricelistLv7ExportExcel[] = [];
      Swal.fire({
        title: 'Confirm!',
         text: 'คุณต้องการ export data ใช่หรือไม่?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          excelData.forEach((item: any) => {
            let approvePricelistData = new ApprovePricelistLv7ExportExcel();
            approvePricelistData.priceLevel = item.priceLevel
            approvePricelistData.itemCode = item.itemCode
            approvePricelistData.itemName = item.itemName
            approvePricelistData.saleUnit = item.saleUnit
            // approvePricelistData.saleOrg = item.saleOrg
            // approvePricelistData.distributionChannel = item.distributionChannel
            // approvePricelistData.priceList = item.priceList
            approvePricelistData.siteCode = item.siteCode
            // approvePricelistData.siteGroup = item.siteGroup
            approvePricelistData.vendor = item.vendor
            // approvePricelistData.validFromO = item.validFromO === null ? "-" : moment(item.validFromO).format('DD.MM.YYYY').toString()
            approvePricelistData.validFrom = item.validFrom === null ? "-" : moment(item.validFrom).format('DD.MM.YYYY').toString()
            // approvePricelistData.validToO = item.validToO === null ? "-" : moment(item.validToO).format('DD.MM.YYYY').toString()
            approvePricelistData.validTo = item.validTo === null ? "-" : moment(item.validTo).format('DD.MM.YYYY').toString()
            // approvePricelistData.amountInMarginO = item.amountInMarginO === null ? 0 : item.amountInMarginO
            // approvePricelistData.amountInMargin = item.amountInMargin === null ? 0 : item.amountInMargin
            approvePricelistData.amountInFinalPrice = item.amountInFinalPrice === null ? 0 : Number(Number(item.amountInFinalPrice).toFixed(2))
            // approvePricelistData.amountInFinalPriceO = item.amountInFinalPriceO === null ? 0 : Number(Number(item.amountInFinalPriceO).toFixed(2))
            // approvePricelistData.amountInFinalPrice = item.amountInFinalPrice === null ? 0 : item.amountInFinalPrice
            approvePricelistData.priceQTY1O = item.priceQTY1O === null ? 0 : Number(Number(item.priceQTY1O).toFixed(2))
            // approvePricelistData.priceQTY1 = item.priceQTY1 === null ? 0 : item.priceQTY1
            approvePricelistData.price1O = item.price1O === null ? 0 : Number(Number(item.price1O).toFixed(2))
            // approvePricelistData.price1 = item.price1 === null ? 0 : item.price1
            approvePricelistData.priceQTY2O = item.priceQTY2O === null ? 0 : Number(Number(item.priceQTY2O).toFixed(2))
            // approvePricelistData.priceQTY2 = item.priceQTY2 === null ? 0 : item.priceQTY2
            approvePricelistData.price2O = item.price2O === null ? 0 : Number(Number(item.price2O).toFixed(2))
            // approvePricelistData.price2 = item.price2 === null ? 0 : item.price2
            approvePricelistData.priceQTY3O = item.priceQTY3O === null ? 0 : Number(Number(item.priceQTY3O).toFixed(2))
            // approvePricelistData.priceQTY3 = item.priceQTY3 === null ? 0 : item.priceQTY3
            approvePricelistData.price3O = item.price3O === null ? 0 : Number(Number(item.price3O).toFixed(2))
            // approvePricelistData.price3 = item.price3 === null ? 0 : item.price3
            approvePricelistData.priceQTY4O = item.priceQTY4O === null ? 0 : Number(Number(item.priceQTY4O).toFixed(2))
            // approvePricelistData.priceQTY4 = item.priceQTY4 === null ? 0 : item.priceQTY4
            approvePricelistData.price4O = item.price4O === null ? 0 : Number(Number(item.price4O).toFixed(2))
            // approvePricelistData.price4 = item.price4 === null ? 0 : item.price4
            approvePricelistData.priceQTY5O = item.priceQTY5O === null ? 0 : Number(Number(item.priceQTY5O).toFixed(2))
            // approvePricelistData.priceQTY5 = item.priceQTY5 === null ? 0 : item.priceQTY5
            approvePricelistData.price5O = item.price5O === null ? 0 : Number(Number(item.price5O).toFixed(2))
            // approvePricelistData.price5 = item.price5 === null ? 0 : item.price5
          

            approvePricelistDatas.push(approvePricelistData);
          });
      
          // let Heading = [['Price Level', 'Article Code','Article Name', 'Sales Unit', 'Sales Organization', 'Distribution Channel', 'Price List', 'Site', 'Site Group', 'Vendor', 'Valid from Current', 'Valid from', 'Valid To Current', 'Valid To',  'Amount in Final Price Current',  'PQtyB1',  'PMPrice1',  'PQtyB2', 'PMPrice2', 'PQtyB3',  'PMPrice3', 'PQtyB4',  'PMPrice4',  'PQtyB5', 'PMPrice5', 'SpecialPrice']];
          let Heading = [['Price Level', 'Article Code','Article Name', 'Sales Unit',  'Site',  'Vendor',  'Valid from',  'Valid To',  'SpecialPrice',  'Qty1',  'Price1',  'Qty2', 'Price2', 'Qty3',  'Price3', 'Qty4',  'Price4',  'Qty5', 'Price5']];
          exportExcelItemPreviewLv7(approvePricelistDatas, Heading, 'PriceListSpecialPreview-' + moment().format('YYYY-MM-DD_hh:mm:ss').toString());
      
        } 
      })
  }
  const columns: GridColDef[] = [
    {
      field: "wfApprover", width: 110, headerAlign: 'center', headerClassName: 'super-app-theme--header',
      renderHeader: () => (<strong> {t('Approver')} </strong>),
    },
    {
      field: "wfApproveStatus", width: 110, headerAlign: 'center', headerClassName: 'super-app-theme--header',
      renderHeader: () => (<strong> {t('Status')} </strong>),
    },
    {
      field: "remark", width: 600, headerAlign: 'center', headerClassName: 'super-app-theme--header',
      renderHeader: () => (<strong> {t('Remark')} </strong>),
    },
    {
      field: "wfApproveDate", width: 100, type: 'date', headerAlign: 'center', headerClassName: 'super-app-theme--header',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('Approve Date')} </strong>),
    },
    {
      field: "createdDate", width: 100, type: 'date', headerAlign: 'center', headerClassName: 'super-app-theme--header',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('Create Date')} </strong>),
    },
    {
      field: "createdBy", width: 100, headerAlign: 'center', headerClassName: 'super-app-theme--header',
      renderHeader: () => (<strong> {t('Create By')} </strong>),
    },
  ];

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<ApprovePricelist>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              {t('PriceList_Preview')}
            </Typography>

            <TextField  id="priceDescription"
              style={{ marginTop: 16 }}
              fullWidth
              InputProps={{readOnly: true,}}
              variant="outlined"
              label={t('priceDescription')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.priceDescription}
              helperText={
                errors.priceDescription && touched.priceDescription
                  ? errors.priceDescription
                  : 'Enter your priceDescription.'
              }
              error={
                errors.priceDescription && touched.priceDescription
                  ? true
                  : false
              } />

            <TextField  id="remark"
              style={{ marginTop: 16 }}
              fullWidth
              multiline
              rows={5}
              InputProps={{readOnly: true,}}
              variant="outlined"
              label={t('remark')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.remark}
              helperText={
                errors.remark && touched.remark
                  ? errors.remark
                  : ''
              }
              error={
                errors.remark && touched.remark
                  ? true
                  : false
              } />
          {/* <Card> */}
              <CardActions>             
                <Button color="info" variant="contained"  onClick={(e) => ExportExcel(values.priceListData)}>
                  Export Excel
                </Button><div/>
                <ButtonBack></ButtonBack>
              </CardActions>
            {/* </Card> */}


            <Box sx={{ width: '100%' }}>
              <TabPanel value={value} index={0}>
                <TableContainer   sx={{ width: windowWidth - 130, height: windowHeight - 200 }} component={Paper}>
                  <Table  sx={{ width: "max-content", height: "max-content" }} aria-label="Approve Pricelist" size="small">
                    <TableHead>
                      <TableRow key={0}>
                        {/* <StyledTableCellHead align="center" rowSpan={2}style={{ position: 'sticky',background: '#00695f',width : 95,left: 0}} >Price Level</StyledTableCellHead> */}
                        <StyledTableCellHead align="center" rowSpan={2}style={{position: 'sticky',background: '#00695f',width : 95,left: 0}}>Article Code</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}style={{background: '#00695f',width : 250,left: 192 }}>Article Name</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}style={{background: '#00695f',width : 90,left: 442 }}>Sales Unit</StyledTableCellHead>
                        {/* <StyledTableCellHead align="center" rowSpan={2} >Sales Organization</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Distribution Chanel</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Price List</StyledTableCellHead> */}
                        <StyledTableCellHead align="center" rowSpan={2}>Site</StyledTableCellHead>
                        {/* <StyledTableCellHead align="center" rowSpan={2}>Site Group</StyledTableCellHead> */}
                        <StyledTableCellHead align="center" rowSpan={2}>Vendor</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>Valid From</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>Valid To</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>SpecialPrice</StyledTableCellHead>
                        {/* <StyledTableCellHead align="center" colSpan={2}>Amount in Margin</StyledTableCellHead> */}
                        {/* <StyledTableCellHead align="center" colSpan={1}>Amount in Final Price</StyledTableCellHead> */}
                        {/* <StyledTableCellHead align="center" colSpan={1}>PQtyB1 (Scale 1)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PMPrice1 (Scale 1)</StyledTableCellHead> */}
                        {/* <StyledTableCellHead align="center" colSpan={1}>PQtyB2 (Scale 2)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PMPrice2 (Scale 2)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PQtyB3 (Scale 3)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PMPrice3 (Scale 3)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PQtyB4 (Scale 4)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PMPrice4 (Scale 4)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PQtyB5 (Scale 5)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={1}>PMPrice5 (Scale 5)</StyledTableCellHead> */}

                        <StyledTableCellHead align="center" colSpan={2} style={{background: '#04BF62'}}>Scale 1</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}style={{background: '#04BF62'}}>Scale 2</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}style={{background: '#04BF62'}}>Scale 3</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}style={{background: '#04BF62'}}>Scale 4</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}style={{background: '#04BF62'}}>Scale 5</StyledTableCellHead>

                      </TableRow>
                      <TableRow key={1}>
                        {/* <StyledTableCellHeadSpan1  align="center">Current</StyledTableCellHeadSpan1> */}
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        // <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">Current</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        {/* <StyledTableCellHeadSpan1 align="center">Qty</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Price</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        {/* <StyledTableCellHeadSpan1 align="center">New</StyledTableCellHeadSpan1> */}
                        {/* <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2> */}
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Qty</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Price</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Qty</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Price</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Qty</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Price</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Qty</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Price</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Qty</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan1 align="center"style={{background: '#04BF62'}}>Price</StyledTableCellHeadSpan1>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? values.priceListData!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : values.priceListData
                      ).map((row, x) => (
                        <TableRow key={x + 2}>
                          {/* <TableCell align="right" style={{position: 'sticky', background: 'white',width : 95,left: 0}}>{row.priceLevel}</TableCell> */}
                          <TableCell style={{position: 'sticky', background: 'white',width : 95,left: 0}}>{row.itemCode}</TableCell>
                          <TableCell style={{ background: 'white',width : 250,left: 192}}>{row.itemName}</TableCell>
                          <TableCell style={{background: 'white',width : 90,left: 442}}>{row.saleUnit}</TableCell>
                          {/* <TableCell>{row.saleOrg}</TableCell>
                          <TableCell>{row.distributionChannel}</TableCell>
                          <TableCell>{row.priceList}</TableCell> */}
                          <TableCell>{row.siteCode}</TableCell>
                          {/* <TableCell>{row.siteGroup}</TableCell> */}
                          <TableCell>{row.vendor}</TableCell>
                          {/* <StyledTableCellData1>{row.validFromO}</StyledTableCellData1>
                          <StyledTableCellData2>{row.validFrom}</StyledTableCellData2>
                          <StyledTableCellData1>{row.validToO}</StyledTableCellData1>
                          <StyledTableCellData2>{row.validTo}</StyledTableCellData2> */}
                        
                          {/* <StyledTableCellData1>{row.validFromO === null || row.validFromO === undefined || row.amountInFinalPriceO===0 || row.price1O===0 ? "-" : format(new Date(row.validFromO), 'dd.MM.yyyy')}</StyledTableCellData1>
                          <StyledTableCellData2>{row.validFrom === null|| row.validFrom === undefined ? "-" : format(new Date(row.validFrom), 'dd.MM.yyyy')}</StyledTableCellData2>
                          <StyledTableCellData1>{row.validToO === null || row.validToO === undefined || row.amountInFinalPriceO===0 || row.price1O===0 ? "-" : format(new Date(row.validToO), 'dd.MM.yyyy')}</StyledTableCellData1>
                          <StyledTableCellData2>{row.validTo === null || row.validTo === undefined? "-" : format(new Date(row.validTo), 'dd.MM.yyyy')}</StyledTableCellData2> */}
                          {/* <StyledTableCellData1>{row.validFromO === null || row.validFromO === ""|| row.validFromO === undefined? "-" :format(new Date(row.validFromO), 'dd.MM.yyyy')}</StyledTableCellData1> */}
                          <StyledTableCellData2>{row.validFrom === null || row.validFrom === ""|| row.validFrom === undefined? "-" :format(new Date(row.validFrom), 'dd.MM.yyyy')}</StyledTableCellData2>
                          {/* <StyledTableCellData1>{row.validToO === null || row.validToO === ""|| row.validToO === undefined? "-" :format(new Date(row.validToO), 'dd.MM.yyyy')}</StyledTableCellData1> */}
                          <StyledTableCellData2>{row.validTo === null || row.validTo === ""|| row.validTo === undefined? "-" :format(new Date(row.validTo), 'dd.MM.yyyy')}</StyledTableCellData2>
                          <StyledTableCellData2 align="right">{row.amountInFinalPrice === null ? 0 : row.amountInFinalPrice}</StyledTableCellData2>
                          {/* <StyledTableCellData1 align="right">{row.amountInMarginO === null ? 0 : row.amountInMarginO}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.amountInMargin === null ? 0 : row.amountInMargin}</StyledTableCellData2> */}
                          {/* <StyledTableCellData1 align="right">{row.amountInFinalPriceO === null ? 0 : row.amountInFinalPriceO}</StyledTableCellData1> */}
                          {/* <StyledTableCellData2 align="right">{row.amountInFinalPrice === null ? 0 : row.amountInFinalPrice}</StyledTableCellData2> */}
                          <StyledTableCellData1 align="right" >{row.priceQTY1O === null ? 0 : row.priceQTY1O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row.priceQTY1 === null ? 0 : row.priceQTY1}</StyledTableCellData2> */}
                          <StyledTableCellData1 align="right">{row.price1O === null ? 0 : row.price1O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row!.price1 === null ? 0 : row.price1}</StyledTableCellData2> */}

                          <StyledTableCellData1 align="right">{row.priceQTY2O === null ? 0 : row.priceQTY2O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row.priceQTY2 === null ? 0 : row.priceQTY2}</StyledTableCellData2> */}
                          <StyledTableCellData1 align="right">{row.price2O === null ? 0 : row.price2O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row!.price2 === null ? 0 : row.price2}</StyledTableCellData2> */}

                          <StyledTableCellData1 align="right">{row.priceQTY3O === null ? 0 : row.priceQTY3O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row.priceQTY3 === null ? 0 : row.priceQTY3}</StyledTableCellData2> */}
                          <StyledTableCellData1 align="right">{row.price3O === null ? 0 : row.price3O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row!.price3 === null ? 0 : row.price3}</StyledTableCellData2> */}

                          <StyledTableCellData1 align="right">{row.priceQTY4O === null ? 0 : row.priceQTY4O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row.priceQTY4 === null ? 0 : row.priceQTY4}</StyledTableCellData2> */}
                          <StyledTableCellData1 align="right">{row.price4O === null ? 0 : row.price4O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row!.price4 === null ? 0 : row.price4}</StyledTableCellData2> */}

                          <StyledTableCellData1 align="right">{row.priceQTY5O === null ? 0 : row.priceQTY5O}</StyledTableCellData1>
                          {/* <StyledTableCellData2 align="right">{row.priceQTY5 === null ? 0 : row.priceQTY5}</StyledTableCellData2> */}
                          <StyledTableCellData1 align="right">{row.price5O === null ? 0 : row.price5O}</StyledTableCellData1>
                          
                          {/* <StyledTableCellData2 align="right">{row!.price5 === null ? 0 : row.price5}</StyledTableCellData2> */}
                       
                        </TableRow>

                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter >
                      <TableRow >
                        <TablePagination
                          rowsPerPageOptions={[12, 30, { label: 'All', value: -1 }]}
                          colSpan={3}
                          style={{position: 'sticky',left:0}}
                          count={values.priceListData ? values.priceListData!.length : 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Box>

            <FormControl style={{ marginTop: 10 }} fullWidth>
              {approveActionReducer.isError && <Alert severity="error">{approveActionReducer.message}</Alert>}
            </FormControl>
          </CardContent>
        </Card>
      </Form>
    );
  };

  // const validationSchema = Yup.object().shape({
  // });

  if (approveActionReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <>
      <Box>
        <Formik
          //validationSchema={validationSchema}
          initialValues={approveActionReducer.result ? approveActionReducer.result! : initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default PriceListSpecialPreviewPage;

