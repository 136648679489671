import * as React from "react";
import {
  Card, CardContent, Typography,Autocomplete, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, OutlinedInput
} from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Role } from "../../../types/role.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { businessGetData, businessSelector, } from "../../../store/slices/businessSlice";
import { roleActionSelector, roleCreateData, } from "../../../store/slices/roleActionSlice";
import { priceLevelSelector, priceLevelGetData } from "../../../store/slices/priceLevelSlice";
import { siteGetData,siteSelector } from "../../../store/slices/siteSlice";
import { click } from "@testing-library/user-event/dist/click";
import { menuGetDataList, menuListSelector } from "../../../store/slices/menuListSlice";
import Loader from "../../component/Loader";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type RoleCreatePageProps = {
  //
};

const RoleCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const priceLevelReducer = useSelector(priceLevelSelector);
  const bussinessReducer = useSelector(businessSelector);
  const menuListReducer = useSelector(menuListSelector);
  const roleActionReducer = useSelector(roleActionSelector);
  const siteReducer = useSelector(siteSelector);
  // const [pricelevel, setPricelevel] = React.useState<string[]>([]);
  const [petInputValue, setPetInputValue] = React.useState("");
  const [selectedSite, setSelectedSite] =  React.useState<any>([]);
  const [menuInputValue, setMenuInputValue] = React.useState("");
  const [selectedMenu, setSelectedMenu] =  React.useState<any>([]);
  const [buInputValue, setBUInputValue] = React.useState("");
  const [selectedBU, setSelectedBU] =  React.useState<any>([]);
  const [priceLevelInputValue, setpriceLevelInputValue] = React.useState("");
  const [selectedpriceLevel, setSelectedpriceLevel] =  React.useState<any>([]);
  const [roleTypeInputValue, setroleTypeInputValue] = React.useState("");
  const [selectedroleType, setSelectedroleType] =  React.useState<any>([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const MySwal = withReactContent(Swal);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // const handlepricelevelChange = (event: SelectChangeEvent<typeof pricelevel>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   console.log(value)
  //   setPricelevel(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );

  // };


  React.useEffect(() => {
    dispatch(businessGetData(""));
    dispatch(menuGetDataList());
    dispatch(priceLevelGetData(""));
    dispatch(siteGetData(""));
  }, []);



  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,

  }: FormikProps<Role>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4}}>
            <Typography gutterBottom variant="h5">
              Create Role
            </Typography>

            <TextField
              name="roleName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t("roleName")}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.roleName && touched.roleName
                  ? errors.roleName
                  : ""
              }
              error={errors.roleName && touched.roleName ? true : false}
            />

            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="buId">{t('select_bucode')}</InputLabel>
              <Select
                name="buId"
                // autoWidth
                // value={roleReducer.result[0] && roleReducer.result[0].roleid}
                // defaultValue={roleReducer.result[0] && roleReducer.result[0].roleId}
                label={t("select_bucode")}
                value={values.buId}
                onChange={(event) => { setFieldValue('buId', event.target.value) }}
                error={
                  errors.buId && touched.buId
                    ? true
                    : false
                }>
                {bussinessReducer.result.map((item, index) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.buName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <FormControl style={{ marginTop: 16 ,width: '99.99%'}} >
            <Autocomplete
                  multiple
                  id="bu1"
                  options={bussinessReducer.result
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.buName}
                    </li>
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.buName}
                  inputValue={buInputValue}
                  onInputChange={(event, newBUInputValue) => {
                    setBUInputValue(newBUInputValue);
                  }}
                  value={selectedBU}
                  onChange={(event, newValue) => {
                    setSelectedBU(newValue);
                    // console.log("check select site :" + newValue);
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("select_bucode")} placeholder="Search" />
                  )}
                />
              </FormControl> */}

            {/* { <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="priceLevel">{t('select_PriceLevel')}</InputLabel>
              <Select
                multiple
                autoWidth
                MenuProps={MenuProps}
                name="priceLevel"
                value={values.priceLevel}
                input={<OutlinedInput label="select_PriceLevel" />}
                // onChange={handlepricelevelChange}
                label={t("select_PriceLevel")}
                onChange={handleChange}
              // onClose={values.priceLevel}
              // onChange={(event) => { setFieldValue('priceLevel', event.target.value) }}
              >
                {priceLevelReducer.result.map((item, index) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.priceLevelDesc}
                  </MenuItem>

                ))}
              </Select>
            </FormControl> } */}

            <FormControl style={{ marginTop: 16 }}fullWidth >
            <Autocomplete
                  multiple
                  id="priceLevel1"
                  options={priceLevelReducer.result}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.priceLevelDesc}
                    </li>
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.priceLevelDesc}
                  inputValue={priceLevelInputValue}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onInputChange={(event, newPriceLevelInputValue) => {
                    setpriceLevelInputValue(newPriceLevelInputValue);
                  }}
                  value={selectedpriceLevel}
                  onChange={(event, newValue) => {
                    setSelectedpriceLevel(newValue);
                    // console.log("check select site :" + newValue);
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("select_PriceLevel")} placeholder="Search" />
                  )}
                />
              </FormControl>
              
            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="site">{t('select_site')}</InputLabel>
              <Select
                multiple
                autoWidth
                MenuProps={MenuProps}
                name="site"
                value={values.site}
                input={<OutlinedInput label="select_site" />}
                // onChange={handlepricelevelChange}
                label={t("select_site")}
                onChange={handleChange}
              >
                {siteReducer.result.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.siteName}
                  </MenuItem>

                ))}
              </Select>
            </FormControl> */}

            <FormControl style={{ marginTop: 16 ,width: '99.99%'}} >
            <Autocomplete
                  multiple
                  id="siteCode1"
                  options={siteReducer.result}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.siteName}
                    </li>
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.siteName}
                  inputValue={petInputValue}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onInputChange={(event, newPetInputValue) => {
                    setPetInputValue(newPetInputValue);
                  }}
                  value={selectedSite}
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    // console.log("check select site :" + newValue);
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('siteCode')} placeholder="Search" />
                  )}
                />
              </FormControl>

            {/* { <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="menu">{t('select_Menu')}</InputLabel>
              <Select
                multiple
                autoWidth
                MenuProps={MenuProps}
                name="menu"
                value={values.menu}
                input={<OutlinedInput label="select_Menu" />}
                // onChange={handlepricelevelChange}
                label={t("select_Menu")}
                onChange={handleChange}

              >

       

                {menuListReducer.result.filter(x => x.parentId !== null && x.parentId !== '').map((item, index) => (
                  <MenuItem key={item.menuId} value={item.menuId}>
                    {item.menuName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> } */}

            <FormControl style={{ marginTop: 16 ,width: '99.99%'}} >
            <Autocomplete
                  multiple
                  id="menu1"
                  options={menuListReducer.result.filter(x => x.parentId !== null && x.parentId !== '')
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.menuName}
                    </li>
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.menuName}
                  inputValue={menuInputValue}
                  isOptionEqualToValue={(option, value) => option.menuId === value.menuId}
                  onInputChange={(event, newMenuInputValue) => {
                    setMenuInputValue(newMenuInputValue);
                  }}
                  value={selectedMenu}
                  onChange={(event, newValue) => {
                    setSelectedMenu(newValue);
                    // console.log("check select site :" + newValue);
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("select_Menu")} placeholder="Search" />
                  )}
                  
                />
              </FormControl>

            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="roleType">{t('select_roleType')}</InputLabel>
              <Select
                MenuProps={MenuProps}
                name="roleType"
                // autoWidth
                value={values.roleType}
                input={<OutlinedInput label="select_roleType" />}
                // onChange={handlepricelevelChange}
                label={t("select_roleType")}
                onChange={handleChange}
              >
                <MenuItem value={"ADMIN"}>{t('select_admin')}</MenuItem>
                <MenuItem value={"MARKETING"}>{t('select_marketing')}</MenuItem>
                <MenuItem value={"BRANCH"}>{t('select_branch')}</MenuItem>
              </Select>
            </FormControl>


            <TextField
              name="remark"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t("remark")}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.remark && touched.remark
                  ? errors.remark
                  : ""
              }
              error={errors.remark && touched.remark ? true : false}
            />
            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>


            {roleActionReducer.isError && (
              <Alert severity="error">{roleActionReducer.message}</Alert>
            )}
          </CardContent>
          <CardActions >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={roleActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/role" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const initialValues: Role = {
    roleId: '',
    roleName: '',
    buId: '',
    priceLevel: [],
    menu: [],
    roleType: '',
    site: [],
    active: true,
    createdBy: '',
    remark:'',
  };

  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required(inputRequired),
    remark: Yup.string().required(inputRequired),
    // bu1: Yup.string().required(),
    // priceLevel1: Yup.string().required(),
    // menu1: Yup.string().required(),
    // siteCode1: Yup.boolean().required(),
  });

  if (roleActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {

          let selectSite=[]
          for (let iSite of selectedSite) {
            selectSite.push(iSite.id)
          }

          let selectPriceLevel=[]
          for (let x of selectedpriceLevel) {
            selectPriceLevel.push(x.id)
          }

          let selectMenu=[]
          for (let x of selectedMenu) {
            selectMenu.push(x.menuId)
          }

          // console.log("selectedSite: " + selectedSite.length);
          // console.log(selectSite);
          // console.log("selectedpriceLevel: " + selectedpriceLevel.length)
          // console.log(selectPriceLevel)
          // console.log("selectedMenu: " + selectedMenu.length)
          // console.log(selectMenu)

          const newValues ={
            ...values,
            site:selectSite,
            // roleType:selectedroleType,
            priceLevel:selectPriceLevel,
            menu:selectMenu,
          }
          if( selectSite.length === 0 || selectPriceLevel.length ===0 || selectMenu.length===0){
            MySwal.fire({
              title: <p>กรุณาเลือก SiteCode หรือ PriceLevel หรือ Menu</p>,
              didOpen: () => {
                // `MySwal` is a subclass of `Swal` with all the same instance & static methods
              },
            });
          }
          else {

              dispatch(roleCreateData(newValues));
              setSubmitting(false);


          }
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default RoleCreatePage;
