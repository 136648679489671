import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { Menu } from "../../types/menu.type";
import { history } from "../../index";
import { redirect  } from "react-router-dom";

type MenuListState = {
    result: Menu[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: MenuListState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const menuGetDataList = createAsyncThunk(
    "Menu/getMenuList",
    async (_, thunkApi) => {
        try {
            const result = await httpClient.get<Menu[]>(server.MENU_URL + "/data/list");
            return result;

        } catch (err: any) {
            return thunkApi.rejectWithValue(err.message);
        }
    }
);

const MenuListSlice = createSlice({
    name: "menuList",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(menuGetDataList.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(menuGetDataList.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(menuGetDataList.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
                redirect("/login");
                // history.back()
            });
 
    }
})


export const { } = MenuListSlice.actions;
export const menuListSelector = (store: RootState) => store.menuListReducer;
export default MenuListSlice.reducer;
