import * as React from "react";
import "./App.css";
import "./Constants"
import 'animate.css';
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { styled, ThemeProvider, createTheme, } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useSelector } from "react-redux";
import ProtectedRoutes from "./router/protected.routes";
import PublicRoutes from "./router/public.routes";
import SpecialRoutes from "./router/special.routes";
import SpecialApproveRoutes from "./router/specialapprove.routes";
import SpecialApprovelv7Routes from "./router/specialapprovelv7.routes";
import Special2Routes from "./router/special2.routes";
import { useAppDispatch } from "./store/store";
import { loginSelector, restoreLogin } from "./store/slices/loginSlice";
import Header from "./components/layouts/Header";
import Menu from "./components/layouts/Menu";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import LoginRedirectPage from "./components/pages/LoginRedirectPage";
import UserPage from "./components/pages/UserPage";
import UserCreatePage from "./components/pages/UserCreatePage";
import UserEditPage from "./components/pages/UserEditPage";
import ApprovePage from "./components/pages/ApprovePage";
import ApprovePriceListPage from "./components/pages/ApprovePriceListPage";
import ApproveHistoryPage from "./components/pages/ApproveHistoryPage";
import ApproveHistoryListPage from "./components/pages/ApproveHistoryListPage";
import ApproveHistoryListSpecialPage from "./components/pages/ApproveHistoryListSpecialPage";
import MonitorPage from "./components/pages/MonitorPage";

import BusinessPage from "./components/pages/BusinessPage";
import BusinessCreatePage from "./components/pages/BusinessCreatePage";
import BusinessEditPage from "./components/pages/BusinessEditPage";
import PriceLevelPage from "./components/pages/PriceLevelPage";
import PriceLevelCreatePage from "./components/pages/PriceLevelCreatePage";
import PriceLevelEditPage from "./components/pages/PriceLevelEditPage";
import RolePage from "./components/pages/RolePage";
import RoleCreatePage from "./components/pages/RoleCreatePage";
import RoleEditPage from "./components/pages/RoleEditPage";
import SiteGroupPage from "./components/pages/SiteGroupPage";
import SiteGroupCreatePage from "./components/pages/SiteGroupCreatePage";
import SiteGroupEditPage from "./components/pages/SiteGroupEditPage";
import SitePage from "./components/pages/SitePage";
import SiteCreatePage from "./components/pages/SiteCreatePage";
import SiteEditPage from "./components/pages/SiteEditPage";
import TemplatePage from "./components/pages/TemplatePage";
import TemplateCreatePage from "./components/pages/TemplateCreatePage";
import TemplateEditPage from "./components/pages/TemplateEditPage";
import PricelistPage from "./components/pages/PriceListPage";
import PriceListCreatePage from "./components/pages/PriceListCreatePage";
import PriceListEditPage from "./components/pages/PriceListEditPage";
import ItemPage from "./components/pages/ItemPage";
import ItemCreatePage from "./components/pages/ItemCreatePage";
import ItemEditPage from "./components/pages/ItemEditPage";
import PriceListScalePage from "./components/pages/PriceListScalePage";
import PriceListScaleCreatePage from "./components/pages/PriceListScaleCreatePage";
import PriceListScaleEditPage from "./components/pages/PriceListScaleEditPage";
import { LicenseInfo } from '@mui/x-license-pro';
import UserApiPage from "./components/pages/UserApiPage";
import UserApiCreatePage from "./components/pages/UserApiCreatePage";
import UserApiEditPage from "./components/pages/UserApiEditPage";
import ReportActualPage from "./components/pages/ReportActualPage";
import ReportActualDownloadPage from "./components/pages/ReportActualDownloadPage";
import PriceListPreviewPage from "./components/pages/PriceListPreviewPage";
import PriceListSpecialPrice from "./components/pages/PriceListSpecialPrice";
import PriceListSpecialPriceCreatePage from "./components/pages/PriceListSpecialPriceCreatePage";
import PriceListSpecialPriceEditPage from "./components/pages/PriceListSpecialPriceEditPage";
import CustomerPage from "./components/pages/CustomerPage/CustomerPage";
import CustomerCreatePage from "./components/pages/CustomerCreatePage/CustomerCreatePage";
import CustomerEditPage from "./components/pages/CustomerEditPage/CustomerEditPage";
import BrandPage from "./components/pages/BrandPage";
import BrandEditPage from "./components/pages/BrandEditPage/BrandEditPage";
import BrandCreatePage from "./components/pages/BrandCreatePage/BrandCreatePage";
import MonitorPreviewPage from "./components/pages/MonitorPreviewPage/MonitorPreviewPage";
import PriceListSpecialPreviewPage from "./components/pages/PriceListSpecialPreviewPage";
import ApprovePriceListSpecialPage from "./components/pages/ApprovePriceListSpecialPage";

// LicenseInfo.setLicfenseKey('b74a5ce2689afd35ed0d9b1e77ea89e6Tz01ODkwOSxFPTE3MDY4NDEzMjA2MzQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
// LicenseInfo.LicenseInfo
LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRIDPRO_KEY as string);
//console.log(LicenseInfo.setLicenseKey(process.env.DATAGRIDPRO_KEY!))


const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // backgroundImage:
          //   "url(" +
          //   `${process.env.PUBLIC_URL}/images/background_menu.jpg` +
          //   ")",
          width: drawerWidth,
        },
      },
    },
  },
  typography: {
    fontFamily: "IBM+Plex+Sans+Thai",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontSize: 12,
  },
  spacing: 8,
  palette: {
    primary: {
      main: "#43a047",
    },
    background: {
      default: "#CFD2D6",
    },
  },
});


export default function App() {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const loginReducer = useSelector(loginSelector);


  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(restoreLogin());
  }, [])


  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          {loginReducer.result && <Header open={open} onDrawerOpen={handleDrawerOpen} />}
          {loginReducer.result && <Menu open={open} onDrawerClose={handleDrawerClose} />}
          <Routes>
            <Route path="/" element={<PublicRoutes />}>
              <Route path="/login" element={<LoginPage />} />     
              <Route path="/login/redirct" element={<LoginRedirectPage />} />   
            </Route>
          </Routes>
          <Routes>
             <Route path="/" element={<SpecialRoutes />}>
                  {/* <Route path="/approvepricelist2" element={<ApprovePage />} />  */}
                  <Route path="/approvepricelist2/:id" element={<ApprovePage />} /> 
              </Route>
          </Routes>
          <Routes>
             <Route path="/" element={<SpecialApproveRoutes />}>
              <Route path="/approvepricelistid2/:id" element={<ApprovePriceListPage />} />    
              </Route>
          </Routes>
          <Routes>
             <Route path="/" element={<SpecialApprovelv7Routes />}>
                  <Route path="/approvepricelistspecialid2/:id" element={<ApprovePriceListSpecialPage />} />
              </Route>
          </Routes>
          <Routes>
             <Route path="/" element={<Special2Routes />}>
                  <Route path="/pricelistscalelist" element={<PriceListScalePage />} />
              </Route>
          </Routes>
          <Main open={open}>
            <DrawerHeader />
            <Routes>
              <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/loginf" element={<LoginPage />} />   
                <Route path="/home" element={<HomePage />} />
                <Route path="/approve/:id" element={<ApprovePage />} />
                <Route path="/approve" element={<ApprovePage />} />
                <Route path="/approvepricelist/:id" element={<ApprovePriceListPage />} />
                <Route path="/approvehistory" element={<ApproveHistoryPage />} />
                <Route path="/approvehistorylist/:id" element={<ApproveHistoryListPage />} />
                <Route path="/approvehistorylistspecial/:id" element={<ApproveHistoryListSpecialPage />} />
                <Route path="/business" element={<BusinessPage />} />
                <Route path="/business/create" element={<BusinessCreatePage />} />
                <Route path="/business/edit/:id" element={<BusinessEditPage />} />
                <Route path="/items" element={<ItemPage />} />
                <Route path="/items/create" element={<ItemCreatePage />} />
                <Route path="/items/edit/:id" element={<ItemEditPage />} />
                <Route path="/pricelevel" element={<PriceLevelPage />} />
                <Route path="/pricelevel/create" element={<PriceLevelCreatePage />} />
                <Route path="/pricelevel/edit/:id" element={<PriceLevelEditPage />} />
                <Route path="/pricelist" element={<PricelistPage />} />
                <Route path="/pricelist/create" element={<PriceListCreatePage />} />
                <Route path="/pricelist/edit/:id" element={<PriceListEditPage />} />
                <Route path="/pricelist/preview/:id" element={<PriceListPreviewPage />} />
                <Route path="/pricelistscale" element={<PriceListScalePage />} />
                <Route path="/pricelistscale/create" element={<PriceListScaleCreatePage />} />
                <Route path="/pricelistscale/edit/:id" element={<PriceListScaleEditPage />} />
                <Route path="/pricelistspecialprice" element={<PriceListSpecialPrice />} />
                <Route path="/pricelistspecialprice/create" element={<PriceListSpecialPriceCreatePage />} />
                <Route path="/pricelistspecialprice/edit/:id" element={<PriceListSpecialPriceEditPage />} />
                <Route path="/role" element={<RolePage />} />
                <Route path="/role/create" element={<RoleCreatePage />} />
                <Route path="/role/edit/:id" element={<RoleEditPage />} />
                <Route path="/reportactual" element={<ReportActualPage />} />
                <Route path="/reportactualdownload" element={<ReportActualDownloadPage />} />
                <Route path="/sitegroup" element={<SiteGroupPage />} />
                <Route path="/sitegroup/create" element={<SiteGroupCreatePage />} />
                <Route path="/sitegroup/edit/:id" element={<SiteGroupEditPage />} />
                <Route path="/site" element={<SitePage />} />
                <Route path="/site/create" element={<SiteCreatePage />} />
                <Route path="/site/edit/:id" element={<SiteEditPage />} />
                <Route path="/template" element={<TemplatePage />} />
                <Route path="/template/create" element={<TemplateCreatePage />} />
                <Route path="/template/edit/:id" element={<TemplateEditPage />} />
                <Route path="/user" element={<UserPage />} />
                <Route path="/user/create" element={<UserCreatePage />} />
                <Route path="/user/edit/:id" element={<UserEditPage />} />
                <Route path="/userapi" element={<UserApiPage />} />
                <Route path="/userapi/create" element={<UserApiCreatePage />} />
                <Route path="/userapi/edit/:id" element={<UserApiEditPage />} />
                <Route path="/customer" element={<CustomerPage />} />
                <Route path="/customer/create" element={<CustomerCreatePage />} />
                <Route path="/customer/edit/:id" element={<CustomerEditPage />} />
                <Route path="/brand" element={<BrandPage />} />
                <Route path="/brand/create" element={<BrandCreatePage />} />
                <Route path="/brand/edit/:id" element={<BrandEditPage />} />
                <Route path="/monitorjob" element={<MonitorPage />} />
                <Route path="/monitorjob/Preview/:id" element={<MonitorPreviewPage />} />
                <Route path="/pricelistspecial/preview/:id" element={<PriceListSpecialPreviewPage />} />
                <Route path="/approvepricelistspecial/:id" element={<ApprovePriceListSpecialPage />} />

                <Route path="/" element={<Navigate to="/login" />} />
                {/* <Route path="*" element={<NotFound />} /> */}
              </Route>
            </Routes>
          </Main>
        </Box>
      </ThemeProvider >
    </>
  );
}

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);
