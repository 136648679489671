import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Pricelist } from "../../types/pricelist.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type PricelistState = {
    result: Pricelist[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: PricelistState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const PricelistSpecialGetData = createAsyncThunk(
    "pricelistSpecial/getPricelistSpecial",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist[]>(server.PRICELIST_URL + "?keyword=" + keyword);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const pricelistSpecialSlice = createSlice({
    name: "Pricelistspecial",
    initialState: initialStateValues,
    reducers: {
        reloadPricelistSpecialData: (state, action: PayloadAction<Pricelist[]>) => {
            state.result = action.payload!;
        },
    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(PricelistSpecialGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(PricelistSpecialGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistSpecialGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { reloadPricelistSpecialData} = pricelistSpecialSlice.actions;
export const PricelistSpecialSelector = (store: RootState) => store.pricelistSpecialReducer;
export default pricelistSpecialSlice.reducer;