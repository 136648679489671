import * as React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  FormControl,
  MenuItem,
  Radio,
  InputLabel,
  Alert,
  FormHelperText,
  IconButton,
  Stack,
  FormLabel,
  Grid,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useMatch } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Template, TemplateData } from "../../../types/template.type";
import { Pricelist, PricelistData } from "../../../types/pricelist.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../component/Loader";
import {
  templateGetList,
  templateSelector,
} from "../../../store/slices/templateSlice";
import {
  priceListGetTemplateDataById,
  priceListGetTemplateDataByIdCreatePage,
  PricelistActionSelector,
  removePricelistData,
  pricelistCreateData,
  updatePricelistData,
  clearPricelist,
  selectPricelist,
} from "../../../store/slices/priceListActionSlice";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { clearTemplateData } from "../../../store/slices/templateActionSlice";
import { unchangedTextChangeRange } from "typescript";
import { GridEditInputCell, GridPreProcessEditCellProps, GridRowParams } from "@mui/x-data-grid";
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LinearProgress } from "@mui/material";

type PriceListCreatePageProps = {
  //
};

let initialValues: Pricelist = {
  buCode: "",
  priceDescription: "",
  priceDate: new Date(),
  priceLevel: "",
  conditionType: "",
  condRecNo: "",
  docRef: "",
  priceListData: [],
  ApproveBy: "",
  validFrom: "" || new Date(),
  validTo: "" || new Date(),
  remark: "",
  documentStatus: "",
  isPriority:false,
  attachment:[],
};

const PriceListCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const templateReducer = useSelector(templateSelector);
  const pricelistActionReducer = useSelector(PricelistActionSelector);
  const [pageSize, setPageSize] = React.useState(50);
  const [templateId, setTemplateId] = React.useState<any>(null);
  const [selectedRows, setSelectedRows] = React.useState<PricelistData[]>([]);
  const [docstatus, setDocstatus] = React.useState<any>("");
  const [checkboxSelection, setCheckboxSelection] = React.useState(false);
  const [loadtemplate, setLoadtemplate] = React.useState(true);
  const [selection, setSelection] = React.useState([]);
  //   pricelistActionReducer.result.priceListData
  // );
  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({
    left: [
      GRID_CHECKBOX_SELECTION_COL_DEF.field,
      "priceLevel",
      "itemCode",
      "itemName",
      "saleUnit",
      "siteCode",
      "siteGroup",
    ],
  });
  // console.log(selectedRows);
  // const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(initialValues.priceListData?.filter((x) => x.isSelect === true).map((x) => x.id!));
  // const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(
  //   pricelistActionReducer.result!.priceListData?.filter((x) => x.isSelect === true).map((x) => x.id!)
  //   ?? []);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
 
 
  // const match = useMatch("/pricelist/edit/:id");
  const [disablebutton, setdisablebutton] = React.useState(false);
  React.useEffect(() => {
    dispatch(clearPricelist());
  }, []);

  React.useEffect(() => {
    dispatch(templateGetList(""));
  }, []);



  React.useEffect(()=>{
    // dispatch(updatePricelistData(initialValues.priceListData!))
  })

  const MySwal = withReactContent(Swal);

  const handleCellEditCommit = ({ id, field, value }: any) => {
    //console.log({ id, field, value });
    const newUpdatedRows = pricelistActionReducer.result.priceListData!.map(
      (row) => {
        if(typeof value != "number")
        {
        }else{
          // console.log("checkNum: false" + value)
          value = Number(Number(value!).toFixed(2))
       
        }
        //console.log(field)
        if (row.id === id) {
          return { ...row, [field]: value,validFrom:initialValues.validFrom,validTo:initialValues.validTo,message:''  };
        }
        return row;
      }
    );
    setdisablebutton(false);
    // setUpdateRow(newUpdatedRows);
    dispatch(updatePricelistData(newUpdatedRows));
  };

 
  const columns: GridColDef[] = [
    {
      field: "priceLevel",
      headerName: "Price Level",
      width: 100,
    },
    {
      field: "itemCode",
      headerName: "Article Code",
      width: 100,
    },
    {
      field: "itemName",
      headerName: "Article Name",
      width: 200,
    },
    {
      field: "saleUnit",
      headerName: "Sales Unit",
      width: 100,
    },
    {
      field: "siteCode",
      headerName: "Site",
      width: 150,
    },
    {
      field: "siteGroup",
      headerName: "Site Group",
      width: 150,
      cellClassName: "super-app-theme--header--pined",
    },
    // { field: "saleOrg", headerName: "Sales Organization", width: 100 },
    // {
    //   field: "distributionChannel",
    //   headerName: "Distribution Channel",
    //   width: 150,
    // },
    // { field: "priceList", headerName: "Price List", width: 150 },

    // { field: "vendor", headerName: "Vendor", width: 150 },
    // {
    //   field: "validFrom", headerName: "Valid From", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "validTo", headerName: "Valid To", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "amountInMargin",
    //   headerName: "Amount in Margin",
    //   width: 150,
    //   type: "number",
    // },
    {
      field: "amountInFinalPrice",
      headerName: "Amount in Final Price",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    // {
    //   field: "priceQTY1",
    //   headerName: "PQtyB1 (Scale 1)",
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "price1",
    //   headerName: "PMPrice1 (Scale 1)",
    //   width: 150,
    //   type: "number",
    // },
    {
      field: "priceQTY2",
      headerName: "PQtyB2 (Scale 2)",
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    // {
    //   field: "price2",
    //   headerName: "PMPrice2 (Scale 2)",
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "priceQTY3",
    //   headerName: "PQtyB3 (Scale 3)",
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "price3",
    //   headerName: "PMPrice3 (Scale 3)",
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "priceQTY4",
    //   headerName: "PQtyB4 (Scale 4)",
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "price4",
    //   headerName: "PMPrice4 (Scale 4)",
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "priceQTY5",
    //   headerName: "PQtyB5 (Scale 5)",
    //   width: 150,
    //   type: "number",
    // },
    // {
    //   field: "price5",
    //   headerName: "PMPrice5 (Scale 5)",
    //   width: 150,
    //   type: "number",
    // },
    {
      field: "message",
      headerName: "Message",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "red",
              width: "100%",
            }}         
          >
            {cellValues.value}
            
          </div>
        );
      },
    },
  ];

  const handleDeleteConfirm = (e: any) => {
    dispatch(
      removePricelistData(
        pricelistActionReducer.result!.priceListData?.filter(
          (row) => row.id !== e.id
        )!
      )
    );
  };

  const onloadtemplate = () => {
     dispatch(templateGetList(""));
    dispatch(priceListGetTemplateDataByIdCreatePage(templateId!));
    setLoadtemplate(false);
     dispatch(clearPricelist());
     setSelectionModel([]);
     setdisablebutton(false);
  };

  const handleSave = (docstatus: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
  };

  const handleCheckbox = (checked: any) => {
    // do what you want like on submi
    console.log(checked.target.checked);
    //console.log(checkboxSelection);
    //setSelection()
  };

  
  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,
  }: FormikProps<Pricelist>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create Price List
            </Typography>
            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="templateName">
                {t("templateName")}
              </InputLabel>
              <Select
                name="templateName"
                onChange={(event) => {
                  setFieldValue("templateName", event.target.value!);
                  setTemplateId(event.target.value!);
                }}
              >
                {/* pricelevel only 1,2 */}
                {templateReducer.result
                  .filter((x) => x.priceLevel <= "2")
                  .map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.templateName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
    
            <Button
              style={{ marginBottom: 10, marginTop: 10 }}
              variant="contained"
              color="warning"
              type="button"
              sx={{ marginRight: 1 }}
              disabled={templateReducer.isFetching}
              onClick={onloadtemplate}
            >
              Load Template
            </Button>
   
            <h5 style={{ color: "red" }}>***{t("loadtemplate")}</h5>
            <TextField
              id="priceDescription"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t("priceDescription")}
              onChange={e =>{
                handleChange(e)
                setdisablebutton(false)}}
              onBlur={handleBlur}
              // value={values.priceDescription}
              helperText={
                errors.priceDescription && touched.priceDescription
                  ? errors.priceDescription
                  : ""
              }
              error={
                errors.priceDescription && touched.priceDescription
                  ? true
                  : false
              }
              disabled={loadtemplate}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={t('validFrom')}
                value={values.validFrom}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(2)}
                maxDate={new Date().setDate(365)}
                onChange={(validFrom) => {
                  setFieldValue('validFrom', validFrom);
                  setdisablebutton(false);
                 
                }}
                disablePast={true}
                disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />

            </LocalizationProvider>
            To
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("validTo")}
                value={values.validTo}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(2)}
                maxDate={new Date().setDate(365)}
                onChange={(validTo) => {
                  setFieldValue("validTo", validTo);
                  setdisablebutton(false);
                 
                }}
                disablePast={true}
                disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              id="remark"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              multiline
              rows={5}
              inputProps={{ maxLength: 1000 }}
              variant="outlined"
              label={t("remark")}
              onChange={e =>{
                handleChange(e)
                setdisablebutton(false)}}
              onBlur={handleBlur}
              // value={values.remark}
              disabled={loadtemplate}
              // helperText={
              //   errors.remark && touched.remark
              //     ? errors.remark
              //     : 'Enter your remark.'
              // }
              error={errors.remark && touched.remark ? true : false}
            />
          <FormControlLabel
            control={
              <Checkbox 
              value={values.isPriority}             
              onChange={e =>{
                  handleChange(e)
                  setdisablebutton(false)}} 
                  name="isPriority"
                  style ={{
                    color: "Red",
                  }}
                  />
            }
            label={t("isPriority")}
            style ={{
              color: "Red",
            }}
          />
          <br/>
        
              {pricelistActionReducer.isFetching && (
                                  <LinearProgress />
               )}
                <br></br>
            <DataGridPro
              // isRowSelectable={(params: GridRowParams) => params.row.message !==""}
              checkboxSelection
              sx={{
                backgroundColor: "white",
                height: "80vh",
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#6dc270",
                },
              }}
              // pinnedColumns={pinnedColumns}
              initialState={{ pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'priceLevel','itemCode','itemName','saleUnit','siteCode','siteGroup'] } }}
              getRowId={(row) => row.id}
              // rows={rowsData ? rowsData : []}
              // rows={
              //   pricelistActionReducer.result
              //     ? pricelistActionReducer!.result!.priceListData!
              //     : []
              // }
              // rows={pricelistActionReducer!.result!.priceListData! == undefined? []:
              //   pricelistActionReducer!.result!.priceListData!
              // }
              rows={
                pricelistActionReducer.result
                  ? pricelistActionReducer!.result!.priceListData
                  : []
              }
              columns={columns}
              rowsPerPageOptions={[10, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize: number) =>
                setPageSize(newPageSize)
              }
              disableColumnSelector={true}
              onCellEditCommit={handleCellEditCommit}
              disableSelectionOnClick={true}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
                const selectedIDs = new Set(ids);
                const selectedRows =
                  pricelistActionReducer.result.priceListData!.filter(
                    (row: any) => selectedIDs.has(row.id)
                  );
               
                setSelectedRows(selectedRows);
                setdisablebutton(false);
                // dispatch(selectPricelist(selectedRows));
              }}
              selectionModel={selectionModel}
            />
            <FormControl style={{ marginTop: 16, marginBottom: 16 }} fullWidth>
              {pricelistActionReducer.isError && (
                <Alert severity="error">{pricelistActionReducer.message}</Alert>
              )}
            </FormControl>
          </CardContent>

          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="info"
              type="submit"
              onClick={() => handleSave({ documentStatus: "Draft" })}
              sx={{ marginRight: 1 }}
              disabled={templateReducer.isFetching || (disablebutton ==true)}
            >
              {" "}
              Save Draft
            </Button>

            <Button
              variant="contained"
              fullWidth
              color="success"
              type="submit"
              onClick={() =>
                handleSave({ documentStatus: "Waiting for Approve" })
              }
              disabled={templateReducer.isFetching|| (disablebutton ==true)  }
            >
              {" "}
              Send for Approve
            </Button>

            <Button
              component={Link}
              to="/pricelist"
              variant="outlined"
              fullWidth
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t("inputRequired");
  const validationSchema = Yup.object().shape({
    priceDescription: Yup.string().required(inputRequired),
  });

  if (pricelistActionReducer.isFetching) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            // console.log(docstatus.documentStatus)
            initialValues = {
              ...initialValues,
              priceDescription: values.priceDescription,
              validFrom: values.validFrom,
              validTo: values.validTo,
              remark: values.remark,
            };
          
            const valuesToSend = {
              ...values,
              priceLevel:
                pricelistActionReducer!.result!.priceListData[0].priceLevel,
              priceListData: pricelistActionReducer!.result!.priceListData,
              documentStatus: docstatus.documentStatus,
              wfStep: 1,
              wfStepAll: 1,
            };
            let alertText ;
            if (docstatus.documentStatus ==="Draft"){
              alertText = "ยืนยันการบันทึก Draft?"
            }
            else{
              alertText = "ยืนยันการส่ง Approve?"
            }
            //  console.log(valuesToSend);
            // console.log(pricelistActionReducer!.result!.priceListData);
            // docstatus.documentStatus === "Waiting for Approve"
            if (
              selectedRows.length === 0 
             
            ) {
              MySwal.fire({
                title: <p>{t("itemselected")}</p>,
                didOpen: () => {
                  // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                },
              });
            } else {
              Swal.fire({
                title: 'Confirm!',
                text: alertText,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
              }).then((result) => {
                if (result.isConfirmed) {             
                  dispatch(
                    pricelistCreateData({
                      formData: valuesToSend,
                      selectList: selectedRows,
                    })
                  );
                  setSubmitting(false);
                  // Swal.fire('ดำเนินการเรียบร้อย!', '', 'success')
    
                  setdisablebutton(true);
                
                // console.log("pricelistActionReducer.isError :"+pricelistActionReducer.isError)
                } 
              })
 
            }
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default PriceListCreatePage;
