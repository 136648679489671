export class ItemData {
    id!: string
    itemCode!: string
    itemName!: string
    uom!: string
    ean11!: string
    hpean!: string
    taxType!: string
    matkl!: string
    scagr!: string
    brandId!: string
    brandDesc!: string
    curcy!: string
    aenkz!: string
    kwdht!: string
    active!: boolean
  }

  export class Item {
    ItemData!: ItemData[];
    // active: boolean;
}
