import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, Radio, InputLabel, Alert, FormHelperText, IconButton, Stack, FormLabel, Grid, FormGroup, Select, Checkbox, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Pricelist, PricelistData, PricelistDataExportExcel } from "../../../types/pricelist.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
// import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { DataGridPro, GridColDef, GridSelectionModel, GridRenderCellParams, GridPinnedColumns, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { PricelistSpecialActionSelector, updatePricelistSpecialData, pricelistSpecialUpdateData, clearPricelistSpecial, PricelistSpecialGetDataById,priceListSpecialGetTemplateDataById } from "../../../store/slices/priceListSpecialActionSlice";
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Loader from "../../component/Loader";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "../../../utils/httpclient";
import { server } from "../../../Constants";
import { exportExcelItem } from "../../../utils/excel";
import { GridPreProcessEditCellProps } from "@mui/x-data-grid";
import ButtonBack from "../../layouts/ButtonBack";
import * as XLSX from "xlsx";
import { readExcelTemplateScale,readExcelTemplateMap } from "../../../utils/excel";

type PriceListSpecialPriceEditPageProps = {
  //
};


const PriceListSpecialPriceEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pricelistSpecialActionReducer = useSelector(PricelistSpecialActionSelector);
  const [pageSize, setPageSize] = React.useState(50);
  const [selectedRows, setSelectedRows] = React.useState<PricelistData[]>([]);
  const [docstatus, setDocstatus] = React.useState<any>("");
  // const [updateRow, setUpdateRow] = React.useState(pricelistActionReducer.result.priceListData);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(pricelistSpecialActionReducer.result.priceListData.filter((x) => x.isSelect === true).map((x) => x.id));
  const match = useMatch("/pricelistspecialprice/edit/:id");
  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'priceLevel', 'itemCode', 'itemName', 'saleUnit', 'siteCode', 'siteGroup'],
  });
  const [fileName, setFileName] = React.useState(null);
  const [docId, setDocId] = React.useState("");
  const [disablebutton, setdisablebutton] = React.useState(false);

  React.useEffect(() => {
    let id = match?.params.id;
    // dispatch(clearPricelist());
    setDocId(id!);
    // dispatch(PricelistSpecialGetDataById(id!));
    
     dispatch(PricelistSpecialGetDataById({formData:[],id:id!,docRef:""}));
    dispatch(PricelistSpacialCheckboxGetDataById(id!));

  }, []);

  const MySwal = withReactContent(Swal)

  const PricelistSpacialCheckboxGetDataById = createAsyncThunk(
    "priceListspecialpriceedit/getPricelistspecialbyID",
    async (id: string, thunkApi) => {
      try {
        const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id!}`);
        setSelectionModel(result.data.priceListData.filter((x) => x.isSelect === true).map((x) => x.id))
        setSelectedRows(result.data.priceListData.filter((x) => x.isSelect === true))
        // console.log("selectionModel done.")
        return result;
      } catch (error: any) {
        let err = error.response.data.message ?? error.message
        return thunkApi.rejectWithValue(err);
      }
    }
  );

  const initialValues: Pricelist = {
    buCode: '',
    priceDescription: '',
    priceDate: new Date(),
    priceLevel: '',
    conditionType: '',
    condRecNo: '',
    priceListData: [],
    ApproveBy: '',
    validFrom: '' || new Date(),
    validTo: '' || new Date(),
    remark: '',
    documentStatus: '',
    isPriority:false,
    attachment:[],
  };

  const handleExcelChange = (e: any) => {
    // e.preventDefault();
    dispatch(clearPricelistSpecial());
    const files = e.target.files;
    setFileName(files[0].name);

    if (e.target.files) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: rABS ? "binary" : "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: [
            "docRef",
            "priceLevel",
            "itemCode",
            "itemName",
            "saleUnit",
            "siteCode",
            "siteGroup",
            "vendor",
            "amountInFinalPrice",

          ],
          blankrows: false,
        });
        const templateData = readExcelTemplateMap(jsonData,"7");       
        // setRowsData(templateData);
        // console.log("templateData:"+ templateData.length)
        CompareDataImport(templateData);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
   
  };

  const CompareDataImport =  (excelData: PricelistData[]) => {
    // dispatch(clearPricelistScale());
    let docRef!: string;
    let models = JSON.parse(JSON.stringify(excelData)) as PricelistData[];
    models.forEach((item)=>{
      docRef = item.condRec!;
    })
  // console.log("docRef:" +docRef)
  // console.log("excelData:" + excelData)
     dispatch(PricelistSpecialGetDataById({formData:excelData,id:docId!,docRef:""}));
    //  setLoadtemplate(false);
  };

  const columns: GridColDef[] = [
        // {field: "isSelect"} , 
    // {
    //   field: "isSelect", headerName: "", width: 100, editable: true, type: 'boolean',
    //   renderHeader: () => (
    //     <Checkbox color="success" />
    //   ),
    //   renderCell: (params: GridRenderCellParams<boolean>) => (
    //     <Checkbox color="success" checked={params.value} />
    //   ),
    // },
    {
      field: "priceLevel", headerName: "Price Level", width: 100,

      cellClassName: 'super-app-theme--header--pined'
    },
    {
      field: "itemCode", headerName: "Article Code", width: 100,
      cellClassName: 'super-app-theme--header--pined'
    },
    {
      field: "itemName", headerName: "Article Name", width: 200,
      cellClassName: 'super-app-theme--header--pined'
    },
    {
      field: "saleUnit", headerName: "Sales Unit", width: 100,
      cellClassName: 'super-app-theme--header--pined'
    },
    {
      field: "siteCode", headerName: "Site", width: 150
    },
    {
      field: "siteGroup", headerName: "Site Group", width: 150
    },
    // { field: "saleOrg", headerName: "Sales Organization", width: 100 },
    // { field: "distributionChannel", headerName: "Distribution Channel", width: 150 },
    // { field: "priceList", headerName: "Price List", width: 150 },

    { field: "vendor", headerName: "Vendor", width: 150 ,editable: true,},
    // {
    //   field: "validFrom", headerName: "Valid From", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "validTo", headerName: "Valid To", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // { field: "amountInMargin", headerName: "Amount in Margin", width: 150, type: 'number', },
    {
      field: "amountInFinalPrice", headerName: "Amount in Final Price", width: 150, editable: true,
      type: 'number', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--header'
      ,      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    // {
    //   field: "priceQTY1", headerName: "PQtyB1 (Scale 1)", width: 150,
    //   type: 'number'
    // },
    // {
    //   field: "price1", headerName: "PMPrice1 (Scale 1)", width: 150,
    //   type: 'number'
    // },
    // { field: "priceQTY2", headerName: "PQtyB2 (Scale 2)", width: 150, type: 'number' },
    // { field: "price2", headerName: "PMPrice2 (Scale 2)", width: 150, type: 'number' },
    // { field: "priceQTY3", headerName: "PQtyB3 (Scale 3)", width: 150, type: 'number' },
    // { field: "price3", headerName: "PMPrice3 (Scale 3)", width: 150, type: 'number' },
    // { field: "priceQTY4", headerName: "PQtyB4 (Scale 4)", width: 150, type: 'number' },
    // { field: "price4", headerName: "PMPrice4 (Scale 4)", width: 150, type: 'number' },
    // { field: "priceQTY5", headerName: "PQtyB5 (Scale 5)", width: 150, type: 'number' },
    // { field: "price5", headerName: "PMPrice5 (Scale 5)", width: 150, type: 'number' },
    {
      field: "message", headerName: "Message", width: 300,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "red",
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      }
    },
  ];



  const handleSave = (docstatus: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
  }

  // const handleCellEditCommit = ({ id, field, value }: any) => {
  //   //console.log({ id, field, value });
  //   const newUpdatedRows = updateRow.map((row) => {
  //     //console.log(field)
  //     if (row.id === id) {
  //       return { ...row, [field]: value };
  //     }
  //     return row;
  //   });
  //   // const field = 'xyz'
  //   // row.field = '123'
  //   // row['field'] = '123'
  //   // row = {field: '123'}
  //   // // row = {field: 123}

  //   // row[field] = '123'
  //   // row = { [field]: '123' }
  //   // row = {xyz: 123}
  //   // console.log('after', newUpdatedRows);
  //   setUpdateRow(newUpdatedRows);
  // }

  const handleCellEditCommit = ({ id, field, value }: any) => {
    //console.log({ id, field, value });
    const newUpdatedRows = pricelistSpecialActionReducer.result.priceListData!.map((row) => {
      // console.log(field)
      if(typeof value != "number")
      {
      }else{
        // console.log("checkNum: false" + value)
        value = Number(Number(value!).toFixed(2))
     
      }
      if (row.id === id) {
        return { ...row, [field]: value,message:'' };
      }

      return row;
    });
    // setUpdateRow(newUpdatedRows);
    setdisablebutton(false);
    dispatch(updatePricelistSpecialData(newUpdatedRows))
  }

  const ExportExcel = (excelData: any) => {
    let pricelistDatas: PricelistDataExportExcel[] = [];

    excelData.forEach((item: any) => {
      let pricelistData = new PricelistDataExportExcel();
      pricelistData.priceLevel = item.priceLevel
      pricelistData.itemCode = item.itemCode
      pricelistData.saleUnit = item.saleUnit
      pricelistData.siteCode = item.siteCode
      pricelistData.siteGroup = item.siteGroup
      // pricelistData.saleOrg = item.saleOrg
      // pricelistData.distributionChannel = item.distributionChannel
      // pricelistData.priceList = item.priceList
      // pricelistData.vendor = item.vendor
      // pricelistData.amountInMargin = item.amountInMargin === null ? 0 : item.amountInMargin
      pricelistData.amountInFinalPrice = item.amountInFinalPrice === null ? 0 : item.amountInFinalPrice
      pricelistData.priceQTY1 = item.priceQTY1 === null ? 0 : item.priceQTY1
      pricelistData.price1 = item.price1 === null ? 0 : item.price1
      pricelistData.priceQTY2 = item.priceQTY2 === null ? 0 : item.priceQTY2
      pricelistData.price2 = item.price2 === null ? 0 : item.price2
      pricelistData.priceQTY3 = item.priceQTY3 === null ? 0 : item.priceQTY3
      pricelistData.price3 = item.price3 === null ? 0 : item.price3
      pricelistData.priceQTY4 = item.priceQTY4 === null ? 0 : item.priceQTY4
      pricelistData.price4 = item.price4 === null ? 0 : item.price4
      pricelistData.priceQTY5 = item.priceQTY5 === null ? 0 : item.priceQTY5
      pricelistData.price5 = item.price5 === null ? 0 : item.price5
      pricelistDatas.push(pricelistData);
    });

    let Heading = [['Price Level', 'Article Code', 'Sales Unit', 'Site', 'Site Group', 'Sales Organization', 'Distribution Channel', 'Price List', 'Vendor', 'Amount in Margin', 'Amount in Final Price', 'PQtyB1 (Scale 1)', 'PMPrice1 (Scale 1)', 'PQtyB2 (Scale 2)', 'PMPrice2 (Scale 2)', 'PQtyB3 (Scale 3)', 'PMPrice3 (Scale 3)', 'PQtyB4 (Scale 4)', 'PMPrice4 (Scale 4)', 'PQtyB5 (Scale 5)', 'PMPrice5 (Scale 5)']];

    exportExcelItem(pricelistDatas, Heading, 'PriceList' + moment().format('YYYY-MM-DD_hh:mm:ss').toString());
  }

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Pricelist>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Edit Special Price (lv7)
            </Typography>

            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="templateName">{t('templateName')}</InputLabel>
              <Select
                name="templateName"
                onChange={(event) => {
                  setFieldValue('templateName', event.target.value);
                  setTemplateId(event.target.value);
                }}>
                {templateReducer.result.map((item, index) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.templateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              style={{ marginBottom: 10, marginTop: 10 }}
              variant="contained"
              color="warning"
              type="button"
              sx={{ marginRight: 1 }}
              disabled={templateReducer.isFetching}
              onClick={onloadtemplate}
            >Load Template</Button> */}

            <TextField
              id="priceDescription"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t("priceDescription")}
              onChange={e =>{
                handleChange(e)
                setdisablebutton(false)}}
              onBlur={handleBlur}
              value={values.priceDescription}
              // helperText={
              //   errors.priceDescription && touched.priceDescription
              //     ? errors.priceDescription
              //     : "Enter your priceDescription."
              // }
              error={errors.priceDescription && touched.priceDescription ? true : false}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t('validFrom')}
                value={values.validFrom}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(0)}
                maxDate={new Date().setDate(365)}
                onChange={(validFrom) => {
                  setFieldValue('validFrom', validFrom);
                  setdisablebutton(false);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            To
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker
                label={t('validTo')}
                value={values.validTo}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(0)}
                maxDate={new Date().setDate(365)}
                onChange={(validTo) => {
                  setFieldValue('validTo', validTo);
                  setdisablebutton(false);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <TextField id="remark"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              multiline
              rows={5}
              inputProps={{ maxLength: 1000 }}
              variant="outlined"
              label={t('remark')}
              onChange={e =>{
                handleChange(e)
                setdisablebutton(false)}}
              onBlur={handleBlur}
              value={values.remark}
              helperText={
                errors.remark && touched.remark
                  ? errors.remark
                  : 'Enter your remark.'
              }
              error={
                errors.remark && touched.remark
                  ? true
                  : false
              } />

            {/* <Button
              style={{ marginBottom: 10, marginTop: 10 }}
              variant="outlined" color="info"
              type="button"
              sx={{ marginRight: 1 }}
              // disabled={templateReducer.isFetching}
              // onClick={onloadtemplate}
              onClick={(e) => ExportExcel(selectedRows)}
            > {t('exportbtn')}
            </Button> */}
            <CardActions>
      
              <Button variant="contained" component="label">
                Import Template
                <input
                  hidden
                  type="file"
                  multiple={false}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleExcelChange.bind(this)}
                  onClick={(event: any) => {
                    event.target.value = null;
                
                    //  dispatch(updatePricelistScaleData(pricelistDatas));
                  }}
                />
              </Button><div/>
              <ButtonBack  ></ButtonBack>
            </CardActions>

            <DataGridPro
              checkboxSelection
              sx={{
                backgroundColor: "white", height: "80vh", width: '100%',
                '& .super-app-theme--header': {
                  backgroundColor: '#6dc270',
                },
                // '& .super-app-theme--header--pined': {
                //   backgroundColor: '#fffffc',
                // },

              }}
              // pinnedColumns={pinnedColumns}
              initialState={{ pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'priceLevel','itemCode','itemName','saleUnit','siteCode','siteGroup'] } }}
              getRowId={(row) => row.id}
              rows={pricelistSpecialActionReducer.result ? pricelistSpecialActionReducer!.result!.priceListData! : []}
              columns={columns}
              rowsPerPageOptions={[10, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
              disableColumnSelector={true}
              onSelectionModelChange={(e) => {
                setSelectionModel(e);
                const selectedIDs = new Set(e);
                const selectedRows = pricelistSpecialActionReducer.result.priceListData!.filter((row) => selectedIDs.has(row.id));
                setSelectedRows(selectedRows);
                setdisablebutton(false);
                // dispatch(selectPricelist(selectedRows));
              }}

              selectionModel={selectionModel}
              onCellEditCommit={handleCellEditCommit}
              disableSelectionOnClick={true}
            />

            <FormControl style={{ marginTop: 16, }} fullWidth>
              {pricelistSpecialActionReducer.isError && <Alert severity="error">{pricelistSpecialActionReducer.message}</Alert>}
            </FormControl>

          </CardContent>

          <CardActions>
            <Button fullWidth
              variant="contained"
              color="info"
              type="submit"
              onClick={() => handleSave({ documentStatus: "Draft" })}
              sx={{ marginRight: 1 }}
              disabled={disablebutton==true}
              // disabled={pricelistSpecialActionReducer.result.documentStatus !== "Draft" }//|| (disablebutton==true&&pricelistSpecialActionReducer.isError==false)}
            >  Save Draft
            </Button>

            <Button
              variant="contained"
              fullWidth color="success"
              type="submit"
              onClick={() => handleSave({ documentStatus: "Waiting for Approve" })}
              // disabled={(pricelistSpecialActionReducer.result.documentStatus !== "Draft" ) }//|| (disablebutton==true&&pricelistSpecialActionReducer.isError==false)}
              disabled={disablebutton==true}
            > Send Approve
            </Button>

            <Button component={Link} to="/pricelist"
              variant="outlined" fullWidth
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  if (pricelistSpecialActionReducer.isFetching) {
    return <><Loader /></>
  }


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    priceDescription: Yup.string().required(inputRequired),
  });
  return (
    <>
      <Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={pricelistSpecialActionReducer.result ? pricelistSpecialActionReducer.result! : initialValues}
          onSubmit={(values, { setSubmitting }) => {
            // console.log("Select",selectionModel);
            const valuesToSend = {
              ...values,
              priceLevel: pricelistSpecialActionReducer!.result!.priceListData[0].priceLevel,
              // priceListData: docstatus.documentStatus === "Draft" ? pricelistSpecialActionReducer!.result!.priceListData : selectedRows,
              priceListData: pricelistSpecialActionReducer!.result!.priceListData ,
              documentStatus: docstatus.documentStatus,
              // vendor:'0'+ values.vendor!,
              wfStep: 1,
              wfStepAll: 1,
            }
            let alertText ;
            if (docstatus.documentStatus ==="Draft"){
              alertText = "ยืนยันการบันทึก Draft?"
            }
            else{
              alertText = "ยืนยันการส่ง Approve?"
            }
            if ((selectedRows.length === 0)) {
              MySwal.fire({
                title: <p>{t('itemselected')}</p>,
                didOpen: () => {
                  // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                },
              })
            }
            else {
              Swal.fire({
                title: 'Confirm!',
                text: alertText,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCancelButton: true,
              }).then((result) => {
                if (result.isConfirmed) {             
                  dispatch(pricelistSpecialUpdateData({ formData: valuesToSend, selectList: selectedRows }));
                  setSubmitting(false);
                  // Swal.fire('ดำเนินการเรียบร้อย!', '', 'success')
                  
                     setdisablebutton(true);
                
                } 
              })


            }
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default PriceListSpecialPriceEditPage;
