import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Brand } from "../../types/brand.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type BrandState = {
    result: Brand[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: BrandState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const brandGetData = createAsyncThunk(
    "brand/getBrand",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<Brand[]>(server.BRAND_URL + "?keyword=" + keyword);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const brandSlice = createSlice({
    name: "brand",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(brandGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(brandGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(brandGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = brandSlice.actions;
export const brandSelector = (store: RootState) => store.brandReducer;
export default brandSlice.reducer;