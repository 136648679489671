import { Button } from "@mui/material";
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { t } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { history } from "../../../index";

type ButtonBackToHomeProps = {
  //
};

const ButtonBackToHome: React.FC<any> = () => {
  return <div>
    <Button
      style={{ marginBottom: 10,marginTop: 10 }}
      variant="contained" color="inherit"
      type="button"
      sx={{ marginRight: 1 }}
      onClick={() =>  
        window.location.assign('/home')}
    >
      {t('backbtn')}
    </Button>
  </div>;
};

export default ButtonBackToHome;
