import React from 'react'
import './loader.css'

type Props = {
    loading?: boolean;
}

const Loader = () => {

    return <div className="loading"></div>


}


export default Loader