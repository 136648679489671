import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { brandActionSelector, brandCreateData } from "../../../store/slices/brandActionSlice";
import { Brand } from "../../../types/brand.type";
import Loader from "../../component/Loader";


type BrandCreatePageProps = {
  //
};

const initialValues: Brand = { id: "", brandId: "", brandName: "",  active: true ,createdBy: "" };

const BrandCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const brandActionReducer = useSelector(brandActionSelector);


  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Brand>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create Brand
            </Typography>

            <TextField id="brandId"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('Brand Id')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.brandId && touched.brandId
                  ? errors.brandId
                  : ''
              }
              error={
                errors.brandId && touched.brandId
                  ? true
                  : false
              } />

            <TextField id="brandName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('Brand Name')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.brandName && touched.brandName
                  ? errors.brandName
                  : ''
              }
              error={
                errors.brandName && touched.brandName
                  ? true
                  : false
              } />



            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {brandActionReducer.isError && <Alert severity="error">{brandActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={brandActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/brand" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    brandId: Yup.string().required(inputRequired),
    brandName: Yup.string().required(inputRequired),
  });


  if (brandActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {

          dispatch(brandCreateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default BrandCreatePage;
