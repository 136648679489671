import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { Item, ItemData } from "../../types/items.type";
import { history } from "../../index";


type ItemsData = {
    result: ItemData| null;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: ItemsData = {
    result:null,
    message: "",
    isFetching: false,
    isError: false,
};


export const itemsCreateData = createAsyncThunk(
    "items/createItems",
    async (formData: Item, thunkApi) => {
        try {
            const result = await httpClient.post<ItemData[]>(server.ITEMS_URL, formData);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const itemsImportData = createAsyncThunk(
    "items/importItems",
    async (formData: Item, thunkApi) => {
        try {
            if (formData.ItemData.length>601 ){
                return thunkApi.rejectWithValue("ข้อมูลต้องไม่เกิน 600 rows!");
            }
            const result = await httpClient.post<ItemData[]>(server.ITEMS_URL + "/import", formData);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const ItemUpdateData = createAsyncThunk(
    "items/putItems",
    // async (formData: Pricelist, thunkApi) => {
    async (formData: ItemData, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.ITEMS_URL}/${formData.id}`, formData);
            return result.data.Data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const ItemGetDataById = createAsyncThunk(
    "items/ItemGetDataById",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<ItemData>(`${server.ITEMS_URL}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const itemsActionSlice = createSlice({
    name: "Createitems",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(itemsCreateData.pending, (state, action) => {
                // state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(itemsCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(itemsCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(itemsImportData.pending, (state, action) => {
                // state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(itemsImportData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(itemsImportData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(ItemUpdateData.pending, (state, action) => {
                // state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(ItemUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(ItemUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(ItemGetDataById.pending, (state, action) => {
                // state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(ItemGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(ItemGetDataById.rejected, (state, action: PayloadAction<any>) => {
                // state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = itemsActionSlice.actions;
export const itemsActionSelector = (store: RootState) => store.itemsActionReducer;
export default itemsActionSlice.reducer;