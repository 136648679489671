import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Chip, Fab, IconButton, Stack,Typography } from "@mui/material";
import { Add} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { PricelistScaleGetData, PricelistScaleSelector, reloadPricelistScaleData} from "../../../store/slices/priceListScaleSlice";
import Loader from "../../component/Loader";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { PricelistLog } from "../../../types/pricelist.type";
import { httpClient } from "../../../utils/httpclient";
import { server } from "../../../Constants";
import Swal from "sweetalert2";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import InfoIcon from '@mui/icons-material/Info';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ButtonBackToHome from "../../layouts/ButtonBackToHome";
import { pricelistDeleteData } from "../../../store/slices/priceListActionSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { reloadPricelistSpecialData } from "../../../store/slices/priceListSpecialSlice";

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

const PriceListSpecialPrice: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pricelistScaleReducer = useSelector(PricelistScaleSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const [specialprice, setSpecialprice] = React.useState<string>("7")
  const [value, setValue] = React.useState(0);
  const [valueDel, setValueDel] = React.useState(0);

  React.useEffect(() => {
    dispatch(PricelistScaleGetData(specialprice));
  }, [keywordSearch])

  const handleReprocess = (row: any) => {
    // console.log(row)
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(PricelistScaleGetData(specialprice));
    // dispatch(PricelistGetByStatus({
    //   formData:pricelistReducer.result!,
    //   type:newValue
    // }))
  };


  const handleDeleteConfirm = (e: any) => {
    // setRowsData(rowsData.filter((row) => row.id !== e.id));
    Swal.fire({
      title: 'คุณต้องการลบเอกสาร :   ',
      text: e.priceDescription! + ' ?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(pricelistDeleteData(e.id!));
        // dispatch(templateGetData(""));
        Swal.fire('ลบเรียบร้อย!', '', 'success')
        // dispatch(reloadTemplateData(templateReducer.result!));
        const newUpdatedRows = pricelistScaleReducer.result!.filter((row) => row.id !== e.id!)
        // setUpdateRow(newUpdatedRows);
        dispatch(reloadPricelistScaleData(newUpdatedRows));
        // dispatch(PricelistGetData(keywordSearch));
      } 
    })
  
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3, pl: 0, pr: 0 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const logSwal = withReactContent(Swal)

  const PricelistGetLog = async (row: any) => {
    try {
      const result = await httpClient.get<PricelistLog[]>(`${server.PRICELISTSITE_URL}/${row.id}`);
      // JSON.stringify(result.data)
      logSwal.fire({
        title: <strong>{t("LogEror")}</strong>,
        html: `<table border="1" align="center" class="w3-table-all" style="auto; height:auto;">
            <thead>
                <tr>
                    
                    <th>No</th>
                    <th>Site</th>
                    <th>POS</th>
                    <th>SAP</th>
                    <th>WFP</th>
                </tr>
            </thead>
            <tbody> ${result.data.map((row: PricelistLog, index) => ` <tr>
                    <td>${index + 1}</td>
                    <td>${row.site === null ? "" : row.site}</td>
                    <td>${row.posMessage === null ? "" : row.posMessage}</td>
                    <td>${row.sapMessage === null ? "" : row.sapMessage}</td>
                    <td>${row.wfpMessage === null ? "" : row.wfpMessage}</td>
                  
                </tr> `).join('')} </tbody>
        </table>
        `,
        showClass: {
          popup: 'animate__animated animate__bounceInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        customClass: 'swal-wide',
      })
      // return result;
    } catch (error: any) {
      let err = error.response.data.message ?? error.message

    }
    return PricelistGetLog
  }




  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0, }}>
        <div style={{ height: '60px' }}></div>

        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/pricelistspecialprice/create"
          sx={{ position: "absolute", top: 10, right: 10, }}>
          <Add />
        </Fab>
      </Box>
    );
  }


  const columns: GridColDef[] = [
    {
      headerName: "Edit",
      field: ".",
      width: 80,
      renderHeader: () => (<strong> {t('GridEdit')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              navigate("/pricelistspecialprice/edit/" + row.id);
            }}
          >
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      headerName: "Preview",
      field: "preview",
      width: 80,
      renderHeader: () => (<strong> {t('GridPreview')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="preview"
            size="large"
            onClick={() => {
              // navigate("/pricelist/preview/" + row.id);
              navigate("/pricelistspecial/preview/" + row.id);
            }}
          >
            <VisibilityIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      headerName: "Delete",
      field: "Delete",
      width: 80,
      renderHeader: () => (<strong> {t('GridDelete')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="Delete"
            size="large"
            disabled={valueDel > 0}
            // hidden={valueDel2}
            // disabled={pricelistReducer.result!.filter((x) => x.documentStatus !=="Draft")}
            onClick={() => {
              handleDeleteConfirm(row);
            }}
          >
            <DeleteIcon fontSize="inherit" style={{ color: "#f00c50" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "priceDescription", width: 250,
      renderHeader: () => (<strong> {t('priceDescription')} </strong>),
    },
    {
      field: "priceDate", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
    },
    // {
    //   field: "conditionType", width: 80,headerAlign: 'center',align: 'center',
    //   renderHeader: () => (<strong> {t('conditionType')} </strong>),
    // },
    {
      field: "remark", width: 300,
      renderHeader: () => (<strong> {t('remark')} </strong>),
    },
    {
      field: "documentStatus", width: 150,
      renderHeader: () => (<strong> {t('documentStatus')} </strong>),
    },
    {
      field: "createdBy", width: 150,
      renderHeader: () => (<strong> {t('createdBy')} </strong>),
    },
    // {
    //   headerName: "POS",
    //   field: "sendToPos",
    //   type: 'string',
    //   width: 100,
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     row.sendToPos == "Queue" ? <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
    //       : row.sendToPos == "Successed" ? <Chip className="w-chip" label={row.sendToPos} color="success"></Chip>
    //         : row.sendToPos == "Processing" ? <Chip className="w-chip" label={row.sendToPos} color="info"></Chip>
    //           : <Chip className="w-chip" label={row.sendToPos} color="error"></Chip>
    //   ),
    // },
    // {
    //   // headerName: "SAP",
    //   renderHeader: () => (<strong> {t('sendToSap')} </strong>),
    //   field: "sendToSap",
    //   type: 'boolean',
    //   width: 100,headerAlign: 'center',align: 'center',
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     row.sendToSap == "Queue" ? <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
    //       : row.sendToSap == "Successed" ? <Chip className="w-chip" label={row.sendToSap} color="success"></Chip>
    //         : row.sendToSap == "Processing" ? <Chip className="w-chip" label={row.sendToSap} color="info"></Chip>
    //           : row.sendToSap == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToSap} color="error"></Chip>
    //             : <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
    //   ),
    // },
    // {
    //   // headerName: "WFP",
    //   renderHeader: () => (<strong> {t('sendToWFP')} </strong>),
    //   field: "sendToWFP",
    //   type: 'boolean',
    //   width: 100,headerAlign: 'center',align: 'center',
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     row.sendToWFP == "Queue" ? <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
    //       : row.sendToWFP == "Successed" ? <Chip className="w-chip" label={row.sendToWFP} color="success"></Chip>
    //         : row.sendToWFP == "Processing" ? <Chip className="w-chip" label={row.sendToWFP} color="info"></Chip>
    //           : row.sendToWFP == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToWFP} color="error"></Chip>
    //             : <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
    //   ),
    // },
  ];

  const columns2: GridColDef[] = [
    // {
    //   headerName: "Edit",
    //   field: ".",
    //   width: 80,
    //   renderHeader: () => (<strong> {t('GridEdit')} </strong>),
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     <Stack direction="row">
    //       <IconButton
    //         aria-label="edit"
    //         size="large"
    //         onClick={() => {
    //           navigate("/pricelistspecialprice/edit/" + row.id);
    //         }}
    //       >
    //         <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
    //       </IconButton>
    //     </Stack>
    //   ),
    // },
    {
      headerName: "Preview",
      field: "preview",
      width: 80,
      renderHeader: () => (<strong> {t('GridPreview')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="preview"
            size="large"
            onClick={() => {
              // navigate("/pricelist/preview/" + row.id);
              navigate("/pricelistspecial/preview/" + row.id);
            }}
          >
            <VisibilityIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "priceDescription", width: 250,
      renderHeader: () => (<strong> {t('priceDescription')} </strong>),
    },
    {
      field: "priceDate", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
    },
    // {
    //   field: "conditionType", width: 80,headerAlign: 'center',align: 'center',
    //   renderHeader: () => (<strong> {t('conditionType')} </strong>),
    // },
    {
      field: "remark", width: 300,
      renderHeader: () => (<strong> {t('remark')} </strong>),
    },
    {
      field: "documentStatus", width: 150,
      renderHeader: () => (<strong> {t('documentStatus')} </strong>),
    },
    {
      field: "createdBy", width: 150,
      renderHeader: () => (<strong> {t('createdBy')} </strong>),
    },
    {
      field: "ApproveBy", width: 150,
      renderHeader: () => (<strong> {t('Approver')} </strong>),
    },
    // {
    //   headerName: "POS",
    //   field: "sendToPos",
    //   type: 'string',
    //   width: 100,
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     row.sendToPos == "Queue" ? <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
    //       : row.sendToPos == "Successed" ? <Chip className="w-chip" label={row.sendToPos} color="success"></Chip>
    //         : row.sendToPos == "Processing" ? <Chip className="w-chip" label={row.sendToPos} color="info"></Chip>
    //           : <Chip className="w-chip" label={row.sendToPos} color="error"></Chip>
    //   ),
    // },
    {
      // headerName: "SAP",
      renderHeader: () => (<strong> {t('sendToSap')} </strong>),
      field: "sendToSap",
      type: 'boolean',
      width: 100,headerAlign: 'center',align: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToSap == "Queue" ? <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
          : row.sendToSap == "Successed" ? <Chip className="w-chip" label={row.sendToSap} color="success"></Chip>
            : row.sendToSap == "Processing" ? <Chip className="w-chip" label={row.sendToSap} color="info"></Chip>
              : row.sendToSap == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToSap} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
      ),
    },
    {
      // headerName: "WFP",
      renderHeader: () => (<strong> {t('sendToWFP')} </strong>),
      field: "sendToWFP",
      type: 'boolean',
      width: 100,headerAlign: 'center',align: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToWFP == "Queue" ? <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
          : row.sendToWFP == "Successed" ? <Chip className="w-chip" label={row.sendToWFP} color="success"></Chip>
            : row.sendToWFP == "Processing" ? <Chip className="w-chip" label={row.sendToWFP} color="info"></Chip>
              : row.sendToWFP == "Failed" ? <Chip onClick={() => PricelistGetLog(row)} className="w-chip" label={row.sendToWFP} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
      ),
    },
  ];

  if (pricelistScaleReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          SPECIAL PRICE (Lv7)
        </Typography>

        <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider'  }}>
                <Tabs  value={value} onChange={handleChangeTab} aria-label="PriceList" variant="fullWidth">
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<LibraryAddIcon />} label={t('StatusDraft')} {...a11yProps(0)} />
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }}icon={<HowToRegIcon />} label={t('StatusWaitForApprove')}{...a11yProps(1)} />
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<DoneOutlineIcon />} label={t('StatusApproved')} {...a11yProps(2)} />
                </Tabs>
              </Box>
        <TabPanel value={value} index={0}>
              <DataGridPro
                // checkboxSelection={true}
                disableSelectionOnClick={true}
                sx={{ backgroundColor: "white", height: "80vh" }}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    value: keywordSearchNoDelay,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setKeywordSearch(e.target.value);
                      setKeywordSearchNoDelay(e.target.value);
                    },
                    clearSearch: () => {
                      setKeywordSearch("");
                      setKeywordSearchNoDelay("");
                    },
                  },
                }}
                getRowId={(row) => row.id}
                // rows={templateReducer.result!}
                rows={pricelistScaleReducer.result!.filter((x) => x.documentStatus==="Draft" && Number(x.priceLevel!) === 7)}
                columns={columns}
                // pageSize={50}
                pagination
                rowsPerPageOptions={[10]}
              />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <DataGridPro
              // checkboxSelection={true}
              disableSelectionOnClick={true}
              sx={{ backgroundColor: "white", height: "80vh" }}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: keywordSearchNoDelay,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setKeywordSearch(e.target.value);
                    setKeywordSearchNoDelay(e.target.value);
                  },
                  clearSearch: () => {
                    setKeywordSearch("");
                    setKeywordSearchNoDelay("");
                  },
                },
              }}
              getRowId={(row) => row.id}
              // rows={templateReducer.result!}
              rows={pricelistScaleReducer.result!.filter((x) => x.documentStatus==="Waiting for Approve"&& Number(x.priceLevel!) ===7)}
              columns={columns2}
              // pageSize={50}
              pagination
              rowsPerPageOptions={[10]}
            />
        </TabPanel>
        <TabPanel value={value} index={2}>
                <DataGridPro
                  // checkboxSelection={true}
                  disableSelectionOnClick={true}
                  sx={{ backgroundColor: "white", height: "80vh" }}
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      value: keywordSearchNoDelay,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setKeywordSearch(e.target.value);
                        setKeywordSearchNoDelay(e.target.value);
                      },
                      clearSearch: () => {
                        setKeywordSearch("");
                        setKeywordSearchNoDelay("");
                      },
                    },
                  }}
                  getRowId={(row) => row.id}
                  // rows={templateReducer.result!}
                  rows={pricelistScaleReducer.result!.filter((x) => x.documentStatus==="Approved"&& Number(x.priceLevel!) ===7)}
                  columns={columns2}
                  // pageSize={50}
                  pagination
                  rowsPerPageOptions={[10]}
                />
        </TabPanel>
       </Box>

      </CardContent>
    </Card>
  );
};

export default PriceListSpecialPrice;

