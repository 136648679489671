import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { User } from "../../types/user.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";

type UserState = {
    result: User[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: UserState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const userGetData = createAsyncThunk(
    "user/getUsers",
    async (iaAd: boolean, thunkApi) => {
        try {
            const result = await httpClient.get<User[]>(server.USER_URL + "?isad=" + iaAd);
            // console.log(result)
            return result;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const userSlice = createSlice({
    name: "user",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(userGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(userGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(userGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = userSlice.actions;
export const userSelector = (store: RootState) => store.userReducer;
export default userSlice.reducer;