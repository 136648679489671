import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Approve } from "../../types/approve.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";

type ApproveState = {
    result: Approve[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: ApproveState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const approveGetData = createAsyncThunk(
    "approvelist",
    async (type:Number, thunkApi) => {
        try {
            const result = await httpClient.get<Approve[]>(server.APPROVE_URL + "/todolist");
            //   if (type ==0){
            //         const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==1 || Number(x.priceLevel)==2)
            //         result.data = selectData
            //     }else if(type==1){
            //         const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==5 )  
            //         result.data = selectData
            //     }else{
            //         const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==7 )
            //         result.data = selectData
            //     }
            return result;
            
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const approveGetDataByPriceLevel = createAsyncThunk(
    "approvelistByPriceLevel",
    async (type:Number, thunkApi) => {
        try {
            let priceLevel
            // const result = await httpClient.get<Approve[]>(`${server.APPROVE_URL}/todolist/${priceLevel}`)
            const result = await httpClient.get<Approve[]>(server.APPROVE_URL + "/todolist");
            
                // if (type ==0){
                //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==1 || Number(x.priceLevel)==2)
                //     result.data = selectData
                // }else if(type==1){
                //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==5 )  
                //     result.data = selectData
                // }else{
                //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==7 )
                //     result.data = selectData
                // }
         
          
            return result;
            
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const approveSlice = createSlice({
    name: "approve",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(approveGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(approveGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(approveGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = approveSlice.actions;
export const approveSelector = (store: RootState) => store.approveReducer;
export default approveSlice.reducer;