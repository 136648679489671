import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { SxProps } from "@mui/system";
import { Alert, Button, Stack, Theme, } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./LoginPage.css"
import {useLocation,useNavigate} from "react-router";


type LoginPageProps = {
  //
};

const LoginPage: React.FC<any> = () => {
  let isError = false;
  let errMassage = "";

  const { t } = useTranslation()
  // const dashboardURL = window.location.origin
  // const loginRedirectURL = new URL(window.location.origin + '/login/redirect')
  // loginRedirectURL.searchParams.append('continue', dashboardURL)
const navigate = useNavigate();
const location = useLocation();


  const [searchParams] = useSearchParams();

  if (searchParams.get("key") === "401") {
    errMassage = "Username not authorize.";
    isError = true;
  }
  else if (searchParams.get("key") === "500") {
    errMassage = "Invalid token.";
    isError = true;
  } else {
    isError = false;
  }

  const classses: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center", alignItems: 'center', },
    buttons: { marginTop: 2 },
  };


  return (
     <div className="pageContainer">
      {isError && <Alert severity="error">{errMassage}</Alert>}

      <Stack direction="row" spacing={2} sx={classses.buttons}>
        <Button type="submit"
          fullWidth
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_API_HOST + '/authentication/aad?continue=' + window.location.origin + '/login/redirct'}>
          {t('loginWithBetagroAccount')}
        </Button>
        {/* <button
          onClick={()=>{
             if(location.state?.from){
              navigate(location.state.from);
              }else{
                navigate("/home");
              }
          }
          }
        >
          Login
        </button> */}
      </Stack>
    </div>
  );
};

export default LoginPage;
