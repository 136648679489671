import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Pricelist, PricelistData,PricelistDataExportExcel } from "../../types/pricelist.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";
import uuid from "react-uuid";
import moment from "moment";

type PricelistState = {
    result: Pricelist;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialValues: Pricelist = {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    priceLevel: "",
    conditionType: "",
    condRecNo: "",
    priceListData: [],
    remark: "",
    documentStatus: "",
};
const initialStateValues: PricelistState = {
    result: initialValues,
    message: "",
    isFetching: false,
    isError: false,
};




export const pricelistScaleCreateData = createAsyncThunk(
    "pricelistscale/postpricelistscale",
    async ({ formData, selectList }: { formData: Pricelist, selectList: PricelistData[]}, thunkApi) => {
        try {
            // console.log("Post Create")
            // console.log("data :" + formData.priceListData!.length + " : " + formData.priceListData!) 
            let priceListDataSite = [];

            // if (formData.priceListData!.length > 0){
            //     console.log("Post Create chk row :" + formData.priceListData)
            //     // for await (let item of formData.priceListData!) {
            //     formData.priceListData.forEach((item)=>{
            //         console.log("item.isSelect:"+item.isSelect + ";" + item.message.trim())
            //         if (item.isSelect ==true){
            //             if(item.message.trim() !==""){
            //                 return thunkApi.rejectWithValue("Data invalid!");
            //               }  
            //         }
                    
            //     })

             
            // }  else{
            //     return thunkApi.rejectWithValue("Please select data!");
            // }
            // console.log("Post Create : Check site")
            // if (formData.site.length == 0) {
            //     return thunkApi.rejectWithValue("Please select site!");
            // }     
            // console.log("Post Create : Check date")
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate()-1);

            let dateFrom = new Date(formData.validFrom!);
            let dateTo= new Date(formData.validTo!);
            let dateNow = new Date(currentDate);
            let dateFrom_normal = new Date(formData.priceListData[0].validFrom!);
            // console.log("formData.priceListData[0].validFrom"+formData.priceListData[0].validFrom)
            let dateTo_normal= new Date(formData.priceListData[0].validTo!);
            // console.log("validFromNM :" + dateFrom_normal.getDate() + "  ,validToNM :"+ dateTo_normal.getDate() )
            // console.log("date1:"+ dateFrom.getDate() + " date2:" +dateTo.getDate() + "now:"+dateNow.getDate())
            //**check date new transaction match with normal price template */
            if (dateTo.setHours(0, 0, 0, 0) > dateTo_normal.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("!ช่วงวันที่กำหนดไม่ตรงกับ Template!-Template Date From :"+ moment(dateFrom_normal).format("DD-MM-YYYY") +", Template Date To:"+ moment(dateTo_normal).format("DD-MM-YYYY"));
            }
            if (dateFrom.setHours(0, 0, 0, 0) < dateFrom_normal.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ช่วงวันที่กำหนดไม่ตรงกับ Template!-Template Date From :"+ moment(dateFrom_normal).format("DD-MM-YYYY") +", Template Date To:"+ moment(dateTo_normal).format("DD-MM-YYYY"));
                // return thunkApi.rejectWithValue("ValidFrom Or validTo not match NormalPrice Template!");
            }

            // if (dateFrom.setHours(0, 0, 0, 0) < dateNow.setHours(0, 0, 0, 0)) {
            //     return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
            // }
            // const start = new Date();
            // start.setDate(start.getDate())
            // if (dateFrom.setHours(0, 0, 0, 0) > dateNow.setHours(0, 0, 0, 0)) {
            //     return thunkApi.rejectWithValue("Valid From ไม่สามารถกำหนดเป็นวันปัจจุบันได้!");
            // }
            if (dateTo.setHours(0, 0, 0, 0) <dateFrom.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ช่วงวันที่ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            // console.log("checkValueSlice : "+formData.priceListData[0].price3)
            let priceListData: PricelistData[] = [];
            for (let i = 0; i < formData.priceListData.length; i++) {
                let found = selectList.find((x) => x.id === formData.priceListData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: true ,priceLevel:"5",validFrom:dateFrom, validTo:dateTo, }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: false,priceLevel:"5",validFrom:dateFrom, validTo:dateTo,  }
                    priceListData.push(valuesToSend);
                }
            }
            formData.priceListData = priceListData.filter((x)=>x.isSelect===true&&x.price2!==0);
            if (formData?.priceListData.length == 0) {
                return thunkApi.rejectWithValue("Please input data");
            }
            if (formData?.priceListData.length >= 601) {
                return thunkApi.rejectWithValue("Data invalid! limit data 600 rows.");
            }
            // if (formData.priceListData.length >1000 ){
            //     return thunkApi.rejectWithValue("คุณ Import ข้อมูลเกิน 1,000 กรุณาตรวจสอบ!");
            // }
            // gen docItem 
            //     let idocItem ="";
            //     let iNum =0;     
            // for await (let site of formData.site) {

            //         let fdocItem ="$";
            //         let i=0;
            //         let chklength =iNum.toString().length
            //         let docMaxLength = (10-chklength);
            //         let docMinLength = iNum.toString().length;
            //         for (let i=docMinLength; i< docMaxLength;i++){
            //             fdocItem = fdocItem + "0"
            //         }
            //         for await (let item of priceListData) {
            //             idocItem = fdocItem + (iNum+1)
            //             // console.log("docItem : max : "+idocItem)
            //             if(item.isSelect ===true){
            //                 const valuesToSend = { ...item,id:uuid(), siteCode:site,docItem : idocItem.toString() }
            //                 priceListDataSite.push(valuesToSend);
            //                 i = i + 1;
            //                 iNum = iNum +1;
            //             }
            //     }

            // }
        // }
        // console.log("Json:"+JSON.parse(JSON.stringify(priceListDataSite)))
        //  formData.priceListData = priceListDataSite;//JSON.parse(JSON.stringify(priceListDataSite));
            const result = await httpClient.post(server.PRICELIST_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue("error catch :" + err);
        }
    }
);


export const pricelistScaleUpdateData = createAsyncThunk(
    "pricelistscale/putpricelistscale",
    // async (formData: Pricelist, thunkApi) => {
    async ({ formData, selectList }: { formData: Pricelist, selectList: PricelistData[] }, thunkApi) => {
        try {
            // if (formData.priceListData.length > 0){
            //     for await (let item of formData.priceListData) {
            //       if(item.message !==""){
            //         return thunkApi.rejectWithValue("Data invalid!");
            //       }  
            //     }
            // }else{
            //     return thunkApi.rejectWithValue("Please select data!");
            // }

            // if (formData.site.length === 0) {
            //     return thunkApi.rejectWithValue("Please select site!");
            // }

            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate()-1);

            let dateFrom = new Date(formData.validFrom!);
            let dateTo= new Date(formData.validTo!);
            let dateNow = new Date(currentDate);
            let dateFrom_normal = new Date(formData.priceListData[0].validFrom!);
            let dateTo_normal= new Date(formData.priceListData[0].validTo!);
            // console.log("validFromNM :" + dateFrom_normal.toDateString() + "  ,validToNM :"+ dateTo_normal.toDateString()  )
            //  console.log("dateFrom:"+ dateFrom.toDateString()  + " dateTo:" +dateTo.toDateString()  + "now:"+dateNow.toDateString() )
            // //**check date new transaction match with normal price template */
            // if (dateTo.toDateString() > dateTo_normal.toDateString() ) {
            //     return thunkApi.rejectWithValue("1ช่วงวันที่กำหนดไม่ตรงกับ Template!-Date To :" + moment(dateTo_normal).format("DD-MM-YYYY") +", Sale-Date To:"+ moment(dateTo).format("DD-MM-YYYY") );
            //     // return thunkApi.rejectWithValue("ValidFrom Or validTo not match NormalPrice Template!");
            // }
            // console.log("date from:" +dateFrom )
            // console.log("dateFrom_normal:" +dateFrom_normal )
            // if (dateFrom < dateFrom_normal) {
            //     // return thunkApi.rejectWithValue("ช่วงวันที่กำหนดไม่ตรงกับ Template!");
            //     return thunkApi.rejectWithValue("2ช่วงวันที่กำหนดไม่ตรงกับ Template! ,Date from :" + moment(dateFrom).format("DD-MM-YYYY") +",Date To:"+ moment(dateTo).format("DD-MM-YYYY") );
            //     // return thunkApi.rejectWithValue("ValidFrom Or validTo not match NormalPrice Template!");
            // }

            // if (dateFrom  < dateNow ) {
            //     return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
            // }
            // const start = new Date();
            // start.setDate(start.getDate())
            // if (formData.validFrom! > start) {
            //     return thunkApi.rejectWithValue("ValidFrom invalid! This price is effective tomorrow.");
            // }
            
            if (dateTo.setHours(0, 0, 0, 0) <dateFrom.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ช่วงวันที่ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            // if (dateFrom.setHours(0, 0, 0, 0) < dateNow.setHours(0, 0, 0, 0)) {
            //     return thunkApi.rejectWithValue("วันที่เริ่มไม่ถูกต้อง กรุณาตรวจสอบ!");
            // }
            let priceListData: PricelistData[] = [];
            for (let i = 0; i < formData.priceListData.length; i++) {
                let found = selectList.find((x) => x.id === formData.priceListData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: true ,priceLevel:"5",validFrom:dateFrom, validTo:dateTo, }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: false ,priceLevel:"5",validFrom:dateFrom, validTo:dateTo,}
                    priceListData.push(valuesToSend);
                }
            }

            formData.priceListData = priceListData.filter((x)=>x.isSelect===true && x.price2!==0);
            if (formData?.priceListData.length == 0) {
                return thunkApi.rejectWithValue("Please input data");
            }
            if (formData?.priceListData.length >= 601) {
                return thunkApi.rejectWithValue("Data invalid! limit data 600 rows.");
            }
            
            // if (formData.priceListData.length >1000 ){
            //     return thunkApi.rejectWithValue("คุณ Import ข้อมูลเกิน 1,000 กรุณาตรวจสอบ!");
            // }

            const result = await httpClient.put(`${server.PRICELIST_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

// export const pricelistScaleUpdateDataList = createAsyncThunk(
//     "pricelistscale/putpricelistscalelist",
//     async ({ pricelistspecial,formData }: {pricelistspecial: string,formData: Pricelist[] }, thunkApi) => {
//         try {
//             // let dateFrom = new Date(formData[0].validFrom!);
//             // let dateTo= new Date(formData[0].validTo!);
//             // let dateNow = new Date();
//             // let checkError = false;
//             // for (let i = 0; i < formData.length; i++) {
//             //     if (dateFrom.setHours(0, 0, 0, 0)  < dateNow.setHours(0, 0, 0, 0) ) {
//             //         // return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
//             //         formData[i].message="ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!";
//             //         checkError =true;
//             //     }
//             // }
//             // let result: Pricelist[]=[];
//             // if(checkError==true){
//             //     result.push(formData[0]);
//             //     return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
//             // }

//           const   result = await httpClient.put(`${server.PRICELIST_URL}/list/${formData[0].id!}`, formData);
//             // const result = await httpClient.get<Pricelist[]>(`${server.PRICELIST_URL}/scale/all`+ "?pricelevel='5'");
//         //  console.log("result:"+result)
//             return result;
//         } catch (error: any) {
//             let err = error.response.data.message ?? error.message
//             return thunkApi.rejectWithValue(err);
//         }
//     }
// );

export const PricelistScaleGetDataByIdOnly = createAsyncThunk(
    "pricelistscale/getPricelisttemplateId",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/scale/template/${id}`);
            //const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id}`);
            //console.log(result.data);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const PricelistScaleGetDataById = createAsyncThunk(
    "pricelistscale/getPricelisttemplate",
    async ({ formData,id,docRef}: {formData: PricelistData[],id:string,docRef:string}, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/scale/template/${id}`);
            let title ='';
            let remark ='';
            let chkValidFrom = new Date();
            let chkValidTo= new Date();
        if(id !==null || id !== undefined){


            if (result.data.priceListData.length===0 || result.data ===undefined ){
                return thunkApi.rejectWithValue("Template invalid!");
            }



            let xData = formData?.filter((row) => row.price2 !== 0)!
           if(formData.length !== 0){
            let itemModels : PricelistData[]=[];
            result.data.priceListData.forEach((data) => {
                chkValidFrom = result.data.validFrom!;
                chkValidTo = result.data.validTo!;
                // title=result.data.priceDescription!;
                // remark=result.data.remark!;
              let found = xData.find((x) => Number(x.itemCode!) === Number(data.itemCode!) && x.siteCode! ===data.siteCode!);
              if (found !== undefined) {
                if (found.price2 !==0 || found.price2 !==undefined){
                let msg ="";
                let itemModel = new PricelistData();
                itemModel.id= data.id!
                itemModel.itemCode = data.itemCode
                itemModel.itemName= data.itemName
                itemModel.isSelect= false
                itemModel.priceLevel= "5"
                itemModel.ean11= data.ean11
                itemModel.hpean= data.hpean
                itemModel.taxType= data.taxType
                itemModel.matkl= data.matkl
                itemModel.brandId= data.brandId
                itemModel.brandDesc= data.brandDesc
                itemModel.saleUnit= data.saleUnit
                itemModel.saleOrg= data.saleOrg
                itemModel.distributionChannel= data.distributionChannel
                itemModel.priceList= data.priceList
                itemModel.siteCode= data.siteCode
                itemModel.siteGroup= data.siteGroup
                itemModel.vendor= data.vendor
                itemModel.validFrom= result.data.validFrom
                itemModel.validTo= result.data.validTo
                itemModel.amountInMargin = 0
                itemModel.amountInFinalPrice = data.amountInFinalPrice == null ? 0 : data.amountInFinalPrice!
                if(data.price1 != found.price1){
                    msg ="normal Price not Match! :" +found.price1!
                }
                itemModel.price1= data.price1! == null ? 0 :  data.price1!
                //check invalid
                if (found.priceQTY2 !==0  && found.price2 !==0 ){
                    if(found.price2! >= data.price1! || found.priceQTY2! <= data.priceQTY1! ){
                        msg = msg +",Scale Price or Qty lv2 invalid!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv2 invalid!"
                    }
                }

                if (found.priceQTY3 !==0  && found.price3 !==0 ){
                    if(found.price3! >= found.price2! || found.priceQTY3! <= found.priceQTY2!){
                        msg = msg +",Scale Price or Qty lv3 invalid!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv3 invalid!"
                    }
                }
                if ( found.priceQTY4 !==0 && found.price4 !==0 ){
                    if(found.price4! >= found.price3! || found.priceQTY4! <= found.priceQTY3!  ){
                        msg = msg +",Scale Price or Qty lv4 invalid!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv4 invalid!"
                    }
                }
                if( found.priceQTY5 !==0   && found.price5 !==0 ){
                    if(found.price5! >= found.price4! || found.priceQTY5! <= found.priceQTY3!  ){
                        msg = msg +",Scale Price or Qty lv5 invalid!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv5 invalid!"
                    }
                }
                if(found.priceQTY1! > found.priceQTY2! && found.priceQTY2! > found.priceQTY3! && found.priceQTY3! > found.priceQTY4!
                    && found.priceQTY4! > found.priceQTY5!){
                    msg = msg +",Scale Qty invalid!"
                }
                if(found.price5! > found.price4! && found.price4! > found.price3! && found.price3! > found.price2!
                    && found.price2! > found.price1!){
                    msg = msg +",Scale price invalid!"
                }
                itemModel.price2=  found.price2! == null ? 0 :  Number(Number(found.price2!).toFixed(2))
                itemModel.price3=  found.price3! == null ? 0 : Number(Number(found.price3!).toFixed(2))
                itemModel.price4=  found.price4! == null ? 0 :   Number(Number(found.price4!).toFixed(2))
                itemModel.price5=   found.price5! == null ? 0 :  Number(Number(found.price5!).toFixed(2))
                itemModel.priceQTY1=   found.priceQTY1! == null ? 0 :  found.priceQTY1!
                itemModel.priceQTY2=  found.priceQTY2! == null ? 0 : Number(Number(found.priceQTY2!).toFixed(2))
                itemModel.priceQTY3=  found.priceQTY3! == null ? 0 : Number(Number(found.priceQTY3!).toFixed(2))
                itemModel.priceQTY4=  found.priceQTY4! == null ? 0 :  Number(Number(found.priceQTY4!).toFixed(2))
                itemModel.priceQTY5=  found.priceQTY5! == null ? 0 :  Number(Number(found.priceQTY5!).toFixed(2))
                itemModel.validFromO= undefined
                itemModel.validToO= undefined
                itemModel.amountInMarginO= undefined
                itemModel.amountInFinalPriceO= undefined
                itemModel.priceQTY1O=undefined
                itemModel.priceQTY2O= undefined
                itemModel.priceQTY3O= undefined
                itemModel.priceQTY4O= undefined
                itemModel.priceQTY5O= undefined
                itemModel.price1O= undefined
                itemModel.price2O=  undefined
                itemModel.price3O=  undefined
                itemModel.price4O=  undefined
                itemModel.price5O= undefined
                itemModel.condRec= found.condRec
                itemModel.message=msg       
                itemModels.push(itemModel)     
              }
            }
            //   else{
            //     // result.data.priceListData.forEach((data) => {
            //     //     let itemModel = new PricelistData();
            //     //     itemModel.id= data.id!
            //     //     itemModel.itemCode = data.itemCode
            //     //     itemModel.itemName= data.itemName
            //     //     itemModel.isSelect= data.isSelect
            //     //     itemModel.priceLevel= "5"
            //     //     itemModel.ean11= data.ean11
            //     //     itemModel.hpean= data.hpean
            //     //     itemModel.taxType= data.taxType
            //     //     itemModel.matkl= data.matkl
            //     //     itemModel.brandId= data.brandId
            //     //     itemModel.brandDesc= data.brandDesc
            //     //     itemModel.saleUnit= data.saleUnit
            //     //     itemModel.saleOrg= data.saleOrg
            //     //     itemModel.distributionChannel= data.distributionChannel
            //     //     itemModel.priceList= data.priceList
            //     //     itemModel.siteCode= data.siteCode
            //     //     itemModel.siteGroup= data.siteGroup
            //     //     itemModel.vendor= data.vendor
            //     //     itemModel.validFrom= data.validFrom
            //     //     itemModel.validTo= data.validTo
            //     //     itemModel.amountInMargin = 0
            //     //     itemModel.amountInFinalPrice = data.amountInFinalPrice == null ? 0 : data.amountInFinalPrice!
            //     //     itemModel.price1= data.price1! == null ? 0 :  data.price1!
            //     //     itemModel.price2=  data.price2! == null ? 0 :  data.price2!
            //     //     itemModel.price3=  data.price3! == null ? 0 :data.price3!
            //     //     itemModel.price4=  data.price4! == null ? 0 :  data.price4!
            //     //     itemModel.price5=   data.price5! == null ? 0 : data.price5!
            //     //     itemModel.priceQTY1=   data.priceQTY1! == null ? 0 :  data.priceQTY1!
            //     //     itemModel.priceQTY2=  data.priceQTY2! == null ? 0 : data.priceQTY2!
            //     //     itemModel.priceQTY3=  data.priceQTY3! == null ? 0 : data.priceQTY3!
            //     //     itemModel.priceQTY4=  data.priceQTY4! == null ? 0 :  data.priceQTY4!
            //     //     itemModel.priceQTY5=  data.priceQTY5! == null ? 0 :  data.priceQTY5!
            //     //     itemModel.validFromO= data.validFromO
            //     //     itemModel.validToO= data.validToO
            //     //     itemModel.amountInMarginO= data.amountInMarginO
            //     //     itemModel.amountInFinalPriceO= data.amountInFinalPrice
            //     //     itemModel.priceQTY1O=data.priceQTY1O
            //     //     itemModel.priceQTY2O= data.priceQTY2O
            //     //     itemModel.priceQTY3O= data.priceQTY3O
            //     //     itemModel.priceQTY4O= data.priceQTY3O
            //     //     itemModel.priceQTY5O= data.priceQTY5O
            //     //     itemModel.price1O= data.price1O
            //     //     itemModel.price2O=  data.price2O
            //     //     itemModel.price3O=  data.price3O
            //     //     itemModel.price4O=  data.price4O
            //     //     itemModel.price5O= data.price5O
            //     //     itemModel.condRec= data.condRec
            //     //     itemModel.message=data.message       
            //     //     itemModels.push(itemModel) 
            //     // })
                  
            //   }
            });

            
// console.log("row select : " + itemModels.length )
            if(docRef !==""){
                const resultEdit = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/scale/template/${docRef}`);
                resultEdit.data.priceListData=itemModels?.filter((row) => row.price2 !== 0)!
                resultEdit.data.validFrom=chkValidFrom;
                resultEdit.data.validTo = chkValidTo;
                result.data = resultEdit.data
            }
            else{
                //   console.log("validFrom:"+result.data.validFrom! + " : " + result.data.validTo!)
                // result.data.priceDescription  =title!;
                // result.data.remark  =remark!;
                // result.data.validFrom=chkValidFrom;
                // result.data.validTo = chkValidTo;
                result.data.priceListData =itemModels?.filter((row) => row.price2 !== 0)!
            }
            
           }

        }

            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue("Import error : "+ err);
        }
    }
);

export const PricelistScaleGetDataByIdCreatePage = createAsyncThunk(
    "pricelistscale/getPricelisttemplateCreatePage",
    async ({ formData,id,docRef}: {formData: PricelistData[],id:string,docRef:string}, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/scale/template/${id}`);

            let chkValidFrom //= new Date();
            let chkValidTo//= new Date();
        if(id !==null || id !== undefined){


            if (result.data.priceListData.length===0 || result.data ===undefined ){
                return thunkApi.rejectWithValue("Template invalid!");
            }



            let xData = formData?.filter((row) => row.price2 !== 0)!
            // console.log("xData:" + xData.length + " result :" + result.data.priceListData.length)
           if(formData.length !== 0){
            let itemModels : PricelistData[]=[];
            result.data.priceListData.forEach((data) => {
                chkValidFrom = result.data.validFrom;//data.validFrom!;
                chkValidTo = result.data.validTo;//data.validTo!;
                // console.log("item :" + data.itemCode!)
                // console.log("xDataitem :" + xData[1].itemCode! + " :"+xData[1].siteCode! + "result : "+data.itemCode! + ": " + data.siteCode!)
              let found = xData.find((x) => Number(x.itemCode!) === Number(data.itemCode!) && x.siteCode! ===data.siteCode!);
              if (found !== undefined) {
                if (found.price2 !==0 || found.price2 !==undefined){
                let msg ="";
                let itemModel = new PricelistData();
                itemModel.id= data.id!
                itemModel.itemCode = data.itemCode
                itemModel.itemName= data.itemName
                itemModel.isSelect= false
                itemModel.priceLevel= "5"
                itemModel.ean11= data.ean11
                itemModel.hpean= data.hpean
                itemModel.taxType= data.taxType
                itemModel.matkl= data.matkl
                itemModel.brandId= data.brandId
                itemModel.brandDesc= data.brandDesc
                itemModel.saleUnit= data.saleUnit
                itemModel.saleOrg= data.saleOrg
                itemModel.distributionChannel= data.distributionChannel
                itemModel.priceList= data.priceList
                itemModel.siteCode= data.siteCode
                itemModel.siteGroup= data.siteGroup
                itemModel.vendor= data.vendor
                itemModel.validFrom= result.data.validFrom
                itemModel.validTo= result.data.validTo
                itemModel.amountInMargin = 0
                itemModel.amountInFinalPrice = data.amountInFinalPrice == null ? 0 : data.amountInFinalPrice!
                if(Number(data.price1) != Number(found.price1)){
                    msg ="normal Price ไม่ตรงกับ Template! :" +found.price1!
                }
                itemModel.price1= data.price1! == null ? 0 :  data.price1!
                //check invalid
                if (Number(found.priceQTY2) !==0  && Number(found.price2) !==0 ){
                    if(Number(found.price2!) >= Number(data.price1!) || Number(found.priceQTY2!) <= Number(data.priceQTY1!) ){
                        msg = msg +",Scale Price หรือ Qty lv2 ไม่ถูกต้อง!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv2 invalid!"
                    }
                }

                if (Number(found.priceQTY3) !==0  && Number(found.price3) !==0 ){
                    if(Number(found.price3!) >= Number(found.price2!) || Number(found.priceQTY3!) <= Number(found.priceQTY2!)){
                        msg = msg +",Scale Price หรือ Qty lv3 ไม่ถูกต้อง!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv3 invalid!"
                    }
                }
                if ( Number(found.priceQTY4) !==0 && Number(found.price4) !==0 ){
                    if(Number(found.price4!) >= Number(found.price3!) || Number(found.priceQTY4!) <= Number(found.priceQTY3!)  ){
                        msg = msg +",Scale Price หรือ Qty lv4 ไม่ถูกต้อง!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv4 invalid!"
                    }
                }
                if( Number(found.priceQTY5) !==0   && Number(found.price5) !==0 ){
                    if(Number(found.price5!) >= Number(found.price4!) || Number(found.priceQTY5!) <= Number(found.priceQTY4!)  ){
                        msg = msg +",Scale Price หรือ Qty lv5 ไม่ถูกต้อง!"
                    }
                    else{
                        // msg = msg +",Scale Price or Qty lv5 invalid!"
                    }
                }
                if(Number(found.priceQTY1!) > Number(found.priceQTY2!) && Number(found.priceQTY2!) > Number(found.priceQTY3!) && Number(found.priceQTY3!) > Number(found.priceQTY4!)
                    && Number(found.priceQTY4!) > Number(found.priceQTY5!)){
                    msg = msg +",Scale Qty กำหนดไม่ถูกต้องกรุณาตรวจสอบ!"
                }
                if(Number(found.price5!) > Number(found.price4!) && Number(found.price4!) > Number(found.price3!) && Number(found.price3!) > Number(found.price2!)
                    && Number(found.price2!) > Number(found.price1!)){
                    msg = msg +",Scale price กำหนดไม่ถูกต้องกรุณาตรวจสอบ!"
                }
                itemModel.price2=  found.price2! == null ? 0 :  Number(Number(found.price2!).toFixed(2))
                itemModel.price3=  found.price3! == null ? 0 : Number(Number(found.price3!).toFixed(2))
                itemModel.price4=  found.price4! == null ? 0 :   Number(Number(found.price4!).toFixed(2))
                itemModel.price5=   found.price5! == null ? 0 :  Number(Number(found.price5!).toFixed(2))
                itemModel.priceQTY1=   found.priceQTY1! == null ? 0 :  found.priceQTY1!
                itemModel.priceQTY2=  found.priceQTY2! == null ? 0 :  Number(Number(found.priceQTY2!).toFixed(2))
                itemModel.priceQTY3=  found.priceQTY3! == null ? 0 :  Number(Number(found.priceQTY3!).toFixed(2))
                itemModel.priceQTY4=  found.priceQTY4! == null ? 0 :   Number(Number(found.priceQTY4!).toFixed(2))
                itemModel.priceQTY5=  found.priceQTY5! == null ? 0 :   Number(Number(found.priceQTY5!).toFixed(2))
                itemModel.validFromO= undefined
                itemModel.validToO= undefined
                itemModel.amountInMarginO= undefined
                itemModel.amountInFinalPriceO= undefined
                itemModel.priceQTY1O=undefined
                itemModel.priceQTY2O= undefined
                itemModel.priceQTY3O= undefined
                itemModel.priceQTY4O= undefined
                itemModel.priceQTY5O= undefined
                itemModel.price1O= undefined
                itemModel.price2O=  undefined
                itemModel.price3O=  undefined
                itemModel.price4O=  undefined
                itemModel.price5O= undefined
                itemModel.condRec= found.condRec
                itemModel.message=msg       
                itemModels.push(itemModel)     
              }
            }
            //   else{
            //     // result.data.priceListData.forEach((data) => {
            //     //     let itemModel = new PricelistData();
            //     //     itemModel.id= data.id!
            //     //     itemModel.itemCode = data.itemCode
            //     //     itemModel.itemName= data.itemName
            //     //     itemModel.isSelect= data.isSelect
            //     //     itemModel.priceLevel= "5"
            //     //     itemModel.ean11= data.ean11
            //     //     itemModel.hpean= data.hpean
            //     //     itemModel.taxType= data.taxType
            //     //     itemModel.matkl= data.matkl
            //     //     itemModel.brandId= data.brandId
            //     //     itemModel.brandDesc= data.brandDesc
            //     //     itemModel.saleUnit= data.saleUnit
            //     //     itemModel.saleOrg= data.saleOrg
            //     //     itemModel.distributionChannel= data.distributionChannel
            //     //     itemModel.priceList= data.priceList
            //     //     itemModel.siteCode= data.siteCode
            //     //     itemModel.siteGroup= data.siteGroup
            //     //     itemModel.vendor= data.vendor
            //     //     itemModel.validFrom= data.validFrom
            //     //     itemModel.validTo= data.validTo
            //     //     itemModel.amountInMargin = 0
            //     //     itemModel.amountInFinalPrice = data.amountInFinalPrice == null ? 0 : data.amountInFinalPrice!
            //     //     itemModel.price1= data.price1! == null ? 0 :  data.price1!
            //     //     itemModel.price2=  data.price2! == null ? 0 :  data.price2!
            //     //     itemModel.price3=  data.price3! == null ? 0 :data.price3!
            //     //     itemModel.price4=  data.price4! == null ? 0 :  data.price4!
            //     //     itemModel.price5=   data.price5! == null ? 0 : data.price5!
            //     //     itemModel.priceQTY1=   data.priceQTY1! == null ? 0 :  data.priceQTY1!
            //     //     itemModel.priceQTY2=  data.priceQTY2! == null ? 0 : data.priceQTY2!
            //     //     itemModel.priceQTY3=  data.priceQTY3! == null ? 0 : data.priceQTY3!
            //     //     itemModel.priceQTY4=  data.priceQTY4! == null ? 0 :  data.priceQTY4!
            //     //     itemModel.priceQTY5=  data.priceQTY5! == null ? 0 :  data.priceQTY5!
            //     //     itemModel.validFromO= data.validFromO
            //     //     itemModel.validToO= data.validToO
            //     //     itemModel.amountInMarginO= data.amountInMarginO
            //     //     itemModel.amountInFinalPriceO= data.amountInFinalPrice
            //     //     itemModel.priceQTY1O=data.priceQTY1O
            //     //     itemModel.priceQTY2O= data.priceQTY2O
            //     //     itemModel.priceQTY3O= data.priceQTY3O
            //     //     itemModel.priceQTY4O= data.priceQTY3O
            //     //     itemModel.priceQTY5O= data.priceQTY5O
            //     //     itemModel.price1O= data.price1O
            //     //     itemModel.price2O=  data.price2O
            //     //     itemModel.price3O=  data.price3O
            //     //     itemModel.price4O=  data.price4O
            //     //     itemModel.price5O= data.price5O
            //     //     itemModel.condRec= data.condRec
            //     //     itemModel.message=data.message       
            //     //     itemModels.push(itemModel) 
            //     // })
                  
            //   }
            });

            //   console.log("itemModels:" + itemModels.length + " result :" + result.data.priceListData.length)
// console.log("row select : " + itemModels.length )
            if(docRef !==""){
                // console.log("chkValidFrom:"+chkValidFrom! + " : " +chkValidTo!)
                const resultEdit = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/scale/template/${docRef}`);
                resultEdit.data.priceListData=itemModels?.filter((row) => row.price2 !== 0)!
                 resultEdit.data.validFrom=chkValidFrom;
                resultEdit.data.validTo = chkValidTo;
                result.data = resultEdit.data
            }
            else{
                // console.log("validFrom:"+result.data.validFrom! + " : " + result.data.validTo!)
                result.data.validFrom=chkValidFrom;
                result.data.validTo = chkValidTo;
                result.data.priceListData =itemModels?.filter((row) => row.price2 !== 0)!
            }
            
           }

        }

            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue("Import error : "+ err);
        }
    }
);
// export const PricelistScaleGetDataByIdEdit = createAsyncThunk(
//     "pricelistscale/getPricelisttemplateEdit",
//     async ({ formData,id,docRef}: {formData: Pricelist,id:string,docRef:string}, thunkApi) => {
//         try {
//             const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/scale/template/${id}`);
            
//            if(formData.priceListData.length !== 0){
//             let itemModels : PricelistData[]=[];
//             result.data.priceListData.forEach((data) => {
//               let found = formData.priceListData.find((x) => x.itemCode === data.itemCode);
//               if (found !== undefined) {
//                 if (found.price2 !==0 || found.price2 !==undefined){
//                 let msg ="";
//                 let itemModel = new PricelistData();
//                 itemModel.id= data.id!
//                 itemModel.itemCode = data.itemCode
//                 itemModel.itemName= data.itemName
//                 itemModel.isSelect= false
//                 itemModel.priceLevel= "5"
//                 itemModel.ean11= data.ean11
//                 itemModel.hpean= data.hpean
//                 itemModel.taxType= data.taxType
//                 itemModel.matkl= data.matkl
//                 itemModel.brandId= data.brandId
//                 itemModel.brandDesc= data.brandDesc
//                 itemModel.saleUnit= data.saleUnit
//                 itemModel.saleOrg= data.saleOrg
//                 itemModel.distributionChannel= data.distributionChannel
//                 itemModel.priceList= data.priceList
//                 itemModel.siteCode= data.siteCode
//                 itemModel.siteGroup= data.siteGroup
//                 itemModel.vendor= data.vendor
//                 itemModel.validFrom= undefined
//                 itemModel.validTo= undefined
//                 itemModel.amountInMargin = 0
//                 itemModel.amountInFinalPrice = data.amountInFinalPrice == null ? 0 : data.amountInFinalPrice!
//                 if(data.price1 != found.price1){
//                     msg ="normal Price not Match! :" +found.price1!
//                 }
//                 itemModel.price1= data.price1! == null ? 0 :  data.price1!
//                 //check invalid
//                 if (found.priceQTY2 !==0  && found.price2 !==0 ){
//                     if(found.price2! >= data.price1! || found.priceQTY2! <= data.priceQTY1! ){
//                         msg = msg +",Scale Price or Qty lv2 invalid!"
//                     }
//                     else{
//                         // msg = msg +",Scale Price or Qty lv2 invalid!"
//                     }
//                 }

//                 if (found.priceQTY3 !==0  && found.price3 !==0 ){
//                     if(found.price3! >= found.price2! || found.priceQTY3! <= found.priceQTY2!){
//                         msg = msg +",Scale Price or Qty lv3 invalid!"
//                     }
//                     else{
//                         // msg = msg +",Scale Price or Qty lv3 invalid!"
//                     }
//                 }
//                 if ( found.priceQTY4 !==0 && found.price4 !==0 ){
//                     if(found.price4! >= found.price3! || found.priceQTY4! <= found.priceQTY3!  ){
//                         msg = msg +",Scale Price or Qty lv4 invalid!"
//                     }
//                     else{
//                         // msg = msg +",Scale Price or Qty lv4 invalid!"
//                     }
//                 }
//                 if( found.priceQTY5 !==0   && found.price5 !==0 ){
//                     if(found.price5! >= found.price4! || found.priceQTY5! <= found.priceQTY3!  ){
//                         msg = msg +",Scale Price or Qty lv5 invalid!"
//                     }
//                     else{
//                         // msg = msg +",Scale Price or Qty lv5 invalid!"
//                     }
//                 }
//                 if(found.priceQTY1! > found.priceQTY2! && found.priceQTY2! > found.priceQTY3! && found.priceQTY3! > found.priceQTY4!
//                     && found.priceQTY4! > found.priceQTY5!){
//                     msg = msg +",Scale Qty invalid!"
//                 }
//                 if(found.price5! > found.price4! && found.price4! > found.price3! && found.price3! > found.price2!
//                     && found.price2! > found.price1!){
//                     msg = msg +",Scale price invalid!"
//                 }
//                 itemModel.price2=  found.price2! == null ? 0 :  found.price2!
//                 itemModel.price3=  found.price3! == null ? 0 :found.price3!
//                 itemModel.price4=  found.price4! == null ? 0 :  found.price4!
//                 itemModel.price5=   found.price5! == null ? 0 : found.price5!
//                 itemModel.priceQTY1=   found.priceQTY1! == null ? 0 :  found.priceQTY1!
//                 itemModel.priceQTY2=  found.priceQTY2! == null ? 0 : found.priceQTY2!
//                 itemModel.priceQTY3=  found.priceQTY3! == null ? 0 : found.priceQTY3!
//                 itemModel.priceQTY4=  found.priceQTY4! == null ? 0 :  found.priceQTY4!
//                 itemModel.priceQTY5=  found.priceQTY5! == null ? 0 :  found.priceQTY5!
//                 itemModel.validFromO= undefined
//                 itemModel.validToO= undefined
//                 itemModel.amountInMarginO= undefined
//                 itemModel.amountInFinalPriceO= undefined
//                 itemModel.priceQTY1O=undefined
//                 itemModel.priceQTY2O= undefined
//                 itemModel.priceQTY3O= undefined
//                 itemModel.priceQTY4O= undefined
//                 itemModel.priceQTY5O= undefined
//                 itemModel.price1O= undefined
//                 itemModel.price2O=  undefined
//                 itemModel.price3O=  undefined
//                 itemModel.price4O=  undefined
//                 itemModel.price5O= undefined
//                 itemModel.condRec= found.condRec
//                 itemModel.message=msg       
//                 itemModels.push(itemModel)     
//               }
//             }
//               else{
//                 result.data.priceListData.forEach((data) => {
//                     let itemModel = new PricelistData();
//                     itemModel.id= data.id!
//                     itemModel.itemCode = data.itemCode
//                     itemModel.itemName= data.itemName
//                     itemModel.isSelect= data.isSelect
//                     itemModel.priceLevel= "5"
//                     itemModel.ean11= data.ean11
//                     itemModel.hpean= data.hpean
//                     itemModel.taxType= data.taxType
//                     itemModel.matkl= data.matkl
//                     itemModel.brandId= data.brandId
//                     itemModel.brandDesc= data.brandDesc
//                     itemModel.saleUnit= data.saleUnit
//                     itemModel.saleOrg= data.saleOrg
//                     itemModel.distributionChannel= data.distributionChannel
//                     itemModel.priceList= data.priceList
//                     itemModel.siteCode= data.siteCode
//                     itemModel.siteGroup= data.siteGroup
//                     itemModel.vendor= data.vendor
//                     itemModel.validFrom= data.validFrom
//                     itemModel.validTo= data.validTo
//                     itemModel.amountInMargin = 0
//                     itemModel.amountInFinalPrice = data.amountInFinalPrice == null ? 0 : data.amountInFinalPrice!
//                     itemModel.price1= data.price1! == null ? 0 :  data.price1!
//                     itemModel.price2=  data.price2! == null ? 0 :  data.price2!
//                     itemModel.price3=  data.price3! == null ? 0 :data.price3!
//                     itemModel.price4=  data.price4! == null ? 0 :  data.price4!
//                     itemModel.price5=   data.price5! == null ? 0 : data.price5!
//                     itemModel.priceQTY1=   data.priceQTY1! == null ? 0 :  data.priceQTY1!
//                     itemModel.priceQTY2=  data.priceQTY2! == null ? 0 : data.priceQTY2!
//                     itemModel.priceQTY3=  data.priceQTY3! == null ? 0 : data.priceQTY3!
//                     itemModel.priceQTY4=  data.priceQTY4! == null ? 0 :  data.priceQTY4!
//                     itemModel.priceQTY5=  data.priceQTY5! == null ? 0 :  data.priceQTY5!
//                     itemModel.validFromO= data.validFromO
//                     itemModel.validToO= data.validToO
//                     itemModel.amountInMarginO= data.amountInMarginO
//                     itemModel.amountInFinalPriceO= data.amountInFinalPrice
//                     itemModel.priceQTY1O=data.priceQTY1O
//                     itemModel.priceQTY2O= data.priceQTY2O
//                     itemModel.priceQTY3O= data.priceQTY3O
//                     itemModel.priceQTY4O= data.priceQTY3O
//                     itemModel.priceQTY5O= data.priceQTY5O
//                     itemModel.price1O= data.price1O
//                     itemModel.price2O=  data.price2O
//                     itemModel.price3O=  data.price3O
//                     itemModel.price4O=  data.price4O
//                     itemModel.price5O= data.price5O
//                     itemModel.condRec= data.condRec
//                     itemModel.message=data.message       
//                     itemModels.push(itemModel) 
//                 })
                  
//               }
//             });
            
//             result.data.priceListData =itemModels?.filter((row) => row.price2 !== 0)!
//            }
 

//             return result;
//         } catch (error: any) {
//             let err = error.response.data.message ?? error.message
//             return thunkApi.rejectWithValue(err);
//         }
//     }
// );



export const pricelistScaleCreateDataImport = createAsyncThunk(
    "pricelistscale/postpricelistscaleimport",
     async ({ formData, selectList}: {formData: Pricelist, selectList: PricelistData[]}, thunkApi) => {
        // async ({ formData, selectList }: { formData: Pricelist, selectList: PricelistData[] }, thunkApi) => {
        try {
            // const result = await httpClient.post(`${server.PRICELIST_URL}/importxls/`, formData)
            // console.log("row : " + formData.priceListData.length )
            let priceListData: PricelistData[] = [];
            for (let i = 0; i < formData.priceListData.length; i++) {
                let found = selectList.find((x) => x.itemCode === formData.priceListData[i].itemCode);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.priceListData[i],price2: formData.priceListData[i].price2 }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.priceListData[i], price2:1 }
                    priceListData.push(valuesToSend);
                }
            }

            formData.priceListData = priceListData;


            return   formData.priceListData;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const pricelistScaleActionSlice = createSlice({
    name: "PricelistScaleAction",
    initialState: initialStateValues,
    reducers: {
        removePricelistScaleData: (state, action: PayloadAction<PricelistData[]>) => {
            state.result.priceListData = action.payload;
        },
        updatePricelistScaleData: (state, action: PayloadAction<PricelistData[]>) => {
            state.result.priceListData = action.payload!;
            // console.log("state.result.priceListData:" + state.result.priceListData)
        },
        clearPricelistScale: (state, action: PayloadAction) => {
            state.result = initialValues;
        },
        updatePricelistScaleDataImport: (state, action: PayloadAction<PricelistData[]>) => {
            action.payload.map((item) => {
                let index = state.result.priceListData.findIndex((x) => x.itemCode === item.itemCode);
                state.result.priceListData[index] = { ...state.result.priceListData[index], price2:item.price2 };
                // console.log("UpdateReducer :"+ state.result.priceListData[index] )
            })
        },
        selectPriceScalelist: (state, action: PayloadAction<PricelistData[]>) => {
            action.payload.map((item) => {
                let index = state.result.priceListData.findIndex((x) => x.id === item.id);
                state.result.priceListData[index] = { ...state.result.priceListData[index], isSelect: true };
            })
        },
    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(pricelistScaleCreateData.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistScaleCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                if (action!.payload.status == "OK") {
                    state.result = initialValues;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                     history.back();
                } else {
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
                }
            })
            .addCase(pricelistScaleCreateData.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(pricelistScaleCreateDataImport.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistScaleCreateDataImport.fulfilled, (state, action: PayloadAction<any>) => {
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                if (action!.payload.status == "OK") {
                    state.result = initialValues;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                     history.back();
                } else {
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
                }
            })
            .addCase(pricelistScaleCreateDataImport.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(pricelistScaleUpdateData.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistScaleUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                if (action!.payload.status == "OK") {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
                }else{
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;    
                }
            })
            .addCase(pricelistScaleUpdateData.rejected, (state, action: PayloadAction<any>) => {

                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        // builder
        //     .addCase(pricelistScaleUpdateDataList.pending, (state, action) => {
        //         // state.result = [];
        //         state.message = "Loading...";
        //         state.isFetching = true;
        //         state.isError = false;
        //     })
        //     .addCase(pricelistScaleUpdateDataList.fulfilled, (state, action: PayloadAction<any>) => {
        //         // console.log("status :" + action.payload.status!)
        //         if (action!.payload.status == "OK") {
        //             state.result = action.payload.data;
        //             state.message = "Success";
        //             state.isFetching = false;
        //             state.isError = false;
        //             // history.back();
        //         }else{
                    
        //             state.result = action!.payload.data;
        //             state.message = action!.payload.message;
        //             state.isFetching = false;
        //             state.isError = true;    
        //         }
        //     })
        //     .addCase(pricelistScaleUpdateDataList.rejected, (state, action: PayloadAction<any>) => {
        //         // state.result = [];//action.payload.data;
        //         state.message = action.payload.message!;
        //         state.isFetching = false;
        //         state.isError = true;
        //     });
        builder
            .addCase(PricelistScaleGetDataById.pending, (state, action) => {
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(PricelistScaleGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                state.result = action!.payload.data;
                // console.log("check",state.result);
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(PricelistScaleGetDataByIdCreatePage.pending, (state, action) => {
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetDataByIdCreatePage.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                state.result = action!.payload.data;
                // console.log("check",state.result);
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetDataByIdCreatePage.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(PricelistScaleGetDataByIdOnly.pending, (state, action) => {
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetDataByIdOnly.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                state.result = action!.payload.data;
                // console.log("check",state.result);
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistScaleGetDataByIdOnly.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { clearPricelistScale, removePricelistScaleData, updatePricelistScaleDataImport,updatePricelistScaleData, selectPriceScalelist, } = pricelistScaleActionSlice.actions;
export const PricelistScaleActionSelector = (store: RootState) => store.pricelistScaleActionReducer;
export default pricelistScaleActionSlice.reducer;