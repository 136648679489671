import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Role } from "../../types/role.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";

type RoleEditState = {
    result: Role | null;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: RoleEditState = {
    result: null,
    message: "",
    isFetching: false,
    isError: false,
};


export const roleGetDataById = createAsyncThunk(
    "role/getrole",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Role>(`${server.ROLE_URL}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const roleCreateData = createAsyncThunk(
    "role/postRole",
    async (formData: Role, thunkApi) => {
        try {
           
            // let setSite:any=[];
            // if(formData.site!.length >0){
            //  for await (let g of formData.site!) {
            //     setSite.push(g.id!);
            //  }
            // }else{
            //     return thunkApi.rejectWithValue("กรุณาเลือก SiteCode!");
            // }
            // let setMenu:any=[];
            // if(formData.menu!.length >0){
            //  for await (let g of formData.menu!) {
            //     setMenu.push(g.menuId!);
            //  }
            // }else{
            //     return thunkApi.rejectWithValue("กรุณาเลือก Menu!");
            // }
            // let setPriceLevel:any=[];
            // if(formData.priceLevel!.length >0){
            //  for await (let g of formData.priceLevel!) {
            //     setPriceLevel.push(g.id!);
            //  }
            // }else{
            //     return thunkApi.rejectWithValue("กรุณาเลือก PriceLevel!");
            // }
            // let setRoleType:any=[];
            // if(formData.roleType!.length >0){
            //  for await (let g of formData.roleType!) {
            //     setRoleType.push(g.id!);
            //  }
            // }
            // console.log("site:" + formData.site!.length)
            // const setFormData ={
            //     ...formData,
            //     site:setSite,
            //     // roleType:setRoleType,
            //     priceLevel:setPriceLevel,
            //     menu:setMenu,
            // }

            const result = await httpClient.post(server.ROLE_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const roleUpdateData = createAsyncThunk(
    "role/putRole",
    async (formData: Role, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.ROLE_URL}/${formData.roleId}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const roleActionSlice = createSlice({
    name: "roleedit",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(roleGetDataById.pending, (state, action) => {
                 state.result = null;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(roleGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(roleGetDataById.rejected, (state, action: PayloadAction<any>) => {
                 state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(roleCreateData.pending, (state, action) => {
                 state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(roleCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(roleCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = action!.payload.data;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(roleUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(roleUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(roleUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = roleActionSlice.actions;
export const roleActionSelector = (store: RootState) => store.roleActionReducer;
export default roleActionSlice.reducer;