import axios from "axios";
import join from "url-join";
import { apiUrl, NOT_CONNECT_NETWORK, NETWORK_CONNECTION_MESSAGE, TOKEN, } from "../Constants";
import { Route, Routes, useNavigate } from 'react-router-dom';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config: any) => {
    if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = join(apiUrl, config.url);
    }
    config.timeout = 20000; // 10 Second
    config.headers = {
        'Authorization': `Bearer ${localStorage.getItem(TOKEN)}`,
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
    }

    return config;
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: any) => {
        // console.log(JSON.stringify(error, undefined, 2));
        // console.log(error.response.status)
        if (error.response.status === 401) {
            // console.log(error.response.status)
            localStorage.removeItem(TOKEN);
            const navigate = useNavigate();
            navigate('/login');
        }

        if (axios.isCancel(error)) {
            return Promise.reject(error);
        } else if (!error.response) {
            return Promise.reject({
                code: NOT_CONNECT_NETWORK,
                message: NETWORK_CONNECTION_MESSAGE,
            });
        }
        return Promise.reject(error);
    }
);

export const httpClient = axios;