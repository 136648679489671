import * as React from "react";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Autocomplete, Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, OutlinedInput,  TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import Loader from "../../component/Loader";
import moment from "moment";
import { Formik, FormikProps, Form } from "formik";
import { siteRoleSelector, sitePostDataByRole } from "../../../store/slices/siteRoleSlice";
import { Actualreport, ActualExportExcel } from "../../../types/actual.type";
import { actualPostDataBySite, actualSelector,clearPricelistScale } from "../../../store/slices/actualSlice";
import { exportExcelItem } from "../../../utils/excel";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import NativeSelect from '@mui/material/NativeSelect';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { LinearProgress } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type ReportActualPageProps = {
  //
};

const ReportActualPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const SiteRoleReducer = useSelector(siteRoleSelector)
  const ActualReducer = useSelector(actualSelector)
  // const [siteRole, setSiteRole] = React.useState<any>();
  const [priceType, setPriceListType] = React.useState('4');
  const MySwal = withReactContent(Swal)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [petInputValue, setPetInputValue] = React.useState("");
  const [selectedSite, setSelectedSite] =  React.useState<any>([]);

  React.useEffect(() => {
    dispatch(sitePostDataByRole(""));
    // setSiteRole(SiteRoleReducer.result);

  }, []);
  // console.log(siteRole)
  const onloadReport = () => {
    // dispatch(priceListGetTemplateDataById(templateId))
    // setLoadtemplate(false);
    // alert("YES")
    dispatch(clearPricelistScale());
  }

  const [SiteCode, setSiteCode] = React.useState<string[]>([]);
  const handleReprocess = (row: any) => {
    console.log(row)
  };

  const columns: GridColDef[] = [
    {
      field: "siteCode", width: 100,
      renderHeader: () => (<strong> {t('siteCode')} </strong>),
    },
    {
      field: "itemCode", width: 150,
      renderHeader: () => (<strong> {t('itemCode')} </strong>),
    },
    {
      field: "itemName", width: 250,
      renderHeader: () => (<strong> {t('itemName')} </strong>),
    },
    {
      field: "saleUnit", width: 100,
      renderHeader: () => (<strong> {t('saleUnit')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',
       valueFormatter: params => params.value==null?"-":(moment(params.value).format("DD-MM-YYYY")),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',
      // valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      // renderHeader: () => (<strong> {t('validTo')} </strong>),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
      renderCell: (cellValues) => {
        var checkDate = new Date(cellValues.value);
        var toDay = new Date();
        if (checkDate.setHours(0, 0, 0, 0) < toDay.setHours(0, 0, 0, 0)){
          return (
            <div style={{color: "red",width: "100%"}}>
              {cellValues.value==null?"-":(moment(cellValues.value).format("DD-MM-YYYY"))}
            </div>
          )
        }
        else{
          return (
          <div>
            {cellValues.value==null?"-":(moment(cellValues.value).format("DD-MM-YYYY"))}
          </div>
          )
        }

      }
    },
    {
      field: "conditionType", width: 100,
      renderHeader: () => (<strong> {t('conditionType')} </strong>),
    },
    {
      field: "amountInFinalPrice", headerName: "Amount in Final Price", width: 150, editable: true,
      type: 'number', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--header'
    },
    {
      field: "priceQTY1", headerName: "PQtyB1 (Scale 1)", width: 150,
      type: 'number'
    },
    {
      field: "price1", headerName: "PMPrice1 (Scale 1)", width: 150,
      type: 'number'
    },
    { field: "priceQTY2", headerName: "PQtyB2 (Scale 2)", width: 150, type: 'number' },
    { field: "price2", headerName: "PMPrice2 (Scale 2)", width: 150, type: 'number' },
    { field: "priceQTY3", headerName: "PQtyB3 (Scale 3)", width: 150, type: 'number' },
    { field: "price3", headerName: "PMPrice3 (Scale 3)", width: 150, type: 'number' },
    { field: "priceQTY4", headerName: "PQtyB4 (Scale 4)", width: 150, type: 'number' },
    { field: "price4", headerName: "PMPrice4 (Scale 4)", width: 150, type: 'number' },
    { field: "priceQTY5", headerName: "PQtyB5 (Scale 5)", width: 150, type: 'number' },
    { field: "price5", headerName: "PMPrice5 (Scale 5)", width: 150, type: 'number' ,},
  ];

  if (SiteRoleReducer.isFetching) {
    return <><Loader /></>
  }
  const handleChangePriceListType = (event: SelectChangeEvent) => {
    setPriceListType(event.target.value);
  };
  const handleChange = (event: SelectChangeEvent<typeof SiteCode>) => {
    const {
      target: { value },
    } = event;
    setSiteCode(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const initialValues: Actualreport = {
    siteCode: [],
    siteName: '',
    itemCode: '',
    priceListType:'4',
    active: true
  };

  const priceListType = [
    'NormalPrice',
    'ScalePrice',
    'SpecialPrice'];



  const ExportExcel = (excelData: any) => {
    let actualDatas: ActualExportExcel[] = [];

    excelData.forEach((item: any) => {
      let actualData = new ActualExportExcel();
      actualData.siteCode = item.siteCode
      actualData.itemCode = item.itemCode
      actualData.itemName = item.itemName
      actualData.saleUnit = item.saleUnit
      actualData.validFrom = moment(item.validFrom).format("DD-MM-YYYY")
      actualData.validTo = moment(item.validTo).format("DD-MM-YYYY")
      actualData.conditionType = item.conditionType
      actualData.amountInFinalPrice = item.amountInFinalPrice
      actualData.priceQTY1 = item.priceQTY1
      actualData.price1 = item.price1
      actualData.priceQTY2 = item.priceQTY2
      actualData.price2 = item.price2
      actualData.priceQTY3 = item.priceQTY3
      actualData.price3 = item.price3
      actualData.priceQTY4 = item.priceQTY4
      actualData.price4 = item.price4
      actualData.priceQTY5 = item.priceQTY5
      actualData.price5 = item.price5
      actualDatas.push(actualData);
    });

    let Heading = [['Code', 'Material', 'Material description', 'Sale Unit', 'Start Date', 'End Date', 'Condition Type', 'Amount in Final Price', 'PQtyB1 (Scale 1)', 'PMPrice1 (Scale 1)', 'PQtyB2 (Scale 2)', 'PMPrice2 (Scale 2)', 'PQtyB3 (Scale 3)', 'PMPrice3 (Scale 3)', 'PQtyB4 (Scale 4)', 'PMPrice4 (Scale 4)', 'PQtyB5 (Scale 5)', 'PMPrice5 (Scale 5)']];

    exportExcelItem(actualDatas, Heading, 'ActualPriceReport-' + moment().format('YYYY-MM-DD_hh:mm:ss').toString());
  }

  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,

  }: FormikProps<Actualreport>) => {
    return (
      <Form>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              ACTUAL PRICE REPORT
            </Typography>

            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="siteCode">{t('siteCode')}</InputLabel>
              <Select
                id="siteCode"
                multiple
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                name="siteCode"
                value={values.siteCode}
                input={<OutlinedInput label="siteCode" />}
                label={t("siteCode")}
                onChange={handleChange}
              error={
                errors.siteCode && touched.siteCode
                  ? true
                  : false
              } 
              >
             {SiteRoleReducer.result.map((item,index) => (
                 <MenuItem key={item.id} value={item.siteCode}>
                    <ListItemText primary={item.siteName} />
            </MenuItem>
          ))}

              </Select>
            </FormControl> */}

            <FormControl style={{ marginTop: 16 ,width: '100%'}} >
            <Autocomplete
                  multiple
                  id="siteCode1"
                  options={SiteRoleReducer.result
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.siteName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.siteName}
                    </li>
                    
                  )}
                  inputValue={petInputValue}
                  onInputChange={(event, newPetInputValue) => {
                    setPetInputValue(newPetInputValue);
                  }}
                  value={selectedSite}
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    // console.log("check select site :" + newValue);
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('siteCode')} placeholder="Search" />
                  )}
                />
              </FormControl>

            <FormControl style={{ marginTop: 16 }} fullWidth>
            <InputLabel id="demo-simple-select-autowidth-label">PriceList Type</InputLabel>
            <Select
            // defaultValue="1"
              labelId="demo-simple-select-autowidth-label"
              id="priceListType"
              value={priceType}
              onChange={handleChangePriceListType}
              // autoWidth
              label="เลือก PriceList"
              error={
                errors.priceListType && touched.priceListType
                  ? true
                  : false
              } 
            ><MenuItem value={4}>{t("All")}</MenuItem>
            <MenuItem value={1}>{t("NormalPrice")}</MenuItem>
            <MenuItem value={2}>{t("ScalePrice")}</MenuItem>
            <MenuItem value={3}>{t("SpecialPrice")}</MenuItem>
            </Select>
            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
           
                <InputLabel  htmlFor="uncontrolled-native">
                priceListType
                </InputLabel>
                <NativeSelect
                  defaultValue={10}
                  inputProps={{
                    name: 'priceListType',
                    // id: 'uncontrolled-native',
                  }}
                >
                  <option value={10}>Normal Price</option>
                  <option value={20}>Scale Price</option>
                  <option value={30}>Special Price</option>
                </NativeSelect>
              </FormControl> */}
            </FormControl>
            <TextField
              id="itemCode"
              name="itemCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t("itemCode")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.itemCode}
              // helperText={
              //   errors.itemCode && touched.itemCode
              //     ? errors.itemCode
              //     : "Enter your itemCode."
              // }
            />

            <Box sx={{ display: 'flex' }}>
              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="inherit"
                type="submit"
                sx={{ marginRight: 1 }}
                disabled={SiteRoleReducer.isFetching}
              // onClick={() => onloadReport()}
              > {t('searchbtn')}
              </Button>

              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="info"
                type="button"
                sx={{ marginRight: 1 }}
                disabled={SiteRoleReducer.isFetching}
                // onClick={onloadtemplate}
                onClick={() => ExportExcel(ActualReducer.result)}
              > {t('exportbtn')}
              </Button>

            </Box>

            {ActualReducer.isFetching && (
                    <LinearProgress />
                )}
                <br></br>
            <DataGridPro
              // checkboxSelection={true}
              
              disableSelectionOnClick={true}
              sx={{ backgroundColor: "white", height: "80vh" }}
              getRowId={(row) => row.id}
              rows={ActualReducer.result ? ActualReducer!.result! : []}
              // rows={pricelistReducer.result!.filter((x) => x.priceLevel! <= "2")}
              columns={columns}
              // pageSize={50}
              pagination
              rowsPerPageOptions={[10]}
              initialState={{ pinnedColumns: { left: ['siteCode','itemCode','itemName'], right: ['actions'] } }}
            />
          </CardContent>
        </Card>
      </Form>
    );
  }



  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    // SiteCode: Yup.string().required(inputRequired),
    // priceListType: Yup.string().required(inputRequired),
  });

  if (SiteRoleReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <>
      <Box>
        <Formik

          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            //  console.log(priceType)
            let siteCodes: string[] = [];
            for (let i = 0; i < selectedSite.length; i++) {
              siteCodes.push(selectedSite[i].siteCode)
            }
            const valuesToSend = {
              ...values,
              priceListType:priceType,
               siteCode:siteCodes,
            }
            // console.log("site:",siteCodes)
            // console.log("siteOld:",values.siteCode)
// console.log("values.priceListType:"+values.priceListType + " values.site : " + values.siteCode)
            if (( siteCodes.length===0 && values.priceListType !=="" )) {
              MySwal.fire({
                title: <p>กรุณาระบุ SiteCode!</p>,
                didOpen: () => {
                  // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                },
              })
            }
            else {
            dispatch(actualPostDataBySite(valuesToSend));
            setSubmitting(false);
            }
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default ReportActualPage;
