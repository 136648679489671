import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { PriceLevel } from "../../types/pricelevel.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type PriceLevelState = {
    result: PriceLevel[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: PriceLevelState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const priceLevelGetData = createAsyncThunk(
    "pricelevel/getPriceLevel",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<PriceLevel[]>(server.PRICELEVEL_URL + "?keyword=" + keyword);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const priceLevelGetDataList = createAsyncThunk(
    "pricelevel/getPriceLevelList",
    async (_, thunkApi) => {
        try {
            const result = await httpClient.get<PriceLevel[]>(server.PRICELEVEL_URL + "/data/list");
            return result;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const priceLevelSlice = createSlice({
    name: "pricelevel",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(priceLevelGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(priceLevelGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(priceLevelGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(priceLevelGetDataList.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(priceLevelGetDataList.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(priceLevelGetDataList.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = priceLevelSlice.actions;
export const priceLevelSelector = (store: RootState) => store.priceLevelReducer;
export default priceLevelSlice.reducer;