import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  FormControl,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import { useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ApprovePricelist,
  ApprovePricelistExportExcel,
} from "../../../types/approve.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import {
  approveGetDataById,
  ApproveActionSelector,
  approvePriceListById,
} from "../../../store/slices/approveActionSlice";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Loader from "../../component/Loader";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";
import { exportExcelItem } from "../../../utils/excel";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ButtonBack from "../../layouts/ButtonBack";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridPinnedColumns,
  GridColumnGroupingModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import {
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRowParams,
} from "@mui/x-data-grid";
import "../../../App.css";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 80,
    color: theme.palette.common.white,
  },
}));

const StyledTableCellHeadSpan1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 40,
    color: theme.palette.common.white,
  },
}));

const StyledTableCellHeadSpan2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 40,
    color: theme.palette.common.white,
  },
}));

const StyledTableCellData1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // color: "#0000FF",
    // fontWeight: "bold",
  },
}));

const StyledTableCellData2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: "#43a047",
    fontWeight: "bold",
  },
}));

const ApprovePriceListPage: React.FC<any> = (prop) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const approveActionReducer = useSelector(ApproveActionSelector);
  // const match = useMatch("/approvepricelist/:id");
  const match = useMatch("/approvepricelist/:id");
  // const match2 = useMatch("/approvepricelistid2/:id");
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [docstatus, setDocstatus] = React.useState<any>("");
  const [checkvalidFrom, setcheckalidFrom] = React.useState<Date>();
  const [checkPriceLevel, setcheckPriceLevel] = React.useState<string>("");
  const [checkStatus, setcheckStatus] = React.useState<string>("");
  const [isActive, setActive] = React.useState("false");
  const [isActiveIcon, setActiveIcon] = React.useState("false");
  const [isPriceLevel, setIsPriceLevel] = React.useState('5');
  const [isPriority, setIsPriority] = React.useState(false);

  const initialValues: ApprovePricelist = {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    priceLevel: "",
    conditionType: "",
    condRecNo: "",
    priceListData: [],
    priceListOldData: [],
    remark: "",
    comment: "",
    approveBy: "",
    wfStep: null,
    wfStepAll: null,
    documentStatus: "",
    isPriority:false,
  };

  React.useEffect(() => {
    let id = match?.params.id;
    // console.log("id2:"+ match2?.params.id)
    dispatch(approveGetDataById(id!));
    setIsPriceLevel(approveActionReducer.result.priceLevel!)
    setIsPriority(approveActionReducer.result.isPriority!)
    // console.log("IsPriceLevel:"+isPriceLevel)
    // console.log(approveActionReducer.result.priceLevel!)
  }, []);

  const ToggleClass = () => {
    console.log("Toggle Click!");
    if (isActive === "true") {
      setActive("false");
    } else {
      setActive("true");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "priceLevel",
      headerName: "Price Level",
      headerAlign: "center",
      width: 100,
      headerClassName: "super-app-theme--header2",
    },
    {
      field: "itemCode",
      headerName: "Article Code",
      headerAlign: "center",
      width: 100,
      headerClassName: "super-app-theme--header2",
    },
    {
      field: "itemName",
      headerName: "Article Name",
      headerAlign: "center",
      width: 200,
      headerClassName: "super-app-theme--header2",
    },
    {
      field: "saleUnit",
      headerName: "Sales Unit",
      width: 100,
      headerAlign: "center",
      headerClassName: "super-app-theme--header2",
    },
    {
      field: "siteCode",
      headerName: "Site",
      width: 150,
      headerAlign: "center",
      headerClassName: "super-app-theme--header2",
    },
    {
      field: "siteGroup",
      headerName: "Site Group",
      headerAlign: "center",
      width: 150,
      headerClassName: "super-app-theme--header2",
      cellClassName: "super-app-theme--header--pined",
    },
    // { field: "saleOrg", headerName: "Sales Organization", width: 100 },
    // {
    //   field: "distributionChannel",
    //   headerName: "Distribution Channel",
    //   width: 150,
    // },
    // { field: "priceList", headerName: "Price List", width: 150 },

    // { field: "vendor", headerName: "Vendor", width: 150 },
    {
      field: "validFromO",
      headerName: "มีผลวันที่ (ปัจจุบัน)",
      width: 150,
      type: "date",
      headerClassName: "super-app-theme--header2",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == undefined
              ? "-"
              : moment(cellValues.value).format("DD.MM.YYYY")}
          </div>
        );
      },
      // valueFormatter: params =>
      //   moment(params?.value).format("DD.MM.YYYY")
    },
    {
      field: "validToO",
      headerName: "ถึงวันที่ (ปัจจุบัน)",
      width: 150,
      type: "date",
      headerClassName: "super-app-theme--header2",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == undefined
              ? "-"
              : moment(cellValues.value).format("DD.MM.YYYY")}
          </div>
        );
      },
      // valueFormatter: params =>
      //   moment(params?.value).format("DD.MM.YYYY")
    },
    // {
    //   field: "validFrom", headerName: "มีผลวันที่", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "validTo", headerName: "ถึงวันที่", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "amountInMargin",
    //   headerName: "Amount in Margin",
    //   width: 150,
    //   type: "number",
    // },
    {
      field: "amountInFinalPriceO",
      headerName: "ปัจจุบัน",
      width: 150,
      headerAlign: "center",
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 || cellValues.value == null
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "amountInFinalPrice",
      headerName: "ใหม่",
      width: 150,
      headerAlign: "center",
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--forecolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 || cellValues.value == null
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY1O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY1",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell-fontcolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price1O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price1",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--forecolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY2O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            { cellValues.value == 0 ||
            cellValues.value == null ||
           cellValues.value == undefined
              ? (Number(approveActionReducer.result.priceLevel!) ==5?Number(Number(cellValues.value).toFixed(2)):"0")
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY2",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell-fontcolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {isPriceLevel != '5' && cellValues.value == 0 ||
            isPriceLevel != '5' && cellValues.value == null ||
            isPriceLevel != '5' && cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price2O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price2",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--forecolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY3O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY3",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell-fontcolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price3O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price3",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--forecolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY4O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY4",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell-fontcolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price4O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price4",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--forecolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY5O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY5",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell-fontcolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price5O",
      headerName: "ปัจจุบัน",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "price5",
      headerName: "ใหม่",
      headerAlign: "center",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--forecolor",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.value == 0 ||
            cellValues.value == null ||
            cellValues.value == undefined
              ? "0"
              : Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "รายละเอียด",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header2",
      children: [
        {
          field: "priceLevel",
        },
        {
          field: "itemCode",
        },
        {
          field: "itemName",
        },
        {
          field: "saleUnit",
        },
        {
          field: "siteCode",
        },
        {
          field: "vendor",
        },
        {
          field: "siteGroup",
        },
      ],
    },
    {
      groupId: "ช่วงวันที่",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header2",
      // headerClassName: 'super-app-theme--header',
      children: [
        {
          field: "validFromO",
        },
        {
          field: "validFrom",
        },
        {
          field: "validToO",
        },
        {
          field: "validTo",
        },
      ],
    },
    {
      groupId: "ราคาทั่วไป",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "amountInFinalPriceO",
        },
        {
          field: "amountInFinalPrice",
        },
      ],
    },
    {
      groupId: "Scale 1 ปริมาณ",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "priceQTY1O",
        },
        {
          field: "priceQTY1",
        },
      ],
    },
    {
      groupId: "Scale 1 ราคา",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "price1O",
        },
        {
          field: "price1",
        },
      ],
    },
    {
      groupId: "Scale 2 ปริมาณ",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "priceQTY2O",
        },
        {
          field: "priceQTY2",
        },
      ],
    },
    {
      groupId: "Scale 2 ราคา",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "price2O",
        },
        {
          field: "price2",
        },
      ],
    },
    {
      groupId: "Scale 3 ปริมาณ",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "priceQTY3O",
        },
        {
          field: "priceQTY3",
        },
      ],
    },
    {
      groupId: "Scale 3 ราคา",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "price3O",
        },
        {
          field: "price3",
        },
      ],
    },
    {
      groupId: "Scale 4 ปริมาณ",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "priceQTY4O",
        },
        {
          field: "priceQTY4",
        },
      ],
    },
    {
      groupId: "Scale 4 ราคา",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "price4O",
        },
        {
          field: "price4",
        },
      ],
    },
    {
      groupId: "Scale 5 ปริมาณ",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "priceQTY5O",
        },
        {
          field: "priceQTY5",
        },
      ],
    },
    {
      groupId: "Scale 5 ราคา",
      description: "",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      children: [
        {
          field: "price5O",
        },
        {
          field: "price5",
        },
      ],
    },
  ];
  //console.log(approveActionReducer.result)
  //const rows = approveActionReducer.result === null ? 0 : approveActionReducer.result.priceListData.length
  // const rows = approveActionReducer.result === null ? 0 : approveActionReducer.result.priceListData.length
  const [page, setPage] = React.useState(0);
  const [value, setValue] = React.useState<Dayjs | null>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            approveActionReducer.result.priceListData.length
        )
      : 0;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const [pageSize, setPageSize] = React.useState(50);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSave = (docstatus: any, priceData: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
    // console.log("handleSave validFrom :" + priceData[0].validFrom)
    setcheckalidFrom(priceData[0].validFrom);
    setcheckPriceLevel(priceData[0].priceLevel);
  };

  const ExportExcel = (excelData: any) => {
    let approvePricelistDatas: ApprovePricelistExportExcel[] = [];
    Swal.fire({
      title: "Confirm!",
      text: "คุณต้องการ export data ใช่หรือไม่?",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        excelData.forEach((item: any) => {
          let approvePricelistData = new ApprovePricelistExportExcel();
          approvePricelistData.priceLevel = item.priceLevel;
          approvePricelistData.itemCode = item.itemCode;
          approvePricelistData.itemName = item.itemName;
          approvePricelistData.saleUnit = item.saleUnit;
          // approvePricelistData.saleOrg = item.saleOrg
          // approvePricelistData.distributionChannel = item.distributionChannel
          // approvePricelistData.priceList = item.priceList
          approvePricelistData.siteCode = item.siteCode;
          approvePricelistData.siteGroup = item.siteGroup;
          // approvePricelistData.vendor = item.vendor
          approvePricelistData.validFromO =
            item.validFromO === null
              ? "-"
              : moment(item.validFromO).format("DD.MM.YYYY").toString();
          approvePricelistData.validFrom =
            item.validFrom === null
              ? "-"
              : moment(item.validFrom).format("DD.MM.YYYY").toString();
          approvePricelistData.validToO =
            item.validToO === null
              ? "-"
              : moment(item.validToO).format("DD.MM.YYYY").toString();
          approvePricelistData.validTo =
            item.validTo === null
              ? "-"
              : moment(item.validTo).format("DD.MM.YYYY").toString();
          // approvePricelistData.amountInMarginO = item.amountInMarginO === null ? 0 : Number(Number(item.amountInMarginO).toFixed(2))
          // approvePricelistData.amountInMargin = item.amountInMargin === null ? 0 : Number(Number(item.amountInMargin).toFixed(2))
          approvePricelistData.amountInFinalPriceO =
            item.amountInFinalPriceO === null
              ? 0
              : Number(Number(item.amountInFinalPriceO).toFixed(2));
          approvePricelistData.amountInFinalPrice =
            item.amountInFinalPrice === null
              ? 0
              : Number(Number(item.amountInFinalPrice).toFixed(2));
          approvePricelistData.priceQTY1O =
            item.priceQTY1O === null
              ? 0
              : Number(Number(item.priceQTY1O).toFixed(2));
          approvePricelistData.priceQTY1 =
            item.priceQTY1 === null
              ? 0
              : Number(Number(item.priceQTY1).toFixed(2));
          approvePricelistData.price1O =
            item.price1O === null ? 0 : Number(Number(item.price1O).toFixed(2));
          approvePricelistData.price1 =
            item.price1 === null ? 0 : Number(Number(item.price1).toFixed(2));
          approvePricelistData.priceQTY2O =
            item.priceQTY2O === null
              ? 0
              : Number(Number(item.priceQTY2).toFixed(2));
          approvePricelistData.priceQTY2 =
            item.priceQTY2 === null
              ? 0
              : Number(Number(item.priceQTY2).toFixed(2));
          approvePricelistData.price2O =
            item.price2O === null ? 0 : Number(Number(item.price2O).toFixed(2));
          approvePricelistData.price2 =
            item.price2 === null ? 0 : Number(Number(item.price2).toFixed(2));
          approvePricelistData.priceQTY3O =
            item.priceQTY3O === null
              ? 0
              : Number(Number(item.priceQTY3O).toFixed(2));
          approvePricelistData.priceQTY3 =
            item.priceQTY3 === null
              ? 0
              : Number(Number(item.priceQTY3).toFixed(2));
          approvePricelistData.price3O =
            item.price3O === null ? 0 : Number(Number(item.price3O).toFixed(2));
          approvePricelistData.price3 =
            item.price3 === null ? 0 : Number(Number(item.price3).toFixed(2));
          approvePricelistData.priceQTY4O =
            item.priceQTY4O === null
              ? 0
              : Number(Number(item.priceQTY4O).toFixed(2));
          approvePricelistData.priceQTY4 =
            item.priceQTY4 === null
              ? 0
              : Number(Number(item.priceQTY4).toFixed(2));
          approvePricelistData.price4O =
            item.price4O === null ? 0 : Number(Number(item.price4O).toFixed(2));
          approvePricelistData.price4 =
            item.price4 === null ? 0 : Number(Number(item.price4).toFixed(2));
          approvePricelistData.priceQTY5O =
            item.priceQTY5O === null
              ? 0
              : Number(Number(item.priceQTY5O).toFixed(2));
          approvePricelistData.priceQTY5 =
            item.priceQTY5 === null
              ? 0
              : Number(Number(item.priceQTY5).toFixed(2));
          approvePricelistData.price5O =
            item.price5O === null ? 0 : Number(Number(item.price5O).toFixed(2));
          approvePricelistData.price5 =
            item.price5 === null ? 0 : Number(Number(item.price5).toFixed(2));
          approvePricelistDatas.push(approvePricelistData);
        });

        let Heading = [
          [
            "Price Level",
            "Article Code",
            "Article Name",
            "Sales Unit",
            "Site",
            "Site Group",
            "Valid from Current",
            "Valid from",
            "Valid To Current",
            "Valid To",
            "Amount in Final Price Current",
            "Amount in Final Price",
            "PQtyB1 Current",
            "PQtyB1",
            "PMPrice1 Current",
            "PMPrice1",
            "PQtyB2 Current",
            "PQtyB2",
            "PMPrice2 Current",
            "PMPrice2",
            "PQtyB3 Current",
            "PQtyB3",
            "PMPrice3 Current",
            "PMPrice3",
            "PQtyB4 Current",
            "PQtyB4",
            "PMPrice4 Current",
            "PMPrice4",
            "PQtyB5 Current",
            "PQtyB5",
            "PMPrice5 Current",
            "PMPrice5",
          ],
        ];

        exportExcelItem(
          approvePricelistDatas,
          Heading,
          "PriceList-" + moment().format("YYYY-MM-DD_hh:mm:ss").toString()
        );
      }
    });
  };

  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,
  }: FormikProps<ApprovePricelist>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Approve PriceList
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <TextField
                  id="priceDescription"
                  style={{ marginTop: 16 }}
                  fullWidth
                  variant="outlined"
                  label={t("priceDescription")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.priceDescription}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    errors.priceDescription && touched.priceDescription
                      ? true
                      : false
                  }
                />

                <TextField
                  id="remark"
                  style={{ marginTop: 16 }}
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                  label={t("remark")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.remark}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.remark && touched.remark ? true : false}
                />

                <DatePicker
                  views={["day", "month", "year"]}
                  label="Start Date"
                  readOnly
                  inputFormat="DD/MM/YYYY"
                  value={values.validFrom}
                  onChange={(newValue: any) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
                <DatePicker
                  views={["day", "month", "year"]}
                  label="End Date"
                  readOnly
                  inputFormat="DD/MM/YYYY"
                  value={values.validTo}
                  onChange={(newValue: any) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
          <FormControlLabel
            control={
              <Checkbox 
              checked={values.isPriority}     
              disabled
                  name="isPriority"
                  style ={{
                    color: "Red",
                  }}
                  />
            }
            label={t("isPriority")}
            style ={{
              color: "Red",
            }}
          />
          <br/>
                <TextField
                  id="comment"
                  style={{ marginTop: 16 }}
                  fullWidth
                  variant="outlined"
                  label={t("comment")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={values.comment}
                  helperText={
                    errors.comment && touched.comment
                      ? errors.comment
                      : "กรุณาระบุ comment"
                  }
                  error={errors.comment ? true : false}
                />
                <ButtonBack></ButtonBack>
              </Stack>
            </LocalizationProvider>

            {/* <TableContainer sx={{ width: windowWidth - 120, height: windowHeight - 200, mt: 2 }} component={Paper}>
              <Table sx={{ width: "max-content", height: "max-content" }} aria-label="Approve Pricelist" size="small">
                <TableHead>
                  <TableRow key={0}>
                    <StyledTableCellHead align="center" rowSpan={2}  style={{position: 'sticky', background: '#00695f',width : 95,left: 0}}>Price Level</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2} style={{position: 'sticky', background: '#00695f',width : 95,left: 95}}>Article Code</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2} style={{position: 'sticky',background: '#00695f',width : 250,left: 192 }}>Article Name</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2} style={{position: 'sticky',background: '#00695f',width : 90,left: 442 }}>Sales Unit</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2} >Article Name</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2} >Sales Unit</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2}>Sales Organization</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2}>Distribution Chanel</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2}>Price List</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2}>Site</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2}>Site Group</StyledTableCellHead>
                    <StyledTableCellHead align="center" rowSpan={2}>Vendor</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>Valid From</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>Valid To</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>Amount in Margin</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>Amount in Final Price</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PQtyB1 (Scale 1)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PMPrice1 (Scale 1)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PQtyB2 (Scale 2)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PMPrice2 (Scale 2)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PQtyB3 (Scale 3)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PMPrice3 (Scale 3)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PQtyB4 (Scale 4)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PMPrice4 (Scale 4)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PQtyB5 (Scale 5)</StyledTableCellHead>
                    <StyledTableCellHead align="center" colSpan={2}>PMPrice5 (Scale 5)</StyledTableCellHead>
                  </TableRow>
                  <TableRow key={1}>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                    <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                    <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? values.priceListData!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : values.priceListData
                  ).map((row, index) => (
                    <TableRow key={index + 2}>
                      <TableCell align="right" style={{position: 'sticky', background: 'white',width : 95,left: 0}}>{row.priceLevel}</TableCell>
                      <TableCell style={{position: 'sticky', background: 'white',width : 95,left: 95}}>{row.itemCode}</TableCell>
                      <TableCell  style={{position: 'sticky', background: 'white',width : 250,left: 192}}>{row.itemName}</TableCell>
                      <TableCell  style={{position: 'sticky', background: 'white',width : 90,left: 442}}>{row.saleUnit}</TableCell>
                      <TableCell  >{row.itemName}</TableCell>
                      <TableCell  >{row.saleUnit}</TableCell>
                      <TableCell>{row.saleOrg}</TableCell>
                      <TableCell>{row.distributionChannel}</TableCell>
                      <TableCell>{row.priceList}</TableCell>
                      <TableCell>{row.siteCode}</TableCell>
                      <TableCell>{row.siteGroup}</TableCell>
                      <TableCell>{row.vendor}</TableCell>
                      <StyledTableCellData1>{row.validFromO}</StyledTableCellData1>
                      <StyledTableCellData2>{row.validFrom}</StyledTableCellData2>
                      <StyledTableCellData1>{row.validToO}</StyledTableCellData1>
                      <StyledTableCellData2>{row.validTo}</StyledTableCellData2>
                      <StyledTableCellData1>{row.validFromO === null || row.validFromO === ""  || row.validFromO === undefined ||Number(row.amountInFinalPriceO)===0 ?  "-" : format(new Date(row.validFromO), 'dd.MM.yyyy')}</StyledTableCellData1>
                      <StyledTableCellData2>{row.validFrom === null || row.validFrom === ""|| row.validFrom === undefined  ? "-" : format(new Date(row.validFrom), 'dd.MM.yyyy')}</StyledTableCellData2>
                      <StyledTableCellData1>{row.validToO === null  || row.validToO === ""|| row.validToO === undefined || Number(row.amountInFinalPriceO)===0 ? "-" : format(new Date(row.validToO), 'dd.MM.yyyy')}</StyledTableCellData1>
                      <StyledTableCellData2>{row.validTo === null   || row.validTo === "" || row.validTo === undefined ? "-" : format(new Date(row.validTo), 'dd.MM.yyyy')}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.amountInMarginO === null ? 0 : row.amountInMarginO}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row.amountInMargin === null ? 0 : row.amountInMargin}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.amountInFinalPriceO === null ? 0 : row.amountInFinalPriceO}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row.amountInFinalPrice === null ? 0 : row.amountInFinalPrice}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.priceQTY1O === null ||row!.priceQTY1O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY1O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row.priceQTY1 === null ||row!.priceQTY1===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY1)}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.price1O === null ||row!.price1O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price1O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row!.price1 === null ||row!.price1===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price1)}</StyledTableCellData2>

                      <StyledTableCellData1 align="right">{row.priceQTY2O === null ||row!.priceQTY2O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY2O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row.priceQTY2 === null ||row!.priceQTY2===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY2)}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.price2O === null ||row!.price2O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price2O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row!.price2 === null ||row!.price2===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price2)}</StyledTableCellData2>

                      <StyledTableCellData1 align="right">{row.priceQTY3O === null ||row!.priceQTY3O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY3O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row.priceQTY3 === null ||row!.priceQTY3===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY3)}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.price3O === null ||row!.price3O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price3O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row!.price3 === null ||row!.price3===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price3)}</StyledTableCellData2>

                      <StyledTableCellData1 align="right">{row.priceQTY4O === null ||row!.priceQTY4O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY4O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row.priceQTY4 === null ||row!.priceQTY4===0? (Number(row!.priceLevel) !=5? null:0) : (Number(row!.priceLevel) !=5? null:row.priceQTY4)}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.price4O === null ||row!.price4O===0 ? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price4O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row!.price4 === null ||row!.price4===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price4)}</StyledTableCellData2>

                      <StyledTableCellData1 align="right">{row.priceQTY5O === null ||row!.priceQTY5O===0? (Number(row!.priceLevel) !=5? null:0) : (Number(row!.priceLevel) !=5? null:row.priceQTY5O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row.priceQTY5 === null ||row!.priceQTY5===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.priceQTY5)}</StyledTableCellData2>
                      <StyledTableCellData1 align="right">{row.price5O === null ||row!.price5O===0? (Number(row!.priceLevel) !=5? null:0)  : (Number(row!.priceLevel) !=5? null:row.price5O)}</StyledTableCellData1>
                      <StyledTableCellData2 align="right">{row!.price5 === null ||row!.price5===0 ? (Number(row!.priceLevel) !=5? null:0) : (Number(row!.priceLevel) !=5? null:row.price5)}</StyledTableCellData2>
                    </TableRow>

                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[12, 24, { label: 'All', value: -1 }]}
                      colSpan={3}
                      style={{position: 'sticky',left:0}}
                      count={values.priceListData ? values.priceListData!.length : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer> */}

            <div
              id="myDiv"
              className={isActive === "true" ? "fullscreen" : "rescreen"}
            >
              <Button
                color="info"
                variant="contained"
                onClick={(e) => ToggleClass()}
                startIcon={
                  isActive === "true" ? <ZoomInMapIcon /> : <ZoomOutMapIcon />
                }
              >
                Zoom
              </Button>
              <DataGridPro
                // isRowSelectable={(params: GridRowParams) => params.row.message !==""}
                // checkboxSelection
                sx={{
                  backgroundColor: "white",
                  height: "80vh",
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#6dc270",
                  },
                  "& .super-app-theme--header2": {
                    backgroundColor: "#00695f",
                    color: "white",

                    // border: "0.8px  black",
                  },
                  "& .super-app-theme--cell": {
                    // backgroundColor: "#6dc270",
                    backgroundColor: "#e0e0e0",
                  },

                  "& .super-app-theme--cell-fontcolor": {
                    // backgroundColor: "#6dc270",
                    backgroundColor: "#e0e0e0",
                    color: "#006400",
                    fontWeight: "bold",
                    fontSize: 14,
                    fontStyle: "italic",
                  },
                  "& .super-app-theme--forecolor": {
                    // backgroundColor: "#6dc270",
                    color: "#006400",
                    fontWeight: "bold",
                    fontSize: 14,
                    fontStyle: "italic",
                  },
                }}
                // pinnedColumns={pinnedColumns}
                initialState={{ pinnedColumns: { left: ["itemCode"] } }}
                getRowId={(row) => row.id}
                // rows={rowsData ? rowsData : []}
                rows={values.priceListData}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize: number) =>
                  setPageSize(newPageSize)
                }
                disableColumnSelector={true}
                // onCellEditCommit={handleCellEditCommit}
                disableSelectionOnClick={true}
                columnGroupingModel={columnGroupingModel}
                experimentalFeatures={{ columnGrouping: true }}
                showColumnRightBorder={true}
                showCellRightBorder={true}
              />
            </div>
            <FormControl style={{ marginTop: 10 }} fullWidth>
              {approveActionReducer.isError && (
                <Alert severity="error">{approveActionReducer.message}</Alert>
              )}
            </FormControl>
          </CardContent>

          <CardActions>
            <Button
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              variant="contained"
              fullWidth
              onClick={() =>
                handleSave(
                  {
                    id: match?.params.id,
                    documentStatus: "Approved",
                    comment: "",
                  },
                  values.priceListData
                )
              }
              // disabled={approveActionReducer.isFetching}
              disabled={
                approveActionReducer.result.documentStatus !==
                "Waiting for Approve"
              }
            >
              {" "}
              Approve
            </Button>
            <Button
              color="error"
              type="submit"
              variant="contained"
              fullWidth
              onClick={() =>
                handleSave(
                  {
                    id: match?.params.id,
                    documentStatus: "Draft",
                    comment: "",
                  },
                  values.priceListData
                )
              }
              //disabled={approveActionReducer.isFetching}
              disabled={
                approveActionReducer.result.documentStatus !==
                "Waiting for Approve"
              }
            >
              Reject
            </Button>
            <Button
              color="info"
              variant="contained"
              fullWidth
              onClick={(e) => ExportExcel(values.priceListData)}
            >
              Export Excel
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t("inputRequired");
  const validationSchema = Yup.object().shape({
    comment: Yup.string().required(inputRequired),
  });

  if (approveActionReducer.isFetching) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={
            approveActionReducer.result
              ? approveActionReducer.result!
              : initialValues
          }
          onSubmit={(values, { setSubmitting }) => {
            // values.documentStatus = "1";
            //const intApprovePricelist = {...values, id: docstatus.id, documentStatus: docstatus.documentStatus }
            // console.log(valuesToSend);
            var currentDate = new Date();
            let date1 = new Date(currentDate);
            let date2 = new Date(checkvalidFrom!);
            let priceLevel = Number(checkPriceLevel);
            if (priceLevel === 2) {
              priceLevel = 1;
            }
            let alertText;
            if (docstatus.documentStatus === "Draft") {
              alertText = "ยืนยันการ Reject?";
            } else {
              alertText = "ยืนยันการ Approve?";
            }
console.log('isPriority')
console.log(isPriority)
console.log(values.isPriority)
            if (
              date2!.setHours(0, 0, 0, 0) <= date1!.setHours(0, 0, 0, 0) &&
              docstatus.documentStatus !== "Draft" &&
              (priceLevel === 1 || priceLevel === 2)&& values.isPriority==false
            ) {
              Swal.fire({
                title: "Alert!",
                text:
                  "ไม่สามารถอนุมัติได้! รายการที่ขอวันที่เริ่มต้น : " +
                  moment(date2).format("DD.MM.YYYY") +
                  " เกินกำหนดแล้ว. ต้อง Reject เท่านั้น!",
                confirmButtonText: "OK",
              });
            }else if ( date2!.setHours(0, 0, 0, 0) < date1!.setHours(0, 0, 0, 0) &&
            docstatus.documentStatus !== "Draft" &&
            (priceLevel === 1 || priceLevel === 2)&& values.isPriority==true){
              Swal.fire({
                title: "Alert!",
                text:
                  "ไม่สามารถอนุมัติได้! รายการที่ขอวันที่เริ่มต้น : " +
                  moment(date2).format("DD.MM.YYYY") +
                  " เกินกำหนดแล้ว. ต้อง Reject เท่านั้น!",
                confirmButtonText: "OK",
              });
            }
            // else if (date2!.setHours(0, 0, 0, 0) < date1.setHours(0, 0, 0, 0)! && docstatus.documentStatus !=="Draft" && priceLevel > 3){
            //   Swal.fire({
            //     title: 'Alert!',
            //     text:'ไม่สามารถอนุมัติได้!! รายการที่ขอวันที่เริ่มต้น : '+ moment(date2).format("DD.MM.YYYY") +' เกินกำหนดแล้ว. ต้อง Reject เท่านั้น!!',
            //     confirmButtonText: 'OK',

            //   })
            // }
            else {
              Swal.fire({
                title: "Confirm!",
                text: alertText,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                showCancelButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  docstatus.comment = values.comment;

                  dispatch(approvePriceListById(docstatus));
                  setSubmitting(false);
                  Swal.fire("ดำเนินการเรียบร้อย!", "", "success");
                }
              });
            }
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default ApprovePriceListPage;
