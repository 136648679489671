import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Template, TemplateData } from "../../types/template.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";


const initialValues: Template = { 
id: "", 
priceDate: new Date(), 
priceLevel: "", 
templateName: "", 
templateData: [], 
active: true 
};


type TemplateActionState = {
    result: Template;
    message: string;
    isFetching: boolean;
    isError: boolean;
}


const initialStateValues: TemplateActionState = {
    result: initialValues,
    message: "",
    isFetching: false,
    isError: false,
};

export const templateGetDataById = createAsyncThunk(
    "template/getTemplate",
     async (id: string, thunkApi) => {
        try {
    
            const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id}`);
            return result;
       
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const templateGetDataImport = createAsyncThunk(
    "template/getTemplateImport",
    // async (id: string, thunkApi) => {
        async ({ formData}: {formData: TemplateData[]}, thunkApi) => {
        try {  
            let id="";     
            const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id}`);
         

            if(formData.length !== 0){
                let itemModels : TemplateData[]=[];
                // console.log("formData : " + formData)
                formData.forEach((data) => {
                    let msg ="";
                    let itemModel = new TemplateData();
                    itemModel.id= data.id!
                    itemModel.isSelect=true
                    itemModel.itemCode = data.itemCode!.toString();
                    itemModel.itemName= data.itemName
                    // itemModel.isSelect= true
                    itemModel.priceLevel= data.priceLevel
                    itemModel.ean11= data.ean11
                    itemModel.hpean= data.hpean
                    itemModel.taxType= data.taxType
                    itemModel.matkl= data.matkl
                    
                    itemModel.scagr= data.scagr
                    itemModel.brandId= data.brandId
                    itemModel.brandDesc= data.brandDesc
                    itemModel.saleUnit= data.saleUnit!.toUpperCase();
                    itemModel.saleOrg= data.saleOrg
                    itemModel.distributionChannel= data.distributionChannel
                    itemModel.priceList= data.priceList

                    itemModel.siteCode= data.siteCode!.toUpperCase();
                    itemModel.siteGroup= data.priceLevel=="7"?"":data.siteGroup
                    itemModel.vendor= data.vendor!.toString();

                    itemModel.validFrom= data.validFrom
                    itemModel.validTo= data.validTo

                    itemModel.amountInMargin = 0
                    itemModel.amountInFinalPrice = data.amountInFinalPrice == null ? 0 : Number(Number(data.amountInFinalPrice!).toFixed(2))
                    itemModel.price1= data.price1 == null ? 0 : data.price1!
                    itemModel.price2= data.price2 == null ? 0 : data.price2!
                    itemModel.price3=  data.price3 == null ? 0 : data.price3!
                    itemModel.price4=  data.price4 == null ? 0 : data.price4!
                    itemModel.price5=    data.price5 == null ? 0 : data.price5!
                    itemModel.priceQTY1=   data.priceQTY1 == null ? 0 : data.priceQTY1!
                    itemModel.priceQTY2= data.priceQTY2 == null ? 0 : data.priceQTY2!
                    itemModel.priceQTY3= data.priceQTY3 == null ? 0 : data.priceQTY3!
                    itemModel.priceQTY4=  data.priceQTY4 == null ? 0 : data.priceQTY4!
                    itemModel.priceQTY5= data.priceQTY5 == null ? 0 : data.priceQTY5!

                    itemModel.validFromO= data.validFromO
                    itemModel.validToO= data.validToO
                    itemModel.amountInMarginO= data.amountInMarginO == null ? 0 : data.amountInMarginO!
                    itemModel.amountInFinalPriceO= data.amountInFinalPriceO == null ? 0 : data.amountInFinalPriceO!
                    itemModel.priceQTY1O=data.priceQTY1O == null ? 0 : data.priceQTY1O!
                    itemModel.priceQTY2O= data.priceQTY2O == null ? 0 : data.priceQTY2O!
                    itemModel.priceQTY3O= data.priceQTY3O == null ? 0 : data.priceQTY3O!
                    itemModel.priceQTY4O= data.priceQTY4O == null ? 0 : data.priceQTY4O!
                    itemModel.priceQTY5O= data.priceQTY5O == null ? 0 : data.priceQTY5O!
                    itemModel.price1O= data.price1O == null ? 0 : data.price1O!
                    itemModel.price2O=  data.price2O == null ? 0 : data.price2O!
                    itemModel.price3O=  data.price3O == null ? 0 : data.price3O!
                    itemModel.price4O=  data.price4O == null ? 0 : data.price4O!
                    itemModel.price5O= data.price5O == null ? 0 : data.price5O!
                    itemModel.message=msg       
                    itemModels.push(itemModel)     
                      
                });               
                formData =itemModels? itemModels:[];             
              }
           
              result.data.templateData=formData;
              return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const templateCreateData = createAsyncThunk(
    "template/postTemplate",
    async ({ formData, selectList }: { formData: Template, selectList: TemplateData[] } , thunkApi) => {
        try {
            if (formData.templateData?.length == 0) {
                return thunkApi.rejectWithValue("Please input data");
            }

  
            let priceListData: TemplateData[] = [];
            for (let i = 0; i < formData.templateData.length; i++) {
                let found = selectList.find((x) => x.id === formData.templateData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.templateData[i], isSelect: true , }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.templateData[i], isSelect: false,  }
                    priceListData.push(valuesToSend);
                }
            }
            formData.templateData = priceListData.filter((x)=>x.isSelect===true);
            // let found = formData.templateData.find((x) => Number(x.priceLevel) != Number(formData.priceLevel));
            // if (found !== undefined) {
            //     return thunkApi.rejectWithValue("Price level not mat data in excel");
            // }

            if (formData.templateData?.length >= 601) {
                return thunkApi.rejectWithValue("Data invalid! limit data 600 rows.");
            }

            const result = await httpClient.post(server.TEMPLATE_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const templateUpdateData = createAsyncThunk(
    "template/putTemplate",
    // async (formData: Template, thunkApi) => {
        async ({ formData, selectList }: { formData: Template, selectList: TemplateData[] } , thunkApi) => {
        try {
            if (formData.templateData?.length == 0) {
                return thunkApi.rejectWithValue("Please input data");
            }


            // if (formData.priceLevel != formData.templateData![0]["priceLevel"]) {
            //     return thunkApi.rejectWithValue("Price level not mat data in excel");
            // }
  
            let priceListData: TemplateData[] = [];
            for (let i = 0; i < formData.templateData.length; i++) {
                let found = selectList.find((x) => x.id === formData.templateData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.templateData[i], isSelect: true , }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.templateData[i], isSelect: false,  }
                    priceListData.push(valuesToSend);
                }
            }

            formData.templateData = priceListData.filter((x)=>x.isSelect===true);
            // let found = formData.templateData.find((x) => Number(x.priceLevel) != Number(formData.priceLevel));
            // if (found !== undefined) {
            //     return thunkApi.rejectWithValue("Price level not mat data in excel");
            // }
          
            if (formData.templateData?.length >= 601) {
                return thunkApi.rejectWithValue("Data invalid! limit data 600 rows.");
            }
            
            const result = await httpClient.put(`${server.TEMPLATE_URL}/${formData.id}`, formData);
            return result.data;
            

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const templateDeleteData = createAsyncThunk(
    "template/deleteTemplate",
     async (id: string, thunkApi) => {
        try {
    
            const result = await httpClient.delete<Template>(`${server.TEMPLATE_URL}/${id}`);
            return result;
       
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const templateActionSlice = createSlice({
    name: "templateaction",
    initialState: initialStateValues,
    reducers: {
        clearTemplateData: (state) => {
            state.result = initialValues;
        },
        setTemplateData: (state, action: PayloadAction<TemplateData[]>) => {
            state.result.templateData = action.payload;
        },
        removeTemplateData: (state, action: PayloadAction<TemplateData[]>) => {
            state.result.templateData = action.payload;
        },
        updateTemplateData: (state, action: PayloadAction<TemplateData[]>) => {
            state.result.templateData = action.payload!;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(templateGetDataById.pending, (state, action) => {
                state.result = initialValues;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(templateGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                // const templateData: TemplateData[] = JSON.parse(action!.payload.data.templateData) as TemplateData[];

                // action!.payload.data.templateData = templateData;
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(templateGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.result = initialValues;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(templateGetDataImport.pending, (state, action) => {
                state.result = initialValues;
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(templateGetDataImport.fulfilled, (state, action: PayloadAction<any>) => {
                // const templateData: TemplateData[] = JSON.parse(action!.payload.data.templateData) as TemplateData[];

                // action!.payload.data.templateData = templateData;
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(templateGetDataImport.rejected, (state, action: PayloadAction<any>) => {
                state.result = initialValues;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(templateCreateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(templateCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                if (action!.payload.status == "OK") {
                    state.result = action!.payload.data;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
    
                    history.back();
                }else {
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
                }

            })
            .addCase(templateCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(templateUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(templateUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                if (action!.payload.status == "OK") {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
                }else{
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
    
                }
            })
            .addCase(templateUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(templateDeleteData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(templateDeleteData.fulfilled, (state, action: PayloadAction<any>) => {
                if (action!.payload.status == "OK") {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
                }else{
                    state.result = action!.payload.data;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = true;
    
                }
            })
            .addCase(templateDeleteData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { clearTemplateData, setTemplateData, removeTemplateData,updateTemplateData } = templateActionSlice.actions;
export const templateActionSelector = (store: RootState) => store.templateActionReducer;
export default templateActionSlice.reducer;