import * as React from "react";
import { Checkbox,Autocomplete,Card, CardContent, Typography, CardActions, Button, Box, FormControl, FormControlLabel, Radio, InputLabel, Alert, Select, OutlinedInput, MenuItem, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link, useMatch } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { siteGroupActionSelector, siteGroupGetDataById, siteGroupUpdateData } from "../../../store/slices/siteGroupActionSlice";
// import {  siteGroupGetDataById } from "../../../store/slices/siteGroupSlice";
import { SiteGroup } from "../../../types/sitegroup.type";
import { siteGetData, siteSelector } from "../../../store/slices/siteSlice";
import Loader from "../../component/Loader";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "../../../utils/httpclient";
import { server } from "../../../Constants";
import { Site } from "../../../types/site.type";
import { Chip,ListItemText } from '@mui/material';
import { getCombinedNodeFlags } from "typescript";

type SiteGroupEditPageProps = {
  //
};


const SiteGroupEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const siteGroupActionReducer = useSelector(siteGroupActionSelector);
  const siteReducer = useSelector(siteSelector);
  const match = useMatch("/sitegroup/edit/:id");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [petInputValue, setPetInputValue] = React.useState("");
  const [selectedSite, setSelectedSite] =  React.useState<Site[]>([]);
  const [getSite, setGetSite] =  React.useState<Site[]>([]);
  // const [selectedSiteGroup, setSelectedSiteGroup] =  React.useState<string>("");
  // const [selectedSiteGroupName, setSelectedSiteGroupName] =  React.useState<string>("");
  // const [selectedStatus, setSelectedStatus] =  React.useState<boolean>(true);
  // const [selectedId, setSelectedId] =  React.useState<string>("");

  const initialValues: SiteGroup = { siteGroupCode: "", siteGroupName: "", siteCode: [], active: true };

  React.useEffect(() => {
    dispatch(siteGetData(""));
    // console.log("chkSiteMaster:" + siteReducer.result.length)
  }, []);

  let id = match?.params.id;
  React.useEffect(() => {
    // dispatch(siteGetData(""));
    // dispatch(siteData(""));
    dispatch(siteGroupGetDataById(id!));
    dispatch(getSiteGroupEditDataIdPage(id!));
    //  dispatch(siteGroupGetDataById(id!)).then((xSite:any) => {
    //     let listSite=[];
    //     console.log("chkSite:" + xSite.payload.data!.siteCode.length)
    //     console.log("chkSiteMaster2:" + siteReducer.result.length)
        
    //     if(xSite.payload.data!){
    //       for (let x = 0; x < xSite.payload.data!.siteCode.length; x += 1) {
    //         // let getSite =  siteReducer.result.find((j) => j.siteCode! === xSite.payload.data!.siteCode[x]!);
    //         let getSite =  siteReducer.result.find((j) => j.siteCode! === xSite.payload.data!.siteCode[x]!);
    //         listSite.push(getSite!);
    //       }
    //       setSelectedSite(listSite);
    //     }
    //     console.log("chkSite1:" + selectedSite.length)
    //  }).catch((err)=> console.log("err:"+err));
    //  console.log("chkSite2:" + selectedSite.length)
      // setSite(siteGroupActionReducer.result!);
      let listSite=[];
     if(siteGroupActionReducer.result!){
       
          // for (let x = 0; x < siteGroupActionReducer.result!.siteCode.length; x += 1) {
          //   // let getSite =  siteReducer.result.find((j) => j.siteCode! === xSite.payload.data!.siteCode[x]!);
          //   let getSite =  siteReducer.result.find((j) => j.siteCode! === siteGroupActionReducer.result!.siteCode[x]!);
          //   listSite.push(getSite!);
          // }
          // setSelectedSite(listSite);

          // console.log("chkSite2:" +siteGroupActionReducer.result!.siteCode!.length)
          // console.log(siteGroupActionReducer.result!.siteCode!)
        }
        // console.log("chkSite1:" + selectedSite.length)
        // console.log(selectedSite)


  }, [])



  // React.useEffect(()=>{
    // let id = match?.params.id;
    // dispatch(getSiteGroupEditDataIdPage(id!));
  
  // },[])

 const siteData = createAsyncThunk(
    "site/getSites2",
    async (keyword: string,thunkApi) => {
        try {
            const result = await httpClient.get<Site[]>(server.SITE_URL + "?keyword=" + keyword);
            return result.data;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
             return thunkApi.rejectWithValue(err);
        }
    }
);

const getSiteGroupEditDataIdPage = createAsyncThunk(
    "sitegroup/siteGroupEditID",
    async (id: string, thunkApi) => {
      try {
        const result = await httpClient.get<SiteGroup>(`${server.SITEGROUP_URL}/${id}`);
        let keyword="";
        const resultSite = await httpClient.get<Site[]>(server.SITE_URL + "?keyword=" + keyword);
        let listSite:Site[]=[];

        if(result!){        
          // setSelectedSite(result!.data.siteCode);
            for (let x = 0; x < result.data.siteCode.length; x += 1) {
            let getSite = resultSite.data.find((j) => j.siteCode! === result.data.siteCode[x]!);
            listSite.push(getSite!);
          }
          setSelectedSite(listSite);

          // setSelectedSiteGroup(result!.data.siteGroupCode!)
          // setSelectedSiteGroupName(result!.data.siteGroupName!)
          // setSelectedStatus(result!.data.active!)
        }

        // return result.data;
      } catch (error: any) {
        let err = error.response.data.message ?? error.message
        // return thunkApi.rejectWithValue(err);
      }
    }
  );

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<SiteGroup>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4}}>
            <Typography gutterBottom variant="h5">
              Edit Site Group
            </Typography>

            <TextField
              name="siteGroupCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('siteGroupCode')}
              value={values.siteGroupCode}
              // value={selectedSiteGroup}
              onChange={handleChange}
              onBlur={handleBlur}
              // helperText={
              //   errors.siteGroupCode && touched.siteGroupCode
              //     ? errors.siteGroupCode
              //     : 'Enter your Code.'
              // }
              error={
                errors.siteGroupCode && touched.siteGroupCode
                  ? true
                  : false
              } />


            <TextField
              name="siteGroupName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('siteGroupName')}
              value={values.siteGroupName}
              // value={selectedSiteGroupName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.siteGroupName && touched.siteGroupName
                  ? errors.siteGroupName
                  : ''
              }
              error={
                errors.siteGroupName && touched.siteGroupName
                  ? true
                  : false
              } />

            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="siteCode">{t('siteCode')}</InputLabel>
              <Select
                multiple
                MenuProps={MenuProps}
                name="siteCode"
                value={values.siteCode}
             
                input={<OutlinedInput id="select-multiple-chip" label="siteCode" />}
                label={t("siteCode")}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
  
              >
                {siteReducer.result.filter(x => x.siteCode !== null && x.siteCode !== '').map((item, index) => (
                  <MenuItem key={item.id} value={item.siteCode}>
                     <Checkbox checked={values.siteCode.includes(item.siteCode!)} />
                     <ListItemText primary={item.siteName} />
                   
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl style={{ marginTop: 16 ,width: '100%'}} >
            <Autocomplete
                  multiple
                  id="siteCode"
                  options={siteReducer.result!}
                  // options={getSite}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option!.siteName!)}
                  isOptionEqualToValue={(option, value) => option.siteCode === value.siteCode}
                  // getOptionSelected={(option, value) => option!.siteCode! === value.siteCode!}
                 
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.siteName}
                    </li>                
                   )}
                  // value={values.siteCode}
                   value={selectedSite}
                  // defaultValue={selectedSite}
                    // value={selectedSite.length>0?selectedSite:values.siteCode}
                  // onChange={handleChange}
                  onBlur={handleBlur}
                  inputValue={petInputValue}
                  onInputChange={(event, newPetInputValue) => {
                    setPetInputValue(newPetInputValue);
                  }}
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    handleChange(event);             
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('siteCode')} placeholder="Search" />
                  )}
                />
              </FormControl>

            
            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
             
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {siteGroupActionReducer.isError && <Alert severity="error">{siteGroupActionReducer.message}</Alert>}

          </CardContent>
          <CardActions >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={siteGroupActionReducer.isFetching}
            >
              {t('btnEdit')}
            </Button>
            <Button component={Link} to="/siteGroup" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    siteGroupCode: Yup.string().required(inputRequired),
    siteGroupName: Yup.string().required(inputRequired),
  });


  if (siteGroupActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={siteGroupActionReducer.result ? siteGroupActionReducer.result! : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          let selectSite=[]
          for (let iSite of selectedSite) {
            selectSite.push(iSite.siteCode)
          }
          // console.log(selectSite)

          const valuesToSend = {
            ...values,siteCode:selectSite,
          };

          dispatch(siteGroupUpdateData(valuesToSend));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default SiteGroupEditPage;
