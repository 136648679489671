import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { PriceLevel } from "../../types/pricelevel.type";
import { history } from "../../index";

type PriceLevelActionState = {
    result: PriceLevel | null;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: PriceLevelActionState = {
    result: null,
    message: "",
    isFetching: false,
    isError: false,
};


export const priceLevelGetDataById = createAsyncThunk(
    "priceLevel/getPriceLevel",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<PriceLevel>(`${server.PRICELEVEL_URL}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const priceLevelCreateData = createAsyncThunk(
    "priceLevel/postPriceLevel",
    async (formData: PriceLevel, thunkApi) => {
        try {
            const result = await httpClient.post(server.PRICELEVEL_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const priceLevelUpdateData = createAsyncThunk(
    "priceLevel/putPriceLevel",
    async (formData: PriceLevel, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.PRICELEVEL_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const priceLevelActionSlice = createSlice({
    name: "priceLevelAction",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(priceLevelGetDataById.pending, (state, action) => {
                state.result = null;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(priceLevelGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(priceLevelGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(priceLevelCreateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(priceLevelCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(priceLevelCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(priceLevelUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(priceLevelUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(priceLevelUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = priceLevelActionSlice.actions;
export const priceLevelActionSelector = (store: RootState) => store.priceLevelActionReducer;
export default priceLevelActionSlice.reducer;