import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { SiteGroup } from "../../types/sitegroup.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type SiteGroupState = {
    result: SiteGroup[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: SiteGroupState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const siteGroupGetData = createAsyncThunk(
    "sitegroup/getSiteGroups",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<SiteGroup[]>(server.SITEGROUP_URL + "?keyword=" + keyword);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const siteGroupSlice = createSlice({
    name: "sitegroup",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(siteGroupGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(siteGroupGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(siteGroupGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = siteGroupSlice.actions;
export const siteGroupSelector = (store: RootState) => store.siteGroupReducer;
export default siteGroupSlice.reducer;