import * as React from "react";
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardContent, FormControl, Fab, Alert } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import { useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApprovePricelist } from "../../../types/approve.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { approveGetDataById, ApproveActionSelector } from "../../../store/slices/approveActionSlice";
import { approveWorkFlowHistoryGetData, ApproveWorkFlowHistorySelector } from "../../../store/slices/approveWorkFlowHistorySlice";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import { DataGrid, GridColDef, } from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Loader from "../../component/Loader";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewListIcon from '@mui/icons-material/ViewList';
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment";
import { useDebounce } from "@react-hook/debounce";
import { format } from "date-fns";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import ButtonBack from "../../layouts/ButtonBack";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 80,
    color: theme.palette.common.white,
  }
}));

const StyledTableCellHeadSpan1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 40,
    color: theme.palette.common.white,
  }
}));

const StyledTableCellHeadSpan2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#43a047",
    borderRight: "1px solid gray",
    fontSize: "0.70rem",
    minWidth: 40,
    color: theme.palette.common.white,
  }
}));

const StyledTableCellData1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: "#0000FF",
    fontWeight: "bold",
  }
}));

const StyledTableCellData2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: "#43a047",
    fontWeight: "bold",
  }
}));


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pl: 0, pr: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type ApproveHistoryListSpecialPageProps = {

  //
};

const ApproveHistoryListSpecialPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const approveActionReducer = useSelector(ApproveActionSelector);
  const approveWorkFlowHistoryReducer = useSelector(ApproveWorkFlowHistorySelector);
  const match = useMatch("/approvehistorylistspecial/:id");
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [docstatus, setDocstatus] = React.useState<any>("");
  const [value, setValue] = React.useState(0);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const inputSearch = t('inputSearch');
  const [valueDay, setValueDay] = React.useState<Dayjs | null>(null);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const initialValues: ApprovePricelist = {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    priceLevel: "",
    conditionType: "",
    condRecNo: "",
    priceListData: [],
    priceListOldData: [],
    remark: "",
    comment: "",
    approveBy: "",
    wfStep: null,
    wfStepAll: null,
    documentStatus: "",
  };

  React.useEffect(() => {
    let id = match?.params.id;
    dispatch(approveGetDataById(id!));
    dispatch(approveWorkFlowHistoryGetData(id!));
  }, [])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - approveActionReducer.result.priceListData.length) : 0;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSave = (docstatus: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
  }

  // const columns: GridColDef[] = [
  //   {
  //     field: "wfApprover", width: 110, headerAlign: 'center', headerClassName: 'super-app-theme--header',
  //     renderHeader: () => (<strong> {t('Approver')} </strong>),
  //   },
  //   {
  //     field: "wfApproveStatus", width: 110, headerAlign: 'center', headerClassName: 'super-app-theme--header',
  //     renderHeader: () => (<strong> {t('Status')} </strong>),
  //   },
  //   {
  //     field: "remark", width: 600, headerAlign: 'center', headerClassName: 'super-app-theme--header',
  //     renderHeader: () => (<strong> {t('Remark')} </strong>),
  //   },
  //   {
  //     field: "wfApproveDate", width: 100, type: 'date', headerAlign: 'center', headerClassName: 'super-app-theme--header',
  //     valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
  //     renderHeader: () => (<strong> {t('Approve Date')} </strong>),
  //   },
  //   {
  //     field: "createdDate", width: 100, type: 'date', headerAlign: 'center', headerClassName: 'super-app-theme--header',
  //     valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
  //     renderHeader: () => (<strong> {t('Create Date')} </strong>),
  //   },
  //   {
  //     field: "createdBy", width: 100, headerAlign: 'center', headerClassName: 'super-app-theme--header',
  //     renderHeader: () => (<strong> {t('Create By')} </strong>),
  //   },
  // ];
  const columns: GridColDef[] = [
    {
      field: "priceLevel",
      headerName: "Price Level",
      width: 100,
    },
    {
      field: "itemCode",
      headerName: "Article Code",
      width: 100,
    },
    {
      field: "itemName",
      headerName: "Article Name",
      width: 200,
    },
    {
      field: "saleUnit",
      headerName: "Sales Unit",
      width: 100,
    },
    {
      field: "siteCode",
      headerName: "Site",
      width: 150,
    },
    // {
    //   field: "siteGroup",
    //   headerName: "Site Group",
    //   width: 150,
    //   cellClassName: "super-app-theme--header--pined",
    // },
    // { field: "saleOrg", headerName: "Sales Organization", width: 100 },
    // {
    //   field: "distributionChannel",
    //   headerName: "Distribution Channel",
    //   width: 150,
    // },
    // { field: "priceList", headerName: "Price List", width: 150 },

    // { field: "vendor", headerName: "Vendor", width: 150 },

    // {
    //   field: "validFromO", headerName: "มีผลวันที่ (ปัจจุบัน)", width: 150, type: 'date', 
    //   renderCell: (cellValues) => {
    //     return (
    //       <div
    //       >
    //         {cellValues.value==undefined?'-':
    //               moment(cellValues.value).format("DD.MM.YYYY")}
    //       </div>
    //     );
    //   },
    //   // valueFormatter: params =>
    //   //   moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "validToO", headerName: "ถึงวันที่ (ปัจจุบัน)", width: 150, type: 'date', 
    //   renderCell: (cellValues) => {
    //     return (
    //       <div
    //       >
    //         {cellValues.value==undefined?'-':
    //               moment(cellValues.value).format("DD.MM.YYYY")}
    //       </div>
    //     );
    //   },
    //   // valueFormatter: params =>
    //   //   moment(params?.value).format("DD.MM.YYYY")
    // },


    // {
    //   field: "validFrom", headerName: "มีผลวันที่", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "validTo", headerName: "ถึงวันที่", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY")
    // },
    // {
    //   field: "amountInMargin",
    //   headerName: "Amount in Margin",
    //   width: 150,
    //   type: "number",
    // },
    {
      field: "amountInFinalPriceO",
      headerName: "ราคาทั่วไป-ปัจจุบัน",
      width: 150,
    
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "priceQTY1O",
      headerName: "ปริมาณ Lv1 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "price1O",
      headerName: "ราคา Lv1 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "priceQTY2O",
      headerName: "ปริมาณ Lv2 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
       cellClassName: "super-app-theme--cell",
       renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "price2O",
      headerName: "ราคา Lv2 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "priceQTY3O",
      headerName: "ปริมาณ Lv3 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "price3O",
      headerName: "ราคา Lv3 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
   
    {
      field: "priceQTY4O",
      headerName: "ปริมาณ Lv4 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "price4O",
      headerName: "ราคา Lv4 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "priceQTY5O",
      headerName: "ปริมาณ Lv5 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },

    {
      field: "price5O",
      headerName: "ราคา Lv5 ปัจจุบัน",
      width: 150,
      type: "number",
      headerClassName: "super-app-theme--header",
      // cellClassName: "super-app-theme--cell",
      renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
    {
      field: "amountInFinalPrice",
      headerName: "ราคาพิเศษ-ใหม่",
      width: 150,
    
      type: "number",
       headerClassName: "super-app-theme--header2",
       cellClassName: "super-app-theme--forecolor",
       renderCell: (cellValues) => {
        return (
          <div
          >
            {cellValues.value==0||cellValues.value==null?'':Number(Number(cellValues.value).toFixed(2))}
          </div>
        );
      },
    },
 
  ];
  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<ApprovePricelist>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Approve History Details (Special Price)
            </Typography>

            <TextField  id="priceDescription"
              style={{ marginTop: 16 ,marginBottom:16}}
              fullWidth
              variant="outlined"
              label={t('priceDescription')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.priceDescription}
              InputProps={{
                readOnly: true,
              }}
              helperText={
                errors.priceDescription && touched.priceDescription
                  ? errors.priceDescription
                  : ''
              }
              error={
                errors.priceDescription && touched.priceDescription
                  ? true
                  : false
              } /><br></br>
              <LocalizationProvider dateAdapter={AdapterDayjs}  >
                <DatePicker 
                  views={['day', 'month', 'year']}
                  label="Start Date"
                  readOnly
                  inputFormat="DD/MM/YYYY"
                  value={values.validFrom}
                  onChange={(newValue: any) => {
                    setValueDay(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} helperText={null} />}
                  InputProps={{
                    readOnly: true,
                  }}
                /> to 
                <DatePicker
                  views={['day', 'month', 'year']}
                  label="End Date"
                  readOnly
                  inputFormat="DD/MM/YYYY"
                  value={values.validTo}
                  onChange={(newValue: any) => {
                    setValueDay(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} helperText={null} />}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                </LocalizationProvider>
            <TextField  id="remark"
              style={{ marginTop: 16 }}
              fullWidth
              multiline
              rows={5}
              variant="outlined"
              label={t('remark')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.remark}
              InputProps={{
                readOnly: true,
              }}
              helperText={
                errors.remark && touched.remark
                  ? errors.remark
                  : ''
              }
              error={
                errors.remark && touched.remark
                  ? true
                  : false
              } />
             <ButtonBack  ></ButtonBack>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChangeTab} aria-label="Approve History" variant="fullWidth">
                  <Tab icon={<ViewListIcon />} label="PriceList Details" {...a11yProps(0)} />
                  <Tab icon={<InfoIcon />} label="Workflow Details" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {/* <TableContainer sx={{ width: windowWidth - 130, height: windowHeight - 200 }} component={Paper}>
                  <Table sx={{ width: "max-content", height: "max-content" }} aria-label="Approve Pricelist" size="small">
                    <TableHead>
                      <TableRow key={0}>
                        <StyledTableCellHead align="center" rowSpan={2} style={{ position: 'sticky',background: '#00695f',width : 95,left: 0}} >Price Level</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2} style={{position: 'sticky',background: '#00695f',width : 95,left: 95}}>Article Code</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2} style={{position: 'sticky',background: '#00695f',width : 250,left: 192 }}>Article Name</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2} style={{position: 'sticky',background: '#00695f',width : 90,left: 442 }}>Sales Unit</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Sales Organization</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Distribution Chanel</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Price List</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Site</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Site Group</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={2}>Vendor</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>Valid From</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>Valid To</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>Amount in Margin</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>Amount in Final Price</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PQtyB1 (Scale 1)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PMPrice1 (Scale 1)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PQtyB2 (Scale 2)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PMPrice2 (Scale 2)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PQtyB3 (Scale 3)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PMPrice3 (Scale 3)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PQtyB4 (Scale 4)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PMPrice4 (Scale 4)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PQtyB5 (Scale 5)</StyledTableCellHead>
                        <StyledTableCellHead align="center" colSpan={2}>PMPrice5 (Scale 5)</StyledTableCellHead>
                      </TableRow>
                      <TableRow key={1}>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                        <StyledTableCellHeadSpan1 align="center">Current</StyledTableCellHeadSpan1>
                        <StyledTableCellHeadSpan2 align="center">New</StyledTableCellHeadSpan2>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? values.priceListData!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : values.priceListData
                      ).map((row, x) => (
                        <TableRow key={x + 2}>
                          <TableCell align="right" style={{position: 'sticky', background: 'white',width : 95,left: 0}}>{row.priceLevel}</TableCell>
                          <TableCell style={{position: 'sticky', background: 'white',width : 95,left: 95}}>{row.itemCode}</TableCell>
                          <TableCell style={{position: 'sticky', background: 'white',width : 250,left: 192}}>{row.itemName}</TableCell>
                          <TableCell  style={{position: 'sticky', background: 'white',width : 90,left: 442}}>{row.saleUnit}</TableCell>
                          <TableCell>{row.saleOrg}</TableCell>
                          <TableCell>{row.distributionChannel}</TableCell>
                          <TableCell>{row.priceList}</TableCell>
                          <TableCell>{row.siteCode}</TableCell>
                          <TableCell>{row.siteGroup}</TableCell>
                          <TableCell>{row.vendor}</TableCell>
                         
                          <StyledTableCellData1>{row.validFromO === null || row.validFromO === undefined ||row.validFromO === "-"? "-" : format(new Date(row.validFromO), 'dd.MM.yyyy')}</StyledTableCellData1>
                          <StyledTableCellData2>{row.validFrom === null || row.validFrom === undefined ||row.validFrom === "-" ? "-" : format(new Date(row.validFrom), 'dd.MM.yyyy')}</StyledTableCellData2>
                          <StyledTableCellData1>{row.validToO === null || row.validToO === undefined ||row.validToO === "-" ? "-" : format(new Date(row.validToO), 'dd.MM.yyyy')}</StyledTableCellData1>
                          <StyledTableCellData2>{row.validTo === null || row.validTo === undefined ||row.validTo === "-"? "-" : format(new Date(row.validTo), 'dd.MM.yyyy')}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.amountInMarginO === null ? 0 : row.amountInMarginO}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.amountInMargin === null ? 0 : row.amountInMargin}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.amountInFinalPriceO === null ? 0 : row.amountInFinalPriceO}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.amountInFinalPrice === null ? 0 : row.amountInFinalPrice}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.priceQTY1O === null ? 0 : row.priceQTY1O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.priceQTY1 === null ? 0 : row.priceQTY1}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.price1O === null ? 0 : row.price1O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row!.price1 === null ? 0 : row.price1}</StyledTableCellData2>

                          <StyledTableCellData1 align="right">{row.priceQTY2O === null ? 0 : row.priceQTY2O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.priceQTY2 === null ? 0 : row.priceQTY2}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.price2O === null ? 0 : row.price2O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row!.price2 === null ? 0 : row.price2}</StyledTableCellData2>

                          <StyledTableCellData1 align="right">{row.priceQTY3O === null ? 0 : row.priceQTY3O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.priceQTY3 === null ? 0 : row.priceQTY3}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.price3O === null ? 0 : row.price3O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row!.price3 === null ? 0 : row.price3}</StyledTableCellData2>

                          <StyledTableCellData1 align="right">{row.priceQTY4O === null ? 0 : row.priceQTY4O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.priceQTY4 === null ? 0 : row.priceQTY4}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.price4O === null ? 0 : row.price4O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row!.price4 === null ? 0 : row.price4}</StyledTableCellData2>

                          <StyledTableCellData1 align="right">{row.priceQTY5O === null ? 0 : row.priceQTY5O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row.priceQTY5 === null ? 0 : row.priceQTY5}</StyledTableCellData2>
                          <StyledTableCellData1 align="right">{row.price5O === null ? 0 : row.price5O}</StyledTableCellData1>
                          <StyledTableCellData2 align="right">{row!.price5 === null ? 0 : row.price5}</StyledTableCellData2>
                        </TableRow>

                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[12, 24, { label: 'All', value: -1 }]}
                          colSpan={3}
                          style={{position: 'sticky',left:0}}
                          count={values.priceListData ? values.priceListData!.length : 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer> */}
           <DataGridPro
                sx={{
                  backgroundColor: "white",
                  height: "80vh",
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#a2cf6e",
                  },
                  "& .super-app-theme--header2": {
                    backgroundColor: "#6dc270",
                },    
                  "& .super-app-theme--cell": {
                    // backgroundColor: "#6dc270",
                    backgroundColor: "#e0e0e0",

                  },
                  "& .super-app-theme--cell-fontcolor": {
                    // backgroundColor: "#6dc270",
                    backgroundColor: "#e0e0e0",
                    color: "#006400",
                    fontWeight: 'bold',
                    fontSize: 14,
                    fontStyle: 'italic'
                  },
                  "& .super-app-theme--forecolor": {
                    // backgroundColor: "#6dc270",
                    // color: "#6dc270",
                    color: "#006400",
                    fontWeight: 'bold',
                    fontSize: 14,
                    fontStyle: 'italic'
                  },
                }}
              // pinnedColumns={pinnedColumns}
              initialState={{ pinnedColumns: { left: ['itemName'] } }}
              getRowId={(row) => row.id}
              // rows={rowsData ? rowsData : []}
              rows={values.priceListData
              }
              columns={columns}
              rowsPerPageOptions={[10, 50, 100]}
              // pageSize={pageSize}
              // onPageSizeChange={(newPageSize: number) =>
              //   setPageSize(newPageSize)
              // }
              disableColumnSelector={true}
              // onCellEditCommit={handleCellEditCommit}
              disableSelectionOnClick={true}
            />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* <DataGrid
                  disableSelectionOnClick={true}
                  sx={{
                    backgroundColor: "white", height: "80vh", width: '100%', '& .super-app-theme--header': {
                      backgroundColor: '#43a047', color: 'white'
                    }
                  }}
                  componentsProps={{
                    toolbar: {
                      value: keywordSearchNoDelay,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setKeywordSearch(e.target.value);
                        setKeywordSearchNoDelay(e.target.value);
                      },
                      clearSearch: () => {
                        setKeywordSearch("");
                        setKeywordSearchNoDelay("");
                      },
                    },
                  }}
                  //getRowId={(row) => row.id}
                  // rows={templateReducer.result!}
                  rows={approveWorkFlowHistoryReducer.result!}
                  columns={columns}
                  pageSize={50}
                  rowsPerPageOptions={[10]}
                /> */}

                <TableContainer sx={{ width: windowWidth - 130, height: windowHeight - 200 }} component={Paper}>
                  <Table sx={{ width: "50%", height: "max-content" }} aria-label="Approve Pricelist" >
                    <TableHead>
                      <TableRow key={0}>
                        <StyledTableCellHead align="center" rowSpan={1} style={{ position: 'sticky',background: '#00695f',width : 95,left: 0}} >Approver</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={1}>Status</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={1}>Remark</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={1}>Approve Date</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={1}>Created Date</StyledTableCellHead>
                        <StyledTableCellHead align="center" rowSpan={1}>Created By</StyledTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? approveWorkFlowHistoryReducer.result!.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : approveWorkFlowHistoryReducer.result!
                      ).map((row, x) => (
                        <TableRow key={x + 2}>
                          <TableCell align="right" style={{position: 'sticky', background: 'white',width : 95,left: 0}}>{row.wfApprover}</TableCell>
                         {/* <TableCell>{row.wfApprover}</TableCell> */}
                          <TableCell style={{width : 250}}>{row.wfApproveStatus}</TableCell>
                            <TableCell style={{width : 350}}>{row.remark}</TableCell>
                          <TableCell>{row.wfApproveDate === null || row.wfApproveDate === undefined ||row.wfApproveDate === "-"? "-" : format(new Date(row.wfApproveDate), 'dd.MM.yyyy')}</TableCell>
                          <TableCell>{row.createdDate === null || row.createdDate === undefined ||row.createdDate === "-"? "-" : format(new Date(row.createdDate), 'dd.MM.yyyy')}</TableCell>
                        
                          <TableCell>{row.createdBy}</TableCell>
                     
                        
                        </TableRow>

                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        {/* <TablePagination
                          // rowsPerPageOptions={[12, 24, { label: 'All', value: -1 }]}
                          colSpan={3}
                          style={{position: 'sticky',left:0}}
                          count={approveWorkFlowHistoryReducer.result ? approveWorkFlowHistoryReducer.result!.length : 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          // SelectProps={{
                          //   inputProps: {
                          //     'aria-label': 'rows per page',
                          //   },
                          //   native: true,
                          // }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        /> */}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Box>

            <FormControl style={{ marginTop: 10 }} fullWidth>
              {approveActionReducer.isError && <Alert severity="error">{approveActionReducer.message}</Alert>}
            </FormControl>
          </CardContent>
        </Card>
      </Form>
    );
  };

  // const validationSchema = Yup.object().shape({
  // });

  if (approveActionReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <>
      <Box>
        <Formik
          //validationSchema={validationSchema}
          initialValues={approveActionReducer.result ? approveActionReducer.result! : initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};


export default ApproveHistoryListSpecialPage;
