import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector } from "react-redux";
import { User } from "../../../types/user.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { roleGetData, roleSelector } from "../../../store/slices/roleSlice";
import { userActionSelector, userCreateData } from "../../../store/slices/userActionSlice";
import Loader from "../../component/Loader";



type UserCreatePageProps = {
  //
};

const initialValues: User = { username: "",password:"", fullname: "", email: "", emailCC: "",approveLevel:1,isAd:true, roleId: null, active: true,remark:"" };


const UserCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const roleReducer = useSelector(roleSelector);
  const userActionReducer = useSelector(userActionSelector);
  const [approveLevel, setApproveLevel] = React.useState(1);


  React.useEffect(() => {
    dispatch(roleGetData(""));
  }, [])
  const handleChangeApproveLevel = (event: SelectChangeEvent) => {
    let chkNum = (event.target.value! == null|| event.target.value! ==undefined?1:event.target.value!)
    setApproveLevel(Number(chkNum));
  };

  // const handleRoleChange = (event: SelectChangeEvent) => {
  //   setSelectRole(event.target.value as string);
  // };


  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<User>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create User
            </Typography>

            <TextField id="username"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('username')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.username && touched.username
                  ? errors.username
                  : ''
              }
              error={
                errors.username && touched.username
                  ? true
                  : false
              } />

            <TextField id="fullname"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('fullname')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.fullname && touched.fullname
                  ? errors.fullname
                  : ''
              }
              error={
                errors.fullname && touched.fullname
                  ? true
                  : false
              } />

            <TextField id="email"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('email')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.email && touched.email
                  ? errors.email
                  : ''
              }
              error={
                errors.email && touched.email
                  ? true
                  : false
              } />
            <TextField id="emailCC"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('emailCC_ex')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.emailCC && touched.emailCC
                  ? errors.emailCC
                  : ''
               
              }
              error={
                errors.emailCC && touched.emailCC
                  ? true
                  : false
              } />



            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="roleId">{t('selectRole')}</InputLabel>
              <Select
                name="roleId"
                // value={roleReducer.result[0] && roleReducer.result[0].roleid}
                // defaultValue={roleReducer.result[0] && roleReducer.result[0].roleId}
                onChange={(event) => { setFieldValue('roleId', event.target.value) }}
                error={
                  errors.roleId && touched.roleId
                    ? true
                    : false
                }
              >
                {(roleReducer.result!.filter((x) => x.active===true)).map((item, index) => (
                  <MenuItem key={item.roleId} value={item.roleId}>
                    {item.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl style={{ marginTop: 16 }} fullWidth>
            <InputLabel id="demo-simple-select-autowidth-label">{t('approveLevel')}</InputLabel>
            <Select
            // defaultValue="1"
              labelId="demo-simple-select-autowidth-label"
              id="approveLevel"
              value={approveLevel.toString()}
              onChange={handleChangeApproveLevel}
              // autoWidth
              label={t('approveLevel')}
              error={
                errors.approveLevel && touched.approveLevel
                  ? true
                  : false
              } 
            >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            </Select>

            </FormControl>
            <TextField id="remark"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('remark')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.remark
                  ? errors.remark
                  : 'ระบุ Remark!'
              }
              error={
                errors.remark 
                  ? true
                  : false
              } />

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {userActionReducer.isError && <Alert severity="error">{userActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={userActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/user" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(inputRequired),
    fullname: Yup.string().required(inputRequired),
    email: Yup.string().email('Invalid email').required(inputRequired),
    roleId: Yup.string().required(inputRequired),
    remark: Yup.string().required(inputRequired),
    approveLevel: Yup.string().required(inputRequired),
  });


  if (userActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          // alert(JSON.stringify(values));
          // let formData = new FormData();
          // formData.append("username", values.username);
          // formData.append("fullname", values.fullname);
          // formData.append("buCode", values.buCode);
          // formData.append("email", values.email);
          // formData.append("roleId", values.roleId!);
          // formData.append("active", String(values.active));
          // formData.append("createdBy", values.username);
          console.log("approveLevel :"+approveLevel)
          const sendvalues = { ...values, approveLevel:approveLevel }
          dispatch(userCreateData(sendvalues));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default UserCreatePage;
