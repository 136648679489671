export interface Approve {
    id?: string;
    priceDescription: string;
    remark: string;
    priceDate?: Date
    priceLevel?: string
    validFrom: string;
    validTo: string;
    createdBy: string;  
    documentStatus: string;    

}

export interface ApprovePriceListByID {
    id?: string;
    documentStatus: string;    
    comment: string
}

export class ApprovePricelist {
    id?: string
    buCode?: string
    priceDescription?: string
    priceDate?: Date
    validFrom?: Date;
    validTo?: Date;
    priceLevel?: string
    conditionType?: string
    condRecNo?: string
    priceListData!: ApprovePricelistData[]
    priceListOldData?: any
    remark?: string
    comment?: string
    approveBy?: string|null
    wfStep?: number| null
    wfStepAll?: number| null
    documentStatus?: string
    createdBy?: string
    isPriority?:boolean
    attachment?:string[]
}


export class ApprovePricelistData {
    id!: string;
    priceLevel!: string;
    itemCode!: string;
    itemName!: string;
    saleUnit!: string;
    saleOrg!: string;
    distributionChannel!: string;
    priceList!: string;
    siteCode!: string;
    siteGroup!: string;
    vendor!: string;
    validFromO!: string;
    validFrom!: string;
    validToO!: string;
    validTo!: string;
    amountInMarginO?: number | null;
    amountInMargin?: number | null;
    amountInFinalPriceO?: number | null;
    amountInFinalPrice?: number | null;
    priceQTY1O?: number | null;
    priceQTY1?: number | null;
    price1O?: number | null;
    price1?: number | null;
    priceQTY2O?: number | null;
    priceQTY2?: number | null;
    price2O?: number | null;
    price2?: number | null;
    priceQTY3O?: number | null;
    priceQTY3?: number | null;
    price3O?: number | null;
    price3?: number | null;
    priceQTY4O?: number | null;
    priceQTY4?: number | null;
    price4O?: number | null;
    price4?: number | null;
    priceQTY5O?: number | null;
    priceQTY5?: number | null;
    price5O?: number | null;
    price5?: number | null;   
}

export class ApprovePricelistExportExcel {
    priceLevel!: string;
    itemCode!: string;
    itemName!:string;
    saleUnit!: string;
    // saleOrg!: string;
    // distributionChannel!: string;
    // priceList!: string;
    siteCode!: string;
    siteGroup!: string;
    // vendor!: string;
    validFromO!: string;
    validFrom!: string;
    validToO!: string;
    validTo!: string;
    // amountInMarginO?: number | null;
    // amountInMargin?: number | null;
    amountInFinalPriceO?: number | null;
    amountInFinalPrice?: number | null;
    priceQTY1O?: number | null;
    priceQTY1?: number | null;
    price1O?: number | null;
    price1?: number | null;
    priceQTY2O?: number | null;
    priceQTY2?: number | null;
    price2O?: number | null;
    price2?: number | null;
    priceQTY3O?: number | null;
    priceQTY3?: number | null;
    price3O?: number | null;
    price3?: number | null;
    priceQTY4O?: number | null;
    priceQTY4?: number | null;
    price4O?: number | null;
    price4?: number | null;
    priceQTY5O?: number | null;
    priceQTY5?: number | null;
    price5O?: number | null;
    price5?: number | null;   
}

export class ApprovePricelistLv7ExportExcel {
    priceLevel!: string;
    itemCode!: string;
    itemName!:string;
    saleUnit!: string;
    // saleOrg!: string;
    // distributionChannel!: string;
    // priceList!: string;
    siteCode!: string;
    // siteGroup!: string;
    vendor!: string;
    // validFromO!: string;
    validFrom!: string;
    // validToO!: string;
    validTo!: string;
    amountInFinalPrice?: number | null; 
    // amountInFinalPriceO?: number | null;
    priceQTY1O?: number | null;
    price1O?: number | null;
    priceQTY2O?: number | null;
    price2O?: number | null;
    priceQTY3O?: number | null;
    price3O?: number | null;
    priceQTY4O?: number | null;
    price4O?: number | null;
    priceQTY5O?: number | null;
    price5O?: number | null;
  
}

export interface WorkFlowHistory {
    id?:string;
    wfApprover: string;
    wfApproveStatus: string;
    wfApproveDate: string;
    remark: string;
    createdDate: string;  
    createdBy: string;    
}

export interface ApproveHistory {
    priceDescription: string;
    remark: string;
    priceDate?: Date
    priceDateTo?: Date
    priceLevel?: string
    validFrom: Date;
    validTo: Date;
    createdBy: string;  
    documentStatus: string;    

}
