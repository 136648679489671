import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Pricelist, PricelistData } from "../../types/pricelist.type";
import { Template } from "../../types/template.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";


type PricelistState = {
    result: Pricelist;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialValues: Pricelist = {
    id: "",
    buCode: "",
    priceDescription: "",
    priceDate: new Date(),
    priceLevel: "",
    conditionType: "",
    condRecNo: "",
    priceListData: [],
    remark: "",
    documentStatus: "",
};
const initialStateValues: PricelistState = {
    result: initialValues,
    message: "",
    isFetching: false,
    isError: false,
};


// export const PricelistSpecialGetDataById = createAsyncThunk(
//     "pricelistSpecial/getPricelistSpecialbyID",
//     async (id: string, thunkApi) => {
//         try {
//             const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id}`);
//             //const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id}`);
//             //console.log(result.data);

//             return result;
//         } catch (error: any) {
//             let err = error.response.data.message ?? error.message
//             return thunkApi.rejectWithValue(err);
//         }
//     }
// );
export const PricelistSpecialGetDataById = createAsyncThunk(
    "pricelistSpecial/getPricelistSpecialbyID",
    // async (id: string, thunkApi) => {
        async ({ formData,id,docRef}: {formData: PricelistData[],id:string,docRef:string}, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id}`);
            //const result = await httpClient.get<Pricelist>(`${server.PRICELIST_URL}/${id}`);
            //console.log(result.data);
            // console.log("result:"+result.data.templateData.length)
            // console.log("formData:"+ formData.length)

            if(formData.length !== 0){   
                let itemModels : PricelistData[]=[];
                result.data.priceListData.forEach((data) => {
                  let found = formData.find((x) => Number(x.itemCode) === Number(data.itemCode) && Number(x.priceLevel)===7 && data.siteCode! === x.siteCode!);
                //    console.log("Loop")
                  if (found !== undefined) {
                  
                    let msg ="";
                    let itemModel = new PricelistData();
                    itemModel.id= data.id!
                    itemModel.itemCode = data.itemCode
                    itemModel.itemName= data.itemName
                    itemModel.isSelect= false
                    itemModel.priceLevel= "7"
                    itemModel.ean11= data.ean11
                    itemModel.hpean= data.hpean
                    itemModel.taxType= data.taxType
                    itemModel.matkl= data.matkl
                    itemModel.brandId= data.brandId
                    itemModel.brandDesc= data.brandDesc
                    itemModel.saleUnit= data.saleUnit
                    itemModel.saleOrg= data.saleOrg
                    itemModel.distributionChannel= data.distributionChannel
                    itemModel.priceList= data.priceList
                    itemModel.siteCode= data.siteCode
                    itemModel.siteGroup= data.siteGroup
                    itemModel.vendor= found.vendor
                    itemModel.validFrom= new Date()
                    itemModel.validTo= new Date()
                    itemModel.amountInMargin = 0
                    itemModel.amountInFinalPrice = Number(Number(found.amountInFinalPrice!).toFixed(2))
                    itemModel.price1= 0
                    itemModel.price2=  0
                    itemModel.price3= 0
                    itemModel.price4=  0
                    itemModel.price5=  0
                    itemModel.priceQTY1=  0
                    itemModel.priceQTY2=  0
                    itemModel.priceQTY3= 0
                    itemModel.priceQTY4=  0
                    itemModel.priceQTY5=  0

                    itemModel.validFromO= new Date()
                    itemModel.validToO= new Date()
                    itemModel.amountInMarginO= 0
                    itemModel.amountInFinalPriceO= 0
                    itemModel.priceQTY1O=0
                    itemModel.priceQTY2O= 0
                    itemModel.priceQTY3O= 0
                    itemModel.priceQTY4O= 0
                    itemModel.priceQTY5O= 0
                    itemModel.price1O= 0
                    itemModel.price2O=  0
                    itemModel.price3O=  0
                    itemModel.price4O=  0
                    itemModel.price5O= 0
                    itemModel.condRec= found.condRec
                    itemModel.message=msg       
                    itemModels.push(itemModel)     
                  
                }
                })
                if(docRef !==""){
                    // console.log("reducer run db :" + docRef)
                    // const resultEdit = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${docRef}`);
                    // resultEdit.data.templateData =itemModels?.filter((row) => row.amountInFinalPrice !== 0)!
                    // result.data.priceListData = resultEdit.data.templateData
                }
                else{
                    // console.log("result.data.templateData :" + result.data.priceListData)
                    result.data.priceListData =itemModels
                }
                
               }

         if (result.data.priceListData.length ===0 ) {
                return thunkApi.rejectWithValue("Template ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

// export const priceListSpecialGetTemplateDataById = createAsyncThunk(
//     "templateSpecial/priceListSpecialGetTemplateDataById",
//     async (id: string, thunkApi) => {
//         try {
//             const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id}`);
//             return result;

//         } catch (error: any) {
//             let err = error.response.data.message ?? error.message
//             return thunkApi.rejectWithValue(err);
//         }
//     }
// );

export const priceListSpecialGetTemplateDataById = createAsyncThunk(
    "templateSpecial/priceListSpecialGetTemplateDataById",
    async ({ formData,id,docRef}: {formData: PricelistData[],id:string,docRef:string}, thunkApi) => {
        try {
            const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id}`);

        // console.log("result:"+result.data.templateData.length)
        // console.log("formData:"+ formData.length)

       if(formData.length !== 0){
                
                let itemModels : PricelistData[]=[];
                result.data.templateData.forEach((data) => {
                    if (Number(data.priceLevel) !== 7) {
                        return thunkApi.rejectWithValue("Template ไม่ถูกต้อง กรุณาตรวจสอบ!");
                    }
                  let found = formData.find((x) => Number(x.itemCode) === Number(data.itemCode)&& x.siteCode! === data.siteCode!);
                //   console.log("Loop")
                  if (found !== undefined) {
                  
                    let msg ="";
                    let itemModel = new PricelistData();
                    itemModel.id= data.id!
                    itemModel.itemCode = data.itemCode
                    itemModel.itemName= data.itemName
                    itemModel.isSelect= false
                    itemModel.priceLevel= "7"
                    itemModel.ean11= data.ean11
                    itemModel.hpean= data.hpean
                    itemModel.taxType= data.taxType
                    itemModel.matkl= data.matkl
                    itemModel.brandId= data.brandId
                    itemModel.brandDesc= data.brandDesc
                    itemModel.saleUnit= data.saleUnit
                    itemModel.saleOrg= data.saleOrg
                    itemModel.distributionChannel= data.distributionChannel
                    itemModel.priceList= data.priceList
                    itemModel.siteCode= data.siteCode
                    itemModel.siteGroup= data.siteGroup
                    itemModel.vendor= found.vendor
                    itemModel.validFrom= new Date()
                    itemModel.validTo= new Date()
                    itemModel.amountInMargin = 0
                    itemModel.amountInFinalPrice =  Number(Number(found.amountInFinalPrice!).toFixed(2))
                    itemModel.price1= 0
                    itemModel.price2=  0
                    itemModel.price3= 0
                    itemModel.price4=  0
                    itemModel.price5=  0
                    itemModel.priceQTY1=  0
                    itemModel.priceQTY2=  0
                    itemModel.priceQTY3= 0
                    itemModel.priceQTY4=  0
                    itemModel.priceQTY5=  0

                    itemModel.validFromO= new Date()
                    itemModel.validToO= new Date()
                    itemModel.amountInMarginO= 0
                    itemModel.amountInFinalPriceO= 0
                    itemModel.priceQTY1O=0
                    itemModel.priceQTY2O= 0
                    itemModel.priceQTY3O= 0
                    itemModel.priceQTY4O= 0
                    itemModel.priceQTY5O= 0
                    itemModel.price1O= 0
                    itemModel.price2O=  0
                    itemModel.price3O=  0
                    itemModel.price4O=  0
                    itemModel.price5O= 0
                    itemModel.condRec= found.condRec
                    itemModel.message=msg       
                    itemModels.push(itemModel)     
                  
                }
                })
                if(docRef !==""){
                    // console.log("reducer run db :" + docRef)
                    const resultEdit = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${docRef}`);
                    // resultEdit.data.templateData =itemModels?.filter((row) => row.amountInFinalPrice !== 0)!
                    result.data = resultEdit.data
                }
                else{
                    // console.log("result.data.templateData :" + result.data.templateData)
                    result.data.templateData =itemModels
                }
                
               }
            if (result.data.templateData.length ===0 ) {
                return thunkApi.rejectWithValue("Template ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            return result;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const pricelistSpecialCreateData = createAsyncThunk(
    "pricelistSpecial/postpricelistSpecial",
    async ({ formData, selectList }: { formData: Pricelist, selectList: PricelistData[] }, thunkApi) => {
        try {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate()-1 );


            let dateFrom = new Date(formData.validFrom!);
            let dateTo= new Date(formData.validTo!);
            let dateNow = new Date(currentDate);
            // console.log("date1:"+ dateFrom + " date2:" +dateTo + "now:"+dateNow)
            if (dateFrom.setHours(0, 0, 0, 0) < dateNow.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้อง!");
            }
            // const start = new Date();
            // start.setDate(start.getDate())
            // if (formData.validFrom! > start) {
            //     return thunkApi.rejectWithValue("ValidFrom invalid! This price is effective tomorrow.");
            // }
            if (dateTo.setHours(0, 0, 0, 0) < dateFrom.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ช่วงวันที่ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            let priceListData: PricelistData[] = [];
            for (let i = 0; i < formData.priceListData.length; i++) {
                let found = selectList.find((x) => x.id === formData.priceListData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: true ,validFrom:dateFrom, validTo:dateTo, message:'' }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: false ,validFrom:dateFrom, validTo:dateTo, message:''}
                    priceListData.push(valuesToSend);
                }
            }

            formData.priceListData = priceListData?.filter((row) => row.isSelect === true)!;

            if (formData?.priceListData.length == 0) {
                return thunkApi.rejectWithValue("Please input data");
            }
            if (formData?.priceListData.length >= 601) {
                return thunkApi.rejectWithValue("Data invalid! limit data 600 rows.");
            }
            const result = await httpClient.post(server.PRICELIST_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const pricelistSpecialUpdateData = createAsyncThunk(
    "pricelistSpecial/putpricelistSpecial",
    // async (formData: Pricelist, thunkApi) => {
    async ({ formData, selectList }: { formData: Pricelist, selectList: PricelistData[] }, thunkApi) => {
        try {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate()-1 );

            let dateFrom = new Date(formData.validFrom!);
            let dateTo= new Date(formData.validTo!);
            let dateNow = new Date(currentDate);
            // console.log("date1:"+ dateFrom + " date2:" +dateTo + "now:"+dateNow)
            if (dateFrom.setHours(0, 0, 0, 0) < dateNow.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้อง!");
            }
            // const start = new Date();
            // start.setDate(start.getDate())
            // if (formData.validFrom! > start) {
            //     return thunkApi.rejectWithValue("ValidFrom invalid! This price is effective tomorrow.");
            // }
            if (dateTo.setHours(0, 0, 0, 0) <dateFrom.setHours(0, 0, 0, 0)) {
                return thunkApi.rejectWithValue("ช่วงวันที่ไม่ถูกต้อง กรุณาตรวจสอบ!");
            }
            let priceListData: PricelistData[] = [];
            for (let i = 0; i < formData.priceListData.length; i++) {
                let found = selectList.find((x) => x.id === formData.priceListData[i].id);
                if (found !== undefined) {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: true,validFrom:dateFrom, validTo:dateTo, message:''  }
                    priceListData.push(valuesToSend);
                } else {
                    const valuesToSend = { ...formData.priceListData[i], isSelect: false,validFrom:dateFrom, validTo:dateTo, message:'' }
                    priceListData.push(valuesToSend);
                }
            }

            formData.priceListData = priceListData?.filter((row) => row.isSelect === true)!;

            if (formData?.priceListData.length == 0) {
                return thunkApi.rejectWithValue("Please input data");
            }
            if (formData?.priceListData.length >= 601) {
                return thunkApi.rejectWithValue("Data invalid! limit data 600 rows.");
            }

            const result = await httpClient.put(`${server.PRICELIST_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const pricelistSpecialActionSlice = createSlice({
    name: "PricelistSpecialAction",
    initialState: initialStateValues,
    reducers: {
        removePricelistSpecialData: (state, action: PayloadAction<PricelistData[]>) => {
            state.result.priceListData = action.payload;
        },
        updatePricelistSpecialData: (state, action: PayloadAction<PricelistData[]>) => {
            state.result.priceListData = action.payload!;
        },
        clearPricelistSpecial: (state, action: PayloadAction) => {
            state.result = initialValues;
        },
        selectPricelistSpecial: (state, action: PayloadAction<PricelistData[]>) => {
            action.payload.map((item) => {
                let index = state.result.priceListData.findIndex((x) => x.id === item.id);
                state.result.priceListData[index] = { ...state.result.priceListData[index], isSelect: true };
            })
        },
    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(PricelistSpecialGetDataById.pending, (state, action) => {
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(PricelistSpecialGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                state.result = action!.payload.data;
                // console.log("check",state.result);
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistSpecialGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

        builder
            .addCase(pricelistSpecialCreateData.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistSpecialCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                if (action!.payload.status == "OK") {
                    state.result = initialValues;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                    history.back();
                } else {
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
                }
            })
            .addCase(pricelistSpecialCreateData.rejected, (state, action: PayloadAction<any>) => {
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(priceListSpecialGetTemplateDataById.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(priceListSpecialGetTemplateDataById.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log(action!.payload.data.templateData);
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.templateData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                // const templateData: TemplateData[] = JSON.parse(action!.payload.data.templateData) as TemplateData[];
                state.result.priceListData = action!.payload.data.templateData;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;


            })
            .addCase(priceListSpecialGetTemplateDataById.rejected, (state, action: PayloadAction<any>) => {

                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(pricelistSpecialUpdateData.pending, (state, action) => {

                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistSpecialUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                // const pricelistData: PricelistData[] = JSON.parse(action!.payload.data.priceListData) as PricelistData[];
                // action!.payload.data.priceListData = pricelistData;
                // state.result = action!.payload.data;
                if (action!.payload.status == "OK") {
                    state.result = initialValues;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                    history.back()
                } else {
                    state.result = action!.payload.data;
                    state.message = action!.payload.message;
                    state.isFetching = false;
                    state.isError = true;
                }
            })
            .addCase(pricelistSpecialUpdateData.rejected, (state, action: PayloadAction<any>) => {

                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { clearPricelistSpecial, updatePricelistSpecialData, removePricelistSpecialData, selectPricelistSpecial } = pricelistSpecialActionSlice.actions;
export const PricelistSpecialActionSelector = (store: RootState) => store.pricelistSpecialActionReducer;
export default pricelistSpecialActionSlice.reducer;