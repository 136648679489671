import * as React from "react";

type HomePageProps = {
  //
};

const HomePage: React.FC<any> = () => {
  
  return <div>

  </div>;
};

export default HomePage;
