import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Fab, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch } from "../../../store/store";
import { userGetData, userSelector } from "../../../store/slices/userSlice";
import Loader from "../../component/Loader";


type Props = {}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

export default function UserApiPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userReducer = useSelector(userSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const inputSearch = t('inputSearch');
  const [isAD, setisAD] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(userGetData(isAD));
  }, [keywordSearch])

  // console.log(isAD)

  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0, }}>
        <div style={{height:'60px'}}></div>

        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/userapi/create"
          sx={{ position: "absolute", top: 10, right: 10, }}>
          <Add />
        </Fab>
      </Box>
    );
  }


  const columns: GridColDef[] = [
    {
      headerName: "",
      field: ".",
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              navigate("/userapi/edit/" + row.id);
            }}
          >
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "username", width: 150,
      renderHeader: () => (<strong> {t('username')} </strong>),
    },
    {
      field: "fullname", width: 200,
      renderHeader: () => (<strong> {t('fullname')} </strong>),
    },
    {
      field: "email", width: 200,
      renderHeader: () => (<strong> {t('email')} </strong>),
    },
    {
      field: "active", type: "boolean", width: 100,
      renderHeader: () => (<strong> {t('active')} </strong>),
    },
  ];


  if (userReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          USER API
        </Typography>
        <DataGridPro
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          rows={userReducer.result!}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  )
}

