import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Brand } from "../../types/brand.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";

type BrandEditState = {
    result: Brand | null;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: BrandEditState = {
    result: null,
    message: "",
    isFetching: false,
    isError: false,
};


export const brandGetDataById = createAsyncThunk(
    "brand/getBrand",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<Brand>(`${server.BRAND_URL}/${id}`);
            return result;
            
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const brandCreateData = createAsyncThunk(
    "brand/postBrand",
    async (formData: Brand, thunkApi) => {
        try {
            const result = await httpClient.post(server.BRAND_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const brandUpdateData = createAsyncThunk(
    "brand/putBrand",
    async (formData: Brand, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.BRAND_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const brandActionSlice = createSlice({
    name: "brandedit",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(brandGetDataById.pending, (state, action) => {
                state.result = null;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(brandGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(brandGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(brandCreateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(brandCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(brandCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(brandUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(brandUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(brandUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = brandActionSlice.actions;
export const brandActionSelector = (store: RootState) => store.brandActionReducer;
export default brandActionSlice.reducer;