import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Pricelist } from "../../types/pricelist.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type PricelistState = {
    result: Pricelist[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: PricelistState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const PricelistGetData = createAsyncThunk(
    "pricelist/getPricelist",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<Pricelist[]>(server.PRICELIST_URL + "?keyword=" + keyword);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const pricelistUpdateDataList = createAsyncThunk(
    "pricelist/putpricelistlist",
    async ({ formData }: {formData: Pricelist[] }, thunkApi) => {
        try {
            // if(formData.length>1){
            //         let dateFrom = new Date(formData[0].validFrom!);
            //         let dateTo= new Date(formData[0].validTo!);
            //         let dateNow = new Date();
            //         let checkError = false;
            //         for (let i = 0; i < formData.length; i++) {
            //             let idateFrom = new Date(formData[i].validFrom!);
            //             let idateTo= new Date(formData[i].validTo!);

            //             console.log("idateFrom :"+idateFrom)
            //             console.log("idateTo :"+idateTo)
            //             console.log("dateFrom :"+dateFrom)
            //             console.log("dateTo :"+dateTo)

            //             if (dateFrom.setHours(0, 0, 0, 0)  != idateFrom.setHours(0, 0, 0, 0) ) {
            //                 // return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
            //                 formData[i].message="เอกสารที่เลือกวันที่ ValidFrom ไม่ตรงกัน กรุณาตรวจสอบ!";
            //                 checkError =true;
            //             }
            //             if (dateTo.setHours(0, 0, 0, 0)  != idateTo.setHours(0, 0, 0, 0) ) {
            //                 // return thunkApi.rejectWithValue("ValidFrom ไม่ถูกต้องกรุณาตรวจสอบ!");
            //                 formData[i].message= formData[i].message +",เอกสารที่เลือกวันที่ Valid To ไม่ตรงกัน กรุณาตรวจสอบ!";
            //                 checkError =true;
            //             }
            //         }
            //         if(checkError==true){
            //             return thunkApi.rejectWithValue("เอกสารที่เลือกวันที่ไม่ตรงกัน กรุณาตรวจสอบ!");
            //         }
            // }
            const   result = await httpClient.put(`${server.PRICELIST_URL}/list/${formData[0].id!}`, formData);
        //    console.log("status:"+result.data.status)
            if(result.data.status=="OK"){
                let pricelistspecial = formData[0].priceLevel!;//'5';
                let keyword ='';
                const result = await httpClient.get<Pricelist[]>(server.PRICELIST_URL + "?keyword=" + keyword);
                return result;
            }
            return result.data;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
const pricelistSlice = createSlice({
    name: "Pricelists",
    initialState: initialStateValues,
    reducers: {
        reloadPricelistData: (state, action: PayloadAction<Pricelist[]>) => {
            state.result = action.payload!;
        },
        reloadPricelist: (state, action: PayloadAction<Pricelist[]>) => {
            state.result = action.payload!;
        },
    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(PricelistGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(PricelistGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(pricelistUpdateDataList.pending, (state, action) => {
                // state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
                // state.imessage = "";
            })
            .addCase(pricelistUpdateDataList.fulfilled, (state, action: PayloadAction<any>) => {
                // console.log("status :"+ action!.payload.status)
                // console.log("message : " + action!.payload.data.id!)
             
                if (action!.payload.status == "OK") {
                    state.result = action!.payload.data;
                    state.message = "Success";
                    state.isFetching = false;
                    state.isError = false;
                    // state.imessage = action!.payload.status!;
                    // history.go(0);
                    // history.replace("/pricelistscalelist")
                    // window.location.reload();
                    // history.push("/pricelistscalelist");
                }else{
                    
                    state.result = action!.payload.data;
                    state.message = action!.payload.message!;
                    state.isFetching = false;
                    state.isError = true;    
                    // state.imessage = action!.payload.status!;
                }
            })
            .addCase(pricelistUpdateDataList.rejected, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;//action.payload.data;
                state.message = action.payload.message!;
                state.isFetching = false;
                state.isError = true;
                // state.imessage = action!.payload.status!;
            });

    }
})


export const {reloadPricelistData } = pricelistSlice.actions;
export const PricelistSelector = (store: RootState) => store.pricelistReducer;
export default pricelistSlice.reducer;