import * as React from "react";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Autocomplete, Box, Button,Chip, Card, CardContent, FormControl, InputLabel,IconButton, MenuItem, OutlinedInput,  TextField, Typography,Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import Loader from "../../component/Loader";
import moment from "moment";
import { Formik, FormikProps, Form } from "formik";
import { siteRoleSelector, sitePostDataByRole } from "../../../store/slices/siteRoleSlice";
import { Actualreport, ActualExportExcel } from "../../../types/actual.type";
import { actualPostDataBySite, actualSelector,clearPricelistScale } from "../../../store/slices/actualSlice";
import { MonitorJobHistoryGetData,MonitorJobHistoryGetDataList,MonitorJobHistoryGetList,MonitorJobHistoryGetDataById,MonitorJobHistorySelector ,pricelistUpdateDataPOS,pricelistUpdateDataSAP,pricelistUpdateDataWFP} from "../../../store/slices/monitorJobHistorySlice";
import { exportExcelItem } from "../../../utils/excel";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import NativeSelect from '@mui/material/NativeSelect';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { DataGrid, GridRenderCellParams, } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {PriceListSiteQueryList }from "../../../types/pricelistsite.type";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { siteGetData,siteSelector } from "../../../store/slices/siteSlice";
import { useDebounce } from "@react-hook/debounce";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LinearProgress } from "@mui/material";

type MonitorPageProps = {
  //
};

const MonitorPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const SiteRoleReducer = useSelector(siteRoleSelector)
  const ActualReducer = useSelector(actualSelector)
  // const [siteRole, setSiteRole] = React.useState<any>();
  const [priceType, setPriceListType] = React.useState('4');
  const MySwal = withReactContent(Swal)
  const siteReducer = useSelector(siteSelector);
  const approveHistoryReducer = useSelector(MonitorJobHistorySelector);
  const [validFrom, setvalidFrom] = React.useState(new Date());
  const [validTo, setvalidTo] = React.useState(new Date());
  const [selected, setSelected] =  React.useState<any>([]);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [petInputValue, setPetInputValue] = React.useState("");
  const [selectedSite, setSelectedSite] =  React.useState<any>([]);
  
  React.useEffect(() => {
    // dispatch(sitePostDataByRole(""));
    dispatch(siteGetData(""));
  // dispatch(MonitorJobHistoryGetData({
  //     keyword:"",
  //     type:0,
  //   }));
  }, []);

  const onloadreport = () => {
    // dispatch(MonitorJobHistoryGetData(keywordSearch));
    const valuesToSend = {
      ...initialValues,validfrom:validFrom,validto:validTo,siteCode:selectedSite,
    }
    dispatch(MonitorJobHistoryGetList({formData:valuesToSend}));
    //   dispatch(MonitorJobHistoryGetData({
    //   keyword:valuesToSend,
    //   type:0,
    // }));

   }
  const ReStatusDocument = async (type:string ,row: any) => {
    try {
      const valuesToSend = {
        ...initialValues,validfrom:validFrom,validto:validTo,siteCode:selectedSite,pricelevel:priceType,
      }
      Swal.fire({
        title: 'คุณต้องการ Re-process Job '+ type + ':   ',
        text: row.priceDescription! + ' ?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if(type==="POS"){
            // dispatch(pricelistUpdateDataPOS(row.id!))
            dispatch(pricelistUpdateDataPOS({
              id: row.id!,
              keyword: valuesToSend
        }))
            Swal.fire('Re-process POS แล้ว!', '', 'success')
          }else if(type==="SAP"){
            // dispatch(pricelistUpdateDataSAP(row.id!))
            dispatch(pricelistUpdateDataSAP({
              id: row.id!,
              keyword: valuesToSend
        }))
            Swal.fire('Re-process SAP แล้ว!', '', 'success')
          }else if(type==="WFP"){
            // dispatch(pricelistUpdateDataWFP(row.id!))
            dispatch(pricelistUpdateDataWFP({
              id: row.id!,
              keyword: valuesToSend
        }))
            Swal.fire('Re-process Actual แล้ว!', '', 'success')
          }
      
          // dispatch(templateDeleteData(e.id!));
          // dispatch(templateGetData(""));
         
          // dispatch(reloadTemplateData(templateReducer.result!));
          // const newUpdatedRows = templateReducer.result!.filter((row) => row.id !== e.id!)
          // // setUpdateRow(newUpdatedRows);
          // dispatch(reloadTemplateData(newUpdatedRows));
        } 
      })
      // return result;
    } catch (error: any) {
      let err = error.response.data.message ?? error.message

    }
    return ReStatusDocument
  }

  const CheckPreview = async (priceLv:string,id:string) => {
    try {
      console.log(priceLv + ":"+id)
      if(Number(priceLv) ===7){
        navigate("/pricelistspecial/preview/" + id);
      }
      else{
        navigate("/pricelist/preview/" + id);
      }

    } catch (error: any) {
      let err = error.response.data.message ?? error.message

    }
    return ReStatusDocument
  }
  const [SiteCode, setSiteCode] = React.useState<string[]>([]);
  const handleReprocess = (row: any) => {
    console.log(row)
  };

  const columns: GridColDef[] = [

    {
      headerName: "Preview",
      field: "preview",
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="preview"
            size="large"
            onClick={() => {
              // navigate("/pricelist/preview/" + row.priceRef);
              CheckPreview(row.priceLevel,row.priceRef);
            }}
          >
            <VisibilityIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "priceDescription", width: 250,headerAlign: 'center',
      renderHeader: () => (<strong> {t('priceDescription')} </strong>),
    },
    {
      field: "site", width: 100,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong> {t('siteCode')} </strong>),
    },
    {
      field: "priceLevel", width: 100,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong> {t('priceLevel')} </strong>),
    },
    {
      field: "priceDate", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
    },
    {
      field: "createdBy", width: 100,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong>{t('createdBy')}</strong>),
    },
   
    {
      // headerName: "Actual",
      renderHeader: () => (<strong> {t('sendToWFP')} </strong>),
      field: "sendToWFP",
      headerClassName: "super-app-theme--header",
      type: 'string',
      width: 100,headerAlign: 'center',

      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToWFP == "Queue" ? <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
          : row.sendToWFP == "Successed" ? <Chip className="w-chip" label={row.sendToWFP} color="success"></Chip>
            : row.sendToWFP == "Processing" ? <Chip className="w-chip" label={row.sendToWFP} color="info"></Chip>
            : row.sendToWFP == "Failed" ? <Chip onClick={() => ReStatusDocument("WFP",row)} className="w-chip" label={row.sendToWFP} color="error"></Chip>
            : <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip> 
            // : row.sendToWFP == "Failed" ? <Chip className="w-chip" label={row.sendToWFP} color="error"></Chip>
              //   : <Chip className="w-chip" label={row.sendToWFP} color="default"></Chip>
      ),
    },
    {
      // headerName: "POS",
      renderHeader: () => (<strong> {t('sendToPos')} </strong>),
      field: "sendToPos",
      type: 'string',
      headerClassName: "super-app-theme--header",
      width: 100,headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToPos == "Queue" ? <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
          : row.sendToPos == "Successed" ? <Chip onClick={() => ReStatusDocument("POS",row)} className="w-chip" label={row.sendToPos} color="success"></Chip>
            : row.sendToPos == "Processing" ? <Chip onClick={() => ReStatusDocument("POS",row)}  className="w-chip" label={row.sendToPos} color="info"></Chip>
              : row.sendToPos == "Failed" ? <Chip onClick={() => ReStatusDocument("POS",row)} className="w-chip" label={row.sendToPos} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
      ),
    },
    {
      // headerName: "SAP",
      renderHeader: () => (<strong> {t('sendToSap')}         
</strong>),
      field: "sendToSap",
      type: 'boolean',
      width: 100,headerAlign: 'center',
      headerClassName: "super-app-theme--header",
      renderCell: ({ row }: GridRenderCellParams<string>) => (   
        row.sendToSap == "Queue" ? <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
          : row.sendToSap == "Successed" ? <Chip onClick={() => ReStatusDocument("SAP",row)} className="w-chip" label={row.sendToSap} color="success"></Chip>
            : row.sendToSap == "Processing" ? <Chip onClick={() => ReStatusDocument("SAP",row)}  className="w-chip" label={row.sendToSap} color="info"></Chip>
              : row.sendToSap == "Failed" ? <Chip onClick={() => ReStatusDocument("SAP",row)} className="w-chip" label={row.sendToSap} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
     
      ),
    },  
    {
      field: "posMessage", width: 250,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong>POS-Message</strong>),

      renderCell: (cellValues) => {
        if(cellValues.value ==="Success"){
          return(
              <div>
        
        </div>)
        }else{
          return(
            <div style={{color: "red",width: "100%"}}>  
               {cellValues.value}
            </div>)
        }
      }       
    },
    {
      field: "sapMessage", width: 250,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong>SAP-Message</strong>),
      renderCell: (cellValues) => {
        if(cellValues.value ==="Success"){
          return(
              <div>
        
        </div>)
        }else{
          return(
            <div style={{color: "red",width: "100%"}}>  
               {cellValues.value}
            </div>)
        }
      } 
    },
  ];

  if (SiteRoleReducer.isFetching) {
    return <><Loader /></>
  }
  const handleChangePriceListType = (event: SelectChangeEvent) => {
    setPriceListType(event.target.value);
  };
  const handleChange = (event: SelectChangeEvent<typeof SiteCode>) => {
    const {
      target: { value },
    } = event;
    setSiteCode(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // const initialValues: Actualreport = {
  //   siteCode: [],
  //   siteName: '',
  //   itemCode: '',
  //   priceListType:'4',
  //   active: true
  // };
  const initialValues: PriceListSiteQueryList = {
    siteCode: [],
    validfrom: new Date(),
    validto: new Date(),
    pricelevel:'4',

  };

  const priceListType = [
    'NormalPrice',
    'ScalePrice',
    'SpecialPrice'];


    function handleSelectItem(event:any) {
      setSelected(event.target.value)
   }
  const ExportExcel = (excelData: any) => {
    let actualDatas: ActualExportExcel[] = [];

    excelData.forEach((item: any) => {
      let actualData = new ActualExportExcel();
      actualData.siteCode = item.siteCode
      actualData.itemCode = item.itemCode
      actualData.itemName = item.itemName
      actualData.saleUnit = item.saleUnit
      actualData.validFrom = moment(item.validFrom).format("DD-MM-YYYY")
      actualData.validTo = moment(item.validTo).format("DD-MM-YYYY")
      actualData.conditionType = item.conditionType
      actualData.amountInFinalPrice = item.amountInFinalPrice
      actualData.priceQTY1 = item.priceQTY1
      actualData.price1 = item.price1
      actualData.priceQTY2 = item.priceQTY2
      actualData.price2 = item.price2
      actualData.priceQTY3 = item.priceQTY3
      actualData.price3 = item.price3
      actualData.priceQTY4 = item.priceQTY4
      actualData.price4 = item.price4
      actualData.priceQTY5 = item.priceQTY5
      actualData.price5 = item.price5
      actualDatas.push(actualData);
    });

    let Heading = [['Code', 'Material', 'Material description', 'Sale Unit', 'Start Date', 'End Date', 'Condition Type', 'Amount in Final Price', 'PQtyB1 (Scale 1)', 'PMPrice1 (Scale 1)', 'PQtyB2 (Scale 2)', 'PMPrice2 (Scale 2)', 'PQtyB3 (Scale 3)', 'PMPrice3 (Scale 3)', 'PQtyB4 (Scale 4)', 'PMPrice4 (Scale 4)', 'PQtyB5 (Scale 5)', 'PMPrice5 (Scale 5)']];

    exportExcelItem(actualDatas, Heading, 'ActualPriceReport-' + moment().format('YYYY-MM-DD_hh:mm:ss').toString());
  }


  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,

  }: FormikProps<PriceListSiteQueryList>) => {
    return (
      <Form>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
                   MONITOR JOB LOAD
            </Typography>
{/* 
            <FormControl style={{ marginTop: 16,width: 600 }} >
              <InputLabel htmlFor="siteCode">{t('siteCode')}</InputLabel>
              <Select
                id="siteCode"
                multiple
                // renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                name="siteCode"
                 value={values.siteCode}
                // value={selected}
                // input={<OutlinedInput label="siteCode" />}
                // onChange={handlepricelevelChange}
                label={t("siteCode")}
                onChange={handleChange}
                style={{ width: '100%' }}
                
              // renderValue={values.priceLevel}
              error={
                errors.siteCode && touched.siteCode
                  ? true
                  : false
              } 
              >
              {siteReducer.result.map((item) => (
                  <MenuItem key={item.siteCode} value={item.siteCode}>
                    {item.siteName}
                  </MenuItem>

                ))}

              </Select>
            </FormControl> */}

            <FormControl style={{ marginTop: 16 ,width: '98%'}} >
            <Autocomplete
                  multiple
                  id="siteCode1"
                  options={siteReducer.result
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.siteName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.siteName}
                    </li>
                    
                  )}
                  inputValue={petInputValue}
                  onInputChange={(event, newPetInputValue) => {
                    setPetInputValue(newPetInputValue);
                  }}
                  value={selectedSite}
                  onChange={(event, newValue) => {
                    setSelectedSite(newValue);
                    // console.log("check select site :" + newValue);
                  }}

                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label={t('siteCode')} placeholder="Search" />
                  )}
                />
              </FormControl>
            <FormControl style={{ marginTop: 16 ,marginBottom :16,width: '98%'}} >
            <InputLabel id="demo-simple-select-autowidth-label">PriceList Type</InputLabel>
            <Select
            // defaultValue="1"
              labelId="demo-simple-select-autowidth-label"
              id="priceListType"
              value={priceType}
              onChange={handleChangePriceListType}
              // autoWidth
              label="เลือก PriceList"
              error={
                errors.pricelevel && touched.pricelevel
                  ? true
                  : false
              } 
            ><MenuItem value={4}>{t("All")}</MenuItem>
            <MenuItem value={1}>{t("NormalPrice")}</MenuItem>
            <MenuItem value={2}>{t("ScalePrice")}</MenuItem>
            <MenuItem value={3}>{t("SpecialPrice")}</MenuItem>
            </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("validFrom")}
                value={validFrom}
                inputFormat="DD/MM/YYYY"
                onChange={(x) => {
                  setFieldValue("validfrom", x);
                  setvalidFrom(x!);
                }}
                // disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            To
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("validTo")}
                value={values.validto}
                inputFormat="DD/MM/YYYY"

                onChange={(x) => {
                  setFieldValue("validto", x);
                }}
                // disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <Box sx={{ display: 'flex' }}>
              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="inherit"
                type="submit"
                sx={{ marginRight: 1 }}
                disabled={SiteRoleReducer.isFetching}
              // onClick={() => onloadreport()}
              > {t('searchbtn')}
              </Button>

              {/* <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="info"
                type="button"
                sx={{ marginRight: 1 }}
                disabled={SiteRoleReducer.isFetching}
                // onClick={onloadtemplate}
                onClick={() => ExportExcel(ActualReducer.result)}
              > {t('exportbtn')}
              </Button> */}            
            </Box>
            <div style={{color: "blue",width: "100%"}}>  
<p>Note : การเลือก Re-process job สามารถกดที่ช่อง POS หรือ SAP หรือ Actual ในรายการที่ต้องการทีละรายการ</p>
</div>
            {/* <DataGridPro
              // checkboxSelection={true}
              
              disableSelectionOnClick={true}
              sx={{ backgroundColor: "white", height: "80vh" }}
              getRowId={(row) => row.id}
              rows={ActualReducer.result ? ActualReducer!.result! : []}
              // rows={pricelistReducer.result!.filter((x) => x.priceLevel! <= "2")}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[10]}
              initialState={{ pinnedColumns: { left: ['siteCode','itemCode','itemName'], right: ['actions'] } }}
            /> */}

                  {approveHistoryReducer.isFetching && (
                                      <LinearProgress />
                  )}
                <br></br>
                   <DataGridPro
                    // checkboxSelection={true}
                    disableSelectionOnClick={true}
                    sx={{ backgroundColor: "white", height: "80vh",
                    "& .super-app-theme--header": {
                      backgroundColor: "#a2cf6e",
                      color: "#006400",
                      fontWeight: 'bold',
                      // fontSize: 14,
                      // fontStyle: 'italic'
                    }, 
                  }}
                    // rows={templateReducer.result!}
                    rows={approveHistoryReducer.result!}
                    // pagination {...approveHistoryReducer.result!.filter((x) =>  Number(x.priceLevel!) === 2 || Number(x.priceLevel)===1)}
                    columns={columns}
                    // pageSize={50}
                    pagination
                    rowsPerPageOptions={[10]}
                    // onRowClick={onloadtemplate}
                  />
          </CardContent>
        </Card>
      </Form>
    );
  }



  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    // SiteCode: Yup.string().required(inputRequired),
    // priceListType: Yup.string().required(inputRequired),
  });

  if (SiteRoleReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <>
      <Box>
        <Formik

          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
              // console.log("site:",selectedSite)
 
            const valuesToSend = {
              ...values,pricelevel:priceType,
              //  validfrom: new Date(validFrom!),
              //  validto:new Date(validTo!),
                 siteCode:selectedSite,
            }
            // console.log("value:",values.siteCode)
            dispatch(MonitorJobHistoryGetDataList(valuesToSend));
            setSubmitting(false);

          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default MonitorPage;
