import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { Menu } from "../../types/menu.type";
import { history } from "../../index";

type MenuState = {
    result: Menu[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: MenuState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const MenuGetData = createAsyncThunk(
    "Menu/getMenus",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<Menu[]>(server.MENU_URL + "?keyword=" + keyword);
            return result;
        } catch (err: any) {
            // console.log("MenuGet:"+err.message)
            return thunkApi.rejectWithValue(err.message);
        }
    }
);

const MenuSlice = createSlice({
    name: "Menu",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(MenuGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(MenuGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(MenuGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
                history.back()
            });
 
    }
})


export const { } = MenuSlice.actions;
export const MenuSelector = (store: RootState) => store.menuReducer;
export default MenuSlice.reducer;
