import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import { useSelector } from "react-redux";
import { PriceLevel } from "../../../types/pricelevel.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { priceLevelActionSelector, priceLevelCreateData } from "../../../store/slices/priceLevelActionSlice";
import Loader from "../../component/Loader";
import Checkbox from '@mui/material/Checkbox';


type PriceLevelCreatePageProps = {
  //
};

const initialValues: PriceLevel = { id: "", priceLevelDesc: "", priceGroup: true, active: true };

const PriceLevelCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const priceLevelActionReducer = useSelector(priceLevelActionSelector);

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<PriceLevel>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create Price Level
            </Typography>

            <TextField id="id"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('pricelevelId')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.id && touched.id
                  ? errors.id
                  : ''
              }
              error={
                errors.id && touched.id
                  ? true
                  : false
              } />

            <TextField id="priceLevelDesc"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('Description')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.priceLevelDesc && touched.priceLevelDesc
                  ? errors.priceLevelDesc
                  : ''
              }
              error={
                errors.priceLevelDesc && touched.priceLevelDesc
                  ? true
                  : false
              } />

            <FormControlLabel
              control={<Checkbox
                id="priceGroup"
                checked={values.priceGroup}
                onChange={handleChange}
                value={values.priceGroup}
              />} label="Price Group" />

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {priceLevelActionReducer.isError && <Alert severity="error">{priceLevelActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={priceLevelActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/pricelevel" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t('inputRequired');

  const validationSchema = Yup.object().shape({
    id: Yup.string().required(inputRequired!),
    priceLevelDesc: Yup.string().required(inputRequired!),
  });

  if (priceLevelActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(priceLevelCreateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default PriceLevelCreatePage;
