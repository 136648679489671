import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, } from "@mui/material";
import { Formik, FormikHelpers, FormikProps, Form, Field, FieldProps, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link, useMatch } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { Business } from "../../../types/business.type";
import { useTranslation } from "react-i18next";
import { businessActionSelector, businessGetDataById, businessUpdateData, updateBusinessPriceData } from "../../../store/slices/businessActionsSlice";
import { useAppDispatch } from "../../../store/store";
import Loader from "../../component/Loader";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

type BusinessEditPageProps = {
  //
};

const BusinessEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const businessActionReducer = useSelector(businessActionSelector);
  // const [updateRow, setUpdateRow] = React.useState(businessActionReducer.result.buPriceLevel);

  const match = useMatch("/business/edit/:id");
  const initialValues: Business = { id: "", buCode: "", buName: "", conditionType: "", buPriceLevel: [], active: true };


  React.useEffect(() => {
    let id = match?.params.id;
    if (businessActionReducer.isFetching === false) {
      dispatch(businessGetDataById(id!));
    }
  }, [])



  // const handleCellEditCommit = React.useCallback(
  //   ({ id, field, value }: any) => {
  //     const updatedRows = businessActionReducer.result.buPriceLevel!.map((row) => {
  //       if (row.id === id) {
  //         return { ...row, wfStep: value };
  //       }
  //       return row;
  //     });
  //     // setRows(updatedRows);
  //     // console.log(updatedRows);
  //     dispatch(updateBusinessPriceData(updatedRows));

  //   },
  //   [businessActionReducer.result.buPriceLevel],
  // );

  const handleCellEditCommit = ({ id, field, value }: any) => {
    const newUpdatedRows = businessActionReducer.result.buPriceLevel!.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    dispatch(updateBusinessPriceData(newUpdatedRows));
  }


  const columns: GridColDef[] = [
    {
      field: "priceLevelDesc", width: 100,
      renderHeader: () => (<strong> {t('priceLevelDesc')} </strong>),
    },
    {
      field: "wfStep", width: 100, editable: true, type: 'number',
      renderHeader: () => (<strong> {t('wfStep')} </strong>),
    },
  ];



  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Business>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Edit Business
            </Typography>

            <TextField
              id="buCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('buCode')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.buCode}
              helperText={
                errors.buCode && touched.buCode
                  ? errors.buCode
                  : ''
              }
              error={
                errors.buCode && touched.buCode
                  ? true
                  : false
              } />


            <TextField
              name="buName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('buName')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.buName}
              helperText={
                errors.buName && touched.buName
                  ? errors.buName
                  : ''
              }
              error={
                errors.buName && touched.buName
                  ? true
                  : false
              } />


            <TextField id="conditionType"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('conditionType')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.conditionType}
              helperText={
                errors.conditionType && touched.conditionType
                  ? errors.conditionType
                  : ''
              }
              error={
                errors.conditionType && touched.conditionType
                  ? true
                  : false
              } />

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>


            <DataGrid
              sx={{ backgroundColor: "white", height: "80vh" }}
              getRowId={(row) => row.id}
              rows={businessActionReducer.result ? businessActionReducer.result.buPriceLevel! : []}
              columns={columns}
              onCellEditCommit={handleCellEditCommit}
            />

            {businessActionReducer.isError && <Alert severity="error">{businessActionReducer.message}</Alert>}

          </CardContent>

          <CardActions>
            <Button fullWidth variant="contained" color="primary" type="submit"
              sx={{ marginRight: 1 }} disabled={businessActionReducer.isFetching} >
              {t('btnEdit')}
            </Button>
            <Button component={Link} to="/business" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');

  const validationSchema = Yup.object().shape({
    buCode: Yup.string().required(inputRequired!),
    buName: Yup.string().required(inputRequired!),
    conditionType: Yup.string().required(inputRequired!),
  });


  if (businessActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={businessActionReducer.result ? businessActionReducer.result! : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(businessUpdateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default BusinessEditPage;
