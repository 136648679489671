import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, Radio, Alert, FormHelperText, IconButton, Stack, FormLabel, Grid, FormGroup, FormControlLabel, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link, useMatch } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { Template, TemplateData } from "../../../types/template.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import * as XLSX from "xlsx";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { readExcelTemplate } from "../../../utils/excel";
import { clearTemplateData, removeTemplateData, setTemplateData, templateActionSelector, templateGetDataById, templateUpdateData ,templateGetDataImport,updateTemplateData} from "../../../store/slices/templateActionSlice";
import Loader from "../../component/Loader";
import ButtonBack from "../../layouts/ButtonBack";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {GridSelectionModel} from "@mui/x-data-grid-pro";
import { httpClient } from "../../../utils/httpclient";
import { server } from "../../../Constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type TemplateEditPageProps = {
  //
};

const initialValues: Template = { id: "", priceDate: new Date(), priceLevel: "", templateName: "", templateData: [], active: true };


const TemplateEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const match = useMatch("/template/edit/:id");

  const templateActionReducer = useSelector(templateActionSelector);
  const [fileName, setFileName] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(50);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(templateActionReducer.result.templateData.filter((x) => x.isSelect === true).map((x) => x.id));
  const [selectedScaleRows, setSelectedScaleRows] = React.useState<TemplateData[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<TemplateData[]>([]);
  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    let id = match?.params.id;
    dispatch(templateGetDataById(id!));
    dispatch(TemplateGetCheckboxDataById(id!));
  }, [])

  const TemplateGetCheckboxDataById = createAsyncThunk(
    "template/getTemplatebyIDCheck",
      async (id: string, thunkApi) => {
        try {
          const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id!}`);
          setSelectionModel(result.data.templateData.filter((x) => x.isSelect === true).map((x) => x.id))
          setSelectedRows(result.data.templateData.filter((x) => x.isSelect === true))

        return result;
      } catch (error: any) {
        let err = error.response.data.message ?? error.message
        return thunkApi.rejectWithValue(err);
      }
    }
  );

  const handleCellEditCommit = ({ id, field, value }: any) => {
    //console.log({ id, field, value });
    const newUpdatedRows = templateActionReducer.result.templateData!.map(
      (row) => {
        if(typeof value != "number")
        {
          let setValue = value.toUpperCase();
          value = setValue
        }else{
          // console.log("checkNum: false" + value)
          value = Number(Number(value!).toFixed(2))
       
        }
        //console.log(field)
        if (row.id === id) {
          return { ...row, [field]: value,message:''};
          
        }
        return row;
      }
    );
    // setUpdateRow(newUpdatedRows);
    console.log("check:"+newUpdatedRows!.length)
    dispatch(updateTemplateData(newUpdatedRows));
  };

  const columns: GridColDef[] = [
    // {
    //   headerName: "",
    //   field: ".",
    //   width: 60,
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     <Stack direction="row">
    //       {/* <IconButton
    //         aria-label="edit"
    //         size="large"
    //         onClick={() => {
    //           handleDeleteConfirm(row);
    //         }}
    //       >
    //         <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
    //       </IconButton> */}
    //       <IconButton
    //         aria-label="edit"
    //         size="large"
    //         onClick={() => {
    //           handleDeleteConfirm(row);
    //         }}
    //       >
    //         <DeleteIcon fontSize="inherit" style={{ color: "#f00c50" }} />
    //       </IconButton>
    //     </Stack>
    //   ),
    // },

    { field: "priceLevel", headerName: "Price Level", width: 100 },
    { field: "itemCode", headerName: "Article Code", width: 100 ,   editable: true},
    { field: "itemName", headerName: "Article Name", width: 200 },
    { field: "saleUnit", headerName: "Sales Unit", width: 100 ,   editable: true

  },
    // { field: "saleOrg", headerName: "Sales Organization", width: 100 },
    // { field: "distributionChannel", headerName: "Distribution Channel", width: 150 },
    // { field: "priceList", headerName: "Price List", width: 150 },
    { field: "siteCode", headerName: "Site", width: 150 ,editable: true  },
    { field: "siteGroup", headerName: "Site Group", width: 150 , editable: true },
    { field: "vendor", headerName: "Vendor", width: 150,  editable: true },
    // { field: "validFrom", headerName: "Valid From", width: 150 },
    // { field: "validTo", headerName: "Valid To", width: 150 },
    // { field: "amountInMargin", headerName: "Amount in Margin", width: 150 },
    { field: "amountInFinalPrice", headerName: "Amount in Final Price", width: 150,   editable: true,type: "number", },
    // { field: "priceQTY1", headerName: "PQtyB1 (Scale 1)", width: 150 },
    // { field: "price1", headerName: "PMPrice1 (Scale 1)", width: 150 },
    { field: "priceQTY2", headerName: "PQtyB2 (Scale 2)", width: 150 ,   editable: true,type: "number",
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',},
    // { field: "price2", headerName: "PMPrice2 (Scale 2)", width: 150 },
    // { field: "priceQTY3", headerName: "PQtyB3 (Scale 3)", width: 150 },
    // { field: "price3", headerName: "PMPrice3 (Scale 3)", width: 150 },
    // { field: "priceQTY4", headerName: "PQtyB4 (Scale 4)", width: 150 },
    // { field: "price4", headerName: "PMPrice4 (Scale 4)", width: 150 },
    // { field: "priceQTY5", headerName: "PQtyB5 (Scale 5)", width: 150 },
    // { field: "price5", headerName: "PMPrice5 (Scale 5)", width: 150 },
    {
      field: "message",
      headerName: "Message",
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "red",
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
  ];


  const handleExcelChange = (e: any) => {
    // e.preventDefault();
    const files = e.target.files;
    setFileName(files[0].name)

    if (e.target.files) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet,{header: ['A','B','C','D', 'E', 'F', 'G', 'H', 'I', 'J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X'], blankrows: false });
        const templateData = readExcelTemplate(jsonData);

        const valuesToSend = { ...initialValues, templateData: templateData }
        dispatch(templateGetDataImport({formData:templateData}));
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }


  const handleDeleteConfirm = (e: any) => {
    // setRowsData(rowsData.filter((row) => row.id !== e.id));

    dispatch(removeTemplateData(templateActionReducer.result!.templateData?.filter((row) => row.id !== e.id)!))
    // setRowsData(templateActionReducer.result!.templateData!);
  };

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Template>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Edit Template
            </Typography>

            {/* <TextField id="templateName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('templateName')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.templateName}
              helperText={
                errors.templateName && touched.templateName
                  ? errors.templateName
                  : ''
              }
              error={
                errors.templateName && touched.templateName
                  ? true
                  : false
              } /> */}
            <TextField
              id="templateName"
              style={{ marginTop: 16}}
              fullWidth
              variant="outlined"
              label={t("templateName")}
              onChange={handleChange}
              onBlur={handleBlur}
               value={values.templateName}
              helperText={
                errors.templateName && touched.templateName
                  ? errors.templateName
                  : ""
              }
              error={
                errors.templateName && touched.templateName
                  ? true
                  : false
              }
              
            />
            <TextField id="priceLevel"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('priceLevel')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.priceLevel}
              disabled={true}
              helperText={
                errors.priceLevel && touched.priceLevel
                  ? errors.priceLevel
                  : ''
              }
              error={
                errors.priceLevel && touched.priceLevel
                  ? true
                  : false
              } />


            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            <Card>
              <CardActions>
                <FormControl style={{ marginTop: 10, marginBottom: 10 }}>
                </FormControl>
                  <Button variant="contained" component="label">
                    Upload
                    {/* <input hidden type="file" multiple={false}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={handleExcelChange.bind(this)} /> */}

                    <input hidden type="file" multiple={false}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onClick={(event: any) => {
                        event.target.value = null
                      }}
                      onChange={(e: any) => {
                        const files = e.target.files;
                        setFileName(files[0].name)

                        if (e.target.files) {
                          const reader = new FileReader();
                          const rABS = !!reader.readAsBinaryString;

                          reader.onload = (e: any) => {
                            const data = e.target.result;
                            const workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];
                            const jsonData: any = XLSX.utils.sheet_to_json(worksheet,{header: ['A','B','C','D', 'E', 'F', 'G', 'H', 'I', 'J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X'], blankrows: false });
                            const templateData = readExcelTemplate(jsonData);

                            setFieldValue("templateData", templateData);
                            dispatch(setTemplateData(templateData));
                            // setRowsData(templateData);
                          };
                          reader.readAsArrayBuffer(e.target.files[0]);
                        }
                      }} />

                  </Button>         
                  {/* <FormHelperText> {fileName}</FormHelperText>                 */}
              
                <ButtonBack  ></ButtonBack>
                </CardActions>
            </Card>
            <DataGridPro
             checkboxSelection
              sx={{ backgroundColor: "white", height: "80vh" }}
              getRowId={(row) => row.id}
              // rows={rowsData ? rowsData : []}
              rows={templateActionReducer.result ? templateActionReducer.result!.templateData! : []}
              columns={columns}
              rowsPerPageOptions={[20,50,100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onCellEditCommit={handleCellEditCommit}
              disableSelectionOnClick={true}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
                const selectedIDs = new Set(ids);
                const selectedRows =
                templateActionReducer.result.templateData!.filter(
                    (row: any) => selectedIDs.has(row.id)
                  );
               
                setSelectedRows(selectedRows);
                // dispatch(selectPricelist(selectedRows));
              }}
              selectionModel={selectionModel}
            />


            <FormControl style={{ marginTop: 10 }} fullWidth>
              {templateActionReducer.isError && <Alert severity="error">{templateActionReducer.message}</Alert>}
            </FormControl>

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={templateActionReducer.isFetching}
            >
              {t('btnEdit')}
            </Button>
            <Button component={Link} to="/template" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required(inputRequired),
    priceLevel: Yup.string().required(inputRequired),
  });


  if (templateActionReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={templateActionReducer.result ? templateActionReducer.result! : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          const valuesToSend = { ...values, templateData: templateActionReducer.result.templateData! }
          if( selectedRows.length === 0 ){
            MySwal.fire({
              title: <p>{t("itemselected")}</p>,
              didOpen: () => {
                // `MySwal` is a subclass of `Swal` with all the same instance & static methods
              },
            });
      }
      else {
          // dispatch(templateUpdateData(valuesToSend));
          dispatch(templateUpdateData({
            formData:valuesToSend,
            selectList:selectedRows,
          }));
          setSubmitting(false);
        }}
      }

      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default TemplateEditPage;
