import jwtDecode from "jwt-decode";
import * as React from "react";
import { setCookie } from "../../../utils/cookie";
import {useLocation,useNavigate} from "react-router";

type LoginRedirectPageProps = {
  //
};

const LoginRedirectPage: React.FC<any> = () => {
  const queryParams = Object.fromEntries((new URLSearchParams(window.location.search)).entries())
  const token = queryParams['token']
  const continueParam = queryParams['continue']

  const navigate = useNavigate();
  const location = useLocation();

  // console.log("continueParam :" + continueParam!)
  // console.log("location.state?.from:"+ location.state?.from)


  if (token === undefined || token === "" || token === null) {
    // window.location.assign('/login?key=401')
    window.location.assign('/')
  }


  const parsedToken = jwtDecode(token) as { exp: number }
  if (!parsedToken) {
    window.location.assign('/login?key=500')
  }

  const tokenExpiryTime = new Date(parsedToken.exp * 1000)

  setCookie(token, tokenExpiryTime)

  // navigate('/home')

  // if (continueParam) {
  //   window.location.assign(continueParam) 
  // } else {
  //   if(location.state?.from){
  //     navigate(location.state.from);
  //   }else{
  //    window.location.assign('/')
  //   }
  //   // navigate(location.state.from);
  // }

  return <div>

  </div>;
};

export default LoginRedirectPage;
