import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Role } from "../../types/role.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";

type RoleState = {
    result: Role[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: RoleState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const roleGetData = createAsyncThunk(
    "role/getRoles",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<Role[]>(server.ROLE_URL + "?keyword=" + keyword);
            return result;
        } catch (err: any) {
            return thunkApi.rejectWithValue(err.message);
        }
    }
);

const roleSlice = createSlice({
    name: "role",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(roleGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(roleGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                
                // const navigate = useNavigate();
                // navigate('/home');
            })
            .addCase(roleGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = roleSlice.actions;
export const roleSelector = (store: RootState) => store.roleReducer;
export default roleSlice.reducer;