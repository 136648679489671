import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet,redirect } from "react-router-dom";
import { loginSelector } from "../store/slices/loginSlice";
import { useLocation,useNavigate} from "react-router";
import { history } from "../index";

const PublicRoutes = (props: any) => {
    const loginReducer = useSelector(loginSelector);
    const auth = loginReducer.result;
    const location = useLocation();
    const navigate = useNavigate();

    if(auth==null || auth===undefined){
      //    return <Navigate to={"/login"} />;
      redirect("/login");
      }

    // if(auth!=null){
    //     if(location.state?.from){
    //         const backPage = location.state.from;
    //       return  <Navigate to={backPage} replace/>;
    //     }
    
    //  }else{
    //   redirect("/login");
    //  }
    // return auth  ? <Navigate to="/home" /> : <Outlet />; 
    return auth  ? <Navigate to="/home" /> : <Outlet />;
    // return auth ? <Navigate to="/home" replace state={{from: location}}/>:<Outlet />;
};

export default PublicRoutes;