import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { Business } from "../../types/business.type";

type BusinessState = {
    result: Business[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: BusinessState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const businessGetData = createAsyncThunk(
    "business/getBusinesss",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<Business[]>(server.BUSINESS_URL + "?keyword=" + keyword);
            return result;
        } catch (err: any) {
            return thunkApi.rejectWithValue(err.message);
        }
    }
);


const businessSlice = createSlice({
    name: "business",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(businessGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(businessGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(businessGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
 
    }
})


export const { } = businessSlice.actions;
export const businessSelector = (store: RootState) => store.businessReducer;
export default businessSlice.reducer;