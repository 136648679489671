import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { WorkFlowHistory } from "../../types/approve.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";

type ApproveWorkFlowHistoryState = {
    result: WorkFlowHistory[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialWorkFlowStateValues: ApproveWorkFlowHistoryState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const approveWorkFlowHistoryGetData = createAsyncThunk(
    "approvehistory/getApproveWorkflowHistory",
    async (id: string, thunkApi) => {
        try {
            // APPROVEWORKFLOWHISTORY_URL: `pricelists/approve/workflow/history`,

            const result = await httpClient.get<WorkFlowHistory[]>(`${server.APPROVE_URL+'/workflow/history'}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

const approveWorkFlowHistorySlice = createSlice({
    name: "ApproveWorkFlowHistory",
    initialState: initialWorkFlowStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(approveWorkFlowHistoryGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(approveWorkFlowHistoryGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(approveWorkFlowHistoryGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})

export const { } = approveWorkFlowHistorySlice.actions;
export const ApproveWorkFlowHistorySelector = (store: RootState) => store.approveWorkFlowHistoryReducer;
export default approveWorkFlowHistorySlice.reducer;