import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, Radio, InputLabel, Alert, FormHelperText, IconButton, Stack, FormLabel, Grid, FormGroup, Select, Checkbox, RadioGroup, FormControlLabel, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link, useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Loader from "../../component/Loader";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "../../../utils/httpclient";
import { server } from "../../../Constants";
import { ItemData } from "../../../types/items.type";
import { ItemUpdateData, itemsActionSelector, ItemGetDataById } from "../../../store/slices/itemsActionSlice";

const ItemEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const itemsActionReducer = useSelector(itemsActionSelector);
  const [pageSize, setPageSize] = React.useState(50);
  const [docstatus, setDocstatus] = React.useState<any>("");
  const match = useMatch("/items/edit/:id");

  React.useEffect(() => {
    let id: any = match?.params.id;
    // console.log(id)
    // dispatch(clearPricelist());
    dispatch(ItemGetDataById(id!))
  }, []);

  const initialValues: ItemData = {
    id: "",
    itemCode: "",
    itemName: "",
    uom: "",
    ean11: "",
    hpean: "",
    taxType: "",
    matkl: "",
    scagr:"",
    brandId: "",
    brandDesc: "",
    curcy: "",
    aenkz: "",
    kwdht: "",
    active: true
  };


  const handleSave = (docstatus: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
  }

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<ItemData>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              {t("edititem")}
            </Typography>


            <TextField
              id="itemCode"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t("itemCode")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.itemCode}
              disabled
              error={errors.itemCode && touched.itemCode ? true : false}
            />

            <TextField id="itemName"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('itemName')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.itemName}
            />

            <TextField id="uom"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('uom')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.uom}
            />
            <TextField id="ean11"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('ean11')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ean11}
            />

            <TextField id="hpean"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('hpean')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.hpean}
            />
            <TextField id="taxType"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('taxType')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.taxType}
            />
            <TextField id="matkl"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('matkl')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.matkl}
            />
               <TextField id="scagr"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('scagr')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.scagr}
            />
            <TextField id="brandId"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('brandId')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.brandId}
            />
            <TextField id="brandDesc"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('brandDesc')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.brandDesc}
            />
            <TextField id="curcy"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('curcy')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.curcy}
            />
            <TextField id="aenkz"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('aenkz')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.aenkz}
            />
            <TextField id="kwdht"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t('kwdht')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.kwdht}
            />

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            <FormControl style={{ marginTop: 16, }} fullWidth>
              {itemsActionReducer.isError && <Alert severity="error">{itemsActionReducer.message}</Alert>}
            </FormControl>

          </CardContent>

          <CardActions>
            <Button fullWidth
              variant="contained"
              color="info"
              type="submit"
              sx={{ marginRight: 1 }}
            >  Edit
            </Button>

            <Button component={Link} to="/items"
              variant="outlined" fullWidth
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  if (itemsActionReducer.isFetching) {
    return <><Loader /></>
  }


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({

  });
  return (
    <>
      <Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={itemsActionReducer.result ? itemsActionReducer.result! : initialValues}
          onSubmit={(values, { setSubmitting }) => {
            // console.log("Select",selectionModel);
            // const valuesToSend = {
            //   ...values,
            //   priceLevel: pricelistActionReducer!.result!.priceListData[0].priceLevel,
            //   priceListData: docstatus.documentStatus === "Draft" ? pricelistActionReducer!.result!.priceListData : selectedRows,
            //   documentStatus: docstatus.documentStatus,
            //   wfStep: 1,
            //   wfStepAll: 1,
            // }
            // console.log(values)
            dispatch(ItemUpdateData(values));
            setSubmitting(false);
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );
};

export default ItemEditPage;
