import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { TOKEN } from "../../Constants";
import { eraseCookie, setCookie } from "../../utils/cookie";
import { RootState } from "../store";
import { history } from "../../index";

export interface LoginState {
    isFetching: boolean;
    isError: boolean;
    result: any;
}

const initialStateValues: LoginState = {
    isFetching: false,
    isError: false,
    result: null,
};


export const Login = createAsyncThunk(
    "login/Login",
    async () => {

        try {
            const queryParams = Object.fromEntries((new URLSearchParams(window.location.search)).entries())
            const token = queryParams['token']
             const continueParam = queryParams['continue']

             if (token) {
                try {

                    const parsedToken = jwtDecode(token) as { exp: number }
                    if (!parsedToken) {
                        return null;
                    }
                    const tokenExpiryTime = new Date(parsedToken.exp * 1000)
                    setCookie(token, tokenExpiryTime)

                } catch (error) {
                    // localStorage.removeItem('access_token');
                    // window.location.href = '/'
                    localStorage.removeItem('AuthKey');
                    console.log("error token")
                    window.location.href = '/login?token_invalid=true&continue=/';
                }
            }

            // if (!token) {
            //     return null;
            // }

            // const parsedToken = jwtDecode(token) as { exp: number }
            // if (!parsedToken) {
            //     return null;
            // }

            // const tokenExpiryTime = new Date(parsedToken.exp * 1000)

            // setCookie(token, tokenExpiryTime)


            if (continueParam) {
                window.location.assign(continueParam)
            } else {
                window.location.assign('/')
            }

        } catch (err: any) {
            return null;
        }
    }
);


const loginSlice = createSlice({
    name: "login",
    initialState: initialStateValues,
    reducers: {
        //sync
        restoreLogin: (state: LoginState, action: PayloadAction<void>) => {
            const token = localStorage.getItem(TOKEN);
            state.result = token;
            state.isError = false;
            state.isFetching = false;

        },
        logOut: (state: LoginState, action: PayloadAction<void>) => {
            eraseCookie();
            state.result = null;
            state.isError = false;
            state.isFetching = false;
        }
    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(Login.fulfilled, (state, action) => {
                state.result = action.payload;
                state.isFetching = false;
                state.isError = false;
                // const navigate = useNavigate();
                // navigate('/home');
                history.back();              
            })
            .addCase(Login.rejected, (state, action) => {
                state.result = null;
                state.isFetching = false;
                state.isError = false;

            })
            .addCase(Login.pending, (state, action) => {
                state.result = null;
                state.isFetching = true;
                state.isError = false;
            });
    }
})


export const { restoreLogin, logOut } = loginSlice.actions;
export const loginSelector = (store: RootState) => store.loginReducer;
export default loginSlice.reducer;
