import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { Rolemenu } from "../../types/menu.type";
import { redirect  } from "react-router-dom";

type RoleMenuState = {
    result: Rolemenu[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateRoleValues: RoleMenuState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};



export const GetRoleMenu = createAsyncThunk(
    "Menu/getMenus",
    async (_, thunkApi) => {
        try {
            const result = await httpClient.get<RoleMenuState[]>(server.ROLEMENU_URL);
            return result;
        } catch (err: any) {
            // window.location.href = '/login?token_invalid=true&continue=/';
            return thunkApi.rejectWithValue(err.message);
        }
    }
);


const RoleMenuSlice = createSlice({
    name: "Rolemenu",
    initialState: initialStateRoleValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(GetRoleMenu.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(GetRoleMenu.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(GetRoleMenu.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
                window.location.href = '/login';
                // redirect("/login");
            });
 
    }
})



export const { } = RoleMenuSlice.actions;
export const MenuSelector = (store: RootState) => store.rolemenuReducer;
export default RoleMenuSlice.reducer;
