import uuid from "react-uuid";
import { TemplateData } from "../types/template.type";
import { ItemData } from "../types/items.type";
import { parse as parseDate, isValid as isValidDate } from 'date-fns'
import XLXS from 'sheetjs-style';
import { PricelistData, PricelistDataExportExcel } from "../types/pricelist.type";
import { Brand } from "../types/brand.type";

export function readExcelTemplate(json: any) {
    const templateData: TemplateData[] = [];
    // const rowData: any = [];
    try { 
        for (var i = 0; i < json.length; i++) {
            // rowData.push(json[i]);
            if (isNaN(json[i]["B"]) === false) {
                let found = false;
                //PriceLevel
                if (json[i]["A"] == "1") {
                    const t = templateData.find((x) => {
                        //Article + Site
                        return x.itemCode == json[i]["B"] && x.siteCode == json[i]["G"]
                    })
                    if (t === undefined) {
                        found = true;
                    } else {
                        found = false;
                    }
                } else if(json[i]["A"] == "7") {
                    let chkvendor 
                    let chckimpven
                    const t = templateData.find((x) => {
                        //Article + Site
                       
                        if(json[i]["I"]! ==undefined){
                            chkvendor = ""
                        }else{
                            chkvendor = json[i]["I"]!
                        }
                        chckimpven = x.vendor!
                        return x.itemCode! == json[i]["B"]! && x.siteCode! == json[i]["G"]! && x.vendor! === chkvendor
                    })
                    if (t === undefined) {
                        // console.log("x.vendor! :" + chkvendor + " impven:" + chckimpven)
                        found = true;
                    } else {
                        found = false;
                    }

                } else  {  
                    const t = templateData.find((x) => {
                         //Article + SiteGroup
                        return x.itemCode == json[i]["B"] && x.siteCode == json[i]["H"]
                    })
                    if (t === undefined) {
                        found = true;
                    } else {
                        found = false;
                    }
                }


                if (found === true) {
                    let tmpData = new TemplateData();
                    tmpData.id = uuid();
                    tmpData.isSelect = false;
                    tmpData.priceLevel = json[i]["A"] === undefined ? "" : json[i]["A"];
                    if (tmpData.priceLevel == "") {
                        tmpData.priceLevel = json[i]["Price Level\r\n1: Site Level (ราคาระดับสาขา)\r\n2: Site Group (ราคาตามกลุ่มสาขา)\r\n3: Sales Org. & Dist Ch. Level (ราคากลาง)\r\n4: Scale Price (ราคาตามขั้น)"] === undefined ? "" : json[i]["Price Level\r\n1: Site Level (ราคาระดับสาขา)\r\n2: Site Group (ราคาตามกลุ่มสาขา)\r\n3: Sales Org. & Dist Ch. Level (ราคากลาง)\r\n4: Scale Price (ราคาตามขั้น)"];
                    }
                    tmpData.itemCode = json[i]["B"] === undefined ? "" : json[i]["B"];
                    tmpData.itemName = json[i]["X"] === undefined ? "" : json[i]["X"];
                    tmpData.ean11 = "";
                    tmpData.hpean = "";
                    tmpData.taxType = "";
                    tmpData.matkl = "";
                    tmpData.scagr="";
                    tmpData.brandId = "";
                    tmpData.brandDesc = "";
                    tmpData.saleUnit = json[i]["C"] === undefined ||json[i]["C"] === null ? "" : json[i]["C"];
                    tmpData.saleOrg = json[i]["D"] === undefined ||json[i]["D"] === null ? "" : json[i]["D"];
                    tmpData.saleOrg = 'S121';//tmpData.saleOrg ===""?'S121' : tmpData.saleOrg;
                    tmpData.distributionChannel = json[i]["E"] === undefined ||json[i]["E"] === null ? "" : json[i]["E"];
                    tmpData.distributionChannel = '20';//'tmpData.distributionChannel ===""?'20' : tmpData.distributionChannel;
                    tmpData.priceList = json[i]["F"] === undefined ||json[i]["F"] === null? "" : json[i]["F"];
                    tmpData.priceList = 'S1';//tmpData.priceList ===""?'S1' : tmpData.priceList;
                    tmpData.siteCode = json[i]["G"] === undefined ||json[i]["G"] === null? "" : json[i]["G"];
                    tmpData.siteGroup = json[i]["H"] === undefined || json[i]["H"] === null? "" : json[i]["H"];
                    tmpData.vendor = json[i]["I"] === undefined ||json[i]["I"] === null ? "" : json[i]["I"];
             
                    // tmpData.validFrom = json[i]["Valid from"] === undefined ? "" : json[i]["Valid from"];
                    // tmpData.validTo = json[i]["Valid To"] === undefined ? "" : json[i]["Valid To"];

                    if (!isValidDate(parseDate(json[i]['J'], 'dd.MM.yyyy', new Date()))) {
                        tmpData.validFrom = new Date();
                    } else {
                        tmpData.validFrom = new Date();
                    }

                    if (!isValidDate(parseDate(json[i]['K'], 'dd.MM.yyyy', new Date()))) {
                        tmpData.validTo = new Date();
                    } else {
                        tmpData.validTo = new Date();
                    }

                    if (isNaN(json[i]["L"])) {
                        tmpData.amountInMargin = 0;
                    } else {
                        tmpData.amountInMargin = json[i]["L"]===null ? 0:json[i]["L"];
                    }

                    if (isNaN(json[i]["M"])) {
                        tmpData.amountInFinalPrice = 0;
                    } else {
                        tmpData.amountInFinalPrice = json[i]["M"]===null ? 0:Number(Number(json[i]["M"]).toFixed(2));
                    }

                    if (isNaN(json[i]["N"])) {
                        tmpData.priceQTY1 = 0;
                    } else {
                        tmpData.priceQTY1 = json[i]["N"]===null ? 0:json[i]["N"];
                    }

                    if (isNaN(json[i]["O"])) {
                        tmpData.price1 = 0;
                    } else {
                        tmpData.price1 = json[i]["O"]===null ? 0:json[i]["O"];
                    }

                    if (isNaN(json[i]["P"])||json[i]["P"] ==="") {
                        tmpData.priceQTY2 = 0;
                    } else {
                        tmpData.priceQTY2 = json[i]["P"]===null ? 0:json[i]["P"];
                    }


                    if (isNaN(json[i]["Q"])) {
                        tmpData.price2 = 0;
                    } else {
                        tmpData.price2 = json[i]["Q"]===null ? 0:json[i]["Q"];
                    }

                    if (isNaN(json[i]["R"])) {
                        tmpData.priceQTY3 = 0;
                    } else {
                        tmpData.priceQTY3 =  json[i]["R"]===null ? 0:json[i]["R"];
                    }

                    if (isNaN(json[i]["S"])) {
                        tmpData.price3 = 0;
                    } else {
                        tmpData.price3 =  json[i]["S"]===null ? 0:json[i]["S"];
                    }

                    if (isNaN(json[i]["T"])) {
                        tmpData.priceQTY4 = 0;
                    } else {
                        tmpData.priceQTY4 =  json[i]["T"]===null ? 0:json[i]["T"];
                    }

                    if (isNaN(json[i]["U"])) {
                        tmpData.price4 = 0;
                    } else {
                        tmpData.price4 =  json[i]["U"]===null ? 0:json[i]["U"];
                    }

                    if (isNaN(json[i]["V"])) {
                        tmpData.priceQTY5 = 0;
                    } else {
                        tmpData.priceQTY5 =  json[i]["V"]===null ? 0:json[i]["V"];
                    }

                    if (isNaN(json[i]["W"])) {
                        tmpData.price5 = 0;
                    } else {
                        tmpData.price5 =  json[i]["W"]===null ? 0:json[i]["W"];
                    }


                    tmpData.validFromO = null;
                    tmpData.validToO = null;

                    tmpData.amountInMarginO = null;
                    tmpData.amountInFinalPriceO = null;

                    tmpData.priceQTY1O = null;
                    tmpData.price1O = null;

                    tmpData.priceQTY2O = null;
                    tmpData.price2O = null;

                    tmpData.priceQTY3O = null;
                    tmpData.price3O = null;

                    tmpData.priceQTY4O = null;
                    tmpData.price4O = null;

                    tmpData.priceQTY5O = null;
                    tmpData.price5O = null;


                    templateData.push(tmpData);
                }
            }
        }

        return templateData;

    } catch {
        return [];
    }
}

export function readExcelTemplateScale(json: any) {
    const templateData: PricelistDataExportExcel[] = [];
    // const rowData: any = [];
    try { 
        for (var i = 0; i < json.length; i++) {
            // rowData.push(json[i]);
            if (isNaN(json[i]["B"]) !== null || isNaN(json[i]["B"]) !== undefined) {
            
                    let tmpData = new PricelistDataExportExcel();
                    tmpData.docRef = json[i]["docRef"] === undefined ? "" : json[i]["docRef"];
                    tmpData.priceLevel = json[i]["priceLevel"] === undefined ? "" : json[i]["priceLevel"];
                    tmpData.itemCode = json[i]["itemCode"] === undefined ? "" : json[i]["itemCode"];
                    tmpData.itemName = json[i]["itemName"] === undefined ? "" : json[i]["itemName"];
                    tmpData.saleUnit = json[i]["saleUnit"] === undefined ? "" : json[i]["saleUnit"];
                    tmpData.siteCode = json[i]["siteCode"] === undefined ? "" : json[i]["siteCode"];
                    tmpData.siteGroup = json[i]["siteGroup"] === undefined ? "" : json[i]["siteGroup"];

                    if (isNaN(json[i]["amountInFinalPrice"])) {
                        tmpData.amountInFinalPrice = null;
                    } else {
                        tmpData.amountInFinalPrice = json[i]["amountInFinalPrice"];
                    }

                    if (isNaN(json[i]["priceQTY1"])) {
                        tmpData.priceQTY1 = null;
                    } else {
                        tmpData.priceQTY1 = json[i]["priceQTY1"];
                    }

                    if (isNaN(json[i]["price1"])) {
                        tmpData.price1 = null;
                    } else {
                        tmpData.price1 = json[i]["price1"];
                    }

                    if (isNaN(json[i]["priceQTY2"])) {
                        tmpData.priceQTY2 = null;
                    } else {
                        tmpData.priceQTY2 = json[i]["priceQTY2"];
                    }

                    if (isNaN(json[i]["price2"])) {
                        tmpData.price2 = null;
                    } else {
                        tmpData.price2 = json[i]["price2"];
                    }

                    if (isNaN(json[i]["priceQTY3"])) {
                        tmpData.priceQTY3 = null;
                    } else {
                        tmpData.priceQTY3 = json[i]["priceQTY3"];
                    }

                    if (isNaN(json[i]["price3"])) {
                        tmpData.price3 = null;
                    } else {
                        tmpData.price3 = json[i]["price3"];
                    }

                    if (isNaN(json[i]["priceQTY4"])) {
                        tmpData.priceQTY4 = null;
                    } else {
                        tmpData.priceQTY4 = json[i]["priceQTY4"];
                    }

                    if (isNaN(json[i]["price4"])) {
                        tmpData.price4 = null;
                    } else {
                        tmpData.price4 = json[i]["price4"];
                    }

                    if (isNaN(json[i]["priceQTY5"])) {
                        tmpData.priceQTY5 = null;
                    } else {
                        tmpData.priceQTY5 = json[i]["priceQTY5"];
                    }

                    if (isNaN(json[i]["price5"])) {
                        tmpData.price5 = null;
                    } else {
                        tmpData.price5 = json[i]["price5"];
                    }

                    templateData.push(tmpData);
                
            }
        }

        return templateData;

    } catch {
        return [];
    }
}

export function readExcelTemplateMap(json: any,type:string) {
    const templateData: PricelistData[] = [];
    // const rowData: any = [];
    try { 
        for (var i = 0; i < json.length; i++) {
            // rowData.push(json[i]);
            if (type==="5"){      
                if (isNaN(json[i]["B"]) !== false) {
                        let tmpData = new PricelistData();
                        tmpData.id = uuid();
                        tmpData.isSelect = true;
                        tmpData.priceLevel = json[i]["priceLevel"] === undefined ? "" : json[i]["priceLevel"];
                        tmpData.itemCode = json[i]["itemCode"] === undefined ? "" : json[i]["itemCode"];
                        tmpData.itemName = json[i]["itemName"] === undefined ? "" : json[i]["itemName"];
                        tmpData.ean11 = "";
                        tmpData.hpean = "";
                        tmpData.taxType = "";
                        tmpData.matkl = "";
                        tmpData.brandId = "";
                        tmpData.brandDesc = "";
                        tmpData.saleUnit = json[i]["saleUnit"] === undefined ? "" : json[i]["saleUnit"];
                        tmpData.saleOrg = 'S121';
                        tmpData.distributionChannel = '20';
                        tmpData.priceList = 'S1';
                        tmpData.siteCode = json[i]["siteCode"] === undefined ? "" : json[i]["siteCode"];
                        tmpData.siteGroup = json[i]["siteGroup"] === undefined ? "" : json[i]["siteGroup"];
                        tmpData.vendor = "";
                        tmpData.validFrom = new Date();
                        tmpData.validTo = new Date();
                        tmpData.amountInMargin = null;
                        if (isNaN(json[i]["amountInFinalPrice"])) {
                            tmpData.amountInFinalPrice = null;
                        } else {
                            tmpData.amountInFinalPrice = json[i]["amountInFinalPrice"];
                        }

                        if (isNaN(json[i]["priceQTY1"])) {
                            tmpData.priceQTY1 = null;
                        } else {
                            tmpData.priceQTY1 = json[i]["priceQTY1"];
                        }

                        if (isNaN(json[i]["price1"])) {
                            tmpData.price1 = null;
                        } else {
                            tmpData.price1 = json[i]["price1"];
                        }

                        if (isNaN(json[i]["priceQTY2"])) {
                            tmpData.priceQTY2 = null;
                        } else {
                            tmpData.priceQTY2 = json[i]["priceQTY2"];
                        }

                        if (isNaN(json[i]["price2"])) {
                            tmpData.price2 = null;
                        } else {
                            tmpData.price2 = json[i]["price2"];
                        }

                        if (isNaN(json[i]["priceQTY3"])) {
                            tmpData.priceQTY3 = null;
                        } else {
                            tmpData.priceQTY3 = json[i]["priceQTY3"];
                        }

                        if (isNaN(json[i]["price3"])) {
                            tmpData.price3 = null;
                        } else {
                            tmpData.price3 = json[i]["price3"];
                        }

                        if (isNaN(json[i]["priceQTY4"])) {
                            tmpData.priceQTY4 = null;
                        } else {
                            tmpData.priceQTY4 = json[i]["priceQTY4"];
                        }

                        if (isNaN(json[i]["price4"])) {
                            tmpData.price4 = null;
                        } else {
                            tmpData.price4 = json[i]["price4"];
                        }

                        if (isNaN(json[i]["priceQTY5"])) {
                            tmpData.priceQTY5 = null;
                        } else {
                            tmpData.priceQTY5 = json[i]["priceQTY5"];
                        }

                        if (isNaN(json[i]["price5"])) {
                            tmpData.price5 = null;
                        } else {
                            tmpData.price5 = json[i]["price5"];
                        }


                        tmpData.validFromO = undefined;
                        tmpData.validToO = undefined;

                        tmpData.amountInMarginO = null;
                        tmpData.amountInFinalPriceO = null;

                        tmpData.priceQTY1O = null;
                        tmpData.price1O = null;

                        tmpData.priceQTY2O = null;
                        tmpData.price2O = null;

                        tmpData.priceQTY3O = null;
                        tmpData.price3O = null;

                        tmpData.priceQTY4O = null;
                        tmpData.price4O = null;

                        tmpData.priceQTY5O = null;
                        tmpData.price5O = null;
                        tmpData.message ="";
                        tmpData.condRec= json[i]["docRef"];

                        templateData.push(tmpData);
                    }
            }
        else if (type==="7"){
            if (isNaN(json[i]["B"]) !== false) {
                let tmpData = new PricelistData();
                tmpData.id = uuid();
                tmpData.isSelect = true;
                tmpData.priceLevel = json[i]["priceLevel"] === undefined ? "" : json[i]["priceLevel"];
                tmpData.itemCode = json[i]["itemCode"] === undefined ? "" : json[i]["itemCode"];
                tmpData.itemName = json[i]["itemName"] === undefined ? "" : json[i]["itemName"];
                tmpData.ean11 = "";
                tmpData.hpean = "";
                tmpData.taxType = "";
                tmpData.matkl = "";
                tmpData.brandId = "";
                tmpData.brandDesc = "";
                tmpData.saleUnit = json[i]["saleUnit"] === undefined ? "" : json[i]["saleUnit"];
                tmpData.saleOrg = 'S121';
                tmpData.distributionChannel = '20';
                tmpData.priceList = 'S1';
                tmpData.siteCode = json[i]["siteCode"] === undefined ? "" : json[i]["siteCode"];
                tmpData.siteGroup = json[i]["siteGroup"] === undefined ? "" : json[i]["siteGroup"];
                tmpData.vendor =json[i]["vendor"] === undefined ? "" : json[i]["vendor"];
                tmpData.validFrom = new Date();
                tmpData.validTo = new Date();
                tmpData.amountInMargin = null;
                if (isNaN(json[i]["amountInFinalPrice"])) {
                    tmpData.amountInFinalPrice = null;
                } else {
                    tmpData.amountInFinalPrice = json[i]["amountInFinalPrice"];
                }

                tmpData.priceQTY1 = undefined;
                tmpData.price1 = undefined;
                tmpData.priceQTY2 = undefined;
                tmpData.price2 = undefined;
                tmpData.priceQTY3 = undefined;
                tmpData.price3 = undefined;
                tmpData.priceQTY4 = undefined;
                tmpData.price4 = undefined;
                tmpData.priceQTY5 = undefined;
                tmpData.price5 = undefined;

                tmpData.validFromO = undefined;
                tmpData.validToO = undefined;

                tmpData.amountInMarginO = null;
                tmpData.amountInFinalPriceO = null;

                tmpData.priceQTY1O = null;
                tmpData.price1O = null;

                tmpData.priceQTY2O = null;
                tmpData.price2O = null;

                tmpData.priceQTY3O = null;
                tmpData.price3O = null;

                tmpData.priceQTY4O = null;
                tmpData.price4O = null;

                tmpData.priceQTY5O = null;
                tmpData.price5O = null;
                tmpData.message ="";
                tmpData.condRec= json[i]["docRef"];

                templateData.push(tmpData);
            }
        }
    }
        

        return templateData;

    } catch {
        return [];
    }
}


export function readExcelItem(json: any,brand:Brand[]) {
    const itemsData: ItemData[] = [];
    try {

        for (var i = 0; i < json.length; i++) {
            if ((json[i]["M"]) === "X") {

                let itemData = new ItemData();
                itemData.id = uuid();
                itemData.itemCode = json[i]["Material"];
                itemData.itemName = json[i]["Material description"];
                if(json[i]["Sales unit (Basic)"].toString() == ""){
                    itemData.uom = json[i]["Bacode Sales unit"];
                }else{
                    itemData.uom = json[i]["Sales unit (Basic)"];
                }     
                itemData.ean11 = json[i]["EAN/UPC"];
                itemData.taxType = json[i]["TaxCl"];
                // itemData.brandDesc = json[i]["BMR"];

                let found =  brand.find((x) =>  Number(x.brandId!) === Number(json[i]["Brand"]));
                // console.log("found.brandName! :" + json[i]["Brand"] + " : " + found?.brandName!)
                if (found !== undefined) {
                    itemData.brandDesc = found?.brandName!;
                }
                else{
                    itemData.brandDesc = "non";
                }
                itemData.brandId = json[i]["Brand"];
                // itemData.hpean = json[i]["M"];
                itemData.hpean = "X";
                itemData.matkl = json[i]["Matl Group"];
                itemData.curcy = null || "THB";
                itemData.aenkz = null || "DIRC";
                itemData.kwdht = null || " ";
                itemData.active = true;
                itemData.scagr = json[i]["LGrp"];

                itemsData.push(itemData);
                 }
            // }
        }
        // console.log(itemsData)
        return itemsData;
    }

    catch {
        return [];
    }
}

export function exportExcelItem(dataObj: any, headsCol: string[][], fileName: string) {
    const wb = XLXS.utils.book_new();
    const ws: XLXS.WorkSheet = XLXS.utils.json_to_sheet([]);
    XLXS.utils.sheet_add_aoa(ws, headsCol);
    XLXS.utils.sheet_add_json(ws, dataObj, { origin: 'A2', skipHeader: true });
    XLXS.utils.book_append_sheet(wb, ws, 'Sheet1');
    let wscols = [];
    for (let i in ws) {
        wscols.push({ wch: 15 });
        let cell = XLXS.utils.decode_cell(i);
        if (cell.r === 0) { // first row
           if(cell.c >= 0 && cell.c <=9){
                ws[i].s = {
                    alignment: {
                        horizontal: "Left"
                    },
                    fill: {
                        font: { bold: true, color: { rgb: "d50000" } },
                        fgColor: { rgb: "A6F2CC" }
                    },
                    border: { style: "thin", color: "000000" }
                };
           }
            else{
                ws[i].s = {
                    alignment: {
                        horizontal: "center"
                    },
                    fill: {
                        font: { bold: true, color: { rgb: "FFFAF0" } },
                        fgColor: { rgb: "43a047" }
                    },
                    border: { style: "thin", color: "000000" }
                };
            }
    
        }

        if (cell.r % 2) { // every other row
            ws[i].s = { // background color
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFAF0" },
                    bgColor: { rgb: "FFFAF0" }
                }
            };
        }
    }

    if (wscols.length > 0) {
        ws['!cols'] = wscols;
    }
    XLXS.writeFile(wb, fileName + '.xlsx');
}
export function exportExcelItemPreviewLv7(dataObj: any, headsCol: string[][], fileName: string) {
    const wb = XLXS.utils.book_new();
    const ws: XLXS.WorkSheet = XLXS.utils.json_to_sheet([]);
    XLXS.utils.sheet_add_aoa(ws, headsCol);
    XLXS.utils.sheet_add_json(ws, dataObj, { origin: 'A2', skipHeader: true });
    XLXS.utils.book_append_sheet(wb, ws, 'Sheet1');
    let wscols = [];
    for (let i in ws) {
        wscols.push({ wch: 15 });
        let cell = XLXS.utils.decode_cell(i);
        if (cell.r === 0) { // first row
           if(cell.c >= 0 && cell.c <=8){
                ws[i].s = {
                    alignment: {
                        horizontal: "Left"
                    },
                    fill: {
                        font: { bold: true, color: { rgb: "FFFAF0" } },//d50000
                        fgColor: { rgb: "43a047" }//43a047
                    },
                    border: { style: "thin", color: "000000" }
                };
           }
            else{
                ws[i].s = {
                    alignment: {
                        horizontal: "center"
                    },
                    fill: {
                        font: { bold: true, color: { rgb: "d50000" } },//FFFAF0
                        fgColor: { rgb: "A6F2CC" }//A6F2CC
                    },
                    border: { style: "thin", color: "000000" }
                };
            }
    
        }

        if (cell.r % 2) { // every other row
            ws[i].s = { // background color
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFAF0" },
                    bgColor: { rgb: "FFFAF0" }
                }
            };
        }
    }

    if (wscols.length > 0) {
        ws['!cols'] = wscols;
    }
    XLXS.writeFile(wb, fileName + '.xlsx');
}
export function exportExcelItemLv7(dataObj: any, headsCol: string[][], fileName: string) {
    const wb = XLXS.utils.book_new();
    const ws: XLXS.WorkSheet = XLXS.utils.json_to_sheet([]);
    XLXS.utils.sheet_add_aoa(ws, headsCol);
    XLXS.utils.sheet_add_json(ws, dataObj, { origin: 'A2', skipHeader: true });
    XLXS.utils.book_append_sheet(wb, ws, 'Sheet1');
    let wscols = [];
    for (let i in ws) {
        wscols.push({ wch: 15 });
        let cell = XLXS.utils.decode_cell(i);
        if (cell.r === 0) { // first row
           if(cell.c >= 0 && cell.c <=6){
                ws[i].s = {
                    alignment: {
                        horizontal: "Left"
                    },
                    fill: {
                        font: { bold: true, color: { rgb: "d50000" } },
                        fgColor: { rgb: "A6F2CC" }
                    },
                    border: { style: "thin", color: "000000" }
                };
           }
            else{
                ws[i].s = {
                    alignment: {
                        horizontal: "center"
                    },
                    fill: {
                        font: { bold: true, color: { rgb: "FFFAF0" } },
                        fgColor: { rgb: "43a047" }
                    },
                    border: { style: "thin", color: "000000" }
                };
            }
    
        }

        if (cell.r % 2) { // every other row
            ws[i].s = { // background color
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "FFFAF0" },
                    bgColor: { rgb: "FFFAF0" }
                }
            };
        }
    }

    if (wscols.length > 0) {
        ws['!cols'] = wscols;
    }
    XLXS.writeFile(wb, fileName + '.xlsx');
}



