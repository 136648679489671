import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, FormControlLabel, Radio, InputLabel, Alert, FormLabel, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link, useMatch } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { brandActionSelector, brandGetDataById, brandUpdateData } from "../../../store/slices/brandActionSlice";
import { Brand } from "../../../types/brand.type";
import Loader from "../../component/Loader";


type BrandEditPageProps = {
  //
};


const BrandEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const brandActionReducer = useSelector(brandActionSelector);
  // const siteGroupReducer = useSelector(siteGroupSelector);


  const match = useMatch("/brand/edit/:id");
  const initialValues: Brand = { id: "", brandId: "", brandName: "", active: true , createdBy: "" };

  // React.useEffect(() => {
  //   dispatch(siteGroupGetData(""));
  // }, [])


  React.useEffect(() => {
    let id = match?.params.id;
    dispatch(brandGetDataById(id!));
  }, [])


  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Brand>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Edit Brand
            </Typography>

            <TextField id="brandName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('brandName')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.brandName}
              helperText={
                errors.brandName && touched.brandName
                  ? errors.brandName
                  : ''
              }
              error={
                errors.brandName && touched.brandName
                  ? true
                  : false
              } />

            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="siteGroupId">{t('siteGroupId')}</InputLabel>
              <Select
                name="siteGroupId"
                value={values.siteGroupId}
                onChange={(event) => { setFieldValue('siteGroupId', event.target.value) }}
                error={
                  errors.siteGroupId && touched.siteGroupId
                    ? true
                    : false
                }
              >
                {siteGroupReducer.result.map((item, index) => (
                  <MenuItem key={item.siteGroupCode} value={item.siteGroupCode}>
                    {item.siteGroupName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}


            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
            <FormLabel>Active</FormLabel>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

        

            {brandActionReducer.isError && <Alert severity="error">{brandActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={brandActionReducer.isFetching}
            >
              {t('btnEdit')}
            </Button>
            <Button component={Link} to="/brand" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    brandId: Yup.string().required(inputRequired),
    brandName: Yup.string().required(inputRequired),
  });


  if (brandActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={brandActionReducer.result ? brandActionReducer.result! : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(brandUpdateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default BrandEditPage;
