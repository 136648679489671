import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Fab, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";
import { Link, useNavigate,useMatch } from "react-router-dom";
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch } from "../../../store/store";
import { approveGetData,approveGetDataByPriceLevel, approveSelector } from "../../../store/slices/approveSlice";
import Loader from "../../component/Loader";
import moment from "moment";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewListIcon from '@mui/icons-material/ViewList';
import InfoIcon from '@mui/icons-material/Info';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ButtonBackToHome from "../../layouts/ButtonBackToHome";


interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pl: 0, pr: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  return (
    <Box sx={{ p: 0.5, pb: 0, }}>
      
    </Box>
  );
}

export default function ApprovePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loadtemplate, setLoadtemplate] = React.useState(true);
  const approveReducer = useSelector(approveSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const [value, setValue] = React.useState(0);
  const match = useMatch("/approve/:id");

  React.useEffect(() => {
    let id = match?.params.id!;

      dispatch(approveGetData(value));
      let CheckValue=0;
      if (id !=null){
        CheckValue=Number(id);
      }
      // console.log("CheckValue:"+CheckValue)
      setValue(CheckValue);
  //  dispatch(approveGetDataByPriceLevel(0))
  
  }, [keywordSearch])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(approveGetData(value));
    // dispatch(approveGetDataByPriceLevel(newValue))
    // dispatch(approveGetData(newValue));
   

  };
  const columns: GridColDef[] = [
    {
      headerName: "",
      field: ".",
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              if(Number(row.priceLevel)!=7){
                navigate("/approvepricelist/" + row.id);
              }
              else{
                navigate("/approvepricelistspecial/" + row.id);
              }

            }}
          >
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "priceDescription", width: 250,
      renderHeader: () => (<strong> {t('priceDescription')} </strong>),
    }
    ,
    {
      field: "priceDate", width: 200,headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "validFrom", width: 200,headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", width: 200,headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
    },
    {
      field: "remark", width: 300,
      renderHeader: () => (<strong> {t('remark')} </strong>),
    },
    {
      field: "createdBy", width: 100,
      renderHeader: () => (<strong> {t('createdBy')} </strong>),
    },
    {
      field: "documentStatus", width: 150,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong> {t('documentStatus')} </strong>),
    },
  ];

  if (approveReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          APPROVE
        </Typography>
        <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider'  }}>
                <Tabs  value={value} onChange={handleChangeTab} aria-label="Approve History" variant="fullWidth">
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<ViewListIcon />} label={t('NormalPrice')} {...a11yProps(0)} />
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }}icon={<AttachMoneyIcon />} label={t('ScalePrice')}{...a11yProps(1)} />
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<CurrencyExchangeIcon />} label={t('SpecialPrice')} {...a11yProps(2)} />
                </Tabs>
              </Box>
       <TabPanel value={value} index={0}>
            <DataGridPro
                sx={{ backgroundColor: "white", height: "80vh" }}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    value: keywordSearchNoDelay,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setKeywordSearch(e.target.value);
                      setKeywordSearchNoDelay(e.target.value);
                    },
                    clearSearch: () => {
                      setKeywordSearch("");
                      setKeywordSearchNoDelay("");
                    },
                  },
                }}
                getRowId={(row) => row.id}
                rows={approveReducer.result!.filter((x) =>  Number(x.priceLevel!) === 1 ||  Number(x.priceLevel!) === 2)}
                columns={columns}
                // pageSize={10}
                pagination
                rowsPerPageOptions={[10]}
              />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataGridPro
                  sx={{ backgroundColor: "white", height: "80vh" }}
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      value: keywordSearchNoDelay,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setKeywordSearch(e.target.value);
                        setKeywordSearchNoDelay(e.target.value);
                      },
                      clearSearch: () => {
                        setKeywordSearch("");
                        setKeywordSearchNoDelay("");
                      },
                    },
                  }}
                  getRowId={(row) => row.id}
                  rows={approveReducer.result!.filter((x) =>  Number(x.priceLevel!) === 5)}
                  columns={columns}
                  // pageSize={10}
                  pagination
                  rowsPerPageOptions={[10]}
              
                />
        </TabPanel>
        <TabPanel value={value} index={2} >
        <DataGridPro  
                sx={{ backgroundColor: "white", height: "80vh" }}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    value: keywordSearchNoDelay,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setKeywordSearch(e.target.value);
                      setKeywordSearchNoDelay(e.target.value);
                    },
                    clearSearch: () => {
                      setKeywordSearch("");
                      setKeywordSearchNoDelay("");
                    },
                  },
                }}
                getRowId={(row) => row.id}
                rows={approveReducer.result!.filter((x) =>  Number(x.priceLevel!) === 7)}
                columns={columns}
                // pageSize={10}
                pagination
                rowsPerPageOptions={[10]}
             
              />
        </TabPanel>
        </Box>
        <ButtonBackToHome  ></ButtonBackToHome>
      </CardContent>
     
    </Card>
  )
}

