import * as React from "react";
import { DataGridPro, GridColDef,GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Autocomplete, Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, OutlinedInput,  TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import Loader from "../../component/Loader";
import moment from "moment";
import { Formik, FormikProps, Form } from "formik";
import { siteRoleSelector, sitePostDataByRole } from "../../../store/slices/siteRoleSlice";
import { itemsSelector, itemsGetData } from "../../../store/slices/itemsSlice";
import { Actualreport, ActualExportExcel } from "../../../types/actual.type";
import { actualPostDataBySite,actualPostDataSearch,actualPostDataBySiteManaul, actualSelector,clearPricelistScale } from "../../../store/slices/actualSlice";
import { exportExcelItem } from "../../../utils/excel";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import NativeSelect from '@mui/material/NativeSelect';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { LinearProgress } from "@mui/material";

type ReportActualPageProps = {
  //
};

const ReportActualDownloadPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const SiteRoleReducer = useSelector(siteRoleSelector)
  const ItemReducer = useSelector(itemsSelector)
  const ActualReducer = useSelector(actualSelector)
  // const [siteRole, setSiteRole] = React.useState<any>();
  const [priceType, setPriceListType] = React.useState('4');
  const [docstatus, setDocstatus] = React.useState<any>("Get");
  const MySwal = withReactContent(Swal)
  const [checkboxSelection, setCheckboxSelection] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [article, setArticle] = React.useState<string[]>([]);
  const [SiteCode, setSiteCode] = React.useState<string[]>([]);
  const [multiline, setMultiline] = React.useState('');
  const [multilineSite, setMultilineSite] = React.useState('');

  React.useEffect(() => {
    dispatch(sitePostDataByRole(""));
    dispatch(itemsGetData(""));
    // setSiteRole(SiteRoleReducer.result);

  }, []);
  // console.log(siteRole)
  const onloadReport = () => {
    // dispatch(priceListGetTemplateDataById(templateId))
    // setLoadtemplate(false);
    // alert("YES")
    dispatch(clearPricelistScale());
  }


  const handleReprocess = (row: any) => {
    console.log(row)
  };

  const columns: GridColDef[] = [
    {
      field: "siteCode", width: 100,
      renderHeader: () => (<strong> {t('siteCode')} </strong>),
    },
    {
      field: "itemCode", width: 150,
      renderHeader: () => (<strong> {t('itemCode')} </strong>),
    },
    {
      field: "itemName", width: 250,
      renderHeader: () => (<strong> {t('itemName')} </strong>),
    },
    {
      field: "saleUnit", width: 100,
      renderHeader: () => (<strong> {t('saleUnit')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',
      // valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
        renderCell: (cellValues) => {
          var checkDate = new Date(cellValues.value);
          var toDay = new Date();
          if (checkDate.setHours(0, 0, 0, 0) < toDay.setHours(0, 0, 0, 0)){
            return (
              <div style={{color: "red",width: "100%"}}>
                {moment(cellValues?.value).format("DD-MM-YYYY")}
              </div>
            )
          }
          else{
            return (
            <div>
              {moment(cellValues?.value).format("DD-MM-YYYY")}
            </div>
            )
          }

        }
    },
    {
      field: "conditionType", width: 100,
      renderHeader: () => (<strong> {t('conditionType')} </strong>),
    },
    {
      field: "amountInFinalPrice", headerName: "Amount in Final Price", width: 150, editable: true,
      type: 'number', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--header',
      renderCell: (cellValues) => {
            if(Number(cellValues.value) ===0){
              return(
                  <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
              {cellValues.value}
            </div>)
            }else{
              return(
                <div>  
                   {cellValues.value}
                </div>)
            }
          }       
    },
    {
      field: "priceQTY1", headerName: "PQtyB1 (Scale 1)", width: 150,
      type: 'number'
    },
    {
      field: "price1", headerName: "PMPrice1 (Scale 1)", width: 150,
      type: 'number'
    },
    { field: "priceQTY2", headerName: "PQtyB2 (Scale 2)", width: 150, type: 'number' },
    { field: "price2", headerName: "PMPrice2 (Scale 2)", width: 150, type: 'number' },
    { field: "priceQTY3", headerName: "PQtyB3 (Scale 3)", width: 150, type: 'number' },
    { field: "price3", headerName: "PMPrice3 (Scale 3)", width: 150, type: 'number' },
    { field: "priceQTY4", headerName: "PQtyB4 (Scale 4)", width: 150, type: 'number' },
    { field: "price4", headerName: "PMPrice4 (Scale 4)", width: 150, type: 'number' },
    { field: "priceQTY5", headerName: "PQtyB5 (Scale 5)", width: 150, type: 'number' },
    { field: "price5", headerName: "PMPrice5 (Scale 5)", width: 150, type: 'number' ,},
    
      {
        field: "message", headerName: "Message", width: 100,
         renderCell: cellValues => {
          if (cellValues.value === "Success") {
              return <div style={{color: "green",width: "100%"}}> {cellValues.value}</div>;
            }
            return <div style={{color: "red",width: "100%"}}> {cellValues.value}</div>;
        }
        // renderCell: (cellValues) => {
        //   return (
        //     if(cellValues.value ==="Success"){
        //       <div
        //       style={{
        //         color: "red",
        //         width: "100%",
        //       }}
        //     >
        //       {cellValues.value}
        //     </div>
        //     }
            
          
        //   );
        },
    
  ];

  // if (SiteRoleReducer.isFetching) {
  //   return <><Loader /></>
  // }


  const handleChangePriceListType = (event: SelectChangeEvent) => {
    setPriceListType(event.target.value);
  };
  const handleChange = (event: SelectChangeEvent<typeof SiteCode>) => {
    const {
      target: { value },
    } = event;
    setSiteCode(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleChangeArticle = (event:any) => {
    const {
      target: { value },
    } = event;
    setArticle(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const initialValues: Actualreport = {
    siteCode: [],
    siteName: '',
    itemCode: '',
    priceListType:'4',
    active: true
  };
  const handleSave = (docstatus: string) => {
    // do what you want like on submit
    setDocstatus(docstatus);
  }
  //** for dialog article */
  // const handleDialogChange = (event: SelectChangeEvent<typeof age>) => {
  //   setAge(Number(event.target.value) || '');
  // };
  // function handleChangeArticle(event:any){
  //   setArticle(event.currentTarget.value);
  // };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    
  };


  const priceListType = [
    'NormalPrice',
    'ScalePrice',
    'SpecialPrice'];



  const ExportExcel = (excelData: any) => {
    let actualDatas: ActualExportExcel[] = [];

    excelData.forEach((item: any) => {
      let actualData = new ActualExportExcel();
      actualData.siteCode = item.siteCode
      actualData.itemCode = item.itemCode
      actualData.itemName = item.itemName
      actualData.saleUnit = item.saleUnit
      actualData.validFrom = moment(item.validFrom).format("DD-MM-YYYY")
      actualData.validTo = moment(item.validTo).format("DD-MM-YYYY")
      actualData.conditionType = item.conditionType
      actualData.amountInFinalPrice = item.amountInFinalPrice
      actualData.priceQTY1 = item.priceQTY1
      actualData.price1 = item.price1
      actualData.priceQTY2 = item.priceQTY2
      actualData.price2 = item.price2
      actualData.priceQTY3 = item.priceQTY3
      actualData.price3 = item.price3
      actualData.priceQTY4 = item.priceQTY4
      actualData.price4 = item.price4
      actualData.priceQTY5 = item.priceQTY5
      actualData.price5 = item.price5
      actualDatas.push(actualData);
    });

    let Heading = [['Code', 'Material', 'Material description', 'Sale Unit', 'Start Date', 'End Date', 'Condition Type', 'Amount in Final Price', 'PQtyB1 (Scale 1)', 'PMPrice1 (Scale 1)', 'PQtyB2 (Scale 2)', 'PMPrice2 (Scale 2)', 'PQtyB3 (Scale 3)', 'PMPrice3 (Scale 3)', 'PQtyB4 (Scale 4)', 'PMPrice4 (Scale 4)', 'PQtyB5 (Scale 5)', 'PMPrice5 (Scale 5)']];

    exportExcelItem(actualDatas, Heading, 'ActualPriceReport-' + moment().format('YYYY-MM-DD_hh:mm:ss').toString());
  }

  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,

  }: FormikProps<Actualreport>) => {
    return (
      <Form>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              ACTUAL PRICE DOWNLOAD TO POS
            </Typography>
            {/* <Stack spacing={3} sx={{ width: "100%" }}>
                  <Autocomplete
                  multiple
                  id="siteCodelist"
                  options={SiteRoleReducer.result}
                  getOptionLabel={(option) => option.siteName}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // variant="standard"
                      label="Site Code"
                      placeholder="Search"
                    />
              )}
            />
              </Stack> */}
            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="siteCode">{t('siteCode')}</InputLabel>
              <Select
                id="siteCode"
                multiple
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                name="siteCode"
                value={values.siteCode}
                input={<OutlinedInput label="siteCode" />}

                label={t("siteCode")}
                onChange={handleChange}
    
              error={
                errors.siteCode && touched.siteCode
                  ? true
                  : false
              } 
              >
             {SiteRoleReducer.result.map((item,index) => (
                 <MenuItem key={item.id} value={item.siteCode}>
                    <ListItemText primary={item.siteName} />
            </MenuItem>
          ))}

              </Select>
            </FormControl> */}

            <FormControl style={{ marginTop: 16 }} fullWidth>
            <InputLabel id="demo-simple-select-autowidth-label">PriceList Type</InputLabel>
            <Select
            // defaultValue="1"
              labelId="demo-simple-select-autowidth-label"
              id="priceListType"
              value={priceType}
              onChange={handleChangePriceListType}
              // autoWidth
              label="เลือก PriceList"
              error={
                errors.priceListType && touched.priceListType
                  ? true
                  : false
              } 
            ><MenuItem value={4}>All</MenuItem>
              <MenuItem value={1}>Normal Price</MenuItem>
              <MenuItem value={2}>Scale Price</MenuItem>
              <MenuItem value={3}>Special Price</MenuItem>
            </Select>
            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
           
                <InputLabel  htmlFor="uncontrolled-native">
                priceListType
                </InputLabel>
                <NativeSelect
                  defaultValue={10}
                  inputProps={{
                    name: 'priceListType',
                    // id: 'uncontrolled-native',
                  }}
                >
                  <option value={10}>Normal Price</option>
                  <option value={20}>Scale Price</option>
                  <option value={30}>Special Price</option>
                </NativeSelect>
              </FormControl> */}
     </FormControl>

            {/* <TextField
              id="itemCode"
              name="itemCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t("itemCode")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={article}
              helperText={
                errors.itemCode && touched.itemCode
                  ? errors.itemCode
                  : "Enter your itemCode."
              }
            /> */}
          <TextField  style={{ marginTop: 16,marginRight:10 }}
              id="siteCode"
              // value={values.itemCode}
              label="ระบุ SiteCode"
              multiline
              rows={10}
              onChange={e => setMultilineSite(e.target.value)}
            />
          <TextField  style={{ marginTop: 16 }}
            id="itemCode"
            // value={values.itemCode}
            label="ระบุ Article"
            multiline
            rows={10}
            onChange={e => setMultiline(e.target.value)}
          />

<div>
      {/* <Button onClick={handleClickOpen}>Input Multiple Article</Button> */}
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>input condition</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>

            <FormControl sx={{ m: 5, minWidth: 120 ,maxHeight:350 }}>
         
           <TextField 
              id="outlined-multiline-flexible"
              label="Input Article"
              multiline
              minRows="10"
              // onChange={handleChangeArticle}
              value={article}
              inputProps={{
                style: {
                  width:"250px",
                },
              }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
</div>


            <Box sx={{ display: 'flex' }}>
              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="inherit"
                type="submit"
                 onClick={() => handleSave("Get")}
                sx={{ marginRight: 1 }}
                // disabled={SiteRoleReducer.isFetching}
              // onClick={() => onloadReport()}
              > {t('searchbtn')}
              </Button>

              {/* <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="info"
                type="button"
                sx={{ marginRight: 1 }}
                disabled={SiteRoleReducer.isFetching}
                // onClick={onloadtemplate}
                onClick={() => ExportExcel(ActualReducer.result)}
              > {t('exportbtn')}
              </Button> */}

              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="success"
                type="submit"
                sx={{ marginRight: 1 }}
                // disabled={SiteRoleReducer.isFetching}
                onClick={() => handleSave("Download")}
              > {t('downloadbtn')}
              </Button>
             
            </Box>
            {/* <div style={{color: "blue",width: "100%"}}>  
<p>Note : การเลือก Re-process job สามารถกดที่ช่อง POS หรือ SAP หรือ Actual ในรายการที่ต้องการทีละรายการ</p>
</div> */}
            {ActualReducer.isFetching && (
                    <LinearProgress />
                )}
                <br></br>
            <DataGridPro
              disableSelectionOnClick={true}
              sx={{ backgroundColor: "white", height: "80vh" }}
              getRowId={(row) => row.id}
              rows={ActualReducer.result ? ActualReducer!.result! : []}
              // rows={pricelistReducer.result!.filter((x) => x.priceLevel! <= "2")}
              columns={columns}
              // pageSize={50}
              rowsPerPageOptions={[10]}
              pagination
              initialState={{ pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'message','siteCode','itemCode'], right: ['actions'] } }}
            />

          </CardContent>
        </Card>
      </Form>
    );
  }



  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    // SiteCode: Yup.string().required(inputRequired),
    // priceListType: Yup.string().required(inputRequired),
  });

  // if (SiteRoleReducer.isFetching) {
  //   return <><Loader /></>
  // }
  if (ItemReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <>
      <Box>
        <Formik

          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            //  console.log(priceType)


            var getMultiline =  typeof multiline === 'string' ? multiline.split(',') : multiline;
         

            const valuesToSend = {
              ...values,priceListType:priceType,itemCode:multiline,siteCode:multilineSite
            }
// console.log("values.priceListType:"+values.priceListType + " values.site : " + values.siteCode)
// console.log("itemCode:" + multiline)
// console.log("article:" + multiline)
// console.log("siteCode:" + multilineSite)
            if (( multilineSite==='' || multiline==='')) {
              MySwal.fire({
                title: <p>กรุณาระบุเงื่อนไข SiteCode กับ ArticleCode!</p>,
                didOpen: () => {
                  // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                },
              })
            }
            else {
              if(docstatus ==="Get"){
                // console.log("docstatus :"+ docstatus)
                dispatch(actualPostDataSearch(valuesToSend));
                setSubmitting(false);
              
              }  else {
              if(ActualReducer.result!.length ===0){
                  MySwal.fire({
                    title: <p>ไม่มีข้อมูล!</p>,
                    didOpen: () => {
                      // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                    },
                  })
                }
                else {
                Swal.fire({
                  title: 'Confirm!',
                  text: "คุณต้องการส่งราคาไป POS?",
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No',
                  showCancelButton: true,
                }).then((result) => {
                  if (result.isConfirmed) {

                      dispatch(actualPostDataBySiteManaul(valuesToSend));
                      setSubmitting(false);   
                        // Swal.fire('ดำเนินการเรียบร้อย!', '', 'success')            
                      
                    }

                  
                })
              }
              // dispatch(actualPostDataBySite(valuesToSend));
              // setSubmitting(false);
              }  
              // if(ActualReducer.result.length===0 && ActualReducer.message !='Success'){
              //   console.log("result page:", ActualReducer.result.length!)
              //   Swal.fire('ไม่พบข้อมูล!', '', 'success')
              // }
              // else{
              //   //  Swal.fire('ดำเนินการเรียบร้อย!', '', 'success')
              // }   
            } 
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>

    </>
  );
};

export default ReportActualDownloadPage;
