import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Avatar, Box, Card, CardContent, CardHeader, Fab, IconButton, Stack, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Add, Clear, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { businessGetData, businessSelector } from "../../../store/slices/businessSlice";
import Loader from "../../component/Loader";
import { green } from "@mui/material/colors";


type Props = {};


interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}


export default function BusinessPage() {
  const { t } = useTranslation();
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const businessReducer = useSelector(businessSelector);
  const inputSearch = t('inputSearch');

  React.useEffect(() => {
    dispatch(businessGetData(keywordSearch));
  }, [keywordSearch]);


  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0 }}>
        <div style={{ height: '60px' }}></div>

        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/business/create"
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <Add />
        </Fab>
      </Box>
    );
  }

  const columns: GridColDef[] = [
    {
      headerName: "",
      field: ".",
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              navigate("/business/edit/" + row.id);
            }}
          >
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "buCode", width: 200,
      renderHeader: () => (<strong> {t('buCode')} </strong>),
    },
    {
      field: "buName", width: 200,
      renderHeader: () => (<strong> {t('buName')} </strong>),
    },
    {
      field: "conditionType", width: 200,
      renderHeader: () => (<strong> {t('conditionType')} </strong>),
    },
    {
      field: "active", type: "boolean", width: 100,
      renderHeader: () => (<strong> {t('active')} </strong>),
    },
  ];


  if (businessReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          BUSINESS
        </Typography>
        <DataGridPro
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          rows={businessReducer.result}
          columns={columns}
          // pageSize={50}
          pagination
          rowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  );
}
