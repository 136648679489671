export const apiUrl = process.env.REACT_APP_API_HOST!;
export const LicenseInfo = process.env.DATAGRIDPRO_KEY;
export const imageUrl = "http://localhost:8085";
export const TOKEN = "AuthKey";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const server = {
    LOGIN_URL: `authentication/login`,
    APPROVE_URL: `approve`,
    BUSINESS_URL: `business`,
    PRICELEVEL_URL: `pricelevels`,
    PRICELIST_URL: `pricelists`,
    PRICELISTSITE_URL: `pricelistssite`,
    ROLE_URL: `roles`,
    SITEGROUP_URL: `sitegroups`,
    USER_URL: `users`,
    SITE_URL: `sites`,
    LOGIN_PASSED: `yes`,
    TEMPLATE_URL: `templates`,
    MENU_URL: `menu`,
    ROLEMENU_URL: `menu/role`,
    ITEMS_URL: `items`,
    ACTUALPRICE_URL: `actualprice`,
    CUSTOMER_URL: `customers`,
    BRAND_URL:`brands`,
};

// Error Code
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE = "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE = "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MESSAGE = "An error has occurred. The photo was unable to upload.";
export const SALSES_UNIT = [
    {
        unitCode: "KG", unitName: "KG"
    },
]


