import * as React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  FormControl,
  MenuItem,
  Radio,
  InputLabel,
  Alert,
  FormHelperText,
  IconButton,
  Stack,
  FormLabel,
  Grid,
  FormGroup,
  Checkbox,
  OutlinedInput, 
} from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useMatch } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSelector } from "react-redux";
import {
  Pricelist,
  PricelistData,
  PricelistDataExportExcel,
} from "../../../types/pricelist.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import {
  DataGridPro,
  GridColDef,
  GridSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridPinnedColumns,
  GridRowParams,
} from "@mui/x-data-grid-pro";

import {
  PricelistScaleGetTemplate,
  PricelistScaleSelector,
} from "../../../store/slices/priceListScaleSlice";
import {
  pricelistScaleCreateData,
  PricelistScaleActionSelector,
  clearPricelistScale,
  updatePricelistScaleData,
  updatePricelistScaleDataImport,
  removePricelistScaleData,
  PricelistScaleGetDataById,
  pricelistScaleCreateDataImport,
  PricelistScaleGetDataByIdOnly,
  PricelistScaleGetDataByIdCreatePage,
} from "../../../store/slices/priceListScaleActionSlice";
import moment from "moment";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Loader from "../../component/Loader";
import { exportExcelItem } from "../../../utils/excel";
import * as XLSX from "xlsx";
import { readExcelTemplateScale,readExcelTemplateMap } from "../../../utils/excel";
import { siteRoleSelector, sitePostDataByRole } from "../../../store/slices/siteRoleSlice";
import { GridPreProcessEditCellProps } from "@mui/x-data-grid";
import ButtonBack from "../../layouts/ButtonBack";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LinearProgress } from "@mui/material";

type PriceListCreatePageProps = {
  //
};

let initialValues: Pricelist = {
  buCode: "",
  priceDescription: "",
  priceDate: new Date(),
  // ########### Fixlevel ###################
  priceLevel: "5",
  conditionType: "",
  condRecNo: "",
  docRef: "",
  priceListData: [],
  ApproveBy: "",
  validFrom: "" || new Date(),
  validTo: "" || new Date(),
  remark: "",
  documentStatus: "",
  // site:[],
  isPriority:false,
  attachment:[],
};

const PriceListScaleCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pricelistScaleReducer = useSelector(PricelistScaleSelector);
  const pricelistScaleActionReducer = useSelector(PricelistScaleActionSelector);
  const [pageSize, setPageSize] = React.useState(50);
  const [templateId, setTemplateId] = React.useState<any>(null);
  const [selectedRows, setSelectedRows] = React.useState<PricelistData[]>([]);
  const [selectedRowsMsg, setSelectedRowsMsg] = React.useState<PricelistData[]>([]);
  const [docstatus, setDocstatus] = React.useState<any>("");
  const [loadtemplate, setLoadtemplate] = React.useState(true);
  const [selection, setSelection] = React.useState(
    pricelistScaleActionReducer.result.priceListData
  );
  const [validFrom, setvalidFrom] = React.useState<Date>(new Date());
  // const [dateFrom,setDateFrom]=React.useState<Date>(pricelistScaleActionReducer!.result!.priceListData[0]!.validFrom==undefined?new Date():pricelistScaleActionReducer!.result!.priceListData[0]!.validFrom!);
  const [validTo, setvalidTo] = React.useState<Date>(new Date());
  const [disablebutton, setdisablebutton] = React.useState(false);

  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({
    left: [
      GRID_CHECKBOX_SELECTION_COL_DEF.field,
      "priceLevel",
      "itemCode",
      "itemName",
      "saleUnit",
      "siteCode",
      "siteGroup",
    ],
  });
  const [docRef, setDocRef] = React.useState("");
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(pricelistScaleActionReducer.result.priceListData.filter((x) => x.isSelect === true).map((x) => x.id));
  const match = useMatch("/pricelist/edit/:id");
  const [fileName, setFileName] = React.useState(null);
  const [rowsData, setRowsData] = React.useState<PricelistData[]>(
    []
  );
  const [pricelistDatas, setPricelistDatas] = React.useState<PricelistData[]>(
    []
  );
  const SiteRoleReducer = useSelector(siteRoleSelector)
  const [siteRole, setSiteRole] = React.useState<any>();
  // const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>(pricelistScaleActionReducer.result.priceListData.filter((x) => x.isSelect === true).map((x) => x.id));
  const [selectedScaleRows, setSelectedScaleRows] = React.useState<PricelistData[]>([]);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  React.useEffect(() => {
    dispatch(PricelistScaleGetTemplate(""));
    
    // PricelistScaleGetTemplate("");
  }, []);
  // console.log(pricelistActionReducer.result)
  React.useEffect(() => {
     dispatch(clearPricelistScale());
  }, []);

  React.useEffect(() => {
    dispatch(sitePostDataByRole(""));
 }, []);


  // console.log("B",pricelistActionReducer.result)

  const MySwal = withReactContent(Swal);

  const PricelistGetCheckboxDataById = createAsyncThunk(
    "pricelistscale/getPricelistScalebyIDCheck",
    //async (id: string, thunkApi) => {
      async ({ formData }: { formData: Pricelist}, thunkApi) => {
      try {

       const result =  setSelectedScaleRows(formData.priceListData.filter((x) => x.isSelect === true))

        return result;
      } catch (error: any) {
        let err = error.response.data.message ?? error.message
        return thunkApi.rejectWithValue(err);
      }
    }
  );

  const handleCellEditCommit = ({ id, field, value }: any) => {
    //console.log({ id, field, value });
    const newUpdatedRows =
      pricelistScaleActionReducer.result.priceListData!.map((row) => {
        //console.log(field)
        // ########################## Fix  level1 to level5 ###################
        if(typeof value != "number")
        {
        }else{
          // console.log("checkNum: false" + value)
          value = Number(Number(value!).toFixed(2))
       
        }
        if (row.id === id) {
          return { ...row, [field]: value, [row.priceLevel]: "5",message:'' };
        }
        //  console.log("field :"+ field +" values :"+ value)
        return row;
      });
      setdisablebutton(false);
    // setUpdateRow(newUpdatedRows);
    dispatch(updatePricelistScaleData(newUpdatedRows));
  };

  
  const ExportExcel = (excelData: any) => {
    if(excelData.length===0){
      Swal.fire({
        title: 'Alert!',
        text: 'ไม่มีข้อมูล กรุณาเลือก template!!',
        confirmButtonText: 'OK',
        // cancelButtonText: 'No',
        // showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {

          // dispatch(templateGetData(""));
          // Swal.fire('ลบเรียบร้อย!', '', 'success')
        } 
      })
    }
    else{
      let pricelistDatas: PricelistDataExportExcel[] = [];
      excelData.forEach((item: any) => {
        let pricelistData = new PricelistDataExportExcel();
  
        pricelistData.priceLevel = "5";
        pricelistData.itemCode = item.itemCode;
        pricelistData.itemName = item.itemName;
        pricelistData.saleUnit = item.saleUnit;
        pricelistData.siteCode = item.siteCode;
        pricelistData.siteGroup = item.siteGroup;
        pricelistData.amountInFinalPrice =
        item.amountInFinalPrice === null ? 0 : item.amountInFinalPrice;
        pricelistData.priceQTY1 = item.priceQTY1 === null ? 0 : item.priceQTY1;
        pricelistData.price1 = item.price1 === null ? 0 : item.price1;
        pricelistData.priceQTY2 = item.priceQTY2 === null ? 0 : item.priceQTY2;
        pricelistData.price2 = item.price2 === null ? 0 : item.price2;
        pricelistData.priceQTY3 = item.priceQTY3 === null ? 0 : item.priceQTY3;
        pricelistData.price3 = item.price3 === null ? 0 : item.price3;
        pricelistData.priceQTY4 = item.priceQTY4 === null ? 0 : item.priceQTY4;
        pricelistData.price4 = item.price4 === null ? 0 : item.price4;
        pricelistData.priceQTY5 = item.priceQTY5 === null ? 0 : item.priceQTY5;
        pricelistData.price5 = item.price5 === null ? 0 : item.price5;
        pricelistData.docRef = templateId;
        pricelistDatas.push(pricelistData);
      });
  
      // let Heading = [['Price Level', 'Article Code', 'Sales Unit', 'Site', 'Site Group', 'Sales Organization', 'Distribution Channel', 'Price List', 'Vendor', 'Amount in Margin', 'Amount in Final Price', 'PQtyB1 (Scale 1)', 'PMPrice1 (Scale 1)', 'PQtyB2 (Scale 2)', 'PMPrice2 (Scale 2)', 'PQtyB3 (Scale 3)', 'PMPrice3 (Scale 3)', 'PQtyB4 (Scale 4)', 'PMPrice4 (Scale 4)', 'PQtyB5 (Scale 5)', 'PMPrice5 (Scale 5)','DocRef']];
      let Heading = [
        [
          "DocRef",
          "Price Level",
          "Article Code",
          "Article Name",
          "Sales Unit",
          "Site",
          "Site Group",
          "Amount in Final Price",
          "PQtyB1 (Scale 1)",
          "PMPrice1 (Scale 1)",
          "PQtyB2 (Scale 2)",
          "PMPrice2 (Scale 2)",
          "PQtyB3 (Scale 3)",
          "PMPrice3 (Scale 3)",
          "PQtyB4 (Scale 4)",
          "PMPrice4 (Scale 4)",
          "PQtyB5 (Scale 5)",
          "PMPrice5 (Scale 5)",
  
        ],
      ];
  
      exportExcelItem(
        pricelistDatas,
        Heading,
        "PriceList-Scale-" + moment().format("YYYY-MM-DD_hh:mm:ss").toString()
      );
    }

  };

  const CompareDataImport =  (excelData: PricelistData[]) => {
    // dispatch(clearPricelistScale());
    let idocRef!: string;
    let models = JSON.parse(JSON.stringify(excelData)) as PricelistData[];
    models.forEach((item)=>{
      idocRef = item.condRec!;
      setDocRef(idocRef);
    })
    // console.log("docRef:"+idocRef)
    dispatch(PricelistScaleGetDataByIdCreatePage({formData:excelData,id:idocRef,docRef:""}));
    // setvalidFrom(pricelistScaleActionReducer.result.priceListData[0].validFrom!);
    // setvalidTo(pricelistScaleActionReducer.result.priceListData[0].validTo!);
   // dispatch(PricelistGetCheckboxDataById(pricelistScaleActionReducer.result!));
    // setLoadtemplate(false);
    // console.log("docRef2:"+idocRef)
  };

  const columns: GridColDef[] = [
    {
      field: "priceLevel",
      headerName: "Price Level",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              // color: "red",
              width: "100%",
            }}
          >
            {"5"}
          </div>
        );
      },
    },
    {
      field: "itemCode",
      headerName: "Article Code",
      width: 100,
      cellClassName: "super-app-theme--header--pined",
    },
    {
      field: "itemName",
      headerName: "Article Name",
      width: 200,
      cellClassName: "super-app-theme--header--pined",
    },
    {
      field: "saleUnit",
      headerName: "Sales Unit",
      width: 100,
      cellClassName: "super-app-theme--header--pined",
    },
    {
      field: "siteCode",
      headerName: "Site",
      width: 150,
      cellClassName: "super-app-theme--header--pined",
    },
    // {
    //   field: "siteGroup",
    //   headerName: "Site Group",
    //   width: 150,
    //   cellClassName: "super-app-theme--header--pined",
    // },

    {
      field: "amountInFinalPrice",
      headerName: "Amount in Final Price",
      width: 150,
      type: "number",
    },
    {
      field: "priceQTY1",
      headerName: "PQtyB1 (Scale 1)",
      width: 150,
      type: "number",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "price1",
      headerName: "PMPrice1 (Scale 1)",
      width: 150,
      editable: true,
      type: "number",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "priceQTY2",
      headerName: "PQtyB2 (Scale 2)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "price2",
      headerName: "PMPrice2 (Scale 2)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "priceQTY3",
      headerName: "PQtyB3 (Scale 3)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "price3",
      headerName: "PMPrice3 (Scale 3)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "priceQTY4",
      headerName: "PQtyB4 (Scale 4)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "price4",
      headerName: "PMPrice4 (Scale 4)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "priceQTY5",
      headerName: "PQtyB5 (Scale 5)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "price5",
      headerName: "PMPrice5 (Scale 5)",
      width: 150,
      editable: true,
      type: "number",
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--header",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 0;
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "message",
      headerName: "Message",
      width: 450,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "red",
              width: "100%",
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    // { field: "saleOrg", headerName: "Sales Organization", width: 100, },
    // { field: "distributionChannel", headerName: "Distribution Channel", width: 150, },
    // { field: "priceList", headerName: "Price List", width: 150, },
    // { field: "vendor", headerName: "Vendor", width: 150, },
    // {
    //   field: "validFrom", headerName: "Valid From", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY"), editable: true
    // },
    // {
    //   field: "validTo", headerName: "Valid To", width: 150, type: 'date', valueFormatter: params =>
    //     moment(params?.value).format("DD.MM.YYYY"), editable: true
    // },
    // { field: "amountInMargin", headerName: "Amount in Margin", width: 150,  type: 'number', },
  ];

  const handleExcelChange = (e: any) => {
    // e.preventDefault();
    dispatch(clearPricelistScale());
    const files = e.target.files;
    setFileName(files[0].name);

    if (e.target.files) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: rABS ? "binary" : "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: [
            "docRef",
            "priceLevel",
            "itemCode",
            "itemName",
            "saleUnit",
            "siteCode",
            "siteGroup",
            "amountInFinalPrice",
            "priceQTY1",
            "price1",
            "priceQTY2",
            "price2",
            "priceQTY3",
            "price3",
            "priceQTY4",
            "price4",
            "priceQTY5",
            "price5",
          ],
          blankrows: false,
        });
        const templateData = readExcelTemplateMap(jsonData,"5");       
        // setRowsData(templateData);
// console.log("check doc :" + templateData[1].condRec! + " row:" + templateData.length + "chk-Plv2:"+templateData[2].price3!)
        CompareDataImport(templateData);

      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleDeleteConfirm = (e: any) => {
    dispatch(
      removePricelistScaleData(
        pricelistScaleActionReducer.result!.priceListData?.filter(
          (row) => row.id !== e.id
        )!
      )
    );
  };

  const onloadtemplate = () => {
    // if(templateId == null || templateId ==undefined){
    //   Swal.fire({
    //     title: 'Alert!',
    //     text: 'ไม่มีข้อมูล กรุณาเลือก template!!',
    //     confirmButtonText: 'OK',
    //     // cancelButtonText: 'No',
    //     // showCancelButton: true,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
          // dispatch(PricelistScaleGetDataById(templateId));
          dispatch(clearPricelistScale());
          
          if(templateId != null || templateId !=undefined){
            dispatch(PricelistScaleGetDataByIdCreatePage({formData:[],id:templateId,docRef:""}));
            setLoadtemplate(false);
            setDocRef(templateId);

            // dispatch(PricelistScaleGetDataByIdCreatePage({formData:[],id:templateId,docRef:""}));
            // console.log("chkRow:" +pricelistScaleActionReducer.result.priceListData.length)
            // console.log("validFrom :"+pricelistScaleActionReducer.result!.priceListData[0]!.validFrom!)
            // console.log("validTo :"+pricelistScaleActionReducer.result!.priceListData[0]!.validTo!)

           if(pricelistScaleActionReducer!.result!.priceListData!.length>0){
            // setvalidFrom(pricelistScaleActionReducer!.result!.priceListData[0].validFrom!);
            // setvalidTo(pricelistScaleActionReducer!.result!.priceListData[0].validTo!);
           }
          }
         
        // } 
    //   })
    //  }

  };

  const handleSave = (docstatus: any) => {
    // do what you want like on submit
    setDocstatus(docstatus);
  };

  const handleCheckbox = (checked: any) => {
    // do what you want like on submi
    // console.log(checked.target.checked);
    //console.log(checkboxSelection);
    //setSelection()
  };

  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  }: FormikProps<Pricelist>) => {
    return (
      <Form onSubmit={handleSubmit}>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create Price List Scale
            </Typography>
            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="templateName">
                {t("templateName")}
              </InputLabel>
              <Select
                name="templateName"
                onChange={(event) => {
                  if(event.target!.value! != null){
                    setFieldValue("templateName", event.target!.value!);
                    setTemplateId(event.target!.value!);
                  }    

                }}
              >
                {/* pricelevel only 1,2 */}
                {pricelistScaleReducer.result.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.priceDescription}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CardActions>
              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="contained"
                color="warning"
                type="button"
                sx={{ marginRight: 1 }}
                disabled={pricelistScaleActionReducer.isFetching}
                onClick={onloadtemplate}
              >
                Load Template
              </Button>
            </CardActions>
            <h5 style={{ color: "red" }}>***{t("loadtemplate")}</h5>
            <TextField
              id="priceDescription"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              variant="outlined"
              label={t("priceDescription")}
              onChange={e =>{
                handleChange(e)
                setdisablebutton(false)}}
              onBlur={handleBlur}
              // value={values.priceDescription}
              helperText={
                errors.priceDescription && touched.priceDescription
                  ? errors.priceDescription
                  : ""
              }
              error={
                errors.priceDescription && touched.priceDescription
                  ? true
                  : false
              }
                // disabled={loadtemplate}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("validFrom")}
                readOnly
                // value={validFrom!}
                value={pricelistScaleActionReducer.result!.validFrom!}
                inputFormat="DD/MM/YYYY"
                // minDate={new Date().setDate(1)}
                // maxDate={new Date().setDate(365)}
                onChange={(validFrom) => {
                  setFieldValue("validFrom", validFrom);
                  setvalidFrom(validFrom!);
                  setdisablebutton(false);
                }}
                // disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            To
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("validTo")}
                readOnly
                // value={validTo!}
                // value={values.validTo}
                value={pricelistScaleActionReducer.result!.validTo!}
                inputFormat="DD/MM/YYYY"
                // minDate={new Date().setDate(1)}
                // maxDate={new Date().setDate(365)}
                onChange={(validTo) => {
                  setFieldValue("validTo", validTo);
                  setvalidFrom(validTo!);
                  setdisablebutton(false);
                }}
                // disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
            <InputLabel htmlFor="site">{t('select_site')}</InputLabel>
              <Select
                id="site"
                multiple
                MenuProps={MenuProps}
                name="site"
                // value={[]}
                 value={values.site}
                input={<OutlinedInput label="select_site" />}
                // onChange={handlepricelevelChange}
                label={t("select_site")}
                onChange={handleChange}
              // renderValue={values.priceLevel}

              >
                {SiteRoleReducer.result.map((item, index) => (
                  <MenuItem key={item.id} value={item.siteCode}>
                    {item.siteName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <TextField
              id="remark"
              style={{ marginTop: 16, marginBottom: 16 }}
              fullWidth
              multiline
              rows={5}
              inputProps={{ maxLength: 1000 }}
              variant="outlined"
              label={t("remark")}
              onChange={e =>{
                handleChange(e)
                setdisablebutton(false)}}
              onBlur={handleBlur}
              // value={values.remark}
              // disabled={loadtemplate}
              helperText={
                errors.remark && touched.remark
                  ? errors.remark
                  : "Enter your remark."
              }
              error={errors.remark && touched.remark ? true : false}
            />
            <CardActions>
              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="contained"
                color="info"
                type="button"
                sx={{ marginRight: 1 }}
                // disabled={templateReducer.isFetching}
                // onClick={onloadtemplate}
                onClick={(e) =>
                  ExportExcel(pricelistScaleActionReducer.result.priceListData)
                }
              >
                {" "}
                Export Template
              </Button>
              <Button variant="contained" component="label">
                Import Template
                <input
                  hidden
                  type="file"
                  multiple={false}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleExcelChange.bind(this)}
                  onClick={(event: any) => {
                    event.target.value = null;
                
                    //  dispatch(updatePricelistScaleData(pricelistDatas));
                  }}
                />
              </Button><div/>
              <ButtonBack  ></ButtonBack>
            </CardActions>

            {pricelistScaleActionReducer.isFetching && (
                    <LinearProgress />
                )}
                <br></br>

            <DataGridPro
              //  isRowSelectable={(params: GridRowParams) => params.row.message ===""}
                checkboxSelection
              sx={{
                backgroundColor: "white",
                height: "80vh",
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#6dc270",
                },
                // '& .super-app-theme--header--pined': {
                //   backgroundColor: '#fffffc',
                // },
              }}
              // pinnedColumns={pinnedColumns}
              initialState={{ pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field,'priceLevel','itemCode','itemName','saleUnit','siteCode','siteGroup'] } }}
              // initialState={{ pinnedColumns: { left: ['priceLevel', 'itemCode', 'itemName'] } }}
              getRowId={(row) => row.id}
              // rows={rowsData ? rowsData : []}
              rows={
                pricelistScaleActionReducer.result
                  ? pricelistScaleActionReducer!.result!.priceListData
                  : []
              }
              columns={columns}
              rowsPerPageOptions={[10, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize: number) =>
                setPageSize(newPageSize)
              }
              onCellEditCommit={handleCellEditCommit}
              disableSelectionOnClick={true}
              disableColumnSelector={true}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
                const selectedIDs = new Set(ids);
                const selectedRows =
                  pricelistScaleActionReducer.result.priceListData!.filter(
                    (row) => selectedIDs.has(row.id)
                  );

                setSelectedRows(selectedRows);
                setdisablebutton(false);
                // dispatch(selectPricelist(selectedRows));
              }}
              selectionModel={selectionModel}
            />
            <FormControl style={{ marginTop: 16, marginBottom: 16 }} fullWidth>
              {pricelistScaleActionReducer.isError && (
                <Alert severity="error">
                  {pricelistScaleActionReducer.message}
                </Alert>
              )}
            </FormControl>
          </CardContent>

          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="info"
              type="submit"
              // disabled={isSubmitting}
              onClick={() => handleSave({ documentStatus: "Draft" })}
              sx={{ marginRight: 1 }}
              disabled={disablebutton==true}   
              //  disabled={pricelistScaleActionReducer.isFetching}// || (disablebutton==true&&pricelistScaleActionReducer.isError==false)}
            >
              {" "}
              Save Draft
            </Button>

            <Button
              variant="contained"
              fullWidth
              color="success"
              // disabled={isSubmitting}
              type="submit"
              onClick={() =>
                handleSave({ documentStatus: "Waiting for Approve" })
              }      
              disabled={disablebutton==true}     
              // disabled={pricelistScaleActionReducer.isFetching}
                // || (disablebutton==true&&pricelistScaleActionReducer.isError==false)}
            >
              {" "}
              Send for Approve
            </Button>

            <Button
              component={Link}
              to="/pricelistscale"
              variant="outlined"
              fullWidth
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
           
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t("inputRequired");
  const validationSchema = Yup.object().shape({
    priceDescription: Yup.string().required(inputRequired),
    //  site: Yup.string().required(inputRequired),
  });

  if (pricelistScaleActionReducer.isFetching) {
    return (
      <>
        <Loader />
        
      </>
    );
  }
  // if (SiteRoleReducer.isFetching) {
  //   return <><Loader /></>
  // }
  return (
    <>
      <Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          // initialValues={pricelistScaleActionReducer.result ? pricelistScaleActionReducer.result! : initialValues}
          onSubmit={  (values, {setSubmitting}) => {
            // setSubmitting(true);
            initialValues = {
              ...initialValues,
              priceDescription: values.priceDescription,
              validFrom: pricelistScaleActionReducer.result!.validFrom!,//values.validFrom,
              validTo: pricelistScaleActionReducer.result!.validTo!,//values.validTo,
              remark: values.remark,
              site:values.site,
            };
//  console.log("pricelistScaleActionReducer.result!.validFrom! :" + pricelistScaleActionReducer.result!.validFrom!);
// console.log("S_validTo :" + validTo);
            // ############## Fixed login pricelevel5 only########################
             const valuesToSend = {
              ...values,
              priceLevel: "5",
              validFrom: pricelistScaleActionReducer.result!.validFrom!,//values.validFrom,
              validTo: pricelistScaleActionReducer.result!.validTo!,//values.validTo,
              docRef: pricelistScaleActionReducer!.result!.id? pricelistScaleActionReducer!.result!.id:docRef,
              priceListData:
                pricelistScaleActionReducer!.result!.priceListData.map((x) => {
                  return { ...x,amountInMargin:0,
                  validFrom:pricelistScaleActionReducer.result!.validFrom!,
                  validTo:pricelistScaleActionReducer.result!.validTo!};
                }),
              // priceListData:pricelistScaleActionReducer!.result!.priceListData,
              documentStatus: docstatus.documentStatus,
              wfStep: 1,
              wfStepAll: 1,
              // site:pricelistScaleActionReducer!.result!.site,
            };
            //  console.log("checkValue:"+ pricelistScaleActionReducer!.result!.priceListData[0].message)
            let alertText ;
            if (docstatus.documentStatus ==="Draft"){
              alertText = "ยืนยันการบันทึก Draft?"
            }
            else{
              alertText = "ยืนยันการส่ง Approve?"
            }
            // console.log("values:"+ valuesToSend)
            if ((selectedRows.length === 0)) {
              MySwal.fire({
                title: <p>{t('itemselected')}</p>,
                didOpen: () => {
                  // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                },
              })
            }
            else {
                  Swal.fire({
                    title: 'Confirm!',
                    text: alertText,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true,
                  }).then((result) => {
                    if (result.isConfirmed) {             
                      dispatch(pricelistScaleCreateData({
                        formData: valuesToSend,
                        selectList: selectedRows
                  }));
                setSubmitting(false);
                 setdisablebutton(true);
                // Swal.fire('ดำเนินการเรียบร้อย!', '', 'success')
                    } 
                  })
          }}
        }
        >
              {(props: any) => showForm(props)}

        </Formik>
      </Box>
    </>
  );
};

export default PriceListScaleCreatePage;
