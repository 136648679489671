import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";



type LoaderState = {
    loading: boolean;
}

const initialStateValues: LoaderState = {
    loading: false
};


const loaderSlice = createSlice({
    name: "loader",
    initialState: initialStateValues,
    reducers: {
        spinner: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
    },
})


export const { spinner } = loaderSlice.actions;
export const loaderSelector = (store: RootState) => store.loaderReducer;
export default loaderSlice.reducer;
