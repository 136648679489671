import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet ,useNavigate,useMatch } from "react-router-dom";
import { loginSelector } from "../store/slices/loginSlice";
import {useLocation}from "react-router";

const SpecialRoutes = (props: any) => {
    const loginReducer = useSelector(loginSelector);
    const auth = loginReducer.result;
    const prevLocation = useLocation();
    const location = useLocation();
    const navigate = useNavigate();
    const match = useMatch("/approvepricelist2/:id");
  
    let id = match?.params.id;
    let linkpage = "/approve/"+id
    // return auth  ?<Navigate to="/approve" />:<Navigate to="/login" />;

    return auth  ? <Navigate to={linkpage} />:<Navigate to="/login"/>;
 
};

export default SpecialRoutes;