import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Actual, Actualreport,ActualreportManual } from "../../types/actual.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type ActualreportState = {
    result: Actualreport[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: ActualreportState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};




export const actualPostDataBySite = createAsyncThunk(
    "actual/actual",
    async (formData: Actualreport, thunkApi) => {
        try {
            if(formData.siteCode.length===0){
                return thunkApi.rejectWithValue("Please select Site code!!!");
            }
            if(formData.priceListType===""){
                return thunkApi.rejectWithValue("Please select PriceList Type!!!");
            }
            const result = await httpClient.post<Actualreport[]>(server.ACTUALPRICE_URL + "/data", formData);
        

            // console.log("result", result)
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const actualPostDataSearch = createAsyncThunk(
    "actual/actualseach",
    async (formData: Actualreport, thunkApi) => {
        try {
            if(formData.siteCode.length===0){
                return thunkApi.rejectWithValue("Please select Site code!!!");
            }
            if(formData.priceListType===""){
                return thunkApi.rejectWithValue("Please select PriceList Type!!!");
            }
            const result = await httpClient.post<Actualreport[]>(server.ACTUALPRICE_URL + "/dataseach", formData);
        

            //  console.log("result", result.data.length)
            // if(result.data.length===0){
            //     console.log("CheckResult", result.data.length)
            //     return thunkApi.rejectWithValue("ไม่พบข้อมูล!!!");
            // }
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const actualPostDataBySiteManaul = createAsyncThunk(
    "actual/actualmanual",
    async (formData: ActualreportManual, thunkApi) => {
        try {
            if(formData.siteCode.length===0){
                return thunkApi.rejectWithValue("Please select Site code!!!");
            }
            if(formData.priceListType===""){
                return thunkApi.rejectWithValue("Please select PriceList Type!!!");
            }
            console.log("ManualIdoc done")
            const result = await httpClient.post<ActualreportManual[]>(server.ACTUALPRICE_URL + "/ManualIdoc", formData);
        

            // console.log("result", result.data!)
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const actualSlice = createSlice({
    name: "actual",
    initialState: initialStateValues,
    reducers: {
        clearPricelistScale: (state, action: PayloadAction) => {
            state.result = [];
        },

    },
    extraReducers: (builder) => {
        //async
        builder
            .addCase(actualPostDataBySite.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(actualPostDataBySite.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(actualPostDataBySite.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder
            .addCase(actualPostDataSearch.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(actualPostDataSearch.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                // state.message = "Success";
                state.isFetching = false;
                if(action!.payload.data.length>0){
                    state.message = "Success";
                }
                else{
                    state.message = "NoData.";
                }
                state.isError = false;
            })
            .addCase(actualPostDataSearch.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
        builder 
            .addCase(actualPostDataBySiteManaul.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(actualPostDataBySiteManaul.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(actualPostDataBySiteManaul.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { clearPricelistScale} = actualSlice.actions;
export const actualSelector = (store: RootState) => store.actualReducer;
export default actualSlice.reducer;