import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet,useNavigate,redirect  } from "react-router-dom";
import { loginSelector } from "../store/slices/loginSlice";
import { useLocation} from "react-router";
import { history } from "../index";

const ProtectedRoutes = (props: any) => {
    const loginReducer = useSelector(loginSelector);
    const auth = loginReducer.result!;
    const location = useLocation()
    const navigate = useNavigate();
    const state = location.state;

    // console.log("state:"+state?.from)
    //  console.log("auth : "+ auth)
    if(auth===null || auth===undefined){
    //    return <Navigate to={"/login"} />;
    redirect("/login");
    }
    // else{
    //  if (state?.from){
    //    return <Navigate to="/home" replace state={{from: location}}/>
    //    } 
    // }

    return auth ? <Outlet /> : <Navigate to="/login" />;
    // return auth ? <Outlet /> : <Navigate to="/login" replace state={{from: location}}/>;
};

export default ProtectedRoutes;