import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, FormControlLabel, Radio, Alert, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { Customer } from "../../../types/customer.type";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { customerActionSelector, customerCreateData } from "../../../store/slices/customerActionSlice";
import Loader from "../../component/Loader";

type CustomerCreatePageProps = {
  //
};

const initialValues: Customer = { customerCode: "", customerPOSCode: "", customerName: "",siteCode:"", active: true };

const CustomerCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerActionReducer = useSelector(customerActionSelector);

  // const handleRoleChange = (event: SelectChangeEvent) => {
  //   setSelectRole(event.target.value as string);
  // };


  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Customer>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create Customer
            </Typography>

            <TextField id="customerCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('CustomerCode')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.customerCode && touched.customerCode
                  ? errors.customerCode
                  : ''
              }
              error={
                errors.customerCode && touched.customerCode
                  ? true
                  : false
              } />

            <TextField id="customerPOSCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('CustomerPOSCode')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.customerPOSCode && touched.customerPOSCode
                  ? errors.customerPOSCode
                  : ''
              }
              error={
                errors.customerPOSCode && touched.customerPOSCode
                  ? true
                  : false
              } />

            <TextField id="customerName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('customerName')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.customerName && touched.customerName
                  ? errors.customerName
                  : ''
              }
              error={
                errors.customerName && touched.customerName
                  ? true
                  : false
              } />

              <TextField id="siteCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('siteCode')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.siteCode && touched.siteCode
                  ? errors.siteCode
                  : ''
              }
              error={
                errors.siteCode && touched.siteCode
                  ? true
                  : false
              } />



            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {customerActionReducer.isError && <Alert severity="error">{customerActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={customerActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/customer" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    customerCode: Yup.string().required(inputRequired),
    customerPOSCode: Yup.string().required(inputRequired),
    customerName: Yup.string().required(inputRequired),
    siteCode: Yup.string().required(inputRequired),
  });


  if (customerActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          // alert(JSON.stringify(values));
          // let formData = new FormData();
          // formData.append("customerCode", values.customerCode);
          // formData.append("customerPOSCode", values.customerPOSCode);
          // formData.append("buCode", values.buCode);
          // formData.append("customerName", values.customerName);
          // formData.append("roleId", values.roleId!);
          // formData.append("active", String(values.active));
          // formData.append("createdBy", values.customerCode);

          dispatch(customerCreateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default CustomerCreatePage;
