import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
// import { Box, Card, CardContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Box, Card, CardContent, Chip, FormControl, InputLabel, MenuItem, OutlinedInput,  TextField, IconButton,Button, Stack, Typography } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { ApproveHistoryGetData, ApproveHistoryGetDataByPriceLevel,ApproveHistorySelector } from "../../../store/slices/approveHistorySlice";
import Loader from "../../component/Loader";
import moment from "moment";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonBack from "../../layouts/ButtonBack";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewListIcon from '@mui/icons-material/ViewList';
import InfoIcon from '@mui/icons-material/Info';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ButtonBackToHome from "../../layouts/ButtonBackToHome";
import { Formik, FormikProps, Form } from "formik";
import { ApproveHistory, WorkFlowHistory } from "../../../types/approve.type";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from "yup";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type ApproveHistoryPageProps = {
  //
};

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

const ApproveHistoryPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const approveHistoryReducer = useSelector(ApproveHistorySelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const inputSearch = t('inputSearch');
  const [value, setValue] = React.useState(0);
  const MySwal = withReactContent(Swal)
  const [priceType, setPriceListType] = React.useState('4');

  React.useEffect(() => {
    // dispatch(ApproveHistoryGetDataByPriceLevel({
    dispatch(ApproveHistoryGetDataByPriceLevel({
      keyword: keywordSearch,
      type: 0
}));
  }, [keywordSearch])

  const handleReprocess = (row: any) => {
    console.log(row)
  };
  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }
  
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3, pl: 0, pr: 0 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
//     dispatch(ApproveHistoryGetDataByPriceLevel({
//       keyword: keywordSearch,
//       type: newValue
// }));
  
  };

  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0, }}>
      
      </Box>
    );
  }
  const initialValues: ApproveHistory = {
    // id?: string;
    priceDescription: '',
    remark: '',
    priceDate: new Date(),
    priceLevel: '',
    validFrom: new Date(),
    validTo: new Date(),
    createdBy: '',
    documentStatus: '',   
  };

  const columns: GridColDef[] = [
    {
      headerName: "",
      field: ".",
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              if(Number(row.priceLevel)===7){
                navigate("/approvehistorylistspecial/" + row.id);
              }else{
                navigate("/approvehistorylist/" + row.id);
              }
            
            }}
          >
            <VisibilityIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "priceDescription", width: 350,headerAlign: 'center',
      renderHeader: () => (<strong> {t('priceDescription')} </strong>),
    },
    {
      field: "priceDate", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "validFrom", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => params?.value=='-'|| params?.value == null || params?.value == undefined? '-':moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validFrom')} </strong>),
    },
    {
      field: "validTo", headerName: "Valid To", width: 100, type: 'date',headerAlign: 'center',align: 'center',
      valueFormatter: params => params?.value=='-'|| params?.value == null || params?.value == undefined? '-':moment(params?.value).format("DD-MM-YYYY"),
      renderHeader: () => (<strong> {t('validTo')} </strong>),
    },
    // {
    //   field: "conditionType", width: 80,headerAlign: 'center',align: 'center',
    //   renderHeader: () => (<strong> {t('conditionType')} </strong>),
    // },
    {
      field: "remark", width: 350,headerAlign: 'center',
      renderHeader: () => (<strong> {t('remark')} </strong>),
    },
    {
      field: "createdBy", width: 100,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong> {t('createdBy')} </strong>),
    },
    {
      field: "documentStatus", width: 100,headerAlign: 'center',align: 'center',
      renderHeader: () => (<strong> {t('documentStatus')} </strong>),
    },

    // {
    //   field: "sendToSap", width: 150,headerAlign: 'center',
    //   renderHeader: () => (<strong> {t('sendToSap')} </strong>),
    // },  
    // {
    //   headerName: "POS",
    //   field: "sendToPos",
    //   headerAlign: 'center',
    //   type: 'boolean',
    //   width: 100,
    //   renderCell: ({ row }: GridRenderCellParams<string>) => (
    //     <Stack direction="row">
    //       <IconButton           
    //         aria-label="edit"
    //         size="large"
    //         sx={{
    //           ml: 1,
    //           "&.MuiButtonBase-root:hover": {
    //             bgcolor: "transparent"
    //           },
    //           cursor: 'default'
    //         }}
    //         // onClick={() => {
    //         //   handleReprocess(row);
    //         // }}
    //       >
    //         {row.sendToPos ?
    //           <CheckCircleIcon fontSize="inherit" style={{ color: "green", cursor: 'default' }}></CheckCircleIcon> :
    //           <CancelIcon fontSize="inherit" style={{ color: "red", cursor: 'default' }} />
    //         }
    //       </IconButton>
    //     </Stack>
    //   ),
    // }, 
    {
      headerName: "POS",
      field: "sendToPos",
      type: 'string',
      width: 100,headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToPos == "Queue" ? <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
          : row.sendToPos == "Successed" ? <Chip className="w-chip" label={row.sendToPos} color="success"></Chip>
            : row.sendToPos == "Processing" ? <Chip className="w-chip" label={row.sendToPos} color="info"></Chip>
              : row.sendToPos == "Failed" ? <Chip className="w-chip" label={row.sendToPos} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToPos} color="default"></Chip>
      ),
    },
    {
      headerName: "SAP",
      field: "sendToSap",
      type: 'boolean',
      width: 100,headerAlign: 'center',
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        row.sendToSap == "Queue" ? <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
          : row.sendToSap == "Successed" ? <Chip className="w-chip" label={row.sendToSap} color="success"></Chip>
            : row.sendToSap == "Processing" ? <Chip className="w-chip" label={row.sendToSap} color="info"></Chip>
              : row.sendToSap == "Failed" ? <Chip className="w-chip" label={row.sendToSap} color="error"></Chip>
                : <Chip className="w-chip" label={row.sendToSap} color="default"></Chip>
      ),
    },  
  ];
  const handleChangePriceListType = (event: SelectChangeEvent) => {
    setPriceListType(event.target.value);
  };



  const showForm = ({
    values,
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    handleChange,
    handleBlur,

  }: FormikProps<ApproveHistory>) => {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          APPROVE HISTORY
        </Typography>


            {/* <FormControl style={{ marginTop: 16, marginBottom: 16 }} fullWidth>
            <InputLabel id="demo-simple-select-autowidth-label">PriceList Type</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="priceListType"
              value={priceType}
              onChange={handleChangePriceListType}
              // autoWidth
              label="เลือก PriceList"
              error={
                errors.priceLevel && touched.priceLevel
                  ? true
                  : false
              } 
            ><MenuItem value={4}>All</MenuItem>
              <MenuItem value={1}>Normal Price</MenuItem>
              <MenuItem value={2}>Scale Price</MenuItem>
              <MenuItem value={3}>Special Price</MenuItem>
            </Select>

     </FormControl>
     <LocalizationProvider  dateAdapter={AdapterDayjs} >
              <DatePicker
                label={t('priceDate')}
                value={values.priceDate}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(0)}
                maxDate={new Date().setDate(365)}
                onChange={(priceDate) => {
                  setFieldValue('priceDate', priceDate);
                }}
                disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>     ถึง
       <LocalizationProvider  dateAdapter={AdapterDayjs} >
              <DatePicker
                label={t('priceDate')}
                value={values.priceDateTo}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(0)}
                maxDate={new Date().setDate(365)}
                onChange={(priceDateTo) => {
                  setFieldValue('priceDateTo', priceDateTo);
                }}
                disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>   */}
     {/* <LocalizationProvider style={{ marginTop: 16, marginBottom: 16 }} dateAdapter={AdapterDayjs} >
              <DatePicker
                label={t('validFrom')}
                value={values.validTo}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(0)}
                maxDate={new Date().setDate(365)}
                onChange={(validFrom) => {
                  setFieldValue('validFrom', validFrom);
                }}
                disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider> to 
     <LocalizationProvider style={{ marginTop: 16, marginBottom: 16 }} dateAdapter={AdapterDayjs} >
              <DatePicker
                label={t('validTo')}
                value={values.validTo}
                inputFormat="DD/MM/YYYY"
                minDate={new Date().setDate(0)}
                maxDate={new Date().setDate(365)}
                onChange={(validTo) => {
                  setFieldValue('validTo', validTo);
                }}
                disablePast={true}
                // disabled={loadtemplate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider> */}
            


            {/* <Box sx={{ display: 'flex' }}>
              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="outlined" color="inherit"
                type="submit"
                sx={{ marginRight: 1 }}
                // disabled={SiteRoleReducer.isFetching}
              // onClick={() => onloadReport()}
              > {t('searchbtn')}
              </Button>


            </Box> */}


        <div></div>
        <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider'  }}>
                <Tabs  value={value} onChange={handleChangeTab} aria-label="Approve History" variant="fullWidth">
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<ViewListIcon />} label={t('NormalPrice')} {...a11yProps(0)} />
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }}icon={<AttachMoneyIcon />} label={t('ScalePrice')}{...a11yProps(1)} />
                  <Tab sx={{  fontSize: 18, fontWeight: 'medium' }} icon={<CurrencyExchangeIcon />} label={t('SpecialPrice')} {...a11yProps(2)} />
                </Tabs>
              </Box>
       <TabPanel value={value} index={0}>
        <DataGridPro
          // checkboxSelection={true}
          disableSelectionOnClick={true}
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          // rows={templateReducer.result!}
          rows={approveHistoryReducer.result!.filter((x) =>  Number(x.priceLevel!) === 1|| Number(x.priceLevel!) === 2)}
          columns={columns}
          // pageSize={50}
          pagination
          rowsPerPageOptions={[10]}
        />
                </TabPanel>
       <TabPanel value={value} index={1}>
         <DataGridPro
          // checkboxSelection={true}
          disableSelectionOnClick={true}
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          // rows={templateReducer.result!}
          rows={approveHistoryReducer.result!.filter((x) =>  Number(x.priceLevel!) === 5)}
          columns={columns}
          // pageSize={50}
          pagination
          rowsPerPageOptions={[10]}
        />
                </TabPanel>
                <TabPanel value={value} index={2}>
                <DataGridPro
          // checkboxSelection={true}
          disableSelectionOnClick={true}
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          // rows={templateReducer.result!}
          rows={approveHistoryReducer.result!.filter((x) =>  Number(x.priceLevel!) === 7)}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[10]}
        />
                </TabPanel>   
         </Box>

        <ButtonBackToHome  ></ButtonBackToHome>
      </CardContent>
    </Card>
  )};

  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    // SiteCode: Yup.string().required(inputRequired),
    // priceListType: Yup.string().required(inputRequired),
  });

  if (approveHistoryReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <>
      <Box>
        <Formik

          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            //  console.log(priceType)
            const valuesToSend = {
              ...values,priceListType:priceType,
            }
// console.log("values.priceListType:"+values.priceListType + " values.site : " + values.siteCode)
            if (( values.priceLevel !=="" )) {
              MySwal.fire({
                title: <p>Please select Site and PriceList Type!!!</p>,
                didOpen: () => {
                  // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                },
              })
            }
            else {
            // dispatch(actualPostDataBySite(valuesToSend));
            // setSubmitting(false);
            dispatch(ApproveHistoryGetDataByPriceLevel({
              keyword: keywordSearch,
              type: 0
            }));
            setSubmitting(false);
            }
          }}
        >
          {(props: any) => showForm(props)}
        </Formik>
      </Box>
    </>
  );


};

export default ApproveHistoryPage;
