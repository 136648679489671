import { TOKEN } from "../Constants"

const COOKIE_NAME = 'btg-pos-wfp'

export function getCookieDomain() {
    const domain = window.location.hostname
    let cookieDomain = domain
    if (domain.split('.').length > 1) { // if the host has subdomain eg: auth3.zykrr.com
        cookieDomain = domain.split('.').slice(1).join('.') // set cookieDomain to one level up eg: zykrr.com
    }
    return cookieDomain
}

export function setCookie(value: string, date?: Date) {
    // let expires = ''
    // if (date) {
    //     expires = `expires=${date.toUTCString()};`
    // }

    // const domain = getCookieDomain()

    // let secureCookie = ''
    // if (domain !== 'localhost') {
    //     secureCookie = 'Secure;'
    // }

    // console.log(value);
    localStorage.setItem(TOKEN, value);
    // document.cookie = `${COOKIE_NAME}=${value}; ${expires} domain=${domain}; path=/; ${secureCookie} SameSite=Lax;`
}

export function getCookie() {
    const nameEQ = `${COOKIE_NAME}=`
    const ca = document.cookie.split(';')
    for (let c of ca) {
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

export function eraseCookie() {
    localStorage.removeItem(TOKEN);
    document.cookie = `${COOKIE_NAME}=; domain=${getCookieDomain()}; Max-Age=-99999999;`
}