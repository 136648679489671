import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const defaultLanguage = 'en'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: defaultLanguage,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    template: 'Template',
                    loginWithBetagroAccount: 'Login With Betagro Account',
                    username: 'Username',
                    password:'Password',
                    fullname: 'Fullname',
                    generatepassword:'Generate password',
                    email: 'E-mail',
                    emailCC: 'E-mail CC',
                    emailCC_ex: 'E-mail CC input more than 1 person use comma (xxx@btg.com,bbb@btg.com)',
                    selectBu: 'BU',
                    selectRole: 'Role',
                    active: 'Active',
                    inactive: 'InActive',
                    buCode: 'Business-Code',
                    buName: 'Business-Name',
                    buCodeValidate: 'Enter your BU-Code',
                    siteGroupId: 'Site Group',
                    siteGroupCode: 'Site Group Code',
                    siteGroupName: 'Site Group Name',
                    siteCode: 'Site Code',
                    siteName: 'Site Name',
                    roleName: 'Role Name',
                    menu: 'Menu',
                    select_bucode: 'Select Bucode',
                    select_PriceLevel: 'Price Level',
                    templateName: 'Template Name',
                    priceDate: 'Create Date',
                    priceLevel: 'Price Level',
                    pricelevelId: 'Code',
                    priceGroup: 'Site Group',
                    Description: 'Description',
                    select_Menu: 'Select Menu',
                    priceDescription: 'Price Description',
                    remark: 'Remark',
                    validTo: 'End Date',
                    validFrom: 'Start Date',
                    documentStatus: 'Status',
                    conditionType: 'Condition Type',
                    priceLevelId: 'Code',
                    priceLevelDesc: 'Price Level',
                    wfStep: 'Workflow-Step',
                    inputRequired: 'Please enter your data',
                    btnCreate: 'Create',
                    btnEdit: 'Edit',
                    btnCancel: 'Cancel',
                    inputSearch: 'Serach...',
                    searchbtn:'Search',
                    createdBy: 'Create By',
                    sendToPos: 'POS',
                    sendToSap: 'SAP',
                    sendToWFP: 'Actual',
                    comment: 'Comment',
                    itemCode:'Article Code',
                    itemName:'Article description',
                    uom:'Unit',
                    brandId:'brand',
                    brandDesc:'brandDesc',
                    ean11:'EAN/UPC',
                    taxType:'TaxCl',
                    itemcurcy:"THB",
                    select_roleType:'Select Role Type',
                    select_admin:'ADMIN',
                    select_marketing:'MARKETING',
                    select_branch:'BRANCH',
                    select_site:'select_site',
                    edititem:'Edit ITEMS',
                    matkl:'Material Group',
                    aenkz:'Change indicator',
                    curcy:'Currency',
                    kwdht:'Price condition',
                    kwert:'Price condition',
                    hpean:'hpean',
                    itemselected:'Please Selected Item',
                    loadtemplate:'Please loaded your template',
                    importbtn:'Import File',
                    exportbtn:'Export File',
                    downloadbtn:'Download to POS',
                    backbtn:'Back',
                    saleUnit:'saleUnit',
                    select_sitecode:'select_sitecode',
                    createtemplate:'Please choose excel file',
                    PriceList_Preview:'PriceList Preview',
                    PriceList:'Price List',
                    LogEror:'Log Monitor',
                    CustomerName: "Customer Name",
                    CustomerCode: "Customer Code",
                    CustomerPOSCode: "POS Code",
                    AlertExportExcel: "No Data. Please select template!!",
                    NormalPrice:"NORMAL PRICE",
                    ScalePrice:"SCALE PRICE",
                    SpecialPrice:"SPECIAL PRICE",
                    StatusDraft:"New / Draft",
                    StatusWaitForApprove:"Wait for Approve",
                    StatusApproved:"Approved",
                    All:"All",
                    GridPreview:"Preview",
                    GridEdit:"Edit",
                    GridDelete:"Delete",
                    Approver:"Approver",
                    approveLevel:"Approver Level",
                    scagr:"Mark Weight" ,
                    isPriority:"Urgent fix within today?"   
                }
            },
            th: {
                translation: {
                    template: 'Template',
                    loginWithBetagroAccount: 'เข้าใช้งานโดย Betagro Account',
                    username: 'ชื่อผู้ใช้งาาน',
                    password: 'รหัสผ่าน',
                    fullname: 'ชื่อ-นามสกุล',
                    generatepassword:'เปลี่ยนรหัสผ่าน',
                    email: 'อีเมล์',
                    emailCC: 'อีเมล์ CC',
                    emailCC_ex: 'อีเมล์ CC หากมีมากกว่า 1 ให้ใส่ comma เช่น xxx@btg.com,bbb@btg.com',
                    selectBu: 'ธุรกิจ',
                    selectRole: 'สิทธิ',
                    active: 'ใช้งาน',
                    inactive: 'ยกเลิกใช้งาน',
                    buCode: 'รหัสสายธุรกิจ',
                    buName: 'ชื่อสายธุรกิจ',
                    buCodeValidate: 'กรุณาป้อนข้อมูล',
                    siteGroupId: 'กลุ่ม',
                    siteGroupCode: 'กลุ่มรหัส',
                    siteGroupName: 'กลุ่มชื่อ',
                    siteCode: 'site Code',
                    siteName: 'site Name',
                    roleName: 'ชื่อสิทธิ',
                    menu: 'เมนู',
                    select_bucode: 'เลือก BU',
                    select_PriceLevel: 'เลือก Price Level',
                    templateName: 'ชื่อ Template',
                    priceDate: 'วันที่สร้าง',
                    priceLevel: 'Price Level',
                    pricelevelId: 'รหัส',
                    select_Menu: 'เลือกเมนู',
                    priceDescription: 'ชื่อเรื่อง',
                    remark: 'รายละเอียด',
                    validTo: 'วันที่สิ้นสุด',
                    validFrom: 'วันที่เริ่ม',
                    documentStatus: 'สถานะ',
                    conditionType: 'เงื่อนไข',
                    priceLevelId: 'PriceLevel Id',
                    priceLevelDesc: 'ชื่อ',
                    priceGroup: 'Site Group',
                    Description: 'รายละเอียด',
                    wfStep: 'ขั้นตอนอนุมัติ',
                    inputRequired: 'กรุณาป้อนข้อมูล',
                    btnCreate: 'บันทึก',
                    btnEdit: 'แก้ไข',
                    btnCancel: 'ยกเลิก',
                    inputSearch: 'ค้นหา...',
                    searchbtn:'ค้นหา',
                    createdBy: 'สร้างโดย',
                    sendToPos: 'POS',
                    sendToSap: 'SAP',
                    sendToWFP: 'Actual',
                    comment: 'Comment',
                    itemCode:'รหัสสินค้า',
                    itemName:'ชื่อสินค้า',
                    uom:'Sales unit',
                    brandId:'brand',
                    brandDesc:'brandDesc',
                    ean11:'EAN/UPC',
                    taxType:'TaxCl',
                    itemcurcy:"THB",
                    select_roleType:'เลือกสิทธิ์',
                    select_admin:'สิทธิ์ Admin',
                    select_marketing:'สิทธิ์ Marketing',
                    select_branch:'สิทธิ์ Branch',
                    select_site:'เลือก Site',
                    edititem:'แก้ไขข้อมูลสินค้า',
                     matkl:'Material Group',
                    aenkz:'Change indicator',
                    curcy:'Currency',
                    kwdht:'Price condition',
                    kwert:'Price condition',
                    hpean:'hpean',
                    itemselected:'กรุณาเลือกสินค้า',
                    loadtemplate:'กรุณาเลือก Template',
                    importbtn:'อัพโหลด File',
                    exportbtn:'โหลดเป็น File',
                    downloadbtn:'ส่งราคาไป POS',
                    backbtn:'กลับ',
                    saleUnit:'saleUnit',
                    select_sitecode:'select_sitecode',
                    createtemplate:'กรุณาเลือกไฟล์ Excel',
                    PriceList_Preview:'PriceList Preview',
                    PriceList:'Price List!',
                    LogEror:'Log Monitor',
                    CustomerName: "ชื่อลูกค้า",
                    CustomerCode: "รหัสลูกค้า",
                    CustomerPOSCode: "รหัส POS",
                    AlertExportExcel: "ไม่มีข้อมูล กรุณาเลือก template!!",
                    NormalPrice:"ราคาทั่วไป",
                    ScalePrice:"ราคา Scale",
                    SpecialPrice:"ราคาพิเศษ",
                    StatusDraft:"สร้างใหม่ / แบบร่าง",
                    StatusWaitForApprove:"รออนุมัติ",
                    StatusApproved:"อนุมัติแล้ว",
                    All:"ทั้งหมด",
                    GridPreview:"แสดงข้อมูล",
                    GridEdit:"แก้ไขข้อมูล",
                    GridDelete:"ลบเอกสาร",
                    Approver:"ผู้อนุมัติ",
                    approveLevel:"ลำดับขั้นผู้อนุมัติ",
                    scagr:"ขายชั่งน้ำหนัก",
                    isPriority:"ปรับราคาเร่งด่วนมีผลภายในวันนี้?"
                }
            }
        }
    });

export default i18n;