import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { SiteGroup } from "../../types/sitegroup.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";

type SiteGroupEditState = {
    result: SiteGroup | null;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: SiteGroupEditState = {
    result: null,
    message: "",
    isFetching: false,
    isError: false,
};


export const siteGroupGetDataById = createAsyncThunk(
    "siteGroup/getSiteGroup",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<SiteGroup>(`${server.SITEGROUP_URL}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const siteGroupCreateData = createAsyncThunk(
    "siteGroup/postSiteGroup",
    async (formData: SiteGroup, thunkApi) => {
        try {
            const result = await httpClient.post(server.SITEGROUP_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const siteGroupUpdateData = createAsyncThunk(
    "siteGroup/putSiteGroup",
    async (formData: SiteGroup, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.SITEGROUP_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const siteGroupActionSlice = createSlice({
    name: "siteGroupedit",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(siteGroupGetDataById.pending, (state, action) => {
                state.result = null;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(siteGroupGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(siteGroupGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(siteGroupCreateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(siteGroupCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(siteGroupCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(siteGroupUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(siteGroupUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(siteGroupUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const { } = siteGroupActionSlice.actions;
export const siteGroupActionSelector = (store: RootState) => store.siteGroupActionReducer;
export default siteGroupActionSlice.reducer;