import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Template } from "../../types/template.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type TemplateState = {
    result: Template[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: TemplateState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const templateGetData = createAsyncThunk(
    "template/getTemplates",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.get<Template[]>(server.TEMPLATE_URL + "?keyword=" + keyword);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const templateGetList = createAsyncThunk(
    "template/getTemplateList",
    async (group: string, thunkApi) => {
        try {
            const result = await httpClient.get<Template[]>(server.TEMPLATE_URL + "/data/list/?group=" + group);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const templateSlice = createSlice({
    name: "template",
    initialState: initialStateValues,
    reducers: {
        reloadTemplateData: (state, action: PayloadAction<Template[]>) => {
            state.result = action.payload!;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(templateGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(templateGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(templateGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(templateGetList.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(templateGetList.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(templateGetList.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})


export const {reloadTemplateData } = templateSlice.actions;
export const templateSelector = (store: RootState) => store.templateReducer;
export default templateSlice.reducer;