import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Fab, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { templateGetData, templateSelector,reloadTemplateData } from "../../../store/slices/templateSlice";
import { templateDeleteData } from "../../../store/slices/templateActionSlice";
import moment from "moment";
import Loader from "../../component/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpClient } from "../../../utils/httpclient";
import { server } from "../../../Constants";
import { Template } from "../../../types/template.type";

type Props = {}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}


export default function TemplatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const templateReducer = useSelector(templateSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const inputSearch = t('inputSearch');


  React.useEffect(() => {
    dispatch(templateGetData(keywordSearch));
  }, [keywordSearch])

  const MySwal = withReactContent(Swal)
  const handleDeleteConfirm = (e: any) => {
    // setRowsData(rowsData.filter((row) => row.id !== e.id));
    Swal.fire({
      title: 'คุณต้องการลบ Template :   ',
      text: e.templateName! + ' ?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(templateDeleteData(e.id!));
        // dispatch(templateGetData(""));
        Swal.fire('ลบเรียบร้อย!', '', 'success')
        // dispatch(reloadTemplateData(templateReducer.result!));
        const newUpdatedRows = templateReducer.result!.filter((row) => row.id !== e.id!)
        // setUpdateRow(newUpdatedRows);
        dispatch(reloadTemplateData(newUpdatedRows));
      } 
    })
  
  };

  const handleCellEditCommit = ({ id, field, value }: any) => {
    //console.log({ id, field, value });
    const newUpdatedRows = templateReducer.result!.filter((row) => row.id !== id)
    // setUpdateRow(newUpdatedRows);
    dispatch(reloadTemplateData(newUpdatedRows));
  };
  const TemplateGetCheckboxDataById = createAsyncThunk(
    "template/getTemplatebyIDCheck",
      async (id: string, thunkApi) => {
        try {
          const result = await httpClient.get<Template>(`${server.TEMPLATE_URL}/${id!}`);

        return result;
      } catch (error: any) {
        let err = error.response.data.message ?? error.message
        return thunkApi.rejectWithValue(err);
      }
    }
  );
  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0, }}>
        <div style={{height:'60px'}}></div>

        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/template/create"
          sx={{ position: "absolute", top: 10, right: 10, }}>
          <Add />
        </Fab>
      </Box>
    );
  }

  const columns: GridColDef[] = [
    {
      headerName: "Delete",
      field: "Delete",
      width: 80,
      renderHeader: () => (<strong> {t('GridDelete')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="Delete"
            size="large"
            onClick={() => {
              handleDeleteConfirm(row);
            }}
          >
            <DeleteIcon fontSize="inherit" style={{ color: "#f00c50" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      headerName: "Edit",
      field: ".",
      width: 80,
      renderHeader: () => (<strong> {t('GridEdit')} </strong>),
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              navigate("/template/edit/" + row.id);
            }}
          >
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "templateName", width: 300,
      renderHeader: () => (<strong> {t('templateName')} </strong>),
    },
    {
      field: "priceDate", width: 200,
      renderCell: params => moment(params.row.priceDate).format('DD-MM-YYYY'),
      renderHeader: () => (<strong> {t('priceDate')} </strong>),
    },
    {
      field: "priceLevel", width: 100,
      renderHeader: () => (<strong> {t('priceLevel')} </strong>),
    },
    {
      field: "active", type: "boolean", width: 100,
      renderHeader: () => (<strong> {t('active')} </strong>),
    },
  ];

  if (templateReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          TEMPLATE
        </Typography>
        <DataGridPro
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          rows={templateReducer.result!}
          columns={columns}
          // pageSize={50}
          pagination
          rowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  )
}

