import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { User } from "../../types/user.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";
import { history } from "../../index";

type UserActionState = {
    result: User | null;
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: UserActionState = {
    result: null,
    message: "",
    isFetching: false,
    isError: false,
};


export const userGetDataById = createAsyncThunk(
    "user/getUser",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<User>(`${server.USER_URL}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const userCreateData = createAsyncThunk(
    "user/postUser",
    async (formData: User, thunkApi) => {
        try {
            const result = await httpClient.post(server.USER_URL, formData)
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const userUpdateData = createAsyncThunk(
    "user/putUser",
    async (formData: User, thunkApi) => {
        try {
            const result = await httpClient.put(`${server.USER_URL}/${formData.id}`, formData);
            return result.data;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const userActionSlice = createSlice({
    name: "useraction",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userGetDataById.pending, (state, action) => {
                state.result = null;
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(userGetDataById.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(userGetDataById.rejected, (state, action: PayloadAction<any>) => {
                state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(userCreateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(userCreateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
                history.back();
            })
            .addCase(userCreateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });


        builder
            .addCase(userUpdateData.pending, (state, action) => {
                // state.result = null;
                state.message = "Processing...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(userUpdateData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;

                history.back();
            })
            .addCase(userUpdateData.rejected, (state, action: PayloadAction<any>) => {
                // state.result = null;
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = userActionSlice.actions;
export const userActionSelector = (store: RootState) => store.userActionReducer;
export default userActionSlice.reducer;