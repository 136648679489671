import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Approve, WorkFlowHistory } from "../../types/approve.type";
import {PricelistSite,PricelistData,PricelistSiteQuery,PriceListSiteQueryList }from "../../types/pricelistsite.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";

type MonitorJobHistoryState = {
    result: PricelistSite[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: MonitorJobHistoryState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};

export const MonitorJobHistoryGetData = createAsyncThunk(
    "monitorJobHistory/getMonitorJobHistory",
    // async ({keyword,type},{keyword: string,type:Number}, thunkApi) => {
    async ({ keyword,type}: {keyword: string,type:Number}, thunkApi) => {
        try {

            const result = await httpClient.get<PricelistSite[]>(server.PRICELISTSITE_URL + "?keyword=" + keyword);
            // if (type ==0){
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==1 || Number(x.priceLevel)==2)
            //     result.data = selectData
            // }else if(type==1){
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==5 )  
            //     result.data = selectData
            // }else{
            //     const  selectData = result.data.filter((x)=>Number(x.priceLevel!)==7 )
            //     result.data = selectData
            // }

            return result;
        } catch (error: any) {

            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const MonitorJobHistoryGetList = createAsyncThunk(
    "monitorJobHistory/getMonitorlist",
    async ({formData}:{formData: PriceListSiteQueryList}, thunkApi) => {
    // async (formData: PriceListSiteQueryList, thunkApi) => {
        try {
            console.log("slice getData : "+ formData.validto!)
            const result = await httpClient.post<PricelistSite[]>(server.PRICELISTSITE_URL, formData);
            // const result = await httpClient.post<PriceListSiteQueryList[]>(server.PRICELISTSITE_URL+"/data", formData);
            console.log("slice getData result : "+ result.data.length!)
            return result;
        } catch (error: any) {

            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const MonitorJobHistoryGetDataList = createAsyncThunk(
    "monitorJobHistory/getMonitorJoblist",
    // async ({keyword,type},{keyword: string,type:Number}, thunkApi) => {
    async (formData: PriceListSiteQueryList, thunkApi) => {
        try {
            // console.log("slice siteCode2 : "+ formData.siteCode.length)
            // const result = await httpClient.get<PriceListSiteQueryList[]>(server.PRICELISTSITE_URL + "/list/?keyword=" + formData);
            const result = await httpClient.post<PriceListSiteQueryList[]>(server.PRICELISTSITE_URL+"/data", formData);
            // console.log("slice post : "+ result.data.length!)
            return result;
        } catch (error: any) {

            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const MonitorJobHistoryGetDataById = createAsyncThunk(
    "monitorJobHistory/getMonitorJobHistoryByID",
    async (id: string, thunkApi) => {
        try {
            const result = await httpClient.get<PricelistSite>(`${server.PRICELISTSITE_URL}/${id}`);
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const pricelistUpdateDataPOS = createAsyncThunk(
    "monitorJobHistory/putpricelistSitePOS",
    // async (formData: Pricelist, thunkApi) => {
    async ({id,keyword}:{id: string,keyword:PriceListSiteQueryList} , thunkApi) => {
        try {
            const result1 = await httpClient.put(`${server.PRICELISTSITE_URL}/pos/${id}`);

            // let keyword="";
            // console.log("date from:" + keyword.validfrom + " to :" + keyword.validto)
            const result = await httpClient.post<PriceListSiteQueryList[]>(server.PRICELISTSITE_URL+"/data", keyword);
            // const result = await httpClient.get<PricelistSite[]>(server.PRICELISTSITE_URL + "?keyword=" + keyword);
            return result;

        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const pricelistUpdateDataSAP = createAsyncThunk(
    "monitorJobHistory/putpricelistSiteSAP",
    // async (formData: Pricelist, thunkApi) => {
        async ({id,keyword}:{id: string,keyword:PriceListSiteQueryList} , thunkApi) => {
        try {
            const result1 = await httpClient.put(`${server.PRICELISTSITE_URL}/sap/${id}`);
            
            // let keyword="";
            // console.log("date from:" + keyword.validfrom + " to :" + keyword.validto)
            const result = await httpClient.post<PriceListSiteQueryList[]>(server.PRICELISTSITE_URL+"/data", keyword);
            // const result = await httpClient.get<PricelistSite[]>(server.PRICELISTSITE_URL + "?keyword=" + keyword);
            return result;


        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);
export const pricelistUpdateDataWFP = createAsyncThunk(
    "monitorJobHistory/putpricelistSiteWFP",
    // async (formData: Pricelist, thunkApi) => {
        async ({id,keyword}:{id: string,keyword:PriceListSiteQueryList} , thunkApi) => {
        try {
            const result1 = await httpClient.put(`${server.PRICELISTSITE_URL}/wfp/${id}`);
            
             // let keyword="";
            // console.log("date from:" + keyword.validfrom + " to :" + keyword.validto)
            const result = await httpClient.post<PriceListSiteQueryList[]>(server.PRICELISTSITE_URL+"/data", keyword);
            // const result = await httpClient.get<PricelistSite[]>(server.PRICELISTSITE_URL + "?keyword=" + keyword);
            return result;


        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);


const monitorJobHistorySlice = createSlice({
    name: "MonitorJobHistory",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(MonitorJobHistoryGetData.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(MonitorJobHistoryGetData.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(MonitorJobHistoryGetData.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
            builder
            .addCase(MonitorJobHistoryGetDataList.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(MonitorJobHistoryGetDataList.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(MonitorJobHistoryGetDataList.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
            builder
            .addCase(MonitorJobHistoryGetList.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(MonitorJobHistoryGetList.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(MonitorJobHistoryGetList.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
            builder
            .addCase(pricelistUpdateDataSAP.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistUpdateDataSAP.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistUpdateDataSAP.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
            builder
            .addCase(pricelistUpdateDataPOS.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistUpdateDataPOS.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistUpdateDataPOS.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
            builder
            .addCase(pricelistUpdateDataWFP.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistUpdateDataWFP.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(pricelistUpdateDataWFP.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });
    }
})

export const { } = monitorJobHistorySlice.actions;
export const MonitorJobHistorySelector = (store: RootState) => store.monitorJobHistoryReducer;
export default monitorJobHistorySlice.reducer;