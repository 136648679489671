import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, FormLabel, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { siteActionSelector, siteCreateData } from "../../../store/slices/siteActionSlice";
import { Site } from "../../../types/site.type";
import Loader from "../../component/Loader";


type SiteCreatePageProps = {
  //
};

const initialValues: Site = { id: "", siteCode: "", siteName: "",  active: true ,isScale:true, createdBy: "" };

const SiteCreatePage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const siteActionReducer = useSelector(siteActionSelector);


  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<Site>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Create Site
            </Typography>

            <TextField id="siteCode"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('Site Code')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.siteCode && touched.siteCode
                  ? errors.siteCode
                  : ''
              }
              error={
                errors.siteCode && touched.siteCode
                  ? true
                  : false
              } />

            <TextField id="siteName"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('Site Name')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.siteName && touched.siteName
                  ? errors.siteName
                  : ''
              }
              error={
                errors.siteName && touched.siteName
                  ? true
                  : false
              } />


            {/* <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="siteGroupId">{t('siteGroupId')}</InputLabel>
              <Select
                name="siteGroupId"
                onChange={(event) => { setFieldValue('siteGroupId', event.target.value) }}
                error={
                  errors.siteGroupId && touched.siteGroupId
                    ? true
                    : false
                }
              >
                {siteGroupReducer.result.map((item, index) => (
                  <MenuItem key={item.siteGroupCode} value={item.siteGroupCode}>
                    {item.siteGroupName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
            <FormLabel>Active</FormLabel>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
            <FormLabel>Scale Price</FormLabel>
              <RadioGroup row name="isScale" onChange={(event) => { setFieldValue("isScale", event.currentTarget.value === "true" ? true : false) }} value={values.isScale}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {siteActionReducer.isError && <Alert severity="error">{siteActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={siteActionReducer.isFetching}
            >
              {t('btnCreate')}
            </Button>
            <Button component={Link} to="/site" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };

  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    siteCode: Yup.string().required(inputRequired),
    siteName: Yup.string().required(inputRequired),
  });


  if (siteActionReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {

          dispatch(siteCreateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default SiteCreatePage;
