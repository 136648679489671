import * as React from "react";
import { DataGridPro, GridColDef, GridRenderCellParams, } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Fab, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Add, Clear, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "@react-hook/debounce";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch } from "../../../store/store";
import { siteGetData, siteSelector } from "../../../store/slices/siteSlice";
import Loader from "../../component/Loader";

type Props = {}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}


export default function SitePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const siteReducer = useSelector(siteSelector);
  const [keywordSearch, setKeywordSearch] = useDebounce<string>("", 1000); // Delay key search
  const [keywordSearchNoDelay, setKeywordSearchNoDelay] = React.useState<string>("");
  const inputSearch = t('inputSearch');

  React.useEffect(() => {
    dispatch(siteGetData(keywordSearch));
  }, [keywordSearch])



  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box sx={{ p: 0.5, pb: 0, }}>
        <div style={{height:'60px'}}></div>

        <Fab
          color="primary"
          aria-label="add"
          component={Link}
          to="/site/create"
          sx={{ position: "absolute", top: 10, right: 10, }}>
          <Add />
        </Fab>
      </Box>
    );
  }

  const columns: GridColDef[] = [
    {
      headerName: "",
      field: ".",
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<string>) => (
        <Stack direction="row">
          <IconButton
            aria-label="edit"
            size="large"
            onClick={() => {
              navigate("/site/edit/" + row.id);
            }}
          >
            <EditIcon fontSize="inherit" style={{ color: "#43A047" }} />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "siteCode", width: 150,
      renderHeader: () => (<strong> {t('siteCode')} </strong>),
    },
    {
      field: "siteName", width: 200,
      renderHeader: () => (<strong> {t('siteName')} </strong>),
    },
    {
      field: "active", type: "boolean", width: 100,
      renderHeader: () => (<strong> {t('active')} </strong>),
    },
    {
      field: "isScale", type: "boolean", width: 100,
      renderHeader: () => (<strong> {t('ScalePrice')} </strong>),
    },
  ];


  if (siteReducer.isFetching) {
    return <><Loader /></>
  }


  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          SITE
        </Typography>
        <DataGridPro
          sx={{ backgroundColor: "white", height: "80vh" }}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: keywordSearchNoDelay,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywordSearch(e.target.value);
                setKeywordSearchNoDelay(e.target.value);
              },
              clearSearch: () => {
                setKeywordSearch("");
                setKeywordSearchNoDelay("");
              },
            },
          }}
          getRowId={(row) => row.id}
          rows={siteReducer.result!}
          columns={columns}
          // pageSize={10}
          pagination
          rowsPerPageOptions={[10]}
        />
      </CardContent>
    </Card>
  )
}