export class Actualreport {
    siteCode!: any;
    siteName!: string;
    itemCode!: string;
    priceListType!: string;
    active!: boolean;
}
export class ActualreportManual {
    siteCode!: any;
    siteName!: string;
    itemCode!: any;
    priceListType!: string;
    active!: boolean;
}

export class Actual {
    id!: string;
    buCode!: string;
    conditionType!: string;
    distributionChannel!: string;
    saleOrganize!: string;
    siteGroupCode!: string;
    siteCode!: string;
    itemCode!: string;
    itemName!: string;
    saleUnit!: string;
    vendor!: string;
    validFrom!: string;
    validTo!: string;
    amountInMargin: any
    amountInFinalPrice!: string;
    priceQTY1: any
    price1: any
    priceQTY2!: string;
    price2!: string;
    priceQTY3!: string;
    price3!: string;
    priceQTY4!: string;
    price4!: string;
    priceQTY5!: string;
    price5!: string;
    priceQTY6!: string;
    price6!: string;
    priceQTY7!: string;
    price7!: string;
    validFromLv7!: string;
    validToLv7!: string;
    message!:string;
}

export class ActualExportExcel {
    siteCode!: string;
    itemCode!: string;
    itemName!: string;
    saleUnit!: string;
    validFrom!: string;
    validTo!: string;
    conditionType!: string;
    amountInFinalPrice?: number | null;
    priceQTY1?: number | null;
    price1?: number | null;
    priceQTY2?: number | null;
    price2?: number | null;
    priceQTY3?: number | null;
    price3?: number | null;
    priceQTY4?: number | null;
    price4?: number | null;
    priceQTY5?: number | null;
    price5?: number | null;
}