import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { server } from "../../Constants";
import { Site } from "../../types/site.type";
import { httpClient } from "../../utils/httpclient";
import { RootState } from "../store";


type SiteState = {
    result: Site[];
    message: string;
    isFetching: boolean;
    isError: boolean;
}

const initialStateValues: SiteState = {
    result: [],
    message: "",
    isFetching: false,
    isError: false,
};


export const sitePostDataByRole = createAsyncThunk(
    "siterole/getSitesbyRole",
    async (keyword: string, thunkApi) => {
        try {
            const result = await httpClient.post<Site[]>(server.SITE_URL+"/role/sites");
            // console.log("result",result)
            return result;
        } catch (error: any) {
            let err = error.response.data.message ?? error.message
            return thunkApi.rejectWithValue(err);
        }
    }
);



const siteRoleSlice = createSlice({
    name: "site",
    initialState: initialStateValues,
    reducers: {},
    extraReducers: (builder) => {
        //async
        builder
            .addCase(sitePostDataByRole.pending, (state, action) => {
                state.result = [];
                state.message = "Loading...";
                state.isFetching = true;
                state.isError = false;
            })
            .addCase(sitePostDataByRole.fulfilled, (state, action: PayloadAction<any>) => {
                state.result = action!.payload.data;
                state.message = "Success";
                state.isFetching = false;
                state.isError = false;
            })
            .addCase(sitePostDataByRole.rejected, (state, action: PayloadAction<any>) => {
                state.result = [];
                state.message = action.payload;
                state.isFetching = false;
                state.isError = true;
            });

    }
})


export const { } = siteRoleSlice.actions;
export const siteRoleSelector = (store: RootState) => store.siteRoleReducer;
export default siteRoleSlice.reducer;