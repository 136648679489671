import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet ,useNavigate } from "react-router-dom";
import { loginSelector } from "../store/slices/loginSlice";
import {useLocation}from "react-router";

const Special2Routes = (props: any) => {
    const loginReducer = useSelector(loginSelector);
    const auth = loginReducer.result;
    const prevLocation = useLocation();
    const location = useLocation();
    const navigate = useNavigate();

    
    // return auth  ?<Navigate to="/approve" />:<Navigate to="/login" />;
    return auth  ? <Navigate to="/pricelistscale" />:<Navigate to="/login"/>;
 
};

export default Special2Routes;