import * as React from "react";
import { Card, CardContent, Typography, CardActions, Button, Box, FormControl, MenuItem, FormControlLabel, Radio, InputLabel, Alert, } from "@mui/material";
import { Formik, FormikProps, Form, } from "formik";
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { Link, useMatch } from "react-router-dom";
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store/store";
import { roleGetData, roleSelector } from "../../../store/slices/roleSlice";
import { userActionSelector, userGetDataById, userUpdateData } from "../../../store/slices/userActionSlice";
import { User } from "../../../types/user.type";
import Loader from "../../component/Loader";


type UserEditPageProps = {
  //
};


const UserApiEditPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const roleReducer = useSelector(roleSelector);
  const userActionReducer = useSelector(userActionSelector);
  const match = useMatch("/userapi/edit/:id");
  const initialValues: User = { id: "", username: "", password: "", fullname: "", email: "", emailCC: "",approveLevel:1,isAd: false, roleId: null, active: true,remark:"" };
 

  React.useEffect(() => {
    dispatch(roleGetData(""));
  }, [])


  React.useEffect(() => {
    let id = match?.params.id;
    dispatch(userGetDataById(id!));
  }, [])

  // const generatePassword = () => {
  //   const result = Math.random().toString(36).substring(2, 16);
  //   setPassword(result);

  // }

  const showForm = ({ values, setFieldValue, isSubmitting, touched, errors, handleChange, handleBlur }: FormikProps<User>) => {
    return (
      <Form>
        <Card>
          <CardContent sx={{ padding: 4 }}>
            <Typography gutterBottom variant="h5">
              Edit UserApi
            </Typography>

            <TextField id="username"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('username')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              helperText={
                errors.username && touched.username
                  ? errors.username
                  : ''
              }
              error={
                errors.username && touched.username
                  ? true
                  : false
              } />

            


            <TextField id="fullname"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('fullname')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullname}
              helperText={
                errors.fullname && touched.fullname
                  ? errors.fullname
                  : ''
              }
              error={
                errors.fullname && touched.fullname
                  ? true
                  : false
              } />

            <TextField id="email"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('email')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              helperText={
                errors.email && touched.email
                  ? errors.email
                  : ''
              }
              error={
                errors.email && touched.email
                  ? true
                  : false
              } />

<TextField id="emailCC"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('email')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.emailCC}
              helperText={
                errors.emailCC && touched.emailCC
                  ? errors.emailCC
                  : ''
              }
              error={
                errors.emailCC && touched.emailCC
                  ? true
                  : false
              } />

            <FormControl style={{ marginTop: 16 }} fullWidth>
              <InputLabel htmlFor="roleId">{t('selectRole')}</InputLabel>
              <Select
                name="roleId"
                value={values.roleId}
                onChange={(event) => { setFieldValue('roleId', event.target.value) }}
                error={
                  errors.roleId && touched.roleId
                    ? true
                    : false
                }
              >
                {roleReducer.result.map((item, index) => (
                  <MenuItem key={item.roleId} value={item.roleId}>
                    {item.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <TextField id="remark"
              style={{ marginTop: 16 }}
              fullWidth
              variant="outlined"
              label={t('remark')}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={''
              }
              error={
                errors.email && touched.email
                  ? true
                  : false
              } />


            <FormControl component="fieldset" style={{ marginTop: 16 }} fullWidth>
              <RadioGroup row name="active" onChange={(event) => { setFieldValue("active", event.currentTarget.value === "true" ? true : false) }} value={values.active}>
                <FormControlLabel control={<Radio />} value={true} label={t('active')} />
                <FormControlLabel control={<Radio />} value={false} label={t('inactive')} />
              </RadioGroup>
            </FormControl>

            {userActionReducer.isError && <Alert severity="error">{userActionReducer.message}</Alert>}

          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 1 }}
              disabled={userActionReducer.isFetching}
            >
              {t('btnEdit')}
            </Button>
            <Button component={Link} to="/user" variant="outlined" fullWidth>
              {t('btnCancel')}
            </Button>
          </CardActions>
        </Card>
      </Form>
    );
  };


  const inputRequired = t('inputRequired');
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(inputRequired),
    fullname: Yup.string().required(inputRequired),
    email: Yup.string().email('Invalid email').required(inputRequired),
    roleId: Yup.string().required(inputRequired),
  });


  if (userActionReducer.isFetching) {
    return <><Loader /></>
  }

  return (
    <Box>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={userActionReducer.result ? userActionReducer.result! : initialValues}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(userUpdateData(values));
          setSubmitting(false);
        }}
      >
        {(props: any) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default UserApiEditPage;
